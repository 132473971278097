import React from 'react';
import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';

import {SanitizeHTML} from '@components';

export const LoginMessages = (props) => {
    const {messages} = props;
    messages?.sort((a, b) => a?.MsgType - b?.MsgType);

    return <>
        {messages?.map((item, index) => (
            <Alert
                className="mb-3"
                type={item.MsgType ? "warning" : "error"}
                icon={item.MsgType ? <InfoCircleFilled /> : <ExclamationCircleFilled />}
                message={<SanitizeHTML html={item.Message}/>}
                key={index}
            />
        ))}
    </>;
};