import React, {useEffect, useState} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {i18, Logout, secondsToMinuteString, trb} from "@utilities";
import {Modal} from "antd";
import PropTypes from "prop-types";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";


export const IdleModal = () => {
    // First wait for timeout then show modal
    const timeout = parseInt(process.env.REACT_APP_IDLE_TIME)
    // After show modal timeout until logout
    const promptBeforeIdle = parseInt(process.env.REACT_APP_IDLE_MODAL)

    // Modal open state
    const [isOpen, setOpen] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(0)

    const IframeStorageReducer = useSelector(state => state.IframeStorageReducer)

    const onPrompt = () => {
        const user = IframeStorageReducer?.user

        if (user === null) {
            return null
        }
        // onPrompt will be called after the timeout value is reached
        // In this case 30 minutes. Here you can open your prompt.
        // All events are disabled while the prompt is active.
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        if (!!user && !!user?.Bearer) {
            setOpen(true)
            setRemaining(promptBeforeIdle)
        }
    }

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // Here you can close your prompt and
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.
        setOpen(false)
        handleLogout()
    }

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted()`
        // is true. Here you will also want to close your modal and perform
        // any active actions.
        setOpen(false)
        setRemaining(0)
    }

    const {getRemainingTime, isPrompted, activate} = useIdleTimer({
        timeout,
        promptBeforeIdle,
        onPrompt,
        onIdle,
        onActive
    })

    const handleStillHere = () => {
        setOpen(false)
        activate()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])

    const handleLogout = async () => {
        await Logout();
    };

    return (
        <>
            {isOpen && (
                <Modal
                    visible
                    onOk={handleStillHere}
                    onCancel={handleLogout}
                    centered
                    closable={false}
                    okText={trb(i18.Buttons.ContinueWork)}
                    cancelText={trb(i18.Buttons.Logout)}
                    className="bbs_logout_modal">
                    <div className="d-flex">
                        <div className="pl-3 pr-4 pt-2">
                            <QuestionCircleOutlined style={{fontSize: '24px', color: 'orange'}}/>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="pt-2">
                                <h5>{trb(i18.ModalTitles.SessionGoesEnd)}</h5>
                            </div>
                            <div>
                                <p dangerouslySetInnerHTML={{__html: trb(i18.Messages.SessionGoesEndTimeLeft2, {timeLeft: secondsToMinuteString(remaining)})}}/>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

IdleModal.propTypes = {
    onLogout: PropTypes.func
}
