import stringInject from 'stringinject';
import {checkJsonTranslateProp} from './';
import {TranslationContexts, TranslationsAction, store} from '../Redux';

function translationFunction(context, id, params) {
    const state = store.getState();
    const {translations} = state.translationReducer;
    const {language} = state.languageReducer;

    if (!id) {
        return
    }

    let paths = id.replace(/\./, '-i18-').split('-i18-');

    if (!translations) {
        return paths[1];
    }

    let trans = translations.find(e => e.context === context && e.culture === language);

    if (trans === undefined) {
        return paths[1];
    }

    let tr = checkJsonTranslateProp(trans.Groups, id);
    let tr2 = checkJsonTranslateProp(trans.Groups, paths[0]);

    if(context && language && paths[0] && paths[1]) {
        
        if (tr === null && tr2 === null) {
            if (context !== TranslationContexts.classificators && paths.length === 2) {
                TranslationsAction.getCreateTranslation(context, language, paths[0], paths[1]);
            }
        } else if (tr === null && (tr2 === undefined || tr2[paths[1]] === undefined)) {
            if (context !== TranslationContexts.classificators && paths.length === 2) {
                TranslationsAction.getCreateTranslation(context, language, paths[0], paths[1]);
            }
        }
    }

    if (tr === null) {
        tr = id;
    }
    if (params) {
        return stringInject(tr, params)
    }
    return tr;
}


export const trc = (id) => {
    return translationFunction(TranslationContexts.classificators, id);
};

export const trb = (id, params) => {
    // return id;
    return translationFunction(TranslationContexts.eBank, id, params);
};
