import PropTypes from 'prop-types'

import {
  i18,
  translationGroups,
  appEnvSME,
  trb,
  TARGET2_PAYMENT_SYSTEM,
  getClassificators
} from '@utilities'
import { FormPreviewSwitch } from '../FormPreviewSwitch'
import {
  payerPayeeTypeCurrent,
  paymentPurposeTypeCurrent,
  paymentPaymentSystemType
} from '../FormStaticOptions'

export const NewPaymentPreview = props => {
  if (!props?.data?.Payment) {
    return null
  }

  const CISO = getClassificators()?.CISO || {}
  if (props.data?.Payment?.PaymentSystem === TARGET2_PAYMENT_SYSTEM) {
    return {
      parts: [
        {
          fields: [
            {
              title: i18.Labels.FromAccount,
              value: props.data.InitialAccount
            },
            {
              title: i18.Labels.PaymentPurpose,
              value: props.data.Payment.Purpose
              ? props.data.Payment.Purpose
              : props.data.Payment.ReferenceNo
            },
            {
              title: i18.Labels.Amount,
              value: props.data.Payment.AmountFormatted
            },
            {
              title: i18.Labels.Commission,
              value: props.data.Payment.CommissionFeeFormatted
            },
            {
              title: i18.Labels.BankTaxesPays,
              value: trb(i18.Messages.SHARecipientAndPayer)
            }
          ]
        },
        {
          fields: [
            {
              title: i18.Labels.Payee,
              value: props.data.Payment?.Recipient
            },
            {
              title: i18.Labels.ToAccount,
              value: props.data.Payment.BeneficiaryAccountNumber
            },
            {
              title: i18.Labels.BeneficiaryAddress,
              value: props.data.Payment.BeneficiaryAddress
            },
            {
              title: i18.Labels.BeneficiaryCity,
              value: props.data.Payment.BeneficiaryCity
            },
            {
              title: i18.Labels.BeneficiaryPostcode,
              value: props.data.Payment.BeneficiaryPostCode
            },
            {
              title: i18.Labels.BeneficiaryCountryCode,
              value: CISO[props.data.Payment.BeneficiaryCountryCode]
            },
            {
              title: i18.Labels.PayeeBankName,
              value: props.data.Payment.BankName
            },
            {
              title: i18.Labels.BIC,
              value: props.data.Payment.BIC
            },
            {
              title: i18.Labels.RecipientBankCountry,
              value: CISO[props.data.Payment.RecipientBankCountry]
            },
            {
              title: i18.Labels.CorrespondentBankBic,
              value: props.data.Payment.CorrespondentBankBic
            }
          ]
        }
      ]
    }
  }
  return appEnvSME
    ? {
        parts: [
          {
            title: i18.PaymentParts.Payee,
            fields: [
              {
                title: i18.Labels.FromAccount,
                value: props.data.InitialAccount
              },
              {
                title: i18.Labels.PayeeTitle,
                value: props.data.Payment?.Recipient
              },
              {
                title: i18.Labels.PayeeAccount,
                value: props.data.Payment.BeneficiaryAccountNumber
              },
              {
                title: i18.Labels.Amount,
                value: props.data.Payment.AmountFormatted
              },
              /* {
                title: i18.Labels.PaymentType,
                value: paymentPaymentSystemType(props.data.Payment.PaymentSystem),
                type:'translate',
                translate: translationGroups.PaymentTypeSepa,
              }, */
              {
                title: i18.Labels.Commission,
                value: props.data.Payment.CommissionFeeFormatted
              },
              {
                title: i18.Labels.PurposeType,
                value: paymentPurposeTypeCurrent(
                  props.data.Payment.IsPurposeSelected
                ),
                type: 'translate',
                translate: translationGroups.PaymentPurposeTypes,
                visible:
                  props.data.Payment.PaymentSystem !== TARGET2_PAYMENT_SYSTEM
              },
              {
                title: i18.Labels.Purpose,
                value: props.data.Payment.Purpose
                  ? props.data.Payment.Purpose
                  : props.data.Payment.ReferenceNo
              },
              {
                title: i18.Labels.PaymentUniqueCode,
                value: props.data.Payment.CustomersCode
              },
              {
                title: i18.Labels.BankName,
                value: props.data.Payment.RecipientBankName
              },
              {
                title: i18.Labels.BIC,
                value: props.data.Payment.BIC
              },
              {
                title: i18.Labels.PaymentType,
                value: paymentPaymentSystemType(
                    props.data.Payment.PaymentSystem
                ),
                type: 'translate',
                translate: translationGroups.PaymentTypeSepa
              },
              {
                title: i18.Labels.CorrespondentBankBic,
                value: props.data.Payment.CorrespondentBankBic,
                visible:
                  props.data.Payment.PaymentSystem === TARGET2_PAYMENT_SYSTEM
              },
              {
                title: i18.Labels.BankTaxesPays,
                value: trb(i18.Messages.SHARecipientAndPayer),
                visible:
                  props.data.Payment.PaymentSystem === TARGET2_PAYMENT_SYSTEM
              },
              {
                title: i18.Labels.BeneficiaryCountryCode,
                value: CISO[props.data.Payment.BeneficiaryCountryCode]
              },
              {
                title: i18.Labels.BeneficiaryCity,
                value: props.data.Payment.BeneficiaryCity
              },
              {
                title: i18.Labels.BeneficiaryAddress,
                value: props.data.Payment.BeneficiaryAddress
              },
              {
                title: i18.Labels.BeneficiaryPostcode,
                value: props.data.Payment.BeneficiaryPostCode
              },
              {
                title: i18.Labels.BankName,
                value: props.data.Payment.BankName,
                visible:
                  props.data.Payment.PaymentSystem === TARGET2_PAYMENT_SYSTEM
              },
              {
                title: i18.Labels.RecipientBankCountry,
                value: CISO[props.data.Payment.RecipientBankCountry]
              }
            ]
          },
          {
            title: i18.PaymentParts.PayerInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.PayerType,
                value: payerPayeeTypeCurrent(props.data.Payment.PayerType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes && props.data.Payment.PayerIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e => e.Code === props.data.Payment.PayerIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.PayerType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.PayerIdentTypeValue,
                visible: !!props.data.Payment.PayerType
              },
              {
                title: i18.Labels.PayerAddress,
                value: props.data.Payment.PayerAddress,
                visible: !!props.data.Payment.PayerType
              }
            ]
          },
          {
            title: i18.PaymentParts.InitialPayerInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.InitPayerType,
                value: payerPayeeTypeCurrent(props.data.Payment.InitPayerType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.InitPayerTitle,
                value: props.data.Payment.InitPayerName,
                visible: !!props.data.Payment.InitPayerType
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes &&
                  props.data.Payment.InitPayerIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e =>
                          e.Code === props.data.Payment.InitPayerIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.InitPayerType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.InitPayerIdentTypeValue,
                visible: !!props.data.Payment.InitPayerType
              }
            ]
          },
          {
            title: i18.PaymentParts.PayeeInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.PayeeType,
                value: payerPayeeTypeCurrent(props.data.Payment.PayeeType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes && props.data.Payment.PayeeIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e => e.Code === props.data.Payment.PayeeIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.PayeeType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.PayeeIdentTypeValue,
                visible: !!props.data.Payment.PayeeType
              },
              {
                title: i18.Labels.PayeeAddress,
                value: props.data.Payment.PayeeAddress,
                visible: !!props.data.Payment.PayeeType
              }
            ]
          },
          {
            title: i18.PaymentParts.InitialPayeeInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.FinalPayeeType,
                value: payerPayeeTypeCurrent(props.data.Payment.FinalPayeeType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.FinalPayeeTitle,
                value: props.data.Payment.FinalPayeeName,
                visible: !!props.data.Payment.FinalPayeeType
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes &&
                  props.data.Payment.FinalPayeeIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e =>
                          e.Code === props.data.Payment.FinalPayeeIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.FinalPayeeType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.FinalPayeeIdentTypeValue,
                visible: !!props.data.Payment.FinalPayeeType
              }
            ]
          }
        ]
      }
    : {
        parts: [
          {
            title: i18.PaymentParts.Payer,
            fields: [
              {
                title: i18.Labels.FromAccount,
                value: props.data.InitialAccount
              }
            ]
          },
          {
            title: i18.PaymentParts.Payee,
            fields: [
              {
                title: i18.Labels.PayeeTitle,
                value: props.data.Payment.Recipient
              },
              {
                title: i18.Labels.PayeeAccount,
                value: props.data.Payment.BeneficiaryAccountNumber
              },
              {
                title: i18.Labels.Amount,
                value: props.data.Payment.AmountFormatted
              },
              {
                title: i18.Labels.PaymentType,
                value: paymentPaymentSystemType(
                  props.data.Payment.PaymentSystem
                ),
                type: 'translate',
                translate: translationGroups.PaymentTypeSepa
              },
              {
                title: i18.Labels.Commission,
                value: props.data.Payment.CommissionFeeFormatted
              },
              {
                title: i18.Labels.PurposeType,
                value: paymentPurposeTypeCurrent(
                  props.data.Payment.IsPurposeSelected
                ),
                type: 'translate',
                translate: translationGroups.PaymentPurposeTypes
              },
              {
                title: i18.Labels.Purpose,
                value: props.data.Payment.Purpose
                  ? props.data.Payment.Purpose
                  : props.data.Payment.ReferenceNo
              },
              {
                title: i18.Labels.PaymentUniqueCode,
                value: props.data.Payment.CustomersCode
              },
              {
                title: i18.Labels.BankName,
                value: props.data.Payment.RecipientBankName
              }
            ]
          },
          {
            title: i18.PaymentParts.PayerInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.PayerType,
                value: payerPayeeTypeCurrent(props.data.Payment.PayerType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes && props.data.Payment.PayerIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e => e.Code === props.data.Payment.PayerIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.PayerType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.PayerIdentTypeValue,
                visible: !!props.data.Payment.PayerType
              },
              {
                title: i18.Labels.PayerAddress,
                value: props.data.Payment.PayerAddress,
                visible: !!props.data.Payment.PayerType
              }
            ]
          },
          {
            title: i18.PaymentParts.InitialPayerInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.InitPayerType,
                value: payerPayeeTypeCurrent(props.data.Payment.InitPayerType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.InitPayerTitle,
                value: props.data.Payment.InitPayerName,
                visible: !!props.data.Payment.InitPayerType
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes &&
                  props.data.Payment.InitPayerIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e =>
                          e.Code === props.data.Payment.InitPayerIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.InitPayerType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.InitPayerIdentTypeValue,
                visible: !!props.data.Payment.InitPayerType
              }
            ]
          },
          {
            title: i18.PaymentParts.PayeeInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.PayeeType,
                value: payerPayeeTypeCurrent(props.data.Payment.PayeeType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes && props.data.Payment.PayeeIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e => e.Code === props.data.Payment.PayeeIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.PayeeType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.PayeeIdentTypeValue,
                visible: !!props.data.Payment.PayeeType
              },
              {
                title: i18.Labels.PayeeAddress,
                value: props.data.Payment.PayeeAddress,
                visible: !!props.data.Payment.PayeeType
              }
            ]
          },
          {
            title: i18.PaymentParts.InitialPayeeInformation,
            visible:
              props.data.oldData.Payment.IsAdditionalPaymentInformationSelected,
            fields: [
              {
                title: i18.Labels.FinalPayeeType,
                value: payerPayeeTypeCurrent(props.data.Payment.FinalPayeeType),
                type: 'translate',
                translate: translationGroups.PayerPayeeTypes
              },
              {
                title: i18.Labels.FinalPayeeTitle,
                value: props.data.Payment.FinalPayeeName,
                visible: !!props.data.Payment.FinalPayeeType
              },
              {
                title: i18.Labels.IdentificationType,
                value:
                  props.PayPersonTypes &&
                  props.data.Payment.FinalPayeeIdentTypeCode
                    ? props.PayPersonTypes.find(
                        e =>
                          e.Code === props.data.Payment.FinalPayeeIdentTypeCode
                      ).Text
                    : null,
                visible: !!props.data.Payment.FinalPayeeType
              },
              {
                title: i18.Labels.IdentificationCode,
                value: props.data.Payment.FinalPayeeIdentTypeValue,
                visible: !!props.data.Payment.FinalPayeeType
              }
            ]
          }
        ]
      }
}

FormPreviewSwitch.propTypes = {
  data: PropTypes.object.isRequired
}
