import React from "react";
import PropTypes from "prop-types";
import {Button} from "antd";
import {trb} from "@utilities";

const ButtonGroup = Button.Group;

export const EBTableRowSelectionContentButtons = ({headerClassName, buttons, item}) => {

    return (
        <div className={headerClassName}>
            <ButtonGroup className="d-none d-lg-block">
                {buttons.map((button, index) =>
                    <Button
                        key={index}
                        icon={button.icon}
                        className={button.className}
                        onClick={() => button.onClick(item)}
                        loading={button.loading}
                        disabled={button.loading}
                    >{button.text ? trb(button.text) : null}</Button>
                )}
            </ButtonGroup>
            <div className="d-flex d-lg-none justify-content-between col-12 pr-0">
              {buttons.map((button, index) =>
                    <Button
                        key={index}
                        icon={button.icon}
                        className={button.className + ' mt-1'}
                        onClick={() => button.onClick(item)}
                        loading={button.loading}
                        disabled={button.loading}
                    >{button.text ? trb(button.text) : null}</Button>
                )}
            </div>
        </div>
    );
};

EBTableRowSelectionContentButtons.propTypes = {
    headerClassName: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        classname: PropTypes.string,
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    })).isRequired,
};
