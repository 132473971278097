export const DepositsTerms = `<table width='98%' style='border: 1px solid black;border-collapse: collapse;'>
            <tbody>
            <tr>
                <td colSpan='2' width='100%' style='border: 1px solid black;'>
                    <p><strong>Pagrindinė informacija apie indėlio apsaugą</strong></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>UAB SME Bank laikomi indėliai yra draudžiami</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>valstybės įmonėje „Indėlių ir investicijų draudimas“</p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Apsaugos riba</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>iki 100 000 eurų vienam indėlininkui už visus vienoje kredito įstaigoje laikomus indėlius<sup>1</sup></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Jei turite daugiau indėlių toje pačioje kredito įstaigoje</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>visi jūsų indėliai, laikomi toje pačioje kredito įstaigoje, susumuojami ir bendrai sumai taikoma 100 000 eurų apsaugos riba<sup>1 </sup></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Jei turite bendrą sąskaitą su kitu (kitais) asmeniu (asmenimis)</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>100&nbsp;000 eurų riba atskirai taikoma kiekvienam indėlininkui<sup>2</sup></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Indėlių draudimo i&scaron;mokos i&scaron;mokėjimo terminas, jeigu kredito įstaiga nevykdo įsipareigojimų</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>10 darbo dienų<sup>3</sup></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Indėlių draudimo i&scaron;mokos i&scaron;mokėjimo valiuta</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>eurai</p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Kontaktiniai duomenys</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p>valstybės įmonė „Indėlių ir investicijų draudimas“</p>
                    <p>Algirdo g. 31, LT-03219 Vilnius</p>
                    <p>telefonas (8 5) 213 5657</p>
                    <p>faksas (8 5) 213 5546</p>
                    <p>el. paštas <a target='_blank' href='mailto:idf@idf.lt'>idf@idf.lt</a></p>
                </td>
            </tr>
            <tr>
                <td width='42%' style='border: 1px solid black;'>
                    <p>Daugiau informacijos</p>
                </td>
                <td width='57%' style='border: 1px solid black;'>
                    <p><a target='_blank' href='https://www.iidraudimas.lt'>www.iidraudimas.lt</a></p>
                </td>
            </tr>
            </tbody>
        </table>

        <p></p>
        <p><strong>Pastabos:</strong></p>
        <p><sup>1 </sup>Jeigu indėlis negrąžinamas dėl to, kad kredito įstaiga nepajėgi įvykdyti savo finansinių įsipareigojimų, indėlininkams valstybės įmonė &bdquo;Indėlių ir investicijų draudimas&ldquo; i&scaron;moka indėlių draudimo
            i&scaron;mokas. Didžiausia indėlių, laikomų vienoje kredito įstaigoje, draudimo i&scaron;mokos suma vienam indėlininkui yra 100&nbsp;000 eurų. Nustatant indėlių draudimo i&scaron;mokos dydį, visi toje pačioje kredito įstaigoje laikomi
            indėliai yra susumuojami. Pavyzdžiui, jeigu indėlininkas turi taupomąją sąskaitą, kurioje yra 90 000 eurų, ir einamąją sąskaitą, kurioje yra 20 000 eurų, jam bus i&scaron;mokama tik 100 000 eurų draudimo i&scaron;moka.</p>
        <p>Tam tikrais atvejais (kai į indėlininko sąskaitą ne anksčiau kaip prie&scaron; 6 mėnesius iki indėlių draudžiamojo įvykio dienos pervesti pinigai už parduotą indėlininkui nuosavybės teise priklausiusį gyvenamąjį būstą; indėlininko
            paveldėti pinigai pagal testamentą arba pagal įstatymą; indėlininko kaip naudos gavėjo pagal gyvybės draudimo sutartį ar jos esmę atitinkančią sutartį mirties atveju gauti pinigai; indėlininko kaip kompensacija arba i&scaron;moka įstatymų
            nustatytais atvejais dėl kito asmens žūties atliekant darbo ar tarnybines pareigas gauti pinigai; i&scaron;mokos ar kompensacijos už smurtiniais nusikaltimais padarytą žalą) indėliai apsaugomi didesne kaip 100&nbsp;000 eurų suma.</p>
        <p>Daugiau informacijos galima rasti interneto svetainėje adresu: <a href='http://www.iidraudimas.lt'>www.iidraudimas.lt</a>.</p>
        <p><sup>2 </sup>Jeigu turite bendrą sąskaitą su kitu (kitais) asmeniu (asmenimis), 100 000 eurų apsaugos riba atskirai taikoma kiekvienam indėlio bendraturčiui.</p>
        <p><sup>3 </sup>Draudimo i&scaron;mokų i&scaron;mokėjimas.</p>
        <p>Atsakinga už indėlių draudimo sistemą yra valstybės įmonė &bdquo;Indėlių ir investicijų draudimas&ldquo; (Algirdo g. 31, LT-03219 Vilnius, tel. (8 5) 213 5657, faks. (8 5) 213 5546, el. pa&scaron;tas: <a target='_blank' href='mailto:idf@idf.lt'>idf@idf.lt</a>, interneto svetainė: <a
            href='http://www.iidraudimas.lt'>www.iidraudimas.lt</a>). Ji grąžins jūsų indėlius (iki 100 000 eurų) ne vėliau kaip per:</p>
        <p>&ndash; 10 darbo dienų nuo 2021 m. sausio 1 d. iki 2023 m. gruodžio 31 d.;</p>
        <p>&ndash; 7 darbo dienas nuo 2024 m. sausio 1 d.</p>
        <p>Jei iki 2023 m. gruodžio 31 d. indėlių draudimo i&scaron;mokos nei&scaron;mokamos per 7 darbo dienas nuo indėlių draudžiamojo įvykio dienos, nuo 2016 m. gegužės 16 d. indėlininko pra&scaron;ymu jam per 5 darbo dienas nuo pra&scaron;ymo
            gavimo dienos i&scaron;mokama minimaliosios mėnesinės algos dydžio, bet ne didesnė negu indėlininko turimų tinkamų drausti indėlių suma, indėlių draudimo i&scaron;mokos dalis (avansinė indėlių draudimo i&scaron;moka).</p>
        <p>Jeigu per šiuos terminus draudimo išmoka jums nebuvo išmokėta, turėtumėte susisiekti su valstybės įmone „Indėlių ir investicijų draudimas“. Daugiau informacijos galima rasti interneto svetainėje adresu: <a href='http://www.iidraudimas.lt'>www.iidraudimas.lt</a></p>
        
        <p><strong>Kita svarbi informacija</strong></p>
        <p>Iš principo visi mažmeninių indėlininkų ir įmonių indėliai yra apdrausti valstybės įmonėje „Indėlių ir investicijų draudimas.“</p>
        <p>Tam tikriems indėliams taikomos išimtys nurodytos valstybės įmonės „Indėlių̨ ir investicijų ̨draudimas“ interneto svetainėje <a href='http://www.iidraudimas.lt'>www.iidraudimas.lt</a>. 
            Jūsų̨ paprašyta kredito įstaiga taip pat informuos jus, ar tam tikri produktai yra apdrausti. Jeigu indėliai apdrausti, kredito įstaiga tai patvirtina ir sąskaitos išraše.</p> 
        
        <p><strong>Atvejai, kai indėliai nėra draudžiami </strong><strong>ir kai yra taikomi indėlių draudimo i&scaron;mokų mokėjimo apribojimai</strong></p>
        <p><strong><u>Indėlių draudimo objektas nėra:</u></strong></p>
        <p>1) kitų kredito įstaigų indėliai, laikomi savo sąskaita ir savo vardu;</p>
        <p>2) kredito įstaigos nuosavos lė&scaron;os;</p>
        <p>3) indėliai, dėl kurių priimtas apkaltinamasis nuosprendis baudžiamojoje byloje dėl pinigų plovimo;</p>
        <p>4) finansų įstaigų indėliai;</p>
        <p>5) valdymo įmonių indėliai;</p>
        <p>6) finansų maklerio įmonių indėliai;</p>
        <p>7) indėliai, kurių savininkų tapatybė nenustatyta (anoniminėse ir koduotose sąskaitose laikomi indėliai);</p>
        <p>8) draudimo įmonių ir perdraudimo įmonių, kitose valstybėse narėse ir trečiosiose valstybėse įsteigtų draudimo įmonių ir perdraudimo įmonių indėliai;</p>
        <p>9) kolektyvinio investavimo subjektų indėliai;</p>
        <p>10) pensijų fondų indėliai;</p>
        <p>11) valstybės ir savivaldybių institucijų ir įstaigų, kaip jos apibrėžtos Lietuvos Respublikos valstybės tarnybos įstatyme, indėliai, i&scaron;skyrus tokių institucijų ir įstaigų depozitinėse sąskaitose laikomas kitiems asmenims
            priklausančias lė&scaron;as;</p>
        <p>12) Lietuvos banko indėliai;</p>
        <p>13) paties indėlių draudimo sistemos dalyvio i&scaron;leisti skolos vertybiniai popieriai ir įsipareigojimai, susiję su jo akceptais ir paprastaisiais vekseliais;</p>
        <p>14) elektroniniai pinigai ir i&scaron; elektroninių pinigų turėtojų mainams į elektroninius pinigus gautos lė&scaron;os.</p>
        <p>Aukščiau nurodytų subjektų indėliai nėra indėlių draudimo objektas, neatsižvelgiant į tai, pagal kurios valstybės teisės aktus šie subjektai yra įsteigti.</p>
        
        <p><strong><u>Indėlių draudimo i&scaron;mokų mokėjimo apribojimai:</u></strong></p>
        <p>Indėlių draudimo i&scaron;mokos nemokamos:</p>
        <p>1. už indėlius, lė&scaron;as, pinigus, vertybinius popierius ir įsipareigojimus, kurie nėra indėlių draudimo objektas (kaip nurodyta auk&scaron;čiau &scaron;ioje formoje);</p>
        <p>2. indėlininkams už indėlius sąskaitoje, kurioje per pastaruosius 24 mėnesius iki indėlių draudžiamojo įvykio dienos nėra atlikta jokių su indėliu susijusių operacijų ir laikomo indėlio suma yra mažesnė kaip 10 eurų.</p>
        <p>3. indėlininkams ar kitiems asmenims, kurie turi reikalavimo teisę į indėlių draudimo išmoką ir kuriems dėl jų laikomų kaip indėlių pinigų įsigijimo teisėtumo priimtas apkaltinamasis nuosprendis baudžiamojoje byloje dėl pinigų plovimo ir (ar) teroristų finansavimo;</p>
        
<!--        <p>Indėlių draudimo i&scaron;mokų i&scaron;mokėjimas stabdomas, kai indėlininkui ar kitam asmeniui, turintiems reikalavimo teisę į indėlių draudimo i&scaron;moką, yra pareik&scaron;tas kaltinimas dėl pinigų plovimo, iki galutinio teismo-->
<!--                sprendimo įsiteisėjimo.</p>-->
        <p><strong><u>Indėlių draudimo i&scaron;mokos i&scaron;mokėjimo termino atidėjimas:</u></strong></p>
        <p>Lietuvos Respublikos indėlių ir įsipareigojimų investuotojams įstatyme numatytais atvejais (plačiau žr. <a href='http://www.iidraudimas.lt'>www.iidraudimas.lt</a>) indėlių draudimo i&scaron;mokos i&scaron;mokėjimas gali būti atidedamas,
            pavyzdžiui, jeigu trūksta duomenų, kuriais pagrindžiama teisė į indėlių draudimo i&scaron;moką, arba dėl indėlio vyksta teisminis ginčas, arba indėlininkui apribota teisė disponuoti indėliu.</p>
        <p>&nbsp;</p>
    `