import React from 'react';
import {connect} from 'react-redux';
import {Alert, Button} from "antd";
import {Form} from "react-final-form";

import {appEnvSME, i18, required, translationGroups, trb} from "@utilities";
import {FormField, FormFieldType, OperationApproval} from "@components";

const ButtonGroup = Button.Group;

const GroupPaymentPreview = (props) => {
        const paymentGroups = props?.data?.PaymentGroups,
            payerAccounts = props?.data?.InitialAccounts;

        let payerAccountsRemaped = [];

        payerAccounts && payerAccounts.map(item =>
            payerAccountsRemaped.push({
                'label': item.AccountNumber + ' (' + item?.Amounts?.[0]?.Text + ')',
                'value': item.AccountID,
            })
        );
        const data = props.GGDR.PayTmpltGroupDataList;

        const PaymentsTotalAmount = props?.SGPVR?.PaymentsTotalAmount;
        const PaymentsTotalCurrency = props.SGPVR?.GroupPayments?.[0]?.currency;
        const PaymentsCount = props?.SGPVR?.PaymentsCount;

        return <Form
            onSubmit={props.submit}
            initialValues={props.initialValues}
            render={({handleSubmit, form, values}) => {
                return (
                    <form className="mb-3 row" onSubmit={handleSubmit}>
                        <div className={appEnvSME ? "col-12 no-padding" : "col-12 col-md-6"}>
                            <FormField
                                title={i18.Labels.PaymentGroups}
                                name="PaymentGroupId"
                                component={FormFieldType.select}
                                options={paymentGroups ? paymentGroups : []}
                                optionsLabel="Name"
                                optionsValue="ID"
                                value={props.values.PaymentGroupId}
                                disabled={props.submitting || props.disabled}
                            />
                        </div>
                        <div className={appEnvSME ? "col-12 no-padding" : "col-12 col-md-6"}>
                            <FormField
                                title={i18.Labels.FromAccount}
                                name="AccountID"
                                component={FormFieldType.select}
                                options={payerAccountsRemaped}
                                optionsLabel="label"
                                optionsValue="value"
                                value={props.values.AccountID}
                                disabled={props.submitting || props.disabled}
                            />
                        </div>
                        <div className="eb_table my-3 col-12">
                            <div className="row eb_table_head d-none d-md-flex">
                                <div className="col-4">{trb(i18.Labels.Title)}</div>
                                <div className="col-5">{trb(i18.Labels.Purpose)}</div>
                                <div className="col-3">{trb(i18.Labels.Amount)}</div>
                            </div>
                            {data.map((item, index) =>
                                <div className="row eb_table_row" key={index}>
                                    <div className="col-12 col-md-4">
                                        <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Title)}</div>
                                        <div className="eb_fw_600">{item.ReceiverName}</div>
                                        <div>{item.ReceiverAccount}</div>
                                        <FormField
                                            name={'GroupPayments.' + index + '.ID'}
                                            component={FormFieldType.input}
                                            //defaultValue={item.ID.toString()}
                                            className="d-none"
                                            disabled={props.disabled}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Purpose)}</div>
                                        <FormField
                                            name={'GroupPayments.' + index + '.Description'}
                                            component={FormFieldType.input}
                                            //defaultValue={GroupPayments[index].Description}
                                            validate={required}
                                            className="mb-0"
                                            disabled={props.disabled}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Amount)}</div>
                                        <FormField
                                            name={'GroupPayments.' + index + '.Amount'}
                                            component={FormFieldType.number}
                                            //defaultValue={GroupPayments[index].Amount}
                                            validate={required}
                                            className="mb-0"
                                            disabled={props.disabled}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row eb_table_row">
                                <div className="col-12 offset-md-9 col-md-3 eb_fw_600">
                                    <FormField
                                        name={'PaymentsTotalAmount'}
                                        input={{value: PaymentsTotalAmount}}
                                        component={FormFieldType.number}
                                        defaultValue={PaymentsTotalAmount}
                                        validate={required}
                                        className="mb-0"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 text-right col-12">
                            <ButtonGroup>
                                {props.buttons.map((item, index) => {
                                    if(props.approve && item.title === 'Buttons.Cancel'){
                                        return null;
                                    }
                                    return (<Button
                                        key={index}
                                        onClick={item.func}
                                        //disabled={!values.GroupPayments || !values.GroupPayments.length}
                                        {...item.props}
                                    >{trb(item.title)}</Button>)
                                })}
                            </ButtonGroup>
                        </div>
                        {props.approve &&
                            <OperationApproval
                                RQ_ID={props.RQ_ID}
                                AuthType={props.AuthType}
                                code={props.Code}
                                cancelApprove={props.cancelApprove}
                                successApprove={props.successApprove}
                                submitSmsApprove={props.submitSmsApprove}
                                submitMobileApprove={props.submitMobileApprove}
                                approveSum={{sum: PaymentsTotalAmount, currency: PaymentsTotalCurrency}}
                                approveCount={PaymentsCount}
                            />
                        }
                        {props.errors && <Alert
                            className="mb-3 w-100"
                            message={trb(i18.ErrorTitles.PaymentErrors)}
                            description={
                                <ul>
                                    {props.errors.map((item, index) =>
                                        // <li key={index}>{item.ErrorCaption}</li>
                                        <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                                    )}
                                </ul>
                            }
                            type="error"
                        />}
                    </form>
                )
            }}
        />;
}

function mapStateToProps(state) {
    const {sendGGDR, GGDR, SGPVR} = state.PMCReducers;

    return {
        sendGGDR,
        GGDR,
        SGPVR
    }
}

const connectedGroupPaymentPreview = connect(mapStateToProps)(GroupPaymentPreview);
export {connectedGroupPaymentPreview as GroupPaymentPreview}
