import React from 'react'
import {Button, Result} from 'antd'
import {i18, trb} from '@utilities'
import DOMPurify from 'dompurify'
import {history, routerPath} from '@config'
import {LoginFooter, LoginHeader} from '@containers'
import {useDispatch} from 'react-redux'
import {LanguageActions} from '@redux'
import {useNavigate} from "react-router-dom";

export const IdentificationSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    React.useEffect(() => {
        dispatch(LanguageActions.setLanguage('en-GB'));
    }, [])

    return (
        <div className='bbs_login'>
            <div className='bbs_login_container'>
                <LoginHeader/>
                <div className='bbs_login_content'>
                    <Result
                        status='success'
                        title={trb(i18.ContentTitles.IdentificationSuccess)}
                        subTitle={
                            <div
                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(trb(i18.HTML.IdentificationSuccess))}}
                            />
                        }
                        extra={[
                            <Button
                                size='large'
                                type='primary'
                                key='login'
                                onClick={() => navigate(routerPath.login)}
                            >
                                {trb(i18.Buttons.IdentificationSuccess)}
                            </Button>
                        ]}
                    />
                </div>
                <LoginFooter/>
            </div>
        </div>
    )
}
