import React, {useEffect, useState} from 'react'
import {FormFieldType, paymentPurposeType} from '@components'
import {
    appEnvSME,
    classifikatorsMap, currencyFormat,
    i18, logError,
    PaymentType,
    required, requiredNotUndefined,
    SHA,
    translationGroups,
    trb
} from '@utilities'
import moment from 'moment'
import {AMCActions} from "@redux";
import {useSelector} from "react-redux";

export const InternationalPaymentForm = ({
                                             InitialAccounts,
                                             historicalPayments,
                                             onFullTextSearch,
                                             enums,
                                             forexPayments,
                                             aml,
                                             PaymentTemplates,
                                             dispatch,
                                             ...rest
                                         }) => {
    const isEUR =aml?.internationalPayment?.currency === 'EUR'
    const {accountOverdraftInfo} = useSelector(state => state.AMCReducers)
    const [initialAccount, setInitialAccount] = useState(null)
    const checkAccountId = value => {
        if (value !== initialAccount) {
            setInitialAccount(value)
        }
    }

    useEffect(() => {
        const getAccountOverdraftInfo = async account => {
            try {
                if (account?.hasOverdraftProduct) {
                    await dispatch(AMCActions.getAccountOverdraft(initialAccount))
                } else {
                    dispatch(AMCActions.setAccountOverdraft(null))
                }
            } catch (error) {
                logError(error)
            }
        }
        if (initialAccount) {
            const account = InitialAccounts.find(
                item => item.value === initialAccount
            )
            getAccountOverdraftInfo(account)
        }
    }, [initialAccount])


    const checkOverdraftValue = (value, values) => {
        values.Payment.OverdraftValue = `${
            accountOverdraftInfo?.totalAvailable
                ? currencyFormat(accountOverdraftInfo.totalAvailable)
                : 0
        } EUR`
    }

    const {CISO} = enums


    const preselectInitialAccount = () => {
        const accounts = InitialAccounts?.filter(itm => !itm.disabled)
        return accounts?.length === 1 ? accounts[0].value : null
    }

    const currentTemplates = PaymentTemplates.filter(template =>
        template.PaymentType === PaymentType.INTERNATIONAL
    )

    return {
        parts: [
            {
                title: i18.PaymentParts.Payer,
                fields: [
                    {
                        name: 'Payment.PaymentType',
                        component: FormFieldType.input,
                        className: 'd-none',
                        defaultValue: 'international-payment-form'
                    },
                    {
                        name: 'Payment.RoutingCode',
                        component: FormFieldType.input,
                        defaultValue: SHA,
                        className: 'd-none',
                        disabled: true
                    },
                    {
                        name: 'Payment.PaymentSystem',
                        component: FormFieldType.input,
                        className: 'd-none',
                        defaultValue: 'X4'
                    },
                    {
                        title: i18.Labels.PaymentTemplates,
                        name: 'Payment.Template',
                        component: FormFieldType.selectSearch,
                        options: currentTemplates,
                        optionsLabel: 'TemplateName',
                        optionsValue: 'TemplateID'
                    },
                    {
                        title: i18.Labels.FromAccount,
                        name: 'Payment.InitialAccountID',
                        component: FormFieldType.selectSearch,
                        options: forexPayments?.InitialAccounts || [],
                        optionsColor: 'color',
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        optionsFunction: (params, values, options) => {
                            return options.map((item) => {
                                return {
                                    value: item.AccountID,
                                    label: `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})${
                                        item.RelatedProductNumber
                                            ? ` ${item.RelatedProductNumber} (${currencyFormat(
                                                item.RelatedProductValue
                                            )})`
                                            : ''
                                    }`
                                }
                            })
                        },
                        optionsDisabled: 'disabled',
                        validateCompose: true,
                        validate: [required, checkAccountId],
                        className: 'mobile',
                        defaultValue: preselectInitialAccount()
                    },
                    {
                        title: i18.Labels.OverdraftAvailableAmount,
                        name: 'Payment.OverdraftValue',
                        validate: checkOverdraftValue,
                        component: FormFieldType.input,
                        disabled: true,
                        className:
                            initialAccount && accountOverdraftInfo?.totalAvailable
                                ? ''
                                : 'd-none'
                    }
                ]
            },
            {
                title: i18.PaymentParts.Payee,
                className: '',
                fields: [
                    {
                        title: i18.Labels.PayeeTitle,
                        name: 'Payment.Recipient',
                        component: FormFieldType.autocomplete,
                        options: historicalPayments,
                        optionsValue: 'PayeeName',
                        optionsLabel: 'display',
                        optionsKey: 'PaymentId',
                        onSearch: onFullTextSearch,
                        limitElements: 5,
                        noFilter: true,
                        highlightOne: true
                    },
                    {
                        title: i18.Labels.PayeeAccount,
                        name: 'Payment.BeneficiaryAccountNumber',
                        component: FormFieldType.autocomplete,
                        options: historicalPayments,
                        optionsValue: 'PayeeAccountNumber',
                        optionsLabel: 'display',
                        optionsKey: 'PaymentId',
                        limitElements: 5,
                        noFilter: true,
                        highlightOne: true
                    },
                    {
                        title: i18.Labels.Amount,
                        name: 'Payment.AmountStr',
                        validate: required,
                        component: FormFieldType.amountCurrency
                    },
                    {
                        name: 'Payment.Currency',
                        component: FormFieldType.input,
                        className: 'd-none'
                    },
                    {
                        title: i18.Labels.PurposeType,
                        name: 'Payment.IsPurposeSelected',
                        component: FormFieldType.radio,
                        className: isEUR ? '': 'd-none' ,
                        options: paymentPurposeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PaymentPurposeTypes,
                        defaultValue: true,
                        validate: isEUR ? requiredNotUndefined: null,
                        inline: true,
                        classNameField: appEnvSME
                            ? 'col-10 col-md-7 mt-3 mt-md-0 small-height'
                            : null,
                        classNameTitle: appEnvSME ? 'col-12 col-md-5 pl-4 pr-0' : null
                    },
                    {
                        title: i18.Labels.Purpose,
                        name: 'Payment.Purpose',
                        component: FormFieldType.input
                    },
                    {
                        title: i18.Labels.BeneficiaryAddress,
                        name: 'Payment.BeneficiaryAddress',
                        component: FormFieldType.input
                    },
                    {
                        title: i18.Labels.RecipientInternationalBankCountry,
                        name: 'Payment.RecipientBankCountry',
                        component: FormFieldType.selectSearch,
                        options: classifikatorsMap(CISO),
                        optionsLabel: 'label',
                        optionsValue: 'value'
                    },
                    {
                        title: i18.Labels.BeneficiaryCity,
                        name: 'Payment.BeneficiaryCity',
                        component: FormFieldType.input
                    },
                    {
                        title: i18.Labels.BeneficiaryPostcode,
                        name: 'Payment.BeneficiaryPostCode',
                        component: FormFieldType.input
                    },
                    {
                        title: i18.Labels.CorrespondentBankBicInternational,
                        name: 'Payment.CorrespondentBankBic',
                        component: FormFieldType.input
                    },
                    {
                        name: 'Payment.SaveTemplate',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.SavePaymentTemplate,
                        inline: true
                    }
                ]
            },
            {
                className: 'col-12 col-md-6 offset-md-6 d-none d-md-block',
                fields: [
                    {
                        title: i18.Labels.PaymentTemplateName,
                        name: 'Payment.TemplateName',
                        component: FormFieldType.input,
                        visible: 'Payment.SaveTemplate',
                        validate: required
                    },
                    {
                        name: 'Payment.PaymentSystem',
                        className: 'd-none',
                        disabled: true,
                        component: FormFieldType.input,
                        visible: 'Payment.SaveTemplate',
                        defaultValue: 'X4'
                    }
                ]
            }
        ]
    }
}
