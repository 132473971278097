import {requestEBank, aPIPaths, handleResponse, methods, requestEBankJSON, responseTypes} from '../../Config';

export const kYCServices = {
    getKYC,
    postKYC,
};

function getKYC() {
    return requestEBank(aPIPaths.get_KYC, {
        method: methods.get
    }).then(handleResponse);
}

function postKYC(data = null) {

    return requestEBankJSON(aPIPaths.post_KYC, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}