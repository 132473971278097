import React, {useState} from 'react'
import {Container, ContentLoader, ContentNoData, FormDecoratorSwitch, Notification} from '@components'
import {Steps} from "antd";
import {PaymentFill, PaymentPreview, PaymentUnsuccess, PaymentWaiting} from "@containers";
import {useDispatch, useSelector} from "react-redux";
import {
    checkJsonExist,
    CustomIcon,
    EUR,
    i18,
    logError,
    Payment,
    Sleep,
    throwError,
    translationGroups,
    trb
} from "@utilities";
import {PMCActions} from "@redux";
import {useNavigate} from "react-router-dom";


export const PaymentOwn = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let lastFullTextSearch = null
    let timeoutFullTextSearch = null
    const {
        sendPTR,
        PTR,
        PVR,
        PayPersonTypes,
        sendPayPersonTypes,
        historicalPayments,
        checkIBANResult,
        sendCheckIBAN,
        checkIBANLength,
        checkIBANValid,
        forexPayments
    } = useSelector(state => state.PMCReducers)
    const {sendFeeList, feeList} = useSelector(state => state.FeeReducers)
    const {MALR, sendMALR} = useSelector(state => state.UACReducers)
    const {language} = useSelector(state => state.languageReducer)
    const amcReducers = useSelector(state => state.AMCReducers)
    const {forexRateRequestData, forexDraftInfo} = amcReducers
    // const {translations } = useSelector((state) => state.translationReducer);

    const paymentType = Payment.OWN_ACCOUNTS

    const [errors, setErrors] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [saveSign, setSaveSign] = useState(false)
    const [withError, setWithError] = useState(null)
    const [approve, setApprove] = useState(null)
    const [data, setData] = useState(null)
    const [decorator, setDecorator] = useState(null)
    const [RQ_ID, setRQID] = useState(null)
    const [AuthType, setAuthType] = useState(null)
    const [Code, setCode] = useState(null)

    const edit = () => {
        setErrors(null)
        setCurrentStep(0)
    }


    const submit = async (values, form) => {
        setErrors(null)
        setSubmitting(true)
        setData(values)

        if (values.Payment.PaymentType === 'forex-exchange-form') {
            handleForexExchange(values)
            return
        }

        const currentValues = {
            ...values,
            Payment: {
                ...values.Payment,
                IsPurposeSelected: values.Payment.IsPurposeSelected === 'true'
                    ? values.Payment.IsPurposeSelected.toString()
                    : 'false',
                AmountStr:
                    values?.Payment?.AmountStr?.toString()?.replace('.', ',') ?? '',
                PaymentType: values.Payment.PaymentType
                    ? values.Payment.PaymentType.toString()
                    : null,
                Amount:
                    Number(values?.Payment?.AmountStr?.toString()?.replace(',', '.')) ?? 0,
                //PaymentSystem: 'SepaSct',
            }
        }


        if (
            currentValues.Payment.PayerAddress &&
            currentValues.Payment.PayerAddress !== ''
        ) {
            currentValues.Payment.PayerAddressChk = 'true'
        }
        if (
            currentValues.Payment.PayeeAddress &&
            currentValues.Payment.PayeeAddress !== ''
        ) {
            currentValues.Payment.PayeeAddressChk = 'true'
        }

        try {
            const response = await dispatch(PMCActions.postPVR(currentValues))
            setSubmitting(false)

            if (response?.payload?.IsSuccessfull) {
                setCurrentStep(prevStep => prevStep + 1)
            } else {
                const errs = response?.payload?.ErrorCaptions
                const globalErrors = []
                const errorFields = {}
                const fields = {
                    PMC00005: 'IsPurposeSelected',
                    PMC00008: 'TemplateName',
                    PMC00010: 'PayerIdentTypeValue',
                    PMC00011: 'PayerAddress',
                    PMC00013: 'PayeeIdentTypeValue',
                    PMC00014: 'PayeeAddress',
                    PMC00015: 'CustomersCode',
                    PMC00016: 'Purpose',
                    PMC00017: 'SEPAPaymentType',
                    PMC00003: 'BeneficiaryAccountNumber'
                }
                for (let i in errs) {
                    const item = errs[i]
                    if (checkJsonExist(fields, item.ErrorCode)) {
                        errorFields[fields?.item?.ErrorCode] = trb(
                            translationGroups.ErrorCode + item.ErrorCode
                        )
                    } else {
                        globalErrors.push(item)
                    }
                }
                if (
                    currentValues?.Payment?.PaymentSystem === 'INST' &&
                    response?.payload?.ErrorCode === 'ChangePaymentSystem'
                ) {
                    currentValues.Payment.PaymentSystem = 'SepaSct'
                    globalErrors.push({ErrorCode: 'SepaInstError'})
                }
                setErrors(globalErrors)

                return {
                    Payment: {
                        ...errorFields
                    }
                }
            }
        } catch (error) {
            logError(error)
        }
    }

    let errs = errors ? [...errors] : null

    const submitPreview = async () => {
        setErrors(null)
        setSubmitting(true)
        setWithError(false)

        const currentValues = {
            ...data,
            Payment: {
                ...data.Payment,
                FinalPayeeAddress: '',
                FinalPayeeAddressChk: 'false',
                FinalPayeeCountryCode: '',
                AmountStr: data.Payment.AmountStr.toString().replace('.', ','),
                Amount: Number(data?.Payment?.AmountStr?.toString()?.replace(',', '.')) ?? 0,
            },
            feePayment: {
                ...data.feePayment,
                feeValues: data.feePaymentData
            }
        }


        if (
            currentValues.Payment.PayerAddress &&
            currentValues.Payment.PayerAddress !== ''
        ) {
            currentValues.Payment.PayerAddressChk = 'true'
        }
        if (
            currentValues.Payment.PayeeAddress &&
            currentValues.Payment.PayeeAddress !== ''
        ) {
            currentValues.Payment.PayeeAddressChk = 'true'
        }

        const response = await dispatch(
            PMCActions.postAPR({...currentValues, Language: language})
        )
        if (response.payload?.IsSuccessfull) {
            if (response.payload.RQ_ID !== '') {
                setApprove(true)
                setRQID(response.payload.RQ_ID)
                setAuthType(response.payload.AuthType)
                setCode(response.payload.ControlCode)
            } else {
                setCurrentStep(prevStep => prevStep + 1)
            }
        } else {
            setErrors(
                response?.payload?.PaymentErrors?.filter(
                    (items, index) =>
                        index ===
                        response?.payload?.PaymentErrors.findIndex(
                            item => item.ErrorCode === items.ErrorCode
                        )
                )
            )
        }
        setSubmitting(false)
        if (response?.payload?.RQ_ID === '') {
            await Sleep(1000)
            await dispatch(PMCActions.postPTR())
        }
    }

    const cancelApprove = () => {
        setSubmitting(false)
        setApprove(false)
        setRQID(null)
        setAuthType(null)
        setCode(null)
        Notification({
            type: 'warning',
            message: i18.NotificationTitles.PaymentCanceled,
            description: i18.NotificationMessages.PaymentCanceled
        })
    }

    const successApprove = async () => {
        setApprove(false)
        setCurrentStep(prevStep => prevStep + 1)
        setWithError(false)
    }

    const waitingApprove = async err => {
        setApprove(false)
        setWithError('waiting')
        setCurrentStep(prevStep => prevStep + 1)
        Notification({
            type: 'info',
            message: i18.NotificationTitles.NeedCheckLater,
            description: i18.NotificationMessages.NeedCheckLater
        })
    }

    const unsuccessApprove = async err => {
        setApprove(false)
        setWithError(err)
        setCurrentStep(prevStep => prevStep + 1)
        Notification({
            type: 'error',
            message: i18.NotificationTitles.IsUnsuccessfull,
            description: translationGroups.ErrorCode + err
        })
    }

    const BadApproveCode = () => {
        setSubmitting(false)
        setApprove(false)
        setRQID(null)
        setAuthType(null)
        setCode(null)
        Notification({
            type: 'error',
            message: i18.NotificationTitles.BadApproveCode,
            description: i18.NotificationMessages.BadApproveCode
        })
    }

    const download = () => {
        console.log('download')
    }

    const resetPayment = async () => {
        dispatch(PMCActions.postPTR())
        setSubmitting(false)
        setCurrentStep(0)
        setData(null)

        setApprove(false)
        setErrors(null)
        setSaveSign(false)
        setDecorator(
            FormDecoratorSwitch({
                name: paymentType
                //feePaymentHistory: feePaymentHistory,
            })
        )
        localStorage.removeItem('SavedIBAN')
        localStorage.removeItem('SavedCurrency')
        await dispatch(PMCActions.clearCheckIBANMember())
        await dispatch(PMCActions.clearHistoricalPayments())
        await dispatch(PMCActions.clearPVR())
    }


    const submitSmsApprove = values => {
        dispatch(PMCActions.postAPSMSR(values)).then(response => {
            if (response.payload.IsSuccessfull === true) successApprove()
            else if (
                response.payload.IsSuccessfull === false &&
                response.payload.ErrorCode &&
                response.payload.ErrorCode.toLowerCase() === 'waiting_to_confirm'
            )
                waitingApprove()
            else if (
                response.payload.IsSuccessfull === false &&
                response.payload.ErrorCode
            )
                unsuccessApprove(response.payload.ErrorCode)
            else BadApproveCode()
        })
    }

    const submitMobileApprove = async () => {
        try {
            const response = await dispatch(PMCActions.postAPESCR({RQ_ID: RQ_ID}))
            if (response?.payload) {
                if (response.payload.AuthCheckResult === null) {
                    return true
                }
                if (response.payload.AuthCheckResult === false) {
                    if (
                        response.payload.IsSuccessfull === false &&
                        response.payload.ErrorCode &&
                        response.payload.ErrorCode.toLowerCase() == 'waiting_to_confirm'
                    )
                        waitingApprove()
                    else if (response.payload.ErrorCode)
                        unsuccessApprove(response.payload.ErrorCode)
                    else BadApproveCode()
                } else if (
                    response.payload.AuthCheckResult === true &&
                    response.payload.IsSuccessfull
                ) {
                    successApprove()
                }
            } else {
                throwError(response, 'submitMobileApprove')
            }
            return false
        } catch (error) {
            logError(error)
            return false
        }
    }

    const onFullTextSearch = value => {
        const trimmedValue = value.trim(),
            iban = localStorage.getItem('SavedIBAN')

        if (lastFullTextSearch === trimmedValue) {
            return
        }
        lastFullTextSearch = trimmedValue
        if (timeoutFullTextSearch) {
            clearTimeout(timeoutFullTextSearch)
        }

        const currency = localStorage.getItem('SavedCurrency')
        if ((iban === 'null' || !iban) && (!currency || currency === 'EUR')) {
            timeoutFullTextSearch = setTimeout(
                () => fullTextSearch(trimmedValue),
                500
            )
        }
    }

    const fullTextSearch = value => {
        const ActiveAccount =
            MALR?.ManagedAccounts != null
                ? MALR.ManagedAccounts.find(x => x.IsActive === true)
                : null

        if (
            value &&
            value.length > 4 &&
            value.length < 10 &&
            ActiveAccount &&
            ActiveAccount.ID
        ) {
            dispatch(
                PMCActions.getHistoricalPayments({
                    ClientId: ActiveAccount.ID,
                    SearchText: value
                })
            )
        }
    }

    if (
        (sendPTR && currentStep !== 2) ||
        sendFeeList ||
        sendMALR ||
        sendPayPersonTypes
    ) {
        return <ContentLoader/>
    }

    if ((!PTR && currentStep !== 2) || !MALR) {
        return <ContentNoData/>
    }

    const user = MALR.ManagedAccounts.find(x => x.IsActive === true)


    return (
        <>
            <div className="eb_content_subtitle">{trb(i18.ContentTitles.OwnAccounts)}</div>
            <Container wide gradient={currentStep === 2}>
                <Steps
                    current={currentStep}
                    direction="horizontal"
                    className={`mb-3 mb-md-5 eb_steps`}>
                    <Steps.Step icon={(<CustomIcon size={50} type="custom" icon="paymentFirstStep"/>)}/>
                    <Steps.Step icon={(<CustomIcon size={50} type="custom" icon="paymentSecondStep"/>)}/>
                    <Steps.Step icon={(<CustomIcon size={50} type="custom" icon="paymentThirdStep"/>)}/>
                </Steps>
                <div className="position-relative w-100">
                    {currentStep === 0 && (
                        <div>
                            <PaymentFill
                                paymentType={Payment.OWN_ACCOUNTS}
                                data={data}
                                user={user}
                                decorator={decorator}
                                PTR={PTR}
                                PVR={PVR}
                                forexPayments={forexPayments}
                                PayPersonTypes={PayPersonTypes}
                                submit={submitting ? () => {
                                } : submit}
                                submitting={submitting}
                                errors={errs}
                                fees={feeList}
                                templateId={props?.location?.state?.templateId}
                                historicalPayments={historicalPayments}
                                onFullTextSearch={onFullTextSearch}
                                checkIBANResult={checkIBANResult}
                                checkIBANLength={checkIBANLength}
                                checkIBANValid={checkIBANValid}
                                sendCheckIBAN={sendCheckIBAN}
                                submitDisabled={false}
                                clearErrors={() => setErrors(null)}
                                resetPayment={resetPayment}
                                contractDesc2={amcReducers}

                            />
                        </div>
                    )}
                    {currentStep === 1 && (
                        <PaymentPreview
                            paymentType={Payment.OWN_ACCOUNTS}
                            data={data}
                            PTR={PTR}
                            PVR={PVR}
                            PayPersonTypes={PayPersonTypes}
                            edit={edit}
                            submit={submitting? ()=>{} :submitPreview}
                            submitting={submitting}
                            approve={approve}
                            AuthType={AuthType}
                            RQ_ID={RQ_ID}
                            Code={Code}
                            cancelApprove={cancelApprove}
                            successApprove={successApprove}
                            submitSmsApprove={submitSmsApprove}
                            submitMobileApprove={submitMobileApprove}
                            errors={errors}
                            saveSign={saveSign}
                            resetPayment={resetPayment}

                        />
                    )}
                    {currentStep === 2 && (<>
                            {withError && (<>
                                    {withError === 'waiting' && (<PaymentWaiting resetPayment={resetPayment}/>)}
                                    {withError !== 'waiting' && (
                                        <PaymentUnsuccess resetPayment={resetPayment} error={withError}/>)}</>
                            )}
                            {!withError && (
                                <PaymentPreview
                                    paymentType={Payment.OWN_ACCOUNTS}
                                    data={data}
                                    PTR={PTR}
                                    PVR={PVR}
                                    PayPersonTypes={PayPersonTypes}
                                    edit={edit}
                                    submit={submitting ? () => {
                                    } : submitPreview}
                                    submitting={submitting}
                                    success
                                    download={download}
                                    resetPayment={resetPayment}
                                />
                            )
                            }
                        </>
                    )}
                </div>
            </Container>
        </>
    )
}
