import React from 'react'
import { Card, Button } from 'antd'

import { i18, trb } from '@utilities'
import { externalPath } from '@config'
import { LoginFooter, LoginHeader, LoginPanel } from './'
import { PageLoader } from '@components/PageLoader'
import { LoginMessages } from './Components/LoginMessages'
import {CheckVersion} from "@components";
require('./indexSME.less')

const RegistedPanel = () => {
  const handleClick = () => {
    const language = localStorage.getItem("language");

    window.open(`${externalPath.onboard}?language=${language || "lt-LT"}`, '_blank')
  }

  return (
    <div className="bbs_register_panel">
      <div className="bbs_register_panel_title">
        {trb(i18.Labels.LoginPageSignUpTitle)}
      </div>
      <Button onClick={handleClick} size="large" type="primary" id={`LoginFormRegisterButton`}>
        {trb(i18.Buttons.LoginPageSignUp)}
      </Button>
      <div className="bbs_login_sme_bubble bbs_login_sme_bubble1" />
      <div className="bbs_login_sme_bubble bbs_login_sme_bubble2" />
      <div className="bbs_login_sme_bubble bbs_login_sme_bubble3" />
      <div className="bbs_login_sme_bubble bbs_login_sme_bubble4" />
    </div>
  )
}

export const LoginSmePage = props => {
  const {
    translations,
    UID,
    LoginIdentity,
    clickChangeForm,
    currentForm,
    submit,
    submitSMS,
    submitSMSConfirm,
    loginCancel,
    code,
    submitting,
    submitError,
    submitSmartId,
    confirm,
    confirming,
    seconds,
    secondsRemaining,
    secondsSMS,
    secondsSMSRemaining,
    loginTypes,
    loginMessages,
    scroll,
    authCookie
  } = props

  if (!translations || translations.length < 2) {
    return <PageLoader />
  }

  return (
    <div className="bbs_login" onScroll={scroll}>
      <div className="bbs_login_container">
        <LoginHeader />
        <div className="bbs_login_content">
          <Card className="bbs_login_wrapper" title={trb(i18.Labels.LoginSme)}>
            <LoginPanel
              UID={UID}
              LoginIdentity={LoginIdentity}
              clickChangeForm={clickChangeForm}
              currentForm={currentForm}
              withLang
              submit={submit}
              submitSMS={submitSMS}
              submitSMSConfirm={submitSMSConfirm}
              loginCancel={loginCancel}
              code={code}
              authCookie={authCookie}
              submitting={submitting}
              submitError={submitError}
              submitSmartId={submitSmartId}
              confirm={confirm}
              confirming={confirming}
              seconds={seconds}
              secondsRemaining={secondsRemaining}
              secondsSMS={secondsSMS}
              secondsSMSRemaining={secondsSMSRemaining}
              loginTypes={loginTypes}
            />
            <RegistedPanel />
          </Card>
        </div>
        <LoginMessages messages={loginMessages} />
        <LoginFooter />
      </div>
    </div>
  )
}
