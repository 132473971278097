import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Badge, Button} from "antd";

import {CustomIcon} from "@utilities";
import {history, routerPath} from "@config";
import {MessagingActions} from "@redux";
import {useNavigate} from "react-router-dom";

export const UserMessages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const {MessagesCount, sendMessagesCountInitial} = useSelector(state => state.MessagingReducers);

    useEffect(() => {
        dispatch(MessagingActions.getNewMessagesCount());
    }, [])

    const gotoMessage = () => {
        if (MessagesCount.CountBroadCast > 0) {
            navigate(`${routerPath.messages}/${routerPath.messagesBroadCast}`); //
        } else if (MessagesCount.CountChat > 0) {
            navigate(`${routerPath.messages}/${routerPath.messagesInbox}`);
        } else {
            navigate(`${routerPath.messages}/${routerPath.messagesNew}`)
        }
    };

    if (sendMessagesCountInitial) {
        return null;
    }

    return (
        <Badge offset={[-40, 5]} count={MessagesCount?.CountChat + MessagesCount?.CountBroadCast}>
            <Button
                id="GoToMessageButton"
                className="eb_header_messages mr-0 ml-3"
                onClick={gotoMessage}
            >
                <CustomIcon size={18} icon="ringbell" type="custom"/>
            </Button>
        </Badge>
    );
}

