import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";

import {i18, trb} from "@utilities";
import {routerPath} from "@config";
import {SettingsAccountsLimits, SettingsAccountsManagement, SettingsBlockCard, SettingsKYCRenewContainer} from "./";
import {MenuActions, UACActions} from "@redux";
import {SlidersOutlined, TeamOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";

export const SettingsContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(0)
    const [menuArray, setMenuArray] = useState([{
        title: i18.ContentMenu.AccountsLimits,
        icon: <SlidersOutlined/>,
        type: 'legacy',
        path: routerPath.settings + '/accounts-limits',
        active: true
    }, // {
        //     title: i18.ContentMenu.ChangePassword,
        //     icon: 'edit',
        //     type: 'legacy',
        //     path: routerPath.settings + '/change-password',
        // },
        {
            title: i18.ContentMenu.AccountsManagement,
            icon: <TeamOutlined/>,
            type: 'legacy',
            path: routerPath.settings + '/accounts-management'
        }])

    const {ATR, sendATR} = useSelector(state => state.UACReducers);
    useEffect(() => {
        if (!ATR && sendATR !== true) {
            dispatch(UACActions.postATR());
        }
        dispatch(MenuActions.setContentMenu(menuArray, routerPath.settings));
        dispatch(MenuActions.setTitle(trb(i18.Container.SettingsContainer)));
        return () => {
            dispatch(MenuActions.setContentMenu(null));
        }
    }, [])


    if (location.pathname === routerPath.settings) {
        navigate(`${routerPath.settings}/${routerPath.settingsAccountsLimits}`)
    }

    return <Routes>
        <Route path={routerPath.settingsAccountsLimits} element={<SettingsAccountsLimits/>}/>
        <Route path={routerPath.settingsBlockCard} element={<SettingsBlockCard/>}/>
        <Route path={routerPath.settingsAccountsManagement} element={<SettingsAccountsManagement/>}/>
        <Route path={routerPath.settingsKYCRenew} element={<SettingsKYCRenewContainer/>}/>
    </Routes>;

}