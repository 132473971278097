import React from 'react';
import {useSelector} from 'react-redux'
import {BackTop, Layout} from 'antd';
import {MainRouteContainer} from "./";
import {IdleModal, PartHeader, PartSwapMenu, PrintBrowser} from '@components'
import {getWindowSize} from "@utilities";

const {Content} = Layout

export const Main = props => {
    const windowSize = getWindowSize()

    const {menuCollapsed} = useSelector(state => state.MenuReducer);

    return <>
        <Layout className="eb eb_print_none" id="eb_print_none">
            <PartSwapMenu/>
            <Layout className={`site-layout ${menuCollapsed ? 'site-layout-menuCollapsed' : ''}`}>
                <PartHeader/>
                <Content className={`${(!windowSize.mobileCheck || !menuCollapsed) ? '' : 'd-none'}`}>
                    <MainRouteContainer/>
                </Content>
            </Layout>
            <BackTop/>
            <IdleModal/>
        </Layout>
        <PrintBrowser/>
    </>;
};
