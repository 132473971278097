import React from 'react'
import { trb, i18 } from '@utilities'

export const LoginCountdown = (props) => {
  return (
    <div className="mb-3" id="LoginCountdown">
      {trb(i18.Labels.LoginCountdownMessage)}{' '}
      <strong>{props.secondsRemaining}</strong>{' '}
      {trb(i18.Labels.LoginCountdownMeasure)}
    </div>
  )
}
