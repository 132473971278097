import {
  required,
  checkMaxLength,
  validateTemplateIban,
  validateRegex,
  NAME_VALIDATOR,
  trb,
  i18,
  checkAllLettersAndNumbers,
  checkMinLength,
  translationGroups
} from '@utilities'

/**
 * Field validation function, validates one error at a time, error messages do not stack, if error is found the validation is not continued
 * @param validations is a list of validation functions
 * @param value a property to be validated
 * @returns {null} or error depending on the validation outcome
 */
const validateField = (validations, value) => {
  let error = null
  validations.forEach(validateFunction => {
    if (!error) {
      error = validateFunction(value)
    }
  })
  return error
}

export const InternationalPaymentValidation = (values, _props, initialAccounts) => {
  const errorFields = {}
  const { Payment } = values;
  const getMaximumPaymentAmount = () => {
    if (_props.contractDesc2?.accountOverdraftInfo) {
      const { totalAvailable } = _props.contractDesc2.accountOverdraftInfo
      return totalAvailable || 0
    } else {
      const Accounts = _props?.contractDesc2?.ALR?.Accounts
      if (Accounts?.length) {
        const acc = Accounts.find(
            x => x.AccountID === values.Payment.InitialAccountID
        )
        return acc?.AvailableAmount || 0
      }
    }
  }
  const initialAccountAmount = initialAccounts?.find((e) => e.AccountID === Payment?.InitialAccountID)?.Amounts[0]?.Value;

  if (Payment) {
    errorFields.Recipient = validateField(
      [required, checkMaxLength(35), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidNameError))],
      Payment?.Recipient
    )

    errorFields.BeneficiaryAccountNumber = validateField(
      [validateTemplateIban],
      Payment?.BeneficiaryAccountNumber
    )

    if (values.Payment.AmountStr > 0 && values.Payment.InitialAccountID) {
      const maxPaymentAmount = getMaximumPaymentAmount()
      if (maxPaymentAmount < values.Payment.AmountStr) {
        errorFields.AmountStr = trb(translationGroups.ErrorCode + 'PMC00004')
      }
    }

    errorFields.Purpose = validateField(
      [required, checkMaxLength(140), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidPurposeError))],
      Payment?.Purpose
    )

    errorFields.BeneficiaryAddress = validateField(
      [required, checkMaxLength(35), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidNameError))],
      Payment?.BeneficiaryAddress
    )

    errorFields.RecipientBankCountry = validateField(
      [required],
      Payment?.RecipientBankCountry
    )

    errorFields.BeneficiaryCity = validateField(
      [required, checkMaxLength(25), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidNameError))],
      Payment?.BeneficiaryCity
    )

    errorFields.BeneficiaryPostCode = validateField(
      [required, checkMaxLength(10), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidPostCodeError))],
      Payment?.BeneficiaryPostCode
    )

    errorFields.CorrespondentBankBic = validateField(
      [required, checkAllLettersAndNumbers, checkMaxLength(11), checkMinLength(8)],
      Payment?.CorrespondentBankBic
    )

    // errorFields.ExecutionDate = validateField(
    //   [required],
    //   Payment?.ExecutionDate
    // )
  }

  return {
    Payment: {
      ...errorFields
    }
  }
}
