import React from 'react'
import PropTypes from 'prop-types'

import { MessageResult } from '@components'
import { i18 } from '@utilities'
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

export const PaymentWaiting = ({ resetPayment, close }) => {
  const buttons = []
  if (resetPayment)
    buttons.push({
      title: i18.Buttons.NewPaymentInstruction,
      func: resetPayment,
      props: {
        icon: null
      }
    })
  if (close)
    buttons.push({
      title: i18.Buttons.Close,
      func: close,
      props: {
        type: 'primary',
        icon: <CloseCircleOutlined />
      }
    })
  return (
    <MessageResult
      status="info"
      icon={'sync'}
      title={i18.MessageTitles.PaymentWaitingConfirm}
      subTitle={i18.Messages.PaymentWaitingConfirm}
      buttons={buttons}
    />
  )
}

PaymentWaiting.propTypes = {
  close: PropTypes.func.isRequired,
  resetPayment: PropTypes.func.isRequired
}
