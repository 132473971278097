import React from 'react';
import {Input} from 'antd';

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '../';
import {trb, formFieldId} from '../../../Utilities';

const {Search} = Input;

export const InputSearchFormField = (props) => {

    const title = props.title,
        titleWithoutTranslate = props.titleWithoutTranslate,
        className = props.className,
        inline = props.inline,
        placeholder = props.placeholder,
        disabled = props.disabled,
        onSearch = props.onSearch;

    const meta = props.meta;

    return <div id={formFieldId(props.input.name, 'InputSearchWrapper')} className={FormFieldWrapperClasses(className, inline, disabled)}>
        {title && <div className={inline ? 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0' : 'form-field-title col-12'}>{titleWithoutTranslate ? title : trb(title)}</div>}
        <div className={FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline)}>
            <Search
                id={formFieldId(props.input.name, 'InputSearch')}
                enterButton
                allowClear
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="off"
                placeholder={trb(placeholder)}
                disabled={disabled}
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                onSearch={onSearch}
                onBlur={props.input.onBlur}
            />
        </div>
        <div id={formFieldId(props.input.name, 'InputSearchError')} className={inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>{meta.touched && (meta.error || meta.submitError)}</div>
    </div>
};
