import {Notification} from "@components";
import {i18} from "@utilities";

export function handleResponse(response) {
    if (response.status !== 200) {
        return Promise.reject(response.statusText);
    }
    return response.data;
}

export function handleResponseForexRate(response) {
    if (response.status !== 200) {
        return Promise.reject(response.statusText);
    }

    if(response?.data?.IsValid === false && response?.data?.Comment){
        Notification({
            type: 'error',
            message: 'Error',
            description: response?.data?.Comment
        })
        return Promise.reject(response?.data?.Comment);
    }
    return response.data;
}

export function handleResponseLogin(response) {
    if (response.status !== 200 && response.status !== 202 && response.status !== 401) {
        return Promise.reject(response.statusText);
    }
    return response.data;
}

export function handleErrorLogin(response) {
        return Promise.reject(response.response);
}
