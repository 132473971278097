import React from 'react';
import {Modal} from 'antd';
import {Form} from "react-final-form";

import {i18, translationGroups, trb, checkJsonProp, required, numberFormat} from "../../../Utilities";
import {FormField, FormFieldType, OperationApproval, ContentLoader, ContentNoData} from "../../../Components";

export const ModalDepositTermination = (props) => {
    const bindSubmitForm = () => {
        document.getElementById('depositTermination').dispatchEvent(new Event('submit', {cancelable: true}));
    };

    const onCancel = () => {
        if (props.cancelApprove) props.cancelApprove();
        props.hideModal();
    }

    return <Modal
        width={700}
        zIndex={1021}
        centered
        //style={{top: 160}}
        //bodyStyle={{paddingTop: 0}}
        visible={props.modalVisible}
        title={<div className='eb_fw_600'>
            {trb(i18.ModalTitles.DepositTerminationForm)}
        </div>}
        onCancel={onCancel}
        onOk={bindSubmitForm}
        okText={trb(i18.Buttons.DepositTerminationOk)}
        cancelText={trb(i18.Buttons.DepositTerminationCancel)}
        okButtonProps={{
            disabled: !props.initialValues
        }}
        footer={props.approve ? null : undefined}
    >
        {props.sendDeposit
            ? <ContentLoader/>
            : <>
                  {props.initialValues
                      ? <>{!props.approve
                          ? <Form
                              onSubmit={props.submit}
                              initialValues={{...props.initialValues}}
                              render={({handleSubmit, form, valid, values}) => {
                                  return (
                                      <form
                                          className="mb-3"
                                          onSubmit={handleSubmit}
                                          id="depositTermination"
                                      >
                                          <div className="form-part">
                                              <FormField
                                                  name="depositAmount"
                                                  title={i18.Labels.DepositAmount}
                                                  component={FormFieldType.number}
                                                  //validate={required}
                                                  formatNumber={numberFormat().currency}
                                                  disabled={true}
                                              />
                                              <FormField
                                                  name="terminationDate"
                                                  title={i18.Labels.DepositTransferingDate}
                                                  component={FormFieldType.date}
                                                  //validate={required}
                                                  disabled={true}
                                              />
                                              <FormField
                                                  name="penalty"
                                                  title={i18.Labels.CancelationFee}
                                                  component={FormFieldType.number}
                                                  //validate={required}
                                                  disabled={true}
                                                  formatNumber={numberFormat().currency}
                                              />
                                          </div>
                                      </form>
                                  )
                              }}
                          />
                          : <OperationApproval
                                RQ_ID={props.RQ_ID}
                                AuthType={props.AuthType}
                                code={props.ControlCode}
                                cancelApprove={props.cancelApprove}
                                successApprove={props.successApprove}
                                submitSmsApprove={props.submitSmsApprove}
                                submitMobileApprove={props.submitMobileApprove}
                          />}
                      </>
                  : <ContentNoData/>
                }
            </>
        }
    </Modal>
}
