import React, {useEffect, useState} from "react";
import {Button, Checkbox} from "antd";
import {currencyTextValue, EUR, getWindowSize, i18, trb} from "@utilities";
import {useDispatch, useSelector} from "react-redux";
import {BulkFileActions} from "@redux";
import moment from "moment";
import {generatePath, useNavigate} from "react-router-dom";
import {routerPath} from "@config";
import {ContentLoader, ContentNoData} from '@components'

const ButtonGroup = Button.Group

export const UnsignedPaymentFiles = ({paymentSuccess}) => {
    const windowSize = getWindowSize()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);

    const {FilesList, loading} = useSelector((state) => state.BulkFileReducer)

    useEffect(() => {
        if (!history.location?.state?.paymentSuccess) {
            dispatch(BulkFileActions.getBulkPaymentsFileList())
        }
    }, [])

    const checkAllRow = () => {
        if (FilesList?.length === selectedItems.length) {
            setSelectedItems([])
        } else {
            setSelectedItems(FilesList?.map(({FileGuid}) => FileGuid))
        }
    }

    const toggleRow = (FileGuid) => {
        if (selectedItems.indexOf(FileGuid) === -1) {
            setSelectedItems([...selectedItems, FileGuid])
        } else {
            setSelectedItems(selectedItems.filter((guid) => guid !== FileGuid))
        }
    }

    const openDetails = (FileGuid) => {
        navigate(generatePath(`${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}/${routerPath.unsignedFileDetails}`, {FileGuid}))
    }

    const handleDeleteItems = () => {
        selectedItems?.forEach((FileGuid) => dispatch(BulkFileActions.deleteBulkPaymentsFile(FileGuid)))
        setSelectedItems([])
    }

    if (FilesList?.length < 1) {
        return <ContentNoData/>
    }

    return (<>
        {loading && (<ContentLoader/>)}
        {!loading && (<div className="mb-2 w-100">
            <div className="eb_table_wrapper eb_table_nosigned_payment">
                <div className="eb_table">
                    {!windowSize.mobileCheck && (<div className="row eb_table_head d-flex d-lg-flex flex-wrap">
                        <div className="col-md-2 text-nowrap d-lg-block">
                            <Checkbox
                                className="mr-2"
                                disabled={loading}
                                onChange={checkAllRow}
                                checked={selectedItems?.length === FilesList?.length && FilesList?.length}
                            >
                            </Checkbox>
                            <span style={{cursor: 'default'}}>
                                {trb(i18.Labels.Date)}
                            </span>
                        </div>
                        <div className="col-md-3 d-lg-block">
                            {trb(i18.Labels.FileName)}
                        </div>
                        <div className="col-md-3  d-lg-block">
                            {trb(i18.Labels.FileId)}
                        </div>
                        <div className="col-md-2  d-lg-block">
                            {trb(i18.Labels.PaymentsCount)}
                        </div>
                        <div className="col-md-2 text-right  d-lg-block">
                            {trb(i18.Labels.PaymentSum)}
                        </div>
                    </div>)}

                    {!!FilesList?.length && FilesList.map((file) => (
                        <React.Fragment key={file.FileGuid}>
                            <div className="row eb_table_row d-flex flex-wrap">
                                <div className="col-md-2 text-nowrap  d-block">
                                <span>
                                    <Checkbox
                                        className="mr-2"
                                        disabled={loading}
                                        onChange={() => toggleRow(file.FileGuid)}
                                        checked={selectedItems?.indexOf(file.FileGuid) !== -1}
                                    />
                                </span>
                                    <span>{moment(file.FileUploadDate).format('YYYY-MM-DD')}</span>
                                </div>

                                <div className="col-md-3  d-lg-block  text-truncate"
                                >
                                    {file.FileName}
                                </div>
                                <div className="col-md-3  d-lg-block  text-truncate"
                                >
                                    {file.FileHeaderId}
                                </div>
                                <div className="col-md-2  d-lg-block "
                                >
                                    {file.PaymentsCount}
                                </div>
                                <div
                                    className="col-md-2 text-right  d-lg-block  text-primary eb_fw_600"
                                >
                                    {currencyTextValue(file.PaymentValue, EUR)}
                                </div>
                            </div>
                            <div className="text-end mb-4">
                                <Button
                                    onClick={() => openDetails(file.FileGuid)}
                                >
                                    {trb(i18.Buttons.ReviewAndSign)}
                                </Button>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="mb-3 text-right">
                <ButtonGroup>
                    <Button
                        onClick={handleDeleteItems}
                        disabled={!selectedItems?.length}
                    >{trb(i18.Labels.Delete)}</Button>
                </ButtonGroup>
            </div>
        </div>)}
    </>)
}
