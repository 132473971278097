import React, {useEffect, useState} from 'react'
import {Form, FormSpy} from 'react-final-form'
import {Alert, Button} from 'antd'
import {useSelector} from 'react-redux'
import {NavLink, useLocation, useParams} from "react-router-dom";

import {
    appEnvSME,
    checkJsonExist,
    checkJsonProp,
    checkStringIsFirstTwoNumbers,
    composeValidatorsJSON,
    i18,
    INTERNATIONAL_PAYMENT_SYSTEM,
    Payment,
    PaymentType,
    SEPA_PAYMENT_SYSTEM,
    TARGET2_PAYMENT_SYSTEM,
    trb
} from '@utilities'
import {FormField, TemplateForm, TemplateFormInternational} from '@components'
import {routerPath} from "@config";
import {TemplateValidation} from '@components/Forms/FormValidations/TemplateFormValidation'
import {TemplateSpy} from '@components/Forms/FormSpies/TemplateSpy'
import {PAYMENT_TEMPLATE_INTERNATIONAL, PAYMENT_TEMPLATE_NEW} from "@containers";
import {RollbackOutlined, SaveOutlined} from "@ant-design/icons";


export const PaymentTemplateForm = props => {
    const {checkIBANResult} = useSelector(state => state.PMCReducers)

    const params = useParams();
    const {templateType} = params;
    const [isTarget2, setIsTarget2] = useState(true)

    const [currentForm, setCurrentForm] = useState(null);

    const [initialData, setInitialData] = useState({
        Payment: {
            PaymentType: '2',
            IsAdditionalPaymentInformationSelected: false,
            IsPurposeSelected: 'true',
            PayeeName: '',
            PayeeCode: '',
            IsTemplate: true,
            InitialAccountID: '0',
            DestinationAccountID: null,
            Recipient: '',
            BeneficiarysCode: '',
            Purpose: '',
            ReferenceNo: null,
            CustomersCode: null,
            SaveTemplate: true,
            PaymentHashID: null,
            PayeeType: '',
            PayerAddress: '',
            PayeeIdentTypeValue: '',
            PayeeAddress: '',
            PayerCountryCode: null,
            PayeeCountryCode: null,
            DocumentNumber: null,
            PayeeAddressChk: false,
            PayerAddressChk: false,
            CustCodeChk: true,
            RecipientBankName: null,
            PayerName: '',
            PayerAgreementIdentTypeCode: 'u',
            IsSEPAPaymentTypeSelected: false,
            SEPAPaymentType: null,
            InitPayerName: null,
            InitPayerType: '',
            InitPayerIdentTypeValue: null,
            InitPayerAddressChk: null,
            InitPayerAddress: null,
            InitPayerCountryCode: null,
            FinalPayeeName: null,
            FinalPayeeType: '',
            FinalPayeeIdentTypeValue: null,
            FinalPayeeAddress: null,
            FinalPayeeAddressChk: null,
            FinalPayeeCountryCode: null,
            AmountStr: '1',
            ...props?.data?.Payment,
            BeneficiaryAccountNumber: props?.data?.Payment?.BeneficiaryAccountNumber ?? '',
            RoutingCode: 'BIC',
            TemplateName: props?.data?.TemplateName || null,
            TemplateID: props?.data?.TemplateID || null,
            PayerIdentTypeCode: props?.user?.Type === 'p' ? 'NIDN' : 'COID',
            PayerIdentTypeValue: props?.user?.ClientCode || null,
        },
        TemplateName: props?.data?.TemplateName || null,
        TemplateID: props?.data?.TemplateID
    })

    const {language} = useSelector(state => state.languageReducer);
    const {CISO} = useSelector(
        state =>
            state?.translationReducer?.translations?.find(
                el => el.culture === language && el.context === 'BBS.Classificators'
            )?.Groups ?? []
    )
    useEffect(() => {
        const params = {...props, CISO, type: templateType};

        switch (templateType) {
            case 'international':
                setInitialData({
                    ...initialData,
                    ...props?.data,
                    Payment: {
                        ...initialData.Payment,
                        ...props?.data?.Payment,
                        BeneficiaryAccountNumber: initialData.Payment.BeneficiaryAccountNumber.replace(/ /g, ''),
                        PaymentType: PaymentType.INTERNATIONAL,
                        PaymentSystem: INTERNATIONAL_PAYMENT_SYSTEM
                    }
                })
                setCurrentForm(TemplateFormInternational(params));
                break;
            default:

                setInitialData({
                    ...initialData,
                    ...props?.data,
                    Payment: {
                        ...initialData.Payment,
                        ...props?.data?.Payment,
                        BeneficiaryAccountNumber: initialData.Payment.BeneficiaryAccountNumber.replace(/ /g, ''),
                        PaymentType: isTarget2 ? PaymentType.TARGET2 : PaymentType.SEPA,
                        PaymentSystem: isTarget2 ? TARGET2_PAYMENT_SYSTEM : SEPA_PAYMENT_SYSTEM,
                        TemplateName: initialData.TemplateName
                    }
                })
                setCurrentForm(TemplateForm(params));
                break;
        }
    }, [templateType])

    const currentFormSpy = (feeModuleId, index, values, form) => {
        const current = initialData
        current.Payment.Template = initialData.TemplateID
        return (
            <FormSpy
                key={index}
                name="new-template"
                data={{...current}}
                templateId={props.templateId}
                component={TemplateSpy}
            />
        )
    }

    const buttons = [
        {
            title: i18.Buttons.Cancel,
            func: props.clickBack,
            props: {
                icon: <RollbackOutlined/>,
                disabled: props.submitting
            }
        },
        {
            title: i18.Buttons.SubmitTemplates,
            props: {
                icon: <SaveOutlined/>,
                type: 'primary',
                htmlType: 'submit',
                disabled: props.submitting,
                loading: props.submitting
            }
        }
    ]

    const checkVisibleItem = (values, item) => {
        if (item.visible === undefined) {
            return true;
        }

        if (item.visible && item.visibleValue !== undefined) {
            return checkJsonProp(values, item.visible) === item.visibleValue;
        }

        return checkJsonProp(values, item.visible);
    }

    const isSepaIBAN = () => {
        const {Participant} = checkIBANResult ?? {}
        return Participant?.SepaInst || Participant?.SepaSCT || Participant?.SepaSDD
    }

    const checkVisibleTarget2Item = (values, item) => {
        if (item?.target2Item) {
            const accountNr = values?.Payment?.BeneficiaryAccountNumber
            if (!isSepaIBAN() || checkStringIsFirstTwoNumbers(accountNr)) {
                setIsTarget2(true)
                return true
            }
        }
        setIsTarget2(false)
        return false
    }

    const checkNotHiddenTarget2Item = (values, item) => {
        if (item?.hidden === 'target2Payment') {
            return !checkVisibleTarget2Item(values, item)

            const accountNr = values?.Payment?.BeneficiaryAccountNumber
            if (
                checkStringIsFirstTwoNumbers(accountNr) &&
                !isSepaIBAN()
            ) {
                return false
            }
        }
        return true
    }

    const getOptions = (options, targetName) => {
        if (targetName !== 'Payment.IsPurposeSelected') return options
        if (isTarget2)
            return options.map(opt =>
                opt.label === 'Code' ? {...opt, disabled: true} : opt
            )
        return options
    }

    const routePath = `${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`

    return (
        <div className="form w-100">
            <div className="col-12 mb-3">
                <NavLink className="form-title mr-3 text-gray-basic"
                         to={routePath.replace(':templateType', PAYMENT_TEMPLATE_NEW)}>
                    {trb(i18.PaymentTypes.NewPayment)}
                </NavLink>
                <NavLink className="form-title mr-3 text-gray-basic "
                         to={routePath.replace(':templateType', PAYMENT_TEMPLATE_INTERNATIONAL)}>
                    {trb(i18.ContentMenu.InternationalPayment)}
                </NavLink>
            </div>
            <Form
                onSubmit={props.submit}
                decorators={props.decorator}
                initialValues={{...initialData}}
                validate={values => {
                    return TemplateValidation(values)
                }}
                render={({handleSubmit, form, submitting, pristine, values}) => {
                    return (
                        <form className="mb-3 row" onSubmit={handleSubmit}>
                            {props.name !== Payment.FEE && currentFormSpy()}
                            {currentForm?.parts?.map((item, index) => {
                                    return (!checkJsonExist(values, item.visible) ||
                                        (checkJsonProp(values, item.visible) &&
                                            checkJsonProp(values, item.visible) === true)) && (
                                        item.moduleComponent === 'FeeModule' ? (
                                            currentFormSpy(
                                                values.Payment && values.Payment.FeeProvider,
                                                index,
                                                values,
                                                form
                                            )
                                        ) : (
                                            <div
                                                className={`${
                                                    item.className ? item.className : 'col-12'
                                                } form-part`}
                                                key={`part-${index}`}>
                                                {item.fields.map((item, index) =>
                                                    (checkVisibleItem(values, item) || checkVisibleTarget2Item(values, item)) && checkNotHiddenTarget2Item(values, item) ? (
                                                        <FormField
                                                            key={`part-field-${index}`}
                                                            className={item.className}
                                                            classNameTitle={item.classNameTitle}
                                                            classNameField={item.classNameField}
                                                            classNameError={item.classNameError}
                                                            classNameNumber={item.classNameNumber}
                                                            title={item.title}
                                                            name={item.name}
                                                            component={item.component}
                                                            componentOptions={item.componentOptions}
                                                            formatNumber={item.formatNumber}
                                                            inline={appEnvSME ? item.inline : true}
                                                            options={
                                                                item.optionsFunction
                                                                    ? item.optionsFunction(
                                                                        item.optionsFunctionParams,
                                                                        values,
                                                                        item.options
                                                                    )
                                                                    : item.options
                                                                        ? getOptions(item.options, item.name)
                                                                        : []
                                                            }
                                                            optionsLabel={item.optionsLabel}
                                                            optionsValue={item.optionsValue}
                                                            optionsKey={item.optionsKey}
                                                            optionsDisabled={item.optionsDisabled}
                                                            limitElements={item.limitElements}
                                                            onSearch={item.onSearch}
                                                            translate={item.translate}
                                                            validate={
                                                                item.validateCompose
                                                                    ? composeValidatorsJSON(
                                                                        item.validate,
                                                                        props.form
                                                                    )
                                                                    : item.validate
                                                            }
                                                            // validate={item.validate}
                                                            disabled={item.disabled || props.submitting}
                                                            maxLength={item.maxLength}
                                                            html={item.html}
                                                            type={item.type}
                                                            defaultValue={item.defaultValue}
                                                            notify={submitting ? null : item.notify}
                                                            error={submitting ? null : item.error}
                                                            noFilter={item.noFilter}
                                                            highlightOne={item.highlightOne}
                                                            small={item.small}
                                                            customChecks={item.customChecks}
                                                            optionsColor={item.optionsColor}
                                                        />
                                                    ) : null
                                                )}
                                            </div>
                                        )
                                    )
                                }
                            )}
                            <div className="mb-3 text-right col-12">
                                <Button.Group>
                                    {buttons.map((item, index) => (
                                        <Button key={index} onClick={item.func} {...item.props}>
                                            {trb(item.title)}
                                        </Button>
                                    ))}
                                </Button.Group>
                            </div>
                            {props.errors && (
                                <Alert
                                    className="mb-3 col-12"
                                    message={trb(i18.ErrorTitles.SaveTemplateErrors)}
                                    description={
                                        <ul>
                                            {props.errors.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    }
                                    type="error"
                                />
                            )}
                        </form>
                    )
                }}
            />
        </div>
    )
}
