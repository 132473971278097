import React, {useEffect, useState} from "react";
import {CustomIcon, getWindowSize, trb} from "@utilities";
import {Button, Menu} from "antd";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

export const ContentMenuMobile = () => {
    const windowSize= getWindowSize();
    const navigate = useNavigate();

    const [menuShow, setMenuShow] = useState(false)


    const {contentMenu, contentTitle} = useSelector(state => state.MenuReducer);


    useEffect(() => {
        setMenuShow(false)
    }, [contentMenu])


    const toggleMenu = () => {
        setMenuShow(!menuShow)
    }

    const clickMenu = e => {
        navigate(e.key)
        toggleMenu()
    }


    if (!contentMenu || !windowSize.mobileCheck) {
        return <></>
    }

    const selectedLinks = contentMenu.filter(({path}) => window.location.pathname.indexOf(path) !== -1).map(({path}) => path)


    return (<>
        <div className='d-flex'>
            {contentTitle}
            <Button
                type="primary"
                size="small"
                className="eb_content_menu_mobile_btn"
                onClick={toggleMenu}>
                <CustomIcon
                    type="custom"
                    icon="caretLeft"
                    className={menuShow ? 'rotate-270' : 'rotate-90'}
                />
            </Button>
        </div>
        <div
            className={`eb_content_menu_mobile_wrapper ${menuShow &&
            'eb_content_menu_mobile_wrapper_active'}`}>
            <Menu
                mode="inline"
                className="eb_content_menu_mobile"
                onClick={clickMenu}

                selectable={false}>
                {contentMenu.map((item, index) => (
                    <Menu.Item key={item.path}
                               className="page-title-menu-item">{trb(item.title)}</Menu.Item>
                ))}
            </Menu>
        </div>
    </>)
}
