import React from 'react'
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client';

import {TranslationsInit} from './Utilities'
import {store, TranslationContexts} from '@redux'
import {App} from './App'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {RefreshToken} from "@components";
import {history} from "@config";
import {BrowserRouter} from "react-router-dom";

require('./App/AppSmeCSS.less')

window.VERSION = sessionStorage.getItem('VERSION') || process.env.REACT_APP_VERSION

let dsn = 'https://de490db168754275b071645a308b1f2f@o966103.ingest.sentry.io/6409628' // sentry project: elkusweb-react-sme

Sentry.init({
     dsn,
    integrations: [new Integrations.BrowserTracing(),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        })],
    release: window.VERSION, environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
})

/* Sentry.captureMessage(
  `Sentry init. Environment - ${process.env.REACT_APP_SENTRY_ENVIRONMENT}`
) */
const container = document.getElementById('root');
const root = createRoot(container)
root.render(
    <Provider store={store}>
        <RefreshToken />
        <TranslationsInit context={TranslationContexts.eBank}/>
        <TranslationsInit context={TranslationContexts.classificators}/>
        <BrowserRouter history={history}>
            <App/>
        </BrowserRouter>
    </Provider>
)
