import React from 'react';
import PropTypes from "prop-types";

import {checkJsonProp, trb, i18} from "../../../Utilities";
import {ContentNoData, Number} from "../../../Components";

export const OnGoingOperations = (props) => {
    const {data} = props;

    if (!data || !data.Operations || data.Operations.length === 0) {
        return <ContentNoData/>
    }

    return <div className="eb_table">
        <div className="row eb_table_head d-none d-lg-flex">
            <div className="col-2">{trb(i18.Labels.OperationsData) /*Data*/}</div>
            <div className="col-6">{`${trb(i18.Labels.OperationsPayer)} / ${trb(i18.Labels.OperationsPurpose)} / ${trb(i18.Labels.OperationsCode)}` /*Gavėjas - Mokėtojas / Mokėjimo paskirtis / Kodas*/}</div>
            <div className="col-4">{trb(i18.Labels.OperationsCosts) /*Išlaidos*/}</div>
        </div>
        {data.Operations && data.Operations.length > 0 && data.Operations.map((item, index) =>
            <div className="row eb_table_row" key={index}>
                <div className="col-6 col-md-6 col-lg-2 order-1 order-sm-1 order-md-1 order-lg-1"><div className="d-block d-lg-none text-black-50">{trb(i18.Labels.OperationsData) /*Data*/}</div> {item.DateFormatted}</div>
                <div className="col-12 col-md-12 col-lg-8 order-3 order-sm-3 order-md-3 order-lg-2">
                    <div className="d-block d-lg-none text-black-50">{`${trb(i18.Labels.OperationsPayer)} / ${trb(i18.Labels.OperationsPurpose)} / ${trb(i18.Labels.OperationsCode)}` /*Gavėjas - Mokėtojas / Mokėjimo paskirtis / Kodas*/}</div>
                    <div className="eb_fw_600">{item.ReceiverName} <span className="eb_fw_400 text-word-wrap">{item.Description}</span></div>
                </div>
                <div className="col-6 col-md-6 col-lg-2 order-2 order-sm-2 order-md-2 order-lg-3 text-right">
                    <div className="d-block d-lg-none text-black-50">{trb(i18.Labels.OperationsCosts) /*Išlaidos*/}</div>
                    <div
                        className={checkJsonProp(item, 'Amount.0.Value') > 0 ? 'text-error eb_fw_600' : 'text-primary eb_fw_600'}
                    >{checkJsonProp(item, 'Amount.0.Value') ? <Number value={item.Amount[0].Value}/> : null}</div>
                </div>
            </div>
        )}
    </div>;
};

OnGoingOperations.propTypes = {
    data: PropTypes.shape({
        Operations: PropTypes.arrayOf(PropTypes.shape({
            Amount: PropTypes.arrayOf(PropTypes.shape({
                FeeDirection: PropTypes.number,
                IsPrimary: PropTypes.bool,
                Value: PropTypes.number.isRequired,
                Text: PropTypes.string.isRequired,
            })),
            Date: PropTypes.string.isRequired,
            Description: PropTypes.string.isRequired,
            ID: PropTypes.number.isRequired,
            IsPLAISBlock: PropTypes.bool.isRequired,
            ReceiverAccount: PropTypes.string,
            ReceiverName: PropTypes.string,
            SenderAccount: PropTypes.string,
            SenderName: PropTypes.string,
            Status: PropTypes.number,
            StatusFormatted: PropTypes.string.isRequired,
            UserPermission: PropTypes.number.isRequired,
        })),
    }),
};