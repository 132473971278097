import {combineReducers} from 'redux'
import {
    AMCReducers,
    AMLReducer,
    BulkFileReducer,
    CMCReducers,
    DepositReducers,
    DMCReducers,
    FeeReducers,
    JWEReducer,
    KYCReducers,
    MessagingReducers,
    MonitoringReducers,
    OMCReducers,
    PMCReducers,
    translationReducer,
    UACReducers,
    UTLReducers,
    VersionReducer
} from '@redux/Services'
import {IframeStorageReducer, languageReducer, MenuReducer, printReducer} from '../Local'

export const rootReducer = history =>
    combineReducers({
        AMCReducers,
        CMCReducers,
        DMCReducers,
        FeeReducers,
        MessagingReducers,
        OMCReducers,
        PMCReducers,
        languageReducer,
        printReducer,
        translationReducer,
        UACReducers,
        UTLReducers,
        KYCReducers,
        MonitoringReducers,
        DepositReducers,
        MenuReducer,
        IframeStorageReducer,
        AMLReducer,
        JWEReducer,
        BulkFileReducer,
        VersionReducer
    })
