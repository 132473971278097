import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {aPIPaths, methods, requestEBank} from '@redux';

const initialState = {
    error: null,
    sending: false,
    internationalPayment: null,
}

export const fetchAMLInternationalPaymentCurrency = createAsyncThunk(
    'aml/fetchAMLInternationalPaymentCurrency',
    async (currency, thunkAPI) => {
        try {
            const response = await requestEBank(`${aPIPaths.get_AML_InternationalPayment}${currency}`, {
                method: methods.get
            })
            return response.data
        } catch (error) {
            return error?.response?.data ?? error
        }
    }
)

export const clearValues = createAction('aml/clearInternationalPaymentValues')

const AMLSlice = createSlice({
    name: 'aml',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchAMLInternationalPaymentCurrency.fulfilled, (state, { payload }) => {
            return { ...state, internationalPayment: payload, sending: false, error: null }
        })

        builder.addCase(fetchAMLInternationalPaymentCurrency.pending, (state, { payload }) => {
            return { ...state, sending: true, error: null }
        })

        builder.addCase(fetchAMLInternationalPaymentCurrency.rejected, (state, action) => {
            return { ...state, sending: false, error: action?.error?.message }
        })
        builder.addCase(clearValues, (state, action)=>{
            return {...state, ...initialState}
        })
    }
})

// Extract the action creators object and the reducer
const { reducer } = AMLSlice
// Extract and export each action creator by name
// Export the reducer, either as a default or named export
export { reducer as AMLReducer }
