import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Checkbox, Modal} from 'antd'

import {ContentLoader, ContentNoData, Notification, Number, OperationApproval} from '@components'
import {currencyTextValue, EUR, i18, numberFormat, translationGroups, trb} from '@utilities'
import {OMCActions, OMCConstants, PMCActions} from '@redux'
import moment from "moment/moment";

const ButtonGroup = Button.Group
const maxCheckedRow = 30
const messagesEndRef = React.createRef()

export const UnsignedPayments = () => {
    const dispatch = useDispatch();
    const {UOLR, sendUOLR, PSUOR} = useSelector(state => state.OMCReducers)
    const {
        sendPTR,
        PTR,
        SDPVR
    } = useSelector(state => state.PMCReducers)
    const {ATR, sendATR} = useSelector(state => state.UACReducers)
    const {language} = useSelector(state => state.languageReducer)
    const [state, setState] = useState({
        selectedRowKeys: [], send: false, modalVisible: null, itemInst: null, isError: false, errors: []
    })

    const loading = sendUOLR || sendATR || sendPTR || state.send;

    const loadData = () => {
        dispatch(OMCActions.postUOLR())
    }

    useEffect(() => {
        loadData()
    }, [])


    const selectRow = (checked, id) => {
        if (checked) {
            setState({
                ...state, selectedRowKeys: [...state.selectedRowKeys, id]
            })
        } else {
            setState({
                ...state, selectedRowKeys: state.selectedRowKeys.filter(e => e !== id)
            })
        }
    }

    const clickDeleteButton = () => {
        const {selectedRowKeys} = state

        dispatch(OMCActions.postDUOR({ItemsIDs: selectedRowKeys})).then(response => {
            if (response.type === OMCConstants.POST_OMC_DUOR_SUCCESS) {
                if (response.payload.IsSuccessfull) {
                    Notification({
                        type: 'success', message: trb(i18.Messages.DeletedSuccess)
                    })
                    dispatch(OMCActions.postUOLR())
                    setState({...state, selectedRowKeys: []})
                } else {
                    Notification({
                        type: 'error', message: trb(i18.Messages.ResponseError)
                    })
                }
            }
        })
    }

    const clickApproveButton = (itemInst = null) => {
        const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys, {Operations} = UOLR

        setState({
            ...state, send: true, itemInst, approve: false, approveInst: false
        })

        const checkFeeData = {
            GroupPayments: Operations.filter(item => selectedRowKeys.includes(item.ID)).map(item => ({
                ID: String(item.ID), Description: item.Description, Amount: item.Amount[0].Value
            }))
        }


        dispatch(PMCActions.postSDPVR(checkFeeData))
    }

    useEffect(() => {
        if (SDPVR?.IsSuccessfull) {
            setState({
                ...state, modalVisible: true, send: false, approve: false, approveInst: false
            })

        } else if (SDPVR?.IsSuccessfull === false) {
            setState({
                ...state,
                approve: false,
                approveInst: false,
                RQ_ID: null,
                AuthType: null,
                Code: null,
                send: false,
                submitting: false
            })
            showErrors(SDPVR)
        }

    }, [SDPVR])

    const clickApproveModal = () => {
        const {itemInst} = state
        const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys

        setState({
            ...state, send: true
        })
        dispatch(OMCActions.postPSUOR({ItemsIDs: selectedRowKeys, Language: language}))
    }

    useEffect(() => {

        let newState = {...state};
        if (PSUOR?.IsSuccessfull) {
            if (PSUOR?.ErrorMessage) {
                showErrors(PSUOR)
                loadData()
                return
            }

            const rq = PSUOR?.AuthType && (PSUOR?.AuthType.toUpperCase() === 'SMS' || PSUOR?.AuthType.toUpperCase() === 'BIOFACE') ? PSUOR?.SMSToken : PSUOR?.iSignToken

            newState = state.itemInst ? {
                ...newState,
                approveInst: state.itemInst.ID,
                AuthType: PSUOR?.AuthType,
                RQ_ID: rq,
                Code: PSUOR?.ControlCode
            } : {
                ...newState,
                approve: true,
                AuthType: PSUOR?.AuthType,
                RQ_ID: rq,
                Code: PSUOR?.ControlCode
            }

        } else if (PSUOR?.IsSuccessfull === false) {
            newState = {
                ...newState,
                approve: false,
                approveInst: false,
                RQ_ID: null,
                AuthType: null,
                Code: null,
                send: false,
                submitting: false
            }
            showErrors(PSUOR)
        }
        newState = {
            ...newState, send: PSUOR?.IsSuccessfull === undefined && modalVisible
        }
        setState(newState);

    }, [PSUOR])

    const showErrors = response => {
        setState({
            ...state, isError: true, send: false, errors: {
                errorList: response?.PaymentErrorsItems?.filter((items, index) => index === response.PaymentErrorsItems.findIndex(item => item.ErrorCode === items.ErrorCode)) || [{ErrorCode: response?.ErrorCode || 'Unknown'}]
            }
        })
    }

    const hideErrors = () => {
        setState({
            ...state, isError: false, send: false, errors: {errorList: []}
        })
    }

    const cancelApprove = ErrorCode => {
        let message = ErrorCode
        if (message && message?.replace) {
            message = message.replace('.', '')
        }
        setState({
            ...state, approve: false, approveInst: false, RQ_ID: null, AuthType: null, Code: null, send: false
        })
        Notification({
            type: 'warning',
            message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.ApproveCanceled,
            description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.ApproveCanceled
        })
    }

    const BadApproveCode = ErrorCode => {
        let message = ErrorCode
        if (message?.replace) {
            message = message.replace('.', '')
        }
        setState({
            ...state,
            approve: false,
            approveInst: false,
            AuthType: null,
            Code: null,
            send: false,
            submitting: false
        })
        Notification({
            type: 'error',
            message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.BadApproveCode,
            description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.BadApproveCode
        })
    }

    const successApprove = () => {
        const {itemInst} = state
        const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys

        dispatch(OMCActions.removeUOLRCompleted(selectedRowKeys))

        setState({
            ...state,
            approve: false,
            approveInst: false,
            send: false,
            currentStep: state.currentStep + 1,
            selectedRowKeys: [],
            modalVisible: false
        })
        Notification({
            type: 'success',
            message: i18.NotificationTitles.SuccessApprove,
            description: i18.NotificationMessages.SuccessApprove
        })
    }

    const submitSmsApprove = values => {
        dispatch(OMCActions.postSUORSMS(values)).then(response => {
            const ErrorCode = response?.payload?.ErrorCode
            if (response.payload.IsSuccessfull) {
                successApprove()
            } else {
                BadApproveCode(ErrorCode)
            }
        })
    }

    const submitMobileApprove = async () => {
        const {RQ_ID} = state
        const response = await dispatch(OMCActions.postSUOR({iSignToken: RQ_ID}))
        const AuthType = response?.payload?.AuthType
        const IsSuccessfull = response?.payload?.IsSuccessfull
        const ErrorCode = response?.payload?.ErrorCode
        if (AuthType === null && IsSuccessfull === false) {
            return true
        }
        if (AuthType === 'false') {
            cancelApprove(ErrorCode)
        } else if (IsSuccessfull === true) {
            successApprove()
        }
        return false
    }

    const checkCountMore = (disabled = true) => {
        if (disabled) {
            Notification({
                type: 'error',
                message: trb(i18.Messages.NoSignedPaymentErrorMuchMore),
                description: i18.NotificationMessages.NoSignedPaymentErrorMuchMore + `: ${maxCheckedRow}`
            })
        }
    }

    const checkRow = (e, id = null) => {
        const {selectedRowKeys} = state

        if (selectedRowKeys.length === maxCheckedRow - 1) checkCountMore()
        selectRow(e.target.checked, id)
    }

    const checkAllRow = e => {
        const selectedRowKeys = [];
        UOLR.Operations.slice(0, maxCheckedRow).forEach((item, index) => {
            if (e.target.checked) {
                selectedRowKeys.push(item.ID)
            }
        })
        setState({...state, selectedRowKeys});
    }

    const {modalVisible} = state, PaymentsTotalAmount = SDPVR?.PaymentsTotalAmount,
        PaymentsTotalCurrency = SDPVR?.GroupPayments?.[0]?.currency, PaymentsCount = SDPVR?.PaymentsCount

    if (sendUOLR) {
        return <ContentLoader/>
    }

    if (!UOLR) {
        return <ContentNoData/>
    }
    const {selectedRowKeys, isError, errors} = state

    return (<>
        {modalVisible && (<Modal
            width={700}
            centered
            style={{top: 0}}
            bodyStyle={{paddingTop: 0, paddingBottom: 0}}
            visible={modalVisible}
            title={<div className="eb_fw_600">
                {trb(!!state.itemInst ? i18.Labels.PaymentSubmitModalInstant : i18.Labels.PaymentSubmitModal)}
            </div>}
            onCancel={() => setState({...state, modalVisible: false, itemInst: null})}
            closable
            footer={[<Button.Group key="footer">
                <Button
                    type="primary"
                    disabled={(loading || state.approve || state.approveInst)}
                    onClick={clickApproveModal}>
                    {trb(i18.Buttons.Submit)}
                </Button>
                <Button
                    type="default"
                    style={{marginLeft: 0}}
                    onClick={() => setState({
                        ...state,
                        modalVisible: false,
                        itemInst: null,
                        approve: false,
                        approveInst: false,
                        RQ_ID: null,
                        AuthType: null,
                        Code: null,
                        send: false,
                        submitting: false
                    })}>
                    {trb(i18.Buttons.Cancel)}
                </Button>
            </Button.Group>]}
        >
            <div className="px-0 pb-3 p-md-3">
                {SDPVR?.Report?.map(({
                                         Amount,
                                         Commission,
                                         CommissionCurrency,
                                         Currency,
                                         Quantity,
                                         ExecutionDate,
                                         CutOffTime
                                     }, index) => <div className="row" key={index}>
                    <div
                        className="col-8 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                        {trb(i18.Labels.PaymentsCount)}
                    </div>
                    <div
                        className="col-4 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-10">
                        <Number
                            value={Quantity}
                            format={numberFormat().number}
                        />
                    </div>
                    <div
                        className="col-12 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                        {trb(i18.Labels.PaymentsTotalAmount)}
                        <span
                            className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                        <Number value={Amount}/>
                            {` ${Currency}`}
                            </span>
                    </div>
                    <div
                        className="col-12 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                        {trb(i18.Labels.PaymentsTotalCommFee)}
                        <span
                            className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                        <Number value={Commission}/>
                            {` ${CommissionCurrency}`}
                    </span>
                    </div>
                    <div className="col-12 col-md-4 py-1 text-black-50 my-auto">
                        {ExecutionDate ? `${trb(i18.Labels.ExecutionDateValue, {executionDate: moment(ExecutionDate).format('YYYY-MM-DD')})}` : ''}
                    </div>
                    <div className="col-12 col-md-5 py-1 text-black-50 my-auto">
                        {CutOffTime ? `${trb(i18.Labels.ExecutionDateCutOff, {sameDayCutOff: CutOffTime})}` : ''}
                    </div>
                </div>)}
            </div>

            {(loading && !state.approve) && (<ContentLoader/>)}
            {state.approve && (<div className="pb-3">
                <OperationApproval
                    RQ_ID={state.RQ_ID}
                    AuthType={state.AuthType}
                    code={state.Code}
                    cancelApprove={cancelApprove}
                    successApprove={successApprove}
                    submitSmsApprove={submitSmsApprove}
                    submitMobileApprove={submitMobileApprove}
                    approveCount={PaymentsCount}
                />
            </div>)}
            {state.approveInst && (<div className="pb-3">
                <OperationApproval
                    RQ_ID={state.RQ_ID}
                    AuthType={state.AuthType}
                    code={state.Code}
                    cancelApprove={cancelApprove}
                    successApprove={successApprove}
                    submitSmsApprove={submitSmsApprove}
                    submitMobileApprove={submitMobileApprove}
                    approveSum={{
                        sum: PaymentsTotalAmount, currency: PaymentsTotalCurrency
                    }}
                />
            </div>)}
        </Modal>)}
        {!UOLR?.Operations?.length && (<div className="mb-2 w-100">
            <ContentNoData/>
        </div>)}
        {!!UOLR?.Operations?.length && (<>
            <div className="mb-2 w-100">
                <div className="eb_table_wrapper eb_table_nosigned_payment">
                    <div className="eb_table">
                        <div className="row eb_table_head d-flex d-lg-flex">
                            <div className="col-12 col-lg-2 px-0 px-lg-3 py-1 py-lg-2 text-nowrap d-none d-lg-block">
                                <Checkbox
                                    className="mr-2"
                                    disabled={loading}
                                    onChange={checkAllRow}
                                />
                                {trb(i18.Labels.Date)}
                            </div>
                            <div className="col-1 text-nowrap px-0 d-block d-lg-none">
                                <Checkbox
                                    className="mr-2"
                                    disabled={loading}
                                    onChange={checkAllRow}
                                />
                                {trb(i18.Labels.SelectAll)}
                            </div>
                            <div className="col-3 d-none d-lg-block">
                                {trb(i18.Labels.SenderData)}
                            </div>
                            <div className="col-3 d-none d-lg-block">
                                {trb(i18.Labels.ReceivedData)}
                            </div>
                            <div className="col-2 d-none d-lg-block">
                                {trb(i18.Labels.PaymentDescription)}
                            </div>
                            <div className="col-2 text-right d-none d-lg-block">
                                {trb(i18.Labels.Amount)}
                            </div>
                        </div>
                        {UOLR.Operations.map((item, index) => {
                            const disabled = selectedRowKeys.length === maxCheckedRow && !selectedRowKeys.includes(item.ID)
                            return (<>
                                <div
                                    className="row eb_table_row text-word-wrap py-1 py-lg-0 overflow-hidden"
                                    key={index}>
                                    <div
                                        className="col-12 col-lg-2 px-0 px-lg-3 py-1 py-lg-2 text-nowrap"
                                        onClick={() => checkCountMore(disabled)}>
                                        <div className="row">
                                            <div className="d-none d-lg-block col-1 col-lg-12 mb-2">
                                                <Checkbox
                                                    checked={selectedRowKeys.includes(item.ID)}
                                                    className="mr-2"
                                                    disabled={loading || disabled}
                                                    onChange={e => checkRow(e, item.ID)}
                                                />
                                                {item.DateFormatted}
                                            </div>
                                            <div
                                                style={{position: 'relative', left: 15}}
                                                className="d-block d-lg-none col-1 col-lg-12 mb-2 px-0">
                                                <Checkbox
                                                    checked={selectedRowKeys.includes(item.ID)}
                                                    disabled={loading || disabled}
                                                    onChange={e => checkRow(e, item.ID)}
                                                />
                                            </div>
                                            <div className="col-4 mb-2 text-black-50 d-lg-none">
                                                {trb(i18.Labels.Date)}
                                            </div>
                                            <div className="col-7 d-lg-none mb-2">
                                                      <span className="eb_fw_300">
                                                        {item.DateFormatted}
                                                      </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3 px-0 px-lg-3 py-1 py-lg-2">
                                        <div className="row">
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 mb-2 d-block d-lg-none text-black-50">
                                                {trb(i18.Labels.SenderData)}
                                            </div>
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 col-lg-12 mb-2">
                                                <div>{item.SenderName}</div>
                                                <div
                                                    className="eb_fw_300">
                                                    {item.SenderAccount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3 px-0 px-lg-3 py-1 py-lg-2">
                                        <div className="row">
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 mb-2 d-block d-lg-none text-black-50">
                                                {trb(i18.Labels.ReceivedData)}
                                            </div>
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 col-lg-12 mb-2">
                                                <div>{item.ReceiverName}</div>
                                                <div className="eb_fw_300">
                                                    {item.ReceiverAccount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2 px-0 px-lg-3 py-1 py-lg-2">
                                        <div className="row">
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 mb-2 d-block d-lg-none text-black-50">
                                                {trb(i18.Labels.PaymentDescription)}
                                            </div>
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-11 col-lg-12 mb-2">
                                                <div>{item.Description}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2 px-0 px-lg-3 py-1 py-lg-2 text-right">
                                        <div className="row text-start text-lg-end">
                                            <div className="col-1 mb-2 d-lg-none px-0"/>
                                            <div className="col-4 mb-2 d-block d-lg-none text-black-50">
                                                {trb(i18.Labels.Amount)}
                                            </div>
                                            <div className="col-7 col-lg-12 text-nowrap text-primary eb_fw_600">
                                                {currencyTextValue(item.Amount[0].Value, item.Amount[0].Currency || 'EUR')}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {item.PaymentSystem === "INST" && (UOLR.Operations.length !== selectedRowKeys.length) && !selectedRowKeys.includes(item.ID) && (
                                    <div className="text-end mb-4">
                                        <Button
                                            onClick={() => {
                                                setState((prevState) => ({
                                                    ...prevState, itemInst: item
                                                }));
                                                clickApproveButton(item);
                                            }}
                                        >
                                            {trb(i18.Buttons.ApproveINSTPayment)}
                                        </Button>
                                    </div>)}
                            </>)
                        })}
                    </div>
                </div>
            </div>

            <div ref={messagesEndRef} className="text-right mb-2 w-100">
                <ButtonGroup className="unsigned-payments-btn-group">
                    <Button
                        onClick={clickDeleteButton}
                        disabled={selectedRowKeys.length <= 0 || modalVisible || loading}
                        className="col-12 col-md-auto"
                        style={{marginBottom: 5}}
                    >
                        {trb(i18.Buttons.DeleteSelectedPayments)}
                    </Button>
                    <Button
                        type={selectedRowKeys.length <= 0 ? 'default' : 'primary'}
                        onClick={() => clickApproveButton()} //Don't delete () => () check clickApproveButton action
                        disabled={selectedRowKeys.length <= 0 || modalVisible || loading}
                        className="col-12 col-md-auto">
                        {trb(i18.Buttons.SignSelectedPayments)}
                    </Button>
                </ButtonGroup>
            </div>
            {(loading && !state.approve & !state.approveInst && !modalVisible) && (<ContentLoader
                style={{backgroundColor: 'white', paddingTop: 30}}
            />)}
            {isError && (<Alert
                className="mb-3 w-100"
                message={trb(i18.ErrorTitles.PaymentErrors)}
                description={<ul>
                    {errors?.errorList?.map((item, index) => (<li key={index}>
                        {trb(translationGroups.ErrorCode + item.ErrorCode)}
                    </li>))}
                </ul>}
                handleClose={() => hideErrors()}
                type="error"
            />)}
        </>)}
    </>)

}
