import React, {Component, useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import { Button, Drawer, Table } from 'antd'

import { MessagingActions } from '../../../../Redux'
import { ContentLoader, ContentNoData } from '../../../../Components'
import {
  appEnvSME,
  checkJsonProp,
  dateFormat,
  formatDate,
  getClassificators, getWindowSize,
  i18,
  ToJavaScriptDate2,
  trb
} from '@utilities'
import { ChatHeader, ChatMessages, NewChatMessage } from './Components'
import {DeleteOutlined} from "@ant-design/icons";

const ButtonGroup = Button.Group

export const InboxContainer = () => {
  const windowSize = getWindowSize()
  const { Chats, sendChats, Messages } = useSelector(state=> state.MessagingReducers)
  const [chatId, setChatId] = useState(null);
  const [reset, setReset] =  useState(false)

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(MessagingActions.getChats())
    dispatch(MessagingActions.getNewMessagesCount())
  },[])


  const clickChat = async (e, {ChatId}) => {
    e.stopPropagation()

    await dispatch(MessagingActions.getChatMessages(ChatId))
    setChatId(ChatId)
  }

  const onClose = () => {
    setChatId(null)
    dispatch(MessagingActions.getChats())
    dispatch(MessagingActions.getNewMessagesCount())
  }

  const submitNewMessage = async (values) => {
    const data = {
      Message: values.message,
      ChatId: Messages.ChatId
    }
    await dispatch(MessagingActions.postResponse(data))
    await dispatch(MessagingActions.getChatMessages(Messages.ChatId))
    values.message = ''
  }

  const convertDate = (value) => {
    if (!value) {
      return null
    }
    return ToJavaScriptDate2(value, dateFormat().dateTime)
  }

  const deleteChat = async (e, {ChatId}) => {
    e.stopPropagation()
    await dispatch(MessagingActions.deleteMessage({ chatId: ChatId }))
    await dispatch(MessagingActions.getChats())
    await dispatch(MessagingActions.getNewMessagesCount())
  }

  const deleteMessage = async (e) => {
    e.stopPropagation()
    await dispatch(MessagingActions.deleteMessage({ messageId: chatId}))
    await dispatch(MessagingActions.getChatMessages(Messages.ChatId))
  }


    if (sendChats) {
      return <ContentLoader />
    }
    if (!Chats) {
      return <ContentNoData />
    }

    const MSGL = getClassificators() ? getClassificators().MSGL : {}
    const keysMSGL = MSGL ? Object.keys(MSGL) : null
    const checkMSGL = (subject) => keysMSGL ? keysMSGL.find(item => item === subject) !== undefined : false

    const columns = appEnvSME ?
      [
        {
          title: trb(i18.Chat.LastMessage),
          dataIndex: 'LastMessage',
          render: (el) => <span className="text-nowrap">{formatDate(el)}</span>,
          width: 200
        },
        {
          title: trb(i18.Chat.Subject),
          dataIndex: 'Subject',
          render: (subject) => checkMSGL(subject) ? MSGL[subject] : subject
        }
      ]
      :
      [
        {
          title: trb(i18.Chat.DateCreated),
          dataIndex: 'DateCreated',
          className: 'col-3',
          render: (el) => <span className="text-nowrap">{formatDate(el)}</span>
        },
        {
          title: trb(i18.Chat.LastMessage),
          dataIndex: 'LastMessage',
          render: (el) => <span className="text-nowrap">{formatDate(el)}</span>
        },
        {
          title: trb(i18.Chat.Subject),
          dataIndex: 'Subject',
          render: (subject) => checkMSGL(subject) ? MSGL[subject] : subject
        },
        {
          title: trb(i18.Chat.NewMessage),
          dataIndex: 'NewMessages',
          render: (el) => <span className="text-nowrap">{el}</span>
        },
        {
          title: '',
          dataIndex: 'ChatId',
          render: (el, record) => {
            return record.CanDelete ?
              <ButtonGroup>
                {buttonDeleteChat && buttonDeleteChat.map((itemButton, index) =>
                  <Button
                    key={index}
                    onClick={(e) => itemButton.func && itemButton.func(e, record)}
                    {...itemButton.props}
                  />
                )}
              </ButtonGroup>
              :
              null
          }
        }
      ]

    const mobileColumns = appEnvSME ?
      [
        {
          title: trb(i18.Chat.Subject),
          dataIndex: 'Subject',
          render: (subject, item) => <>
            <span className="text-nowrap text-gray-basic">{formatDate(item.LastMessage)}</span>
            <br />
            <span>{checkMSGL(subject) ? MSGL[subject] : subject}</span>
          </>
        }
      ]
      :
      [
        {
          title: trb(i18.Chat.Subject),
          dataIndex: 'Subject',
          render: (subject, item) => <>
            <span className="text-nowrap text-gray-basic">{formatDate(item.LastMessage)}</span>
            <br />
            <span>{checkMSGL(subject) ? MSGL[subject] : subject}</span>
            <div className={item.NewMessages ? 'widget' : 'd-none'}>
              <div className="widget-inner">{item.NewMessages}</div>
            </div>
            <br />
            {item.CanDelete ?
              <ButtonGroup style={{paddingTop: 5}}>
                {buttonDeleteChat && buttonDeleteChat.map((itemButton, index) =>
                  <Button
                    key={index}
                    onClick={(e) => itemButton.func && itemButton.func(e, item)}
                    {...itemButton.props}
                  />
                )}
              </ButtonGroup> : null}
          </>
        }
      ]

    const buttonDeleteChat = [{
        title: i18.Buttons.Submit,
        func: deleteChat,
        props: {
          icon: <DeleteOutlined />,
          type: 'primary',
          htmlType: 'submit'
          //disabled: props.submitting,
          //loading: props.submitting,
        }
      }],
      buttonDeleteMessage = [{
        title: i18.Buttons.Submit,
        func: deleteMessage,
        props: {
          icon: <DeleteOutlined />,
          type: 'primary',
          htmlType: 'submit'
        }
      }]

    return <>
      {/*{!appEnvSME ?*/}
      <Table
        id="InboxMessagesTable"
        dataSource={Chats}
        columns={windowSize.mobileCheck ? mobileColumns : columns}
        showHeader={!windowSize.mobileCheck}
        pagination={false}
        className="w-100 mobile-inbox-container"
        onRow={(item, index) => {
          return {
            onClick: e => {
              clickChat(e, item)
            }
          }
        }}
      />
      <Drawer
        width={1000}
        onClose={onClose}
        visible={chatId !== null}
      >
        <div className="bbs_tab_content_inner" id="MessagesDrawer">
          <ChatHeader
            data={Messages}
            Subject={checkMSGL(checkJsonProp(Messages, 'Subject')) ? MSGL[Messages.Subject] : checkJsonProp(Messages, 'Subject')}
          />
          <ChatMessages data={Messages} />
          <NewChatMessage submit={submitNewMessage} closeChat={onClose} deleteMessage={deleteMessage} />
        </div>
      </Drawer>
    </>

}
