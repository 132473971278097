import React from 'react';
import PropTypes from 'prop-types';

import {i18, trb} from "../../../Utilities";
import {ContentLoader} from "../../ContentLoader";
import {ContentNoData} from "../../ContentNoData";
import {PrintHeader} from "../PrintHeader";

export const PrintPayment = (props) => {
    if (props.sending) {
        return <ContentLoader/>;
    }

    if (!props.data) {
        return <ContentNoData/>;
    }

    const currentPrintObj = {
        title: props.data.PaymentPurpose,
        parts: [
            {
                title: i18.Labels.Payer,
                rows: [
                    {
                        label: i18.Labels.Date,
                        value: props.data.PaymentDateFormatted
                    }, {
                        label: i18.Labels.PayerAccountNumber,
                        value: props.data.PayerAccountNumber
                    }, {
                        label: i18.Labels.PayerBank,
                        value: props.data.PayerBank
                    }, {
                        label: i18.Labels.PayerTitle,
                        value: props.data.PayerName
                    }, {
                        label: i18.Labels.PayerAddress,
                        value: props.data.PayerAddress
                    }, {
                        label: i18.Labels.PayerIdentityType,
                        value: props.data.PayerIdentType
                    }, {
                        label: i18.Labels.PayerIdentityCode,
                        value: props.data.PayerIdentCode
                    }, {
                        label: i18.Labels.InitPayerType,
                        value: props.data.InitPayerType
                    }, {
                        label: i18.Labels.InitPayerTitle,
                        value: props.data.InitPayerName
                    }, {
                        label: i18.Labels.InitPayerIdentTypeCode,
                        value: props.data.InitPayerIdentTypeCode
                    }, {
                        label: i18.Labels.InitPayerIdentTypeValue,
                        value: props.data.InitPayerIdentTypeValue
                    }
                ]
            }, {
                title: i18.Labels.Payee,
                rows: [
                    {
                        label: i18.Labels.PayeeAccountNumber,
                        value: props.data.PayeeAccountNumber
                    }, {
                        label: i18.Labels.PayeeBank,
                        value: props.data.PayeeBank
                    }, {
                        label: i18.Labels.PayeeTitle,
                        value: props.data.PayeeName
                    }, {
                        label: i18.Labels.PayeeAddress,
                        value: props.data.PayeeAddress
                    }, {
                        label: i18.Labels.PayeeIdentityType,
                        value: props.data.PayeeIdentType
                    }, {
                        label: i18.Labels.PayeeIdentityCode,
                        value: props.data.PayeeIdentCode
                    }, {
                        label: i18.Labels.FinalPayeeType,
                        value: props.data.FinalPayeeType
                    }, {
                        label: i18.Labels.FinalPayeeName,
                        value: props.data.FinalPayeeName
                    }, {
                        label: i18.Labels.FinalPayeeIdentTypeCode,
                        value: props.data.FinalPayeeIdentTypeCode
                    }, {
                        label: i18.Labels.FinalPayeeIdentTypeValue,
                        value: props.data.FinalPayeeIdentTypeValue
                    }
                ]
            }, {
                title: i18.Labels.Information,
                rows: [
                    {
                        label: i18.Labels.Purpose,
                        value: props.data.PaymentPurpose
                    }, {
                        label: i18.Labels.Amount,
                        value: props.data.PaymentAmount
                    }, {
                        label: i18.Labels.Currency,
                        value: props.data.PaymentCurrency
                    }, {
                        label: i18.Labels.Fee,
                        value: props.data.CommFee
                    }, {
                        label: i18.Labels.FeeCurrency,
                        value: props.data.PaymentCurrency
                    }
                ]
            }, {
                title: i18.Labels.AdditionalInformation,
                rows: [
                    {
                        label: i18.Labels.PaymentUniqueCode,
                        value: props.data.PaymentUniqueCode
                    }
                ]
            }
        ]
    };

    return <div className="eb_print_wrapper">
        <PrintHeader
            data={props.data}
            COMPANY={props.COMPANY}
        />
        <div className="eb_print_payment_title">{currentPrintObj.title}</div>
        {currentPrintObj.parts.map((item, index) =>
            <div key={index} className="eb_print_payment_part">
                <div className="eb_print_payment_part_title">{trb(item.title)}</div>
                <table className="eb_print_payment_table">
                    <tbody>
                    {item.rows.map((item, index) =>
                        item.value !== null || true ? (
                            <tr key={index}>
                                <th>{trb(item.label)}</th>
                                <td className="text-word-wrap">{item.value}</td>
                            </tr>
                        ) : null
                    )}
                    </tbody>
                </table>
            </div>
        )}
    </div>
};

PrintPayment.propTypes = {
    sending: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};