import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {Tabs} from 'antd'

import {MenuActions} from '@redux'
import {getWindowSize, i18, trb} from '@utilities'
import {routerPath} from '@config'
import {Container} from '@components'
import {BroadCastContainer, InboxContainer, NewContainer} from './Components'
import {FolderOutlined, MessageOutlined, RightOutlined} from "@ant-design/icons";

const {TabPane} = Tabs

export const MessagesContainer = () => {
    const windowSize = getWindowSize();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    const {MessagesCount, sendMessagesCount} = useSelector(state => state.MessagingReducers)
    const {sendMACR} = useSelector(state => state.UACReducers)

    const [currentStep, setCurrentStep] = useState(trb(i18.ContentTitles.MessagesBroadCast));
    const [menuArray, setMenuArray] = useState([
        {
            title: i18.Chat.New,
            icon: <MessageOutlined />,
            path: routerPath.messagesNew,
            active: true
        }, {
            title: i18.Chat.Inbox,
            icon: <FolderOutlined />,
            path: routerPath.messagesInbox,
        }, {
            title: i18.Chat.BroadCast,
            icon: <RightOutlined />,
            path: routerPath.messagesBroadCast,
        }
    ])

    useEffect(() => {

        const menu = MessagesCount ? [
            {
                title: i18.Chat.New,
                icon: <MessageOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesNew}`,
                active: true
            }, {
                title: i18.Chat.Inbox,
                text: ' (' + MessagesCount.CountChat + ')',
                icon: <FolderOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesInbox}`,
            }, {
                title: i18.Chat.BroadCast,
                text: ' (' + MessagesCount.CountBroadCast + ')',
                icon: <RightOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesBroadCast}`,
            }
        ] : menuArray
        if (MessagesCount) {
            setMenuArray(menu)

        }

        dispatch(MenuActions.setContentMenu(menu))
        dispatch(MenuActions.setTitle(trb(i18.Chat.ChatContainer)))

        return () => {
            dispatch(MenuActions.setContentMenu(null));
        }
    }, [])


    useEffect(() => {
        const menu = [
            {
                title: i18.Chat.New,
                icon: <MessageOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesNew}`,
                active: true
            }, {
                title: i18.Chat.Inbox,
                text: ` (${MessagesCount.CountChat})`,
                icon: <FolderOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesInbox}`,
            }, {
                title: i18.Chat.BroadCast,
                text: ` (${MessagesCount.CountBroadCast})`,
                icon: <RightOutlined />,
                path: `${routerPath.messages}/${routerPath.messagesBroadCast}`,
            }
        ]
        setMenuArray(menu)

        dispatch(MenuActions.setContentMenu(menu));
    }, [MessagesCount])

    const onTabChange = (tabPath) => {
        setCurrentStep(tabPath)
        navigate(tabPath)
    }
    return <Container>
        {!windowSize.mobileCheck &&
            <Tabs id="MessagesContainerTabs" onChange={onTabChange} activeKey={location.pathname.split('/').pop()}
                  className="mt--3 mb-3">
                <TabPane tab={trb(i18.ContentTitles.MessagesNew)} key={routerPath.messagesNew}/>
                <TabPane tab={trb(i18.ContentTitles.MessagesInbox)} key={routerPath.messagesInbox}/>
                <TabPane tab={trb(i18.ContentTitles.MessagesBroadCast)} key={routerPath.messagesBroadCast}/>
            </Tabs>}
        <Routes>
            <Route path={routerPath.messagesNew} element={<NewContainer onTabChange={onTabChange} />}/>
            <Route path={routerPath.messagesInbox} element={<InboxContainer/>}/>
            <Route path={routerPath.messagesBroadCast} element={<BroadCastContainer/>}/>
        </Routes>
    </Container>;

}

