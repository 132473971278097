import {iframeLogout, store} from '@redux'
import {history, routerPath} from '@config'

const LogoutFn = async () => {
    const {dispatch} = store
    // try {
    //   const response = await dispatch(UACActions.getLogout())
    //   if (response?.type !== UACConstants.GET_UAC_LOGOUT_SUCCESS) {
    //     throwError(response, 'LogoutFn')
    //   }
    // } catch (exception) {
    //   logError(exception)
    // } finally {
    sessionStorage.clear()
    localStorage.clear()
    dispatch(iframeLogout())
    history.push(routerPath.index)
    location.reload();
    // }
}

export const Logout = () => {
    return LogoutFn()
}
