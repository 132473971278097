import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VersionActions} from '@redux'

export const CheckVersion = () => {

    const dispatch = useDispatch();
    const ref = useRef(null)
    useImperativeHandle(ref, () => ({
        onTimeoutCheckVersion: () => checkVersion()
    }))
    const [timeoutId, setTimeoutId] = useState(-1)
    const [callCount, setCallCount] = useState(0)

    const VersionReducer = useSelector((state) => state.VersionReducer)

    const checkVersion = () => {
        if (callCount >= process.env.REACT_APP_VERSION_CALL_COUNT) {
            return;
        }
        setCallCount(callCount + 1)
        dispatch(VersionActions.getRemoteVersion())
        setTimeoutId(setTimeout(() => ref?.current?.onTimeoutCheckVersion(), parseInt(process.env.REACT_APP_VERSION_INTERVAL || 300000)))
    }

    useEffect(() => {
        checkVersion()

        return () => {
            clearInterval(timeoutId)
        }
    }, [])


    useEffect(() => {
        if (VersionReducer?.version !== undefined) {
            if (window.VERSION !== VersionReducer.version) {
                sessionStorage.setItem('VERSION', VersionReducer.version)
                window.location.reload()
            }
        }
    }, [VersionReducer?.version])

    return (<></>)
}
