import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, numberFormat, required, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCClientBeneficiaries = (props) => {

    const ClientBeneficiaries = checkJsonProp(props.data, 'clientBeneficiaries') && checkJsonProp(props.data, 'clientBeneficiaries').length
                                ? checkJsonProp(props.data, 'clientBeneficiaries')
                                : [{data:{}}],
          ClientProfile = checkJsonProp(props.data, 'clientProfile'),
          initialData = {
              clientBeneficiaries: ClientBeneficiaries,
              clientProfile: ClientProfile,
          };


    const mapData = [{
        title: i18.Labels.ClientBeneficiaries, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCClientBeneficiaries,
        canDuplicate: true,
        duplicateName: 'clientBeneficiaries',
        maxRows: props.classifikators.BENEF.length - 1,
        description: i18.Labels.ClientBeneficiariesNote,
        elements: [{
            title: i18.Labels.ClientBenefType,
            name: 'clientBeneficiaries.$index.clientBenefType',
            component: FormFieldType.select,
            options: props.classifikators.BENEF,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12 col-md-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefName,
            name: 'clientBeneficiaries.$index.data.clientBenefName',
            component: FormFieldType.input,
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefSurname,
            name: 'clientBeneficiaries.$index.data.clientBenefSurname',
            component: FormFieldType.input,
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefBirthCountry,
            name: 'clientBeneficiaries.$index.data.clientBenefBirthCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefRegCountry,
            name: 'clientBeneficiaries.$index.data.clientBenefRegCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefCountry,
            name: 'clientBeneficiaries.$index.data.clientBenefCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefTaxCountry,
            name: 'clientBeneficiaries.$index.data.clientBenefTaxCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefAddress,
            name: 'clientBeneficiaries.$index.data.clientBenefAddress',
            component: FormFieldType.input,
            className: 'col-12 col-md-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        },{
            title: i18.Labels.ClientBenefTaxId,
            name: 'clientBeneficiaries.$index.data.clientBenefTaxId',
            component: FormFieldType.input,
            className: 'col-12 col-md-6',
        },{
            title: i18.Labels.ClientBenefShare,
            name: 'clientBeneficiaries.$index.data.clientBenefShare',
            component: FormFieldType.number,
            formatNumber: numberFormat().percent,
            className: 'col-12 col-md-6',
            classNameError: 'col-12 col-md-7 offset-md-5',
            validate: required,
        }],
    },{
        elements: [{
            translate: i18.Labels.ClientBenefNera,
            name: 'clientProfile.0.data.clientBenefNera',
            component: FormFieldType.checkboxOnly,
            className: 'col-12 col-md-10 offset-md-2 mb-3',
        },{
            title: i18.Labels.ClientBenefReason,
            name: 'clientProfile.0.data.clientBenefReason',
            component: FormFieldType.input,
            className: 'col-12',
        }]
    },{
        title: i18.Labels.ClientStructure,
        noteFirst: i18.Labels.ExplainAboutStructure,
        elements: [{
            title: i18.Labels.ClientOrgStructureDesc,
            name: 'clientProfile.0.data.clientOrgStructureDesc',
            component: FormFieldType.input,
            className: 'col-12',
        },{
            translate: i18.Labels.ClientOrgStructureDirect,
            name: 'clientProfile.0.data.ClientOrgStructureDirect',
            component: FormFieldType.checkboxOnly,
            className: 'col-12 col-md-10 offset-md-2 mt-3',
        }]
    }];

    const decorators = [
        createDecorator({
            field: /clientBeneficiaries\.*.*clientBenefType/,
            updates: (value, name, allValues) => {
                props.classifikators.BENEF.map((item, index)=>{
                    item.disabled = checkJsonProp(allValues, 'clientBeneficiaries').some((addItem, addIndex) => {
                        return addItem.clientBenefType == item.value;
                    }) ? true : false;
                    item.disabled = item.disabled || item.value == value ? true : false;
                    return item;
                })
                return {}
            }
        })
    ];

    return <KYCFormSwitch approveSubmitText={i18.NotificationMessages.KYCApproveSubmitCompany} permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} submit={props.submit} prevStep={props.prevStep}/>
};