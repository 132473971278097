import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";
import {Form} from 'react-final-form';

import {FormField, FormFieldType, LanguageSelect} from '../../../../Components'
import {
  i18,
  trb,
  required,
  appEnvSME,
  appEnvMano,
  composeValidators,
  checkForWhiteSpaces
} from '../../../../Utilities'
import createDecorator from 'final-form-calculate'
import { checkCookieForUser } from '../../Functions'

export const LoginFormSMS = (props) => {
  const { authCookie, loginType } = props;

  const decorators = createDecorator(
    {
      field: 'LoginCode',
      updates: (value) => {
        let isFullLogin = true;
        if (value && value.length > 5) {
          isFullLogin = checkCookieForUser(value, authCookie);
        }
        return {
          isFullLogin: isFullLogin
        };
      }
    }
  );

    return (
        <Form
            onSubmit={props.submit}
            decorators={[decorators]}
            initialValues={{ isFullLogin: true, LoginSystem: loginType }}
            render={({handleSubmit, values}) => {
              const { isFullLogin } = values;
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            {props.withLang && <LanguageSelect withoutLabels/>}
                            <FormField
                                title={i18.Labels.IdentificationUID}
                                name={appEnvMano || appEnvSME ? 'UID' : 'LoginCode'}
                                component={FormFieldType.input}
                                inputType="tel"
                                validate={composeValidators(required, checkForWhiteSpaces)}
                                size="large"
                                disabled={props.submitting}
                                autoFocus={true}
                            />
                        </div>
                      {
                        !appEnvSME && !appEnvMano &&
                        <div className="d-none">
                          <FormField
                            title=''
                            name="isFullLogin"
                            component={FormFieldType.checkbox}
                            disabled={props.submitting}
                          />
                        </div>
                      }

                        <div className="form-group">
                            {values.FIRST ? (
                                <FormField
                                    title={i18.Labels.Phone}
                                    name="PhoneNumber"
                                    component={FormFieldType.input}
                                    inputType="tel"
                                    validate={composeValidators(required, checkForWhiteSpaces)}
                                    size="large"
                                    disabled={props.submitting}
                                    suffix={
                                        <Tooltip title="Description" placement="left">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    }
                                />
                            ) : (
                              <>
                                <FormField
                                    title={i18.Labels.Password}
                                    name={appEnvSME || appEnvMano ? 'PWD' : 'LoginPass'}
                                    component={FormFieldType.input}
                                    inputType="password"
                                    validate={required}
                                    size="large"
                                    disabled={props.submitting}
                                />
                                {
                                  !appEnvSME && !appEnvMano && isFullLogin &&
                                  <div className="form-group">
                                    <FormField
                                      title={i18.Labels.LoginIdentity}
                                      name="LoginIdentity"
                                      component={FormFieldType.input}
                                      // inputType="password"
                                      validate={composeValidators(required, checkForWhiteSpaces)}
                                      size="large"
                                      disabled={props.submitting}
                                    />
                                  </div>
                                }
                                {
                                  !appEnvSME && !appEnvMano && isFullLogin &&
                                  <div className="form-group mb-4">
                                    <FormField
                                      name="RememberMe"
                                      component={FormFieldType.checkbox}
                                      translate={i18.Labels.RememberMe}
                                      disabled={props.submitting}
                                    />
                                  </div>
                                }
                                </>
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <FormField
                                name="FIRST"
                                component={FormFieldType.checkbox}
                                translate={i18.Labels.FirstLogin}
                                disabled={props.submitting}
                            />
                            {props.submitError && <div className="form-field-error">{trb(submitError)}</div>}
                        </div>
                        <div className="text-right">
                            {values.FIRST ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={props.submitting}
                                >{trb(i18.Buttons.GetPassword)}</Button>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={props.submitting}
                                >{trb(i18.Buttons.SignIn)}</Button>
                            )}
                        </div>
                    </form>
                );
            }}
        />
    );
};
