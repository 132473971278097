import React from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import {Alert, Button} from 'antd';
import {difference} from 'lodash';

import {DMCActions, PMCActions, UACActions} from '../../../../Redux';
import { i18, required, trb, appEnvSME, checkJsonProp } from '../../../../Utilities'
import {
    FormField,
    FormFieldType,
    ContentLoader,
    ContentNoData,
    Notification,
    OperationApproval,
    Container
} from '../../../../Components';
import {AccountsManagementSpy} from './';

const ButtonGroup = Button.Group;

class SettingsAccountsManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            GPR: null,
            initialValues: null,
            inline: !window.matchMedia('(max-width: 600px)').matches,
        };
        /*window.addEventListener('resizeeee', (event) => {
            this.setState({
                inline: !window.matchMedia('(max-width: 600px)').matches,
            });
        });*/

        this.submit = this.submit.bind(this);
    }

    async componentDidMount() {
        await this.props.dispatch(UACActions.postATR());
        const response = await this.props.dispatch(UACActions.postGPR({}));
        this.setState({GPR: response.payload});
    }

    submit = async values => {
        const {GPR, language} = this.props;
        let client = GPR.UsersPermissions.find(x => x.ID === values.ClientID);
        let clientPermissins = client.PermissionsList;

        const delta = difference(clientPermissins, values.ClientPermissions);

        if (Object.keys(delta).length === 0) {
            Notification({
                type: 'warning',
                message: i18.NotificationTitles.ListEmpty,
                description: i18.NotificationMessages.InvoiceListEmpty,
            });
            // this.setState((previousState, currentProps) => {
            //     return {...previousState, submitting: false};
            // });
            return;
        }

        //
        // this.setState((previousState, currentProps) => {
        //     return {
        //         ...previousState,
        //         initialValues: {ClientID: values.ClientID, ...values},
        //     };
        // });

        //const arrChange = Object.keys(difference);
        // const ClientPermissionsChanged = values.ClientPermissions.map(
        //     (item, index) => {
        //         if (arrChange.includes(index.toString())) {
        //             return item;
        //         }
        //     }).filter(x => x !== undefined);
        client = {...client, PermissionsList: values.ClientPermissions};

        const valuesData = JSON.parse(JSON.stringify(values));
        for (let i = 0; i < valuesData.ClientPermissions.length; i++) {
            valuesData.ClientPermissions[i].Permission = valuesData.ClientPermissions[i].Permission.toString();
        }

        const data = {
            DocType: 'AccPerm',
            Content: JSON.stringify(valuesData),
        };
        this.setState({...this.state,
            submitting: true,
        });
        await this.props.dispatch(DMCActions.postSDR({ ...data, Language: language })).then(response => {

            if (response.payload && response.payload.IsSuccessfull &&
                (response.payload.RQ_ID !== null)) {
                this.setState({
                    approve: true,
                    RQ_ID: response.payload.RQ_ID,
                    AuthType: response.payload.AuthType,
                    Code: response.payload.ControlCode,
                    submitting: true,
                });
            } else {
                this.setState({
                    errors: response.payload.ErrorCaptions,
                    submitting: false,
                });
            }

        });
        return;
    };
    cancelApprove = (ErrorCode) => {
        let message = ErrorCode;
        if (message?.replace) {
            message = message.replace('.', '');
        }
        this.setState(prevState => ({
            approve: false,
            RQ_ID: '',
            AuthType: null,
            Code: null,
            submitting: false,
        }));
        Notification({
            type: 'error',
            message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.PermissionsChangeFailed,
            description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.PermissionsChangeFailed
        })
        //window.location.reload(false);
    };

    successApprove = async () => {
        Notification({
            type: 'success',
            message: i18.NotificationTitles.PermissionsChanged,
            description: i18.NotificationMessages.PermissionsChangedSuccessfully,
        });
        this.setState({
            approve: false,
            RQ_ID: '',
            AuthType: null,
            Code: null,
            submitting: false,

        });
        const response = await this.props.dispatch(UACActions.postGPR({}));
        this.setState({GPR: response.payload});
    };

    submitSmsApprove = (values) => {
        const {dispatch} = this.props;
        dispatch(PMCActions.postAPSMSR(values)).then(response => {
            const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull');
            const ErrorCode = checkJsonProp(response, 'payload.ErrorCode');
            if (IsSuccessfull) {
                this.successApprove();
            } else {
                this.cancelApprove(ErrorCode);
            }
        });
    };

    submitMobileApprove = async () => {
        const {dispatch} = this.props;
        const {RQ_ID} = this.state;
        const response = await dispatch(PMCActions.postAPESCR({RQ_ID: RQ_ID}));
        const AuthCheckResult = checkJsonProp(response, 'payload.AuthCheckResult');
        const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull');
        const ErrorCode = checkJsonProp(response, 'payload.ErrorCode');

        if (AuthCheckResult === null) {
            return true;
        }
        if (AuthCheckResult === false) {
            this.cancelApprove(ErrorCode);
        } else if (AuthCheckResult === true && IsSuccessfull) {
            this.successApprove();
        }
        return false;
    };

    render() {
        const {sendGPR, ATR} = this.props;

        if (sendGPR) {
            return <ContentLoader/>;
        }

        if (!this.state.GPR) {
            return <ContentNoData/>;
        }

        return <>
            <div className={appEnvSME ? "eb_content_subtitle" : "eb_content_title"}>
                {trb(i18.ContentTitles.SettingsAccountsManagement)}
            </div>
            <Container>
                <Form
                    onSubmit={this.submit}
                    initialValues={this.state.initialValues}
                    render={({handleSubmit, form, valid, values}) => {
                        return (
                            <form
                                className="mb-3"
                                onSubmit={handleSubmit}
                                style={appEnvSME ? {
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '100%',
                                    maxWidth: '800px',
                                } : null }
                            >
                                <FormField
                                    name="ClientID"
                                    title={i18.Labels.ChoosePersonOrCompany}
                                    component={FormFieldType.select}
                                    options={this.state.GPR.UsersPermissions}
                                    className="mb-5"
                                    classNameField={appEnvSME ? "col-12" : null}
                                    optionsLabel="ClientFullName"
                                    optionsValue="ID"
                                    inline={this.state.inline}
                                    validate={required}
                                    disabled={this.state.submitting}
                                />
                                {!!this.state?.GPR?.UsersPermissions?.length && (<FormSpy
                                    form={form}
                                    data={values}
                                    //submitting={this.state.submitting}
                                    permissions={this.state.GPR.UsersPermissions}
                                    component={AccountsManagementSpy}
                                />)}
                                {this.state.errors &&
                                <Alert
                                    className="mb-3"
                                    message={trb(
                                        i18.ErrorTitles.AccountsLimitsChangeError)}
                                    description={
                                        <ul>
                                            {this.state.errors.map((item, index) =>
                                                <li key={index}>{item.ErrorCode} - {item.ErrorCaption}</li>,
                                            )}
                                        </ul>
                                    }
                                    type="error"
                                />
                                }
                                <div className={appEnvSME ? "mb-3 text-left" : "mb-3 text-right"}>
                                    {ATR.AuthType === 'eSign' &&
                                    <ButtonGroup className={!appEnvSME ? 'd-none d-md-block' : 'd-none d-md-block'}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={this.state.submitting && !this.state.approve}
                                            disabled={this.state.submitting && this.state.approve}
                                        >{trb(
                                            i18.Buttons.ChangeAccountPermissions)}</Button>
                                    </ButtonGroup>
                                    }
                                    {ATR.AuthType === 'eSign' &&
                                    <ButtonGroup className={!appEnvSME ? 'd-block d-md-none' : 'd-block d-md-none'}>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          className={!appEnvSME ? 'w-100' : ''}
                                          loading={this.state.submitting && !this.state.approve}
                                          disabled={this.state.submitting && this.state.approve}
                                        >{trb(
                                          i18.Buttons.ChangeAccountPermissions)}</Button>
                                    </ButtonGroup>
                                    }
                                </div>
                                {this.state.approve &&
                                <OperationApproval
                                    RQ_ID={this.state.RQ_ID}
                                    AuthType={this.state.AuthType}
                                    code={this.state.Code}
                                    cancelApprove={this.cancelApprove}
                                    successApprove={this.successApprove}
                                    submitSmsApprove={this.submitSmsApprove}
                                    submitMobileApprove={this.submitMobileApprove}
                                />
                                }
                            </form>
                        );
                    }}
                />
            </Container>
        </>;
    };
}

function mapStateToProps(state) {
    const {sendGPR, GPR} = state.UACReducers;
    const {ATR} = state.UACReducers;
    const { language } = state.languageReducer;
    return {
        sendGPR,
        GPR,
        ATR,
        language
    };
}

const connectedSettingsAccountsManagement = connect(mapStateToProps)(
    SettingsAccountsManagement);
export {connectedSettingsAccountsManagement as SettingsAccountsManagement};
