import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { DepositActions, PMCActions } from '@redux'
import { i18, trb } from '@utilities'
import { ContentLoader, Notification, OperationApproval } from '@components'
import ButtonGroup from 'antd/lib/button/button-group'

export const DepositsSignContainer = ({ nextStep }) => {
  const {
    sendDepositOffer,
    depositOffer,
    sendConfirmDeposit,
    confirmDepositData
  } = useSelector(state => state.DepositReducers)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(DepositActions.clearPostConfirmDepositData())
    }
  }, [])

  const handleSign = () => {
    dispatch(
      DepositActions.postConfirmDeposit({
        productId: depositOffer.Response.productId
      })
    )
  }

  const successApprove = () => {
    Notification({
      type: 'success',
      message: i18.NotificationTitles.DepositSignSuccess,
      description: i18.NotificationMessages.DepositSignSuccess
    })
    nextStep()
  }

  const cancelApprove = ErrorCode => {
    let message = ErrorCode
    if (message?.replace) {
      message = message.replace('.', '')
    }
    Notification({
      type: 'error',
      message: message
        ? i18.NotificationTitles[message]
        : i18.NotificationTitles.DepositSignFail,
      description: message
        ? i18.NotificationMessages[message]
        : i18.NotificationMessages.DepositSignFail
    })
    dispatch(DepositActions.clearPostConfirmDepositData())
  }

  const submitSmsApprove = async values => {
    const response = await dispatch(PMCActions.postAPESCR(values))
    const ErrorCode = response?.payload.ErrorCode

    if (response?.payload?.IsSuccessfull) {
      successApprove()
    } else {
      cancelApprove(ErrorCode)
    }
  }

  const submitMobileApprove = async () => {
    const response = await dispatch(
      PMCActions.postAPESCR({ RQ_ID: confirmDepositData.RQ_ID })
    )
    const AuthCheckResult = response?.payload?.AuthCheckResult
    const IsSuccessfull = response?.payload?.IsSuccessfull
    const ErrorCode = response?.payload?.ErrorCode

    if (AuthCheckResult === null) {
      return true
    }
    if (AuthCheckResult === false) {
      cancelApprove(ErrorCode)
    } else if (AuthCheckResult && IsSuccessfull) {
      successApprove()
    }
    return false
  }

  return (
    <div className="w-100">
      {sendDepositOffer && !depositOffer && <ContentLoader />}

      {depositOffer && !confirmDepositData && (
        <>
          <embed
            height="1190"
            className="w-100"
            src={`data:application/pdf;base64,${depositOffer.Response.pdfBytes}`}
          />

          <div className="my-3 text-right d-block col-5 col-md-12 pl-4 pl-md-3">
            <ButtonGroup>
              <Button
                onClick={handleSign}
                block
                type="primary"
                htmlType="button"
                className="col-12 col-md-12"
                disabled={sendConfirmDeposit}
                loading={sendConfirmDeposit}>
                {trb(i18.Buttons.Sign)}
              </Button>
            </ButtonGroup>
          </div>
        </>
      )}

      {confirmDepositData && (
        <>
          <div className="text-center mb-4">{trb(i18.Labels.SignDeposit)}</div>
          <OperationApproval
            RQ_ID={confirmDepositData.RQ_ID}
            AuthType={confirmDepositData.AuthType}
            code={confirmDepositData.ControlCode}
            cancelApprove={cancelApprove}
            successApprove={successApprove}
            submitSmsApprove={submitSmsApprove}
            submitMobileApprove={submitMobileApprove}
          />
        </>
      )}
    </div>
  )
}
