import React, {Component, useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Table} from 'antd';

import {AMCActions} from "@redux";
import {ContentLoader, ContentNoData} from "@components";
import {i18, trb, checkJsonProp, formatDate, CustomIcon, getWindowSize} from '@utilities';

import moment from 'moment';

export const  LastOperationContainer  = () => {

    const dispatch = useDispatch();
    const {LLO, sendLLO} = useSelector((state)=>state.AMCReducers);
    const {translations} = useSelector((state)=>state.translationReducer);
    const {mobileCheck} = getWindowSize()

    useEffect(()=>{
        dispatch(AMCActions.postLLO());
    },[])




        if (sendLLO) {
            return <ContentLoader/>
        }

        const columns = [{
            title: '',
            dataIndex: 'ID',
            render: (el, item) => {
                if (checkJsonProp(item, 'Expediture.0.Value') > 0) return <CustomIcon className="payment_type" size={18} type="custom" icon="payment"/>
                if (checkJsonProp(item, 'Income.0.Value') > 0) return <CustomIcon className="payment_type payment_type_active" size={18} type="custom" icon="payment"/>
            },
            className: 'px-0',
            width: 1,
        },{
            title: trb(i18.Labels.OperationsData),
            dataIndex: 'Date',
            render: (el) => <span className="eb_fw_300 text-nowrap">{formatDate(el)}</span>
        },{
            title: trb(i18.Labels.OperationsPayer),
            dataIndex: 'Pay_RecName',
            render: (el, item) => <>
                <span className="eb_fw_300">{el}</span>
            </>,
        },{
            title: trb(i18.Labels.OperationsPurpose),
            dataIndex: 'Description',
            render: (el, item) => <>
                <span className="eb_fw_300">{el}</span>
            </>
        },{
            title: `+ ${trb(i18.Labels.OperationsRevenue)} / - ${trb(i18.Labels.OperationsExpenses)} / ${trb(i18.Labels.OperationsTaxes)}`,
            align: 'right',
            dataIndex: 'Income.0.Value',
            render: (el, item) => <>
                <span className="text-primary text-nowrap float-right">{checkJsonProp(item, 'Income.0.Text') && `+ ${checkJsonProp(item, 'Income.0.Text')}`}</span>
                <span className="text-black text-nowrap float-right">{checkJsonProp(item, 'Expediture.0.Text') && `- ${checkJsonProp(item, 'Expediture.0.Text')}`}</span>
            </>
        }],
        columnsMobile = [{
            title: trb(i18.Labels.OperationsPayer),
            dataIndex: 'Pay_RecName',
            render: (el, item) => <>
                <span className="eb_fw_300">{el}</span>
            </>
        },{
            title: `+ ${trb(i18.Labels.OperationsRevenue)} / - ${trb(i18.Labels.OperationsExpenses)} / ${trb(i18.Labels.OperationsTaxes)}`,
            align: 'right',
            dataIndex: 'Income.0.Value',
            render: (el, item) => <>
                <span className="text-primary text-nowrap float-right">{checkJsonProp(item, 'Income.0.Text')}</span>
                <span className="text-black text-nowrap float-right">{checkJsonProp(item, 'Expediture.0.Text') && `- ${checkJsonProp(item, 'Expediture.0.Text')}`}</span>
            </>
        }];

        return translations && translations.length > 1 && (
            <Table
                dataSource={LLO?.Operations ?? []}
                columns={mobileCheck ? columnsMobile : columns}
                pagination={false}
                rowKey="ID"
            />
        );

}
