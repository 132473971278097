import React from 'react';
import {Button, } from 'antd';
import {LeftOutlined} from '@ant-design/icons';

import {LanguageSelect} from '@components';

export const LoginHeader = () => {
    return <div className="bbs_login_header" id="header">
        <div className="bbs_login_header_wrapper row">
            <div className="bbs_login_logo col text-left pl-0">
                <img src={`${process.env.REACT_APP_BASE_PATH_IMG}images/logos/${process.env.REACT_APP_LOGIN_LOGO}`} alt={process.env.REACT_APP_TITLE}/>
            </div>
            <div className="col-12 text-right pr-0">
                <LanguageSelect className="ml-5 login-language-select"/>
            </div>
        </div>
    </div>;
};
