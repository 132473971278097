import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AMCActions, MenuActions, MessagingActions, PMCActions, UACActions} from '@redux'
import {UserSelect} from '../index';
import {appEnvSME, checkJsonProp, getWindowSize} from '@utilities';
import {useNavigate} from "react-router-dom";

export const UserSelectContainer = () => {
    const windowSize = getWindowSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {MALR} = useSelector((state) => state.UACReducers);
    const {language} = useSelector((state) => state.languageReducer);
    const {translations} = useSelector((state) => state.translationReducer);

    const [managingAccount, setManagingAccount] = useState(null)

    useEffect(() => {
        if (!MALR) {
            dispatch(UACActions.getMALR())
        }

        return () => {
            dispatch(UACActions.deleteMALR());
        }
    }, [])


    const selectManagingAccount = (id, name) => {
        setManagingAccount(name)

        dispatch(UACActions.getMACR({ManagedAccountID: id})).then(async () => {
            await dispatch(AMCActions.getCompany());
            await dispatch(PMCActions.postPTR())
            await dispatch(UACActions.getMALR())
            await dispatch(MessagingActions.getNewMessagesCount())
        });

        if (windowSize.mobileCheck && appEnvSME) dispatch(MenuActions.toggle());
        // FIXME: const navigate = useNavigate()
        navigate('/', {});
    };


    if (!MALR) {
        return null;
    }

    const activeUser = MALR.ManagedAccounts.find(x => x.IsActive === true);
    let userName;
    if (activeUser) {
        userName = checkJsonProp(activeUser, 'Name');
    } else if (MALR.ManagedAccounts.length > 0) {
        userName = checkJsonProp(MALR.ManagedAccounts, '0.Name');
    }

    return <UserSelect
        data={MALR}
        managingAccount={managingAccount ?? userName}
        selectManagingAccount={selectManagingAccount}
    />;

}
