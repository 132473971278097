import React, {useState} from 'react'
import {currencyTextValue, EUR, i18, trb} from '@utilities'
import {Form} from 'react-final-form'
import {Button, Select} from 'antd'
import {FormField, FormFieldType} from '@components'
import PropTypes from "prop-types";

const ButtonGroup = Button.Group
const {Option} = Select;

export const PaymentEditTable = ({data, submit, submitting, buttons}) => {
    const {
        PaymentsList,
        UploadDateTime,
        UploadFileID,
        UploadFileName,
        TotalPayments,
        TotalAmount,
        FileHeaderId,
        Currency
    } = data || {}
    const mapPaymentsList = PaymentsList.map(item => {
        return {PaymentID: item.PaymentID}
    })

    const [ibanID, setIbanID] = useState(null);
    const [iban, setIban] = useState(null);

    const initialData = {
        FileID: UploadFileID,
        UpdateData: mapPaymentsList
    }

    const needsIban = PaymentsList.find(item => item.DebtorIBAN === '')
    const handleIbanSelect = (value) => {
        setIbanID(value)
        setIban(needsIban.AllowedDebtorAccounts.find(item => item.ID === value).Number)
    }

    const onSubmit = (values) => {
        const newValues = {
            ...values,
            UpdateData: values.UpdateData.map(item => ({...item, NewDebtorAcc: ibanID}))
        }
        submit(newValues);
    }

    return (
        <>
            <h3 className="eb_content_subtitle">{trb(i18.Labels.Summary)}</h3>
            <div className="eb_table mb-3 w-100">
                <div className="row eb_table_head">
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                </div>
                <div className="row eb_table_row ">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.UploadDateTime)}</div>
                        <div className="eb_fw_600">{UploadDateTime}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.FileName)}</div>
                        <div className="eb_fw_600 text-truncate">{UploadFileName}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.FileId)}</div>
                        <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                             className="eb_fw_600">{FileHeaderId}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.PaymentCount)}</div>
                        <div className="eb_fw_600">{TotalPayments}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.PaymentSum)}</div>
                        <div className="eb_fw_600">{currencyTextValue(TotalAmount, Currency)}</div>
                    </div>
                </div>
            </div>
            <h3 className="eb_content_subtitle">{trb(i18.Labels.Payments)}</h3>
            <div className="eb_table mb-3 w-100">
                <div className="row eb_table_head">
                    <div className="col-12 col-xl-4">{trb(i18.Labels.Payer)}
                        <div>
                            {

                                needsIban !== null ? (
                                    <Select
                                        onChange={handleIbanSelect}
                                        className="col-12"
                                        style={{padding: '15px 0'}}
                                        placeholder={trb(i18.Labels.SelectIban)}
                                    >
                                        {needsIban?.AllowedDebtorAccounts?.filter(({Currency})=> Currency === EUR).map(item => {
                                            return (<Option value={item.ID}>{item.Number}</Option>)
                                        })}
                                    </Select>
                                ) : null}
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">{trb(i18.Labels.Payee)}</div>
                    <div className="col-12 col-xl-3">{trb(i18.Labels.DestinationsCode)}</div>
                    <div className="col-12 col-xl-1 text-right">{trb(i18.Labels.Amount)}</div>
                </div>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{...initialData}}
                    render={({handleSubmit, values, form}) => {
                        return (
                            <form className="mb-3" onSubmit={handleSubmit}>
                                <FormField
                                    name="FileID"
                                    className="d-none"
                                    component={FormFieldType.input}
                                    value={UploadFileID}
                                />
                                {PaymentsList.map((item, index) => {
                                    return (
                                        <div className="row eb_table_row " key={item.PaymentID}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                                <FormField
                                                    name={`UpdateData.[${index}].PaymentID`}
                                                    className="d-none"
                                                    component={FormFieldType.input}
                                                    value={item.PaymentID}
                                                />
                                                <div>{item.DebtorName}</div>
                                                <div>
                                                    {item.DebtorIBAN?.replace(/.{4}(?=.)/g, '$& ') || item.ProvidedDebtorIBAN?.replace(/.{4}(?=.)/g, '$& ')}
                                                </div>
                                                <div>
                                                    {needsIban ? iban ? iban : trb(item?.Errors?.[0]) : item.DebtorIBAN.replace(/.{4}(?=.)/g, '$& ')}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                                <div>{item.CreditorName}</div>
                                                <div>{item.CreditorIBAN?.replace(/.{4}(?=.)/g, '$& ')}</div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                <div className="">{item.PaymentPurpose}</div>
                                                <div>{item.UniqPayCode}</div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1 text-right">
                                                <div>{currencyTextValue(item.Amount, item.Currency)}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="mb-3 text-right">
                                    <ButtonGroup>
                                        {buttons.map((item, index) => (
                                            <Button key={index} onClick={item.func} {...item.props} disabled={item.props?.htmlType === 'submit' && !!needsIban ? item?.props?.disabled || ibanID === null: item?.props?.disabled }>
                                                {trb(item.title)}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>
        </>
    )
}

PaymentEditTable.propTypes = {
    data: PropTypes.shape({
        PaymentsList: PropTypes.arrayOf(PropTypes.shape({
            PaymentID: PropTypes.string,
            DebtorName: PropTypes.string,
            DebtorIBAN: PropTypes.string,
            ProvidedDebtorIBAN: PropTypes.string,
            Errors: PropTypes.array,
            AllowedDebtorAccounts: PropTypes.array,
            CreditorName: PropTypes.string,
            CreditorIBAN: PropTypes.string,
            PaymentPurpose: PropTypes.string,
            UniqPayCode: PropTypes.string,
            AmountFormatted: PropTypes.string,
        })),
        UploadDateTime: PropTypes.string,
        UploadFileID: PropTypes.string,
        UploadFileName: PropTypes.string,
        TotalPayments: PropTypes.number,
        TotalAmountFormatted: PropTypes.string,
    }),
    submit: PropTypes.func,
    submitting: PropTypes.bool,
    buttons: PropTypes.array,
}