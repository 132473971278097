import React from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Progress, Result } from 'antd'
import {i18, numberFormat, translationGroups, trb} from '../../../Utilities'
import { Number } from '../../../Components'

const ButtonGroup = Button.Group

class OperationApprovalMobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sceconds: 60,
      secondsRemaining: 60,
      secondsEnd: false
    }

    this._ref = React.createRef()

    this.tick = this.tick.bind(this)
    this.resend = this.resend.bind(this)
    this.submitMSingConfirm = this.submitMSingConfirm.bind(this)
    this.timeout = null
  }

  scrollToMyRef = () => this._ref.current.scrollIntoView(false)

  componentDidMount() {
    if(this.props.errorCode) return;
    this.scrollToMyRef()
    this.setState(prevState => ({ secondsRemaining: prevState.sceconds }))
    this.interval = setInterval(this.tick, 1000)
    if (this.props.code === '0000') {
      this.props.submit()
    } else {
      this.intervalMSing = setTimeout(this.submitMSingConfirm, 1000)
    }
  }

  async submitMSingConfirm() {
    const answer = await this.props.submit()
    if (answer && this.intervalMSing) {
      this.intervalMSing = setTimeout(this.submitMSingConfirm, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearTimeout(this.intervalMSing)
    this.intervalMSing = null
    window.scrollTo(0, 0)
  }

  tick() {
    this.setState(prevState => ({
      secondsRemaining: Math.max(prevState.secondsRemaining - 1, 0)
    }))
    if (this.state.secondsRemaining === 0) {
      this.setState({ secondsEnd: true })
      this.setState({ secondsRemaining: <CloseOutlined /> })
      this.cancel('Timeout')
    }
  }

  resend = () => {
    clearInterval(this.interval)

    this.setState({
      sceconds: 5,
      secondsRemaining: 5,
      secondsEnd: false
    })

    this.interval = setInterval(this.tick, 1000)
  }
  cancel = ErrorCode => {
    clearInterval(this.interval)
    clearInterval(this.intervalMSing)
    clearTimeout(this.timeout)
    this.props.cancel(ErrorCode)
  }
  makeCodeCheck = () => {
    if (!this.props.code) {
      if (!this.timeout) {
        // this.timeout = setTimeout(this.cancel, 10000) // AV: commented because after 10s payment is canceled
      }
    }
  }

  render() {
    this.makeCodeCheck()

    const { approveSum, horizontal, approveCount } = this.props

    return (
      <div
        ref={this._ref}
        className={
          horizontal
            ? 'd-flex flex-row justify-content-around align-items-center'
            : null
        }
        style={
          horizontal
            ? { maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }
            : {
                textAlign: 'center',
                marginBottom: '24px'
              }
        }>
        {this.props.code ? (
          <>
            <div
              style={{ marginBottom: '24px' }}
              className={
                horizontal ? 'd-flex flex-row align-items-center' : null
              }>
              <Progress
                type="circle"
                percent={
                  (100 / this.state.sceconds) * this.state.secondsRemaining
                }
                status={this.state.secondsEnd ? 'exception' : 'active'}
                format={() =>
                  !this.state.secondsEnd
                    ? Math.round(this.state.secondsRemaining) + 'sec'
                    : this.state.secondsRemaining
                }
                width={100}
                style={horizontal ? null : { marginBottom: '24px' }}
              />
              <div
                className={
                  horizontal ? 'd-flex flex-column text-left p-3' : null
                }>
                {approveCount && (
                  <div>
                    {trb(i18.Labels.ApprovalPaymentsCount)}:{' '}
                    <Number
                      value={approveCount}
                      format={numberFormat().number}
                      text={1}
                    />
                  </div>
                )}
                {approveSum && approveSum.sum && (
                  <div>
                    {trb(i18.Labels.ApprovalSum)}:{' '}
                    <Number
                      value={approveSum.sum}
                      format={numberFormat().currencyWithMinus}
                      text={1}
                    />{' '}
                    {approveSum.currency}
                  </div>
                )}
                <div>{trb(i18.Messages.ApproveOperationOnYourDevice)}</div>
                <div>
                  {trb(i18.Messages.ControlCode)}{' '}
                  <strong>{this.props.code}</strong>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: '24px' }}>
              <ButtonGroup>
                <Button onClick={() => this.cancel('Canceled')}>
                  <CloseOutlined />
                  {trb(i18.Buttons.Cancel)}
                </Button>
                {/*     <Button
                              type="primary"
                              icon="sync"
                              disabled={!this.state.secondsEnd}
                              onClick={this.resend}
                          >{trb(i18.Buttons.Resend)}</Button>*/}
              </ButtonGroup>
            </div>
          </>
        ) : (<>
              {this.props.errorCode && (<Result
                  status="error"
                  title={trb(translationGroups.ErrorTitles +this.props.errorCode)}
                  subTitle={trb(translationGroups.ErrorDescriptions+this.props.errorCode)}
              />)}
              {!this.props.errorCode && this.props.RQ_ID &&  (<Result
                  status="error"
                  title={trb(i18.ErrorTitles.OperationApprovalMobileNoCode)}
                  subTitle={trb(i18.ErrorDescriptions.OperationApprovalMobileNoCode)}
              />)}
            </>
        )}
      </div>
    )
  }
}

export { OperationApprovalMobile }

OperationApprovalMobile.propTypes = {
  code: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  resend: PropTypes.func.isRequired,
  errorCode: PropTypes.string
}
