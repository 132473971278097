import {createAction, createSlice} from '@reduxjs/toolkit'
import {store} from '@redux/Config'

const IFRAME_DOM_ID = 'ssoIframe'

const ACTION_SAVE = 'ACTION_SAVE'
const ACTION_GET = 'ACTION_GET'
const ACTION_RETURN_DATA = 'ACTION_RETURN_DATA'
const ACTION_CLEAR = 'ACTION_CLEAR'
const ACTION_LOGOUT = 'ACTION_LOGOUT'

export const IFRAME_USER = 'user'
const initialState = {}

export const iframeReturnData = createAction('iframeReturnData')

export const iframeSave = createAction('iframeSave', payload => {
    const iframe = document.getElementById(IFRAME_DOM_ID)
    iframe.contentWindow.postMessage(
        {
            action: ACTION_SAVE,
            key: payload.key,
            value: payload.value
        },
        '*'
    )
    return {payload}
})

export const iframeGet = createAction('iframeGet', payload => {
    const iframe = document.getElementById(IFRAME_DOM_ID)
    iframe?.contentWindow?.postMessage(
        {
            action: ACTION_GET,
            key: payload.key
        },
        '*'
    )
    return {payload}
})

export const iframeClear = createAction('iframeClear', payload => {
    const iframe = document.getElementById(IFRAME_DOM_ID)
    iframe.contentWindow.postMessage(
        {
            action: ACTION_CLEAR
        },
        '*'
    )
    return {payload}
})

export const iframeLogout = createAction('iframeLogout', payload => {
    const iframe = document.getElementById(IFRAME_DOM_ID)
    iframe.contentWindow.postMessage(
        {
            action: ACTION_LOGOUT
        },
        '*'
    )
    return {payload}
})

const iframeSlice = createSlice({
    name: 'iframe',
    initialState,
    extraReducers: builder => {


        window.addEventListener(
            'message',
            event => {
                const {action, key, value} = event.data
                if (action === ACTION_RETURN_DATA) {
                    const dispatch = store.dispatch
                    dispatch(iframeReturnData({key, value}))
                }
            },
            false
        )

        builder.addCase(iframeReturnData, (state, {payload}) => {
            if (JSON.stringify(state[payload.key]) === JSON.stringify(payload.value)) {
                return;
            }
            return {...state, [payload.key]: payload.value}
        })

        builder.addCase(iframeSave, (state, {payload}) => {
            return {...state, [payload.key]: payload.value}
        })

        builder.addCase(iframeLogout, () => {
            return initialState
        })
    }
})

// Extract the action creators object and the reducer
const {reducer} = iframeSlice
// Extract and export each action creator by name
// Export the reducer, either as a default or named export
export {reducer as IframeStorageReducer}
