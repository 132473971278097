import React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {routerPath} from "@config";
import {
    AccountsContainer,
    DepositsContainer,
    InvoiceContainer,
    LogsContainer,
    MainNeedChangePasswordAlert,
    MainNeedChangePasswordContainer, MessagesContainer,
    OperationsContainer,
    PaymentsContainer,
    SettingsContainer,
    SummaryContainer,
} from "@containers";
import {ContentLoader, PartContent} from "@components";
import {checkDevEnv, checkJsonExist, checkJsonProp} from '@utilities';

export const MainRouteContainer = (props) => {

    const navigate = useNavigate();

    const {sendMACR, SMSAR, CRPQ} = useSelector(state => state.UACReducers);

    if (sendMACR) {
        return <PartContent>
            <ContentLoader/>
        </PartContent>
    }

    if (checkJsonExist(props, 'SMSAR') && checkJsonExist(SMSAR, 'NeedChangePass') && checkJsonProp(SMSAR, 'NeedChangePass') && !checkJsonProp(CRPQ, 'IsSuccessfull')) {
        navigate(routerPath.settingsChangePassword);

        return <PartContent>
            <Routes>
                <Route path={routerPath.settingsChangePassword} element={<MainNeedChangePasswordContainer/>}/>
                <Route path={routerPath.index} element={<MainNeedChangePasswordAlert/>}/>
            </Routes>
        </PartContent>
    }

    return <PartContent>
        <Routes>
            <Route path={routerPath.accounts} element={<AccountsContainer/>}/>
            <Route path={routerPath.deposits} element={<DepositsContainer/>}/>
            <Route path={routerPath.invoice} element={<InvoiceContainer/>}/>
            <Route path={routerPath.operations} element={<OperationsContainer/>}/>
            <Route path={`${routerPath.payments}/*`} element={<PaymentsContainer/>}/>
            {/*<Route path={routerPath.paymentGroup} element={<PaymentsContainer/>}/>*/}
            {/*<Route path={routerPath.paymentGroups} element={<PaymentsContainer/>}/>*/}
            <Route path={`${routerPath.settings}/*`} element={<SettingsContainer/>}/>
            <Route path={`${routerPath.messages}/*`} element={<MessagesContainer/>}/>
            {checkDevEnv && false && <Route path={routerPath.logs} element={<LogsContainer/>}/>}
            <Route path={routerPath.index} element={<SummaryContainer/>}/>
        </Routes>
    </PartContent>

}

