import React from "react";
import PropTypes from "prop-types";

import {checkJsonProp} from "../../Utilities";
import {OpeningButtons, Number} from "../../Components";

export const EBTable = ({data, title, headers, mapData, click}) => {
    return <>
        <h3 className="eb_content_subtitle">{title}</h3>
        <div className="eb_table mb-3 p-0 p-md-3">
            <div className="row eb_table_head d-none d-lg-flex">
                {headers.map((item, index) =>
                    <div className={item.className} key={index}>{item.title}</div>
                )}
            </div>
            {data.map((item, index) =>
                <div key={index} className="row eb_table_row eb_table_row_clickable" onClick={() => click(item)}>
                    {mapData.map((item2, index2) =>
                        <div className={item2.headerClassName} key={index2}>
                            {item2.buttons && item2.buttons.length
                            ? <OpeningButtons buttons={item2.buttons} params={{item: item, item2: item2}}/>
                            : <>
                                <div className="d-block d-lg-none text-black-50">{headers[index2].title}</div>
                                <div className={item2.className}>{item2.numberFormat
                                  ?
                                  <>
                                      <Number
                                        // value={checkJsonProp(item, item2.name).replace('.',',')}
                                        value={checkJsonProp(item, item2.name)}
                                        format={item2.numberFormat}
                                      />
                                      {` ${checkJsonProp(item, item2.numberSuffix)}`}
                                      </>
                                  : checkJsonProp(item, item2.name)}
                                </div>
                            </>
                            }
                        </div>
                    )}
                </div>
            )}

        </div>
    </>
};

EBTable.propTypes = {
    data: PropTypes.array.isRequired,
    mapData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        headerClassName: PropTypes.string,
        className: PropTypes.string,
    })),
    title: PropTypes.string.isRequired,
    headers: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })),
    click: PropTypes.func.isRequired,
};
