import React from 'react'
import PropTypes from 'prop-types'

import { FeePaymentSpy, NewPaymentSpy } from './'
import { ForexExchangeSpy } from './FormSpies/ForexExchangeSpy'
import { InternationalPaymentSpy } from './FormSpies/InternationalPaymentSpy'
import {Payment} from "@utilities";

export const FormSpySwitch = props => {
  if (!props.name) {
    return null
  }

  let currentSpy = null
  switch (props.name) {
    case Payment.NEW:
      currentSpy = <NewPaymentSpy {...props} />
      break
    case Payment.FOREX_EXCHANGE:
      currentSpy = <ForexExchangeSpy {...props} />
      break
    case Payment.INTERNATIONAL:
      currentSpy = <InternationalPaymentSpy {...props} />
      break
    case Payment.FEE:
      currentSpy = <FeePaymentSpy {...props} />
      break
    default:
      return null
  }

  return currentSpy
}

FormSpySwitch.propTypes = {
  name: PropTypes.string.isRequired
}
