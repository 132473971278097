import {store, UACActions} from "../Redux";
import {checkJsonProp} from "./checks";

export const ci = (name, input) => {
    let element1 = document.createElement("INPUT");
    element1.name = name;
    element1.value = input;
    element1.type = 'hidden';
    return element1;
};

export const ClickEServices = () => {
    const {dispatch} = store;
    dispatch(UACActions.getGGDataMy()).then(response => {
        if (checkJsonProp(response, 'payload.IsSuccessfull')) {
            const ggData = checkJsonProp(response, 'payload.GGRedirData');
            let form = document.createElement("form");
            document.body.appendChild(form);
            form.method = "POST";
            form.target = "_blank";
            form.action = ggData.RedirURL;
            form.appendChild(ci("SRC", ggData.CompanyCode));
            form.appendChild(ci("TIME", ggData.DateTime));
            form.appendChild(ci("PERSON_CODE", ggData.PersonCode));
            form.appendChild(ci("PERSON_FNAME", ggData.FName));
            form.appendChild(ci("PERSON_LNAME", ggData.LName));
            form.appendChild(ci("SIGNATURE", ggData.Signature));
            form.appendChild(ci("TYPE", "BANK-01"));
            form.submit();
        }
    });
}