import React from 'react';
import { trb, i18 } from '@utilities'

export const LoginFooter = () => {
    return <div className="bbs_login_footer">
        <div className="bbs_login_wrapper">
            <div className="row">
                <div className="col-12 text-center mb-3">
                {trb(i18.Labels.FooterCopyright, { yyyy: new Date().getFullYear() })}
                </div>
            </div>
        </div>
    </div>;
};
