import React from 'react'
import { Editor } from 'draft-js'
import { appEnvSME } from '@utilities'

export const ChatInput = ({editorState, setEditorState, name}) => {
    return (
        <div className='RichEditor-root'>
            <div
                id={`ChatInput${name}`}
                style={appEnvSME ? { borderRadius: 30, backgroundColor: '#f5f5f5', border: 'none' } : {}}
                className='RichEditor-editor'
            >
                <Editor editorState={editorState} onChange={setEditorState} />
            </div>
        </div>
    )
}
