import {
  i18,
  required,
  translationGroups,
  appEnvSME,
  checkMaxLength,
  classifikatorsMap,
  EUR,
  validateTemplateIban
} from '@utilities'
import { FormFieldType, filterFormOptions, payerPayeeType, paymentPurposeType } from '@components'
import {
  getBeneficiaryAddressValidation,
  getBeneficiaryBankCodeValidation,
  getBeneficiaryBankNameValidation,
  getBeneficiaryCityValidation,
  getBeneficiaryPostCodeValidation
} from './PaymentFormValidations'

export const TemplateForm = props => {
  const { CISO } = props

  return {
    parts: [
      {
        title: i18.Labels.Template + props.type,
        fields: [
          {
            title: i18.Labels.PaymentTemplateName,
            name: 'Payment.TemplateName',
            component: FormFieldType.input,
            inline: !appEnvSME,
            //visible: 'Payment.SaveTemplate',
            validate: required
          },
          {
            title: i18.Labels.PayeeTitle,
            name: 'Payment.Recipient',
            component: FormFieldType.input,
            inline: !appEnvSME,
            validate: required
          },
          {
            title: i18.Labels.PayeeAccount,
            name: 'Payment.BeneficiaryAccountNumber',
            component: FormFieldType.input,
            inline: !appEnvSME,
            validate: validateTemplateIban
          },

          {
            title: i18.Labels.PurposeType,
            name: 'Payment.IsPurposeSelected',
            component: FormFieldType.radio,
            options: paymentPurposeType,
            translate: translationGroups.PaymentPurposeTypes,
            optionsLabel: 'label',
            optionsValue: 'value',
            inline: true,
            classNameField: appEnvSME ? 'col-8' : null,
            classNameTitle: appEnvSME ? 'col-4' : null
            //validate: required
          },
          {
            title: i18.Labels.Purpose,
            name: 'Payment.Purpose',
            component: FormFieldType.input,
            inline: !appEnvSME,
            validate: required,
            visible: 'Payment.IsPurposeSelected',
            visibleValue: 'true'
          },
          {
            title: i18.Labels.PurposeNo,
            name: 'Payment.ReferenceNo',
            component: FormFieldType.input,
            inline: !appEnvSME,
            validate: required,
            visible: 'Payment.IsPurposeSelected',
            visibleValue: 'false'
          },
          {
            title: i18.Labels.PaymentUniqueCode,
            name: 'Payment.CustomersCode',
            component: FormFieldType.input,
            inline: !appEnvSME,
            validate: checkMaxLength(10),
            hidden: 'target2Payment'
          },

          {
            title: i18.Labels.BeneficiaryCountryCode,
            name: 'Payment.BeneficiaryCountryCode',
            component: FormFieldType.selectSearch,
            target2Item: true,
            visible: 'target2Payment',
            options: classifikatorsMap(CISO),
            optionsLabel: 'label',
            optionsValue: 'value',
            validate: required
          },
          {
            title: i18.Labels.BeneficiaryCity,
            name: 'Payment.BeneficiaryCity',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryCityValidation(EUR, true)
          },
          {
            title: i18.Labels.BeneficiaryAddress,
            name: 'Payment.BeneficiaryAddress',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryAddressValidation(EUR, true)
          },
          {
            title: i18.Labels.BeneficiaryPostcode,
            name: 'Payment.BeneficiaryPostCode',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryPostCodeValidation(EUR, true)
          },
          {
            title: i18.Labels.BankName,
            name: 'Payment.BankName',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryBankNameValidation(EUR, true)
          },
          {
            title: i18.Labels.RecipientBankCountry,
            name: 'Payment.RecipientBankCountry',
            component: FormFieldType.selectSearch,
            options: classifikatorsMap(CISO),
            optionsLabel: 'label',
            optionsValue: 'value',
            target2Item: true,
            visible: 'target2Payment',
            validate: required
          },
          {
            title: i18.Labels.BIC,
            name: 'Payment.BIC',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryBankCodeValidation(EUR, true)
          },
          {
            title: i18.Labels.RoutingCode,
            name: 'Payment.RoutingCode',
            component: FormFieldType.input,
            defaultValue: 'BIC',
            disabled: true,
            target2Item: true,
            visible: 'target2Payment'
          },
          {
            title: i18.Labels.CorrespondentBankBic,
            name: 'Payment.CorrespondentBankBic',
            component: FormFieldType.input,
            target2Item: true,
            visible: 'target2Payment',
            validateCompose: true,
            validate: getBeneficiaryBankCodeValidation(EUR, true)
          },
          {
            name: 'Payment.IsAdditionalPaymentInformationSelected',
            component: FormFieldType.checkbox,
            translate: i18.Labels.AdditionalPaymentInformation,
            inline: !appEnvSME,
            hidden: 'target2Payment'
          }
        ]
      },
      {
        title: i18.PaymentParts.PayerInformation,
        visible: 'Payment.IsAdditionalPaymentInformationSelected',
        className: 'col-6',
        fields: [
          {
            title: i18.Labels.PayerType,
            name: 'Payment.PayerType',
            component: FormFieldType.radio,
            options: payerPayeeType,
            optionsLabel: 'label',
            optionsValue: 'value',
            translate: translationGroups.PayerPayeeTypes,
            inline: !appEnvSME
          },
          {
            title: i18.Labels.IdentificationType,
            name: 'Payment.PayerIdentTypeCode',
            component: FormFieldType.select,
            options: props.PayPersonTypes,
            optionsFunction: filterFormOptions,
            optionsFunctionParams: 'Payment.PayerType',
            optionsLabel: 'Text',
            optionsValue: 'Code',
            inline: !appEnvSME,
            visible: 'Payment.PayerType'
          },
          {
            title: i18.Labels.IdentificationCode,
            name: 'Payment.PayerIdentTypeValue',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.PayerType'
          },
          {
            title: i18.Labels.PayerAddress,
            name: 'Payment.PayerAddress',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.PayerType'
          }
        ]
      },
      {
        title: i18.PaymentParts.InitialPayerInformation,
        visible: 'Payment.IsAdditionalPaymentInformationSelected',
        className: 'col-6',
        fields: [
          {
            title: i18.Labels.InitPayerType,
            name: 'Payment.InitPayerType',
            component: FormFieldType.radio,
            options: payerPayeeType,
            optionsLabel: 'label',
            optionsValue: 'value',
            translate: translationGroups.PayerPayeeTypes,
            inline: !appEnvSME
          },
          {
            title: i18.Labels.InitPayerTitle,
            name: 'Payment.InitPayerName',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.InitPayerType'
          },
          {
            title: i18.Labels.IdentificationType,
            name: 'Payment.InitPayerIdentTypeCode',
            component: FormFieldType.select,
            options: props.PayPersonTypes,
            optionsFunction: filterFormOptions,
            optionsFunctionParams: 'Payment.InitPayerType',
            optionsLabel: 'Text',
            optionsValue: 'Code',
            inline: !appEnvSME,
            visible: 'Payment.InitPayerType'
          },
          {
            title: i18.Labels.IdentificationCode,
            name: 'Payment.InitPayerIdentTypeValue',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.InitPayerType'
          }
        ]
      },
      {
        title: i18.PaymentParts.PayeeInformation,
        visible: 'Payment.IsAdditionalPaymentInformationSelected',
        className: 'col-6',
        fields: [
          {
            title: i18.Labels.PayeeType,
            name: 'Payment.PayeeType',
            component: FormFieldType.radio,
            options: payerPayeeType,
            optionsLabel: 'label',
            optionsValue: 'value',
            translate: translationGroups.PayerPayeeTypes,
            inline: !appEnvSME
          },
          {
            title: i18.Labels.IdentificationType,
            name: 'Payment.PayeeIdentTypeCode',
            component: FormFieldType.select,
            options: props.PayPersonTypes,
            optionsFunction: filterFormOptions,
            optionsFunctionParams: 'Payment.PayeeType',
            optionsLabel: 'Text',
            optionsValue: 'Code',
            inline: !appEnvSME,
            visible: 'Payment.PayeeType'
          },
          {
            title: i18.Labels.IdentificationCode,
            name: 'Payment.PayeeIdentTypeValue',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.PayeeType'
          },
          {
            title: i18.Labels.PayeeAddress,
            name: 'Payment.PayeeAddress',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.PayeeType'
          }
        ]
      },
      {
        title: i18.PaymentParts.InitialPayeeInformation,
        visible: 'Payment.IsAdditionalPaymentInformationSelected',
        className: 'col-6',
        fields: [
          {
            title: i18.Labels.FinalPayeeType,
            name: 'Payment.FinalPayeeType',
            component: FormFieldType.radio,
            options: payerPayeeType,
            optionsLabel: 'label',
            optionsValue: 'value',
            translate: translationGroups.PayerPayeeTypes,
            inline: !appEnvSME
          },
          {
            title: i18.Labels.IdentificationType,
            name: 'Payment.FinalPayeeIdentTypeCode',
            component: FormFieldType.select,
            options: props.PayPersonTypes,
            optionsFunction: filterFormOptions,
            optionsFunctionParams: 'Payment.FinalPayeeType',
            optionsLabel: 'Text',
            optionsValue: 'Code',
            inline: !appEnvSME,
            visible: 'Payment.FinalPayeeType'
          },
          {
            title: i18.Labels.IdentificationCode,
            name: 'Payment.FinalPayeeIdentTypeValue',
            component: FormFieldType.input,
            inline: !appEnvSME,
            visible: 'Payment.FinalPayeeType'
          }
        ]
      }
    ]
  }
}
