import {MenuConstants} from './';

const mobileCheck = window.screen.width < 426

let menuCollapsed = mobileCheck ? false : JSON.parse(sessionStorage.getItem('menuCollapsed'));

if (menuCollapsed === null || menuCollapsed === undefined && !mobileCheck){
    sessionStorage.setItem('menuCollapsed', true);
}

const initialState = {menuCollapsed}

export function MenuReducer(state = initialState, action) {
    switch (action.type) {
        case MenuConstants.MENU_TOGGLE:
            const newCollapsed = !state.menuCollapsed;
            sessionStorage.setItem('menuCollapsed', newCollapsed);
            return {
                ...state,
                menuCollapsed: newCollapsed,
            };
        case MenuConstants.SET_CONTENT_MENU:
            return {
                ...state,
                contentMenu: action.payload.contentMenu,
                contentMenuPath: action.payload.path,
            };
        case MenuConstants.SET_TITLE:
            return {
                ...state,
                contentTitle: action.payload.title
            };
        default:
            return state;
    }
}