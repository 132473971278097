import React from 'react'
import {Form, FormSpy} from 'react-final-form'
import {Alert, Button} from 'antd'
import {useDispatch, useSelector} from 'react-redux'

import {DepositsValidation, FeeModule, FeePaymentValidation, FormField, FormSpySwitch, NewPaymentValidation} from '../'
import {
    appEnvSME,
    checkJsonExist,
    checkJsonProp,
    composeValidatorsJSON,
    i18,
    Payment,
    PaymentType,
    translationGroups,
    trb
} from '@utilities'
import {
    DepositForm,
    FeePaymentForm,
    ForexExchangeForm,
    InternationalPaymentForm,
    NewPaymentForm,
    OwnAccountsForm
} from './'
import {OwnAccountsValidation} from './FormValidations/OwnAccountsValidation'
import {ForexValidation} from './FormValidations/ForexValidation'
import {InternationalPaymentValidation} from './FormValidations/InternationalPaymentValidation'

const ButtonGroup = Button.Group

export const FormEntrySwitch = props => {
    const dispatch = useDispatch();
    const AMCReducers = useSelector((state) => state.AMCReducers);

    let currentForm = null,
        initialData = {}

    if (!props.name) {
        return null
    }
    let templateData = {};
    switch (props.name) {
        case 'deposits':
            currentForm = DepositForm(props)
            initialData = {
                ProductTypeID: 4,
                SubProductID: 38,
                Interest: 0,
                AutoResume: 'WithInterest'
            }
            break
        case Payment.OWN_ACCOUNTS:
            currentForm = OwnAccountsForm(props)
            initialData = {
                Payment: {
                    Template: props.templateId,
                    PaymentType: 4
                }
            }
            break
        case Payment.NEW:
            let template = null;
            if(props?.templateId){
                template = props.PaymentTemplates.find((item)=>item.TemplateID === props.templateId);
                templateData=template.Payment;
            }

            initialData = {
                Payment: {
                    ...(props?.data?.Payment ?? templateData),
                    Template: props.templateId,
                    PaymentType: template?.PaymentType ?? PaymentType.SEPA,
                    SaveTemplate: false,
                    IsPurposeSelected: 'true',
                }
            }

            currentForm = NewPaymentForm(
                props,
                [PaymentType.SEPA, PaymentType.TARGET2]
            )
            break
        case Payment.FEE:
            initialData = {
                Payment: {
                    Template: props.templateId,
                    PaymentType: 2,
                    IsPurposeSelected: true,
                    IsSEPAPaymentTypeSelected: true,
                    SEPAPaymentType: 'UBIL',
                    Purpose: null
                },
                FeeModuleId: null
            }
            currentForm = FeePaymentForm(props)
            break
        case Payment.INTERNATIONAL: {
            templateData = {};
            if (props?.templateId) {
                const template = props.PaymentTemplates.find((item) => item.TemplateID === props.templateId);
                templateData = template.Payment;
            }

            initialData = {
                Payment: {
                    ...(props?.data?.Payment ?? templateData),
                    Template: props.templateId,
                    PaymentType: PaymentType.INTERNATIONAL,
                    SaveTemplate: false
                }
            }

            currentForm = InternationalPaymentForm({...props, dispatch})
            break
        }

        case Payment.FOREX_EXCHANGE:
            initialData = {
                Payment: {
                    ...props?.data?.Payment
                }
            }
            currentForm = ForexExchangeForm(props)
            break
        default:
            console.log('Form type not found')
    }


    const currentFormSpy = (feeModuleId, index, values, form) => {
        if (props.name === Payment.FEE) {
            values.FeeModuleId = feeModuleId
            return (
                <FeeModule
                    key={index}
                    form={form}
                    values={values}
                    feeModuleId={feeModuleId}
                    feePaymentHistory={props.feePaymentHistory}
                />
            )
        } else {
            return (
                <FormSpy
                    key={index}
                    name={props.name}
                    templates={props.PaymentTemplates}
                    data={{...initialData}}
                    component={FormSpySwitch}
                    PVR={props.PVR}
                    clearErrors={props.clearErrors}
                    templateId={props.templateId}
                    forexPayments={props.forexPayments?.InitialAccounts}
                />
            )
        }
    }
    /**
     * Checks for valuesArr items in values like 'Payments.Currency' and returns values merged into string
     * if none of the values were found returns null
     * @param valuesArr items to search for
     * @param values values to search in like an object
     * @returns {null|*}
     */
    const getTitleValue = (valuesArr, values) => {
        const result = valuesArr.reduce((curr, item) => {
            return (curr += checkJsonExist(values, item)
                ? checkJsonProp(values, item)
                : item)
        }, '')
        return result === valuesArr.join('') ? null : result
    }

    return (
        <div className={`form ${props.className ?? ''}`}>
            <Form
                onSubmit={props.submit}
                decorators={props.decorator}
                keepDirtyOnReinitialize={true}
                initialValues={{...initialData}}
                validate={values => {
                    switch (props.name) {
                        case 'deposits':
                            return DepositsValidation({values, CIPTR: props.CIPTR})
                        case Payment.FEE:
                            return FeePaymentValidation(values, props)
                        case Payment.NEW:
                            return NewPaymentValidation(values, props)
                        case Payment.OWN_ACCOUNTS:
                            return OwnAccountsValidation(values, props)
                        case Payment.FOREX_EXCHANGE:
                            return ForexValidation(values, props, AMCReducers)
                        case Payment.INTERNATIONAL:
                            return InternationalPaymentValidation(values, props, props.forexPayments?.InitialAccounts)
                        default:
                            return null
                    }
                }}
                render={({handleSubmit, form, submitting, pristine, values}) => {

                    return (
                        <form className="mb-3 row" onSubmit={handleSubmit}>
                            {props.name !== Payment.FEE && currentFormSpy()}
                            {values.note && (
                                <Alert
                                    type="warning"
                                    message={
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: values.note.replace(/\n/g, '<br />')
                                            }}
                                        />
                                    }
                                    className="mb-3 col-12"
                                />
                            )}
                            {currentForm.title && (
                                <div className="form-title col-12">
                                    {trb(currentForm.title)}
                                </div>
                            )}
                            {currentForm.parts.map((partItem, partIndex) => {
                                let a = checkJsonExist(values, partItem.visible)
                                const b = checkJsonProp(values, partItem.visible)
                                if (
                                    partItem.visible ===
                                    'Payment.IsAdditionalPaymentInformationSelected'
                                ) {
                                    if (!a) {
                                        a = !a
                                    }
                                }
                                return !a || (b && b === true) ? (
                                    partItem.moduleComponent === 'FeeModule' ? (
                                        currentFormSpy(
                                            values.Payment && values.Payment.FeeProvider,
                                            partIndex,
                                            values,
                                            form
                                        )
                                    ) : (
                                        <div
                                            className={`${
                                                partItem.className
                                                    ? partItem.className
                                                    : appEnvSME
                                                        ? 'col-12 col-md-6'
                                                        : 'col-12'
                                            } form-part`}
                                            key={partIndex}>
                                            {partItem.title && (
                                                <div className="form-part-title">
                                                    {trb(partItem.title)}
                                                </div>
                                            )}
                                            {partItem.fields.map((item, index) => {



                                                return !item.visible ||
                                                checkJsonProp(values, item.visible) ? (
                                                    <FormField
                                                        key={`${item?.name}-${index}`}
                                                        className={item.className}
                                                        classNameTitle={item.classNameTitle}
                                                        classNameField={item.classNameField}
                                                        classNameError={item.classNameError}
                                                        classNameNumber={item.classNameNumber}
                                                        title={
                                                            item?.titleFromInputValues
                                                                ? getTitleValue(
                                                                    item?.titleFromInputValues,
                                                                    values
                                                                )
                                                                : item.title
                                                        }
                                                        name={item.name}
                                                        component={item.component}
                                                        componentOptions={item.componentOptions}
                                                        formatNumber={item.formatNumber}
                                                        inline={appEnvSME ? item.inline : true}
                                                        options={
                                                            item.optionsFunction
                                                                ? item.optionsFunction(
                                                                    item.optionsFunctionParams,
                                                                    values,
                                                                    item.options
                                                                )
                                                                : item.options
                                                                    ? item.options
                                                                    : []
                                                        }
                                                        optionsLabel={item.optionsLabel}
                                                        optionsValue={item.optionsValue}
                                                        optionsKey={item.optionsKey}
                                                        optionsDisabled={item.optionsDisabled}
                                                        limitElements={item.limitElements}
                                                        onSearch={item.onSearch}
                                                        translate={item.translate}
                                                        validate={
                                                            item.validateCompose
                                                                ? composeValidatorsJSON(
                                                                    item.validate,
                                                                    props.form
                                                                )
                                                                : item.validate
                                                        }
                                                        disabled={
                                                            item.disabledFunction
                                                                ? item.disabledFunction(
                                                                    item.disabledFunctionParams,
                                                                    values,
                                                                    item.options
                                                                )
                                                                : (item.disabled || props.submitting)
                                                        }
                                                        loading={item.loading}
                                                        maxLength={item.maxLength}
                                                        html={item.html}
                                                        type={item.type}
                                                        defaultValue={item.defaultValue}
                                                        notify={submitting ? null : item.notify}
                                                        error={submitting ? null : item.error}
                                                        noFilter={item.noFilter}
                                                        highlightOne={item.highlightOne}
                                                        small={item.small}
                                                        customChecks={item.customChecks}
                                                        optionsColor={item.optionsColor}
                                                        hightlight={item.hightlight}
                                                        allowClear={item.allowClear}
                                                        helperOnChange={item.helperOnChange}
                                                        onBlur={item.onBlur}
                                                        minDate={item.minDate}
                                                        maxDate={item.maxDate}
                                                        currency={values?.Payment?.Currency}
                                                    />
                                                ) : null
                                            })}
                                        </div>
                                    )
                                ) : null
                            })}
                            {appEnvSME ? (
                                <>
                                    <div className="mb-3 text-right d-block col-5 col-md-12 pl-4 pl-md-3">
                                        <ButtonGroup>
                                            {props.buttons &&
                                                props.buttons.map((item, index) => (
                                                    <Button
                                                        key={index}
                                                        onClick={item.func}
                                                        {...item.props}>
                                                        {trb(item.title)}
                                                    </Button>
                                                ))}
                                        </ButtonGroup>
                                    </div>
                                    {/*<div className="mb-3 text-right d-block d-sm-none col-6 pr-0 pl-4">*/}
                                    {/*  {props.buttons && props.buttons.map((item, index) =>*/}
                                    {/*    <Button*/}
                                    {/*      className='mb-2'*/}
                                    {/*      key={index}*/}
                                    {/*      onClick={item.func}*/}
                                    {/*      block*/}
                                    {/*      {...item.props}*/}
                                    {/*    >*/}
                                    {/*      {trb(item.title)}*/}
                                    {/*    </Button>*/}
                                    {/*  )}*/}
                                    {/*</div>*/}
                                </>
                            ) : (
                                <>
                                    <div className="mb-3 text-right d-none d-md-block col-9 px-0">
                                        <ButtonGroup>
                                            {props.buttons &&
                                                props.buttons.map((item, index) => (
                                                    <Button
                                                        key={index}
                                                        onClick={item.func}
                                                        {...item.props}>
                                                        {trb(item.title)}
                                                    </Button>
                                                ))}
                                        </ButtonGroup>
                                    </div>
                                    <div className="mb-3 text-right d-none d-md-none d-sm-block col-12">
                                        <ButtonGroup>
                                            {props.buttons &&
                                                props.buttons.map((item, index) => (
                                                    <Button
                                                        key={index}
                                                        onClick={item.func}
                                                        {...item.props}>
                                                        {trb(item.title)}
                                                    </Button>
                                                ))}
                                        </ButtonGroup>
                                    </div>
                                    <div className="mb-3 text-right d-block d-sm-none col-12">
                                        {props.buttons &&
                                            props.buttons.map((item, index) => (
                                                <Button
                                                    className="mb-2"
                                                    key={index}
                                                    onClick={item.func}
                                                    block
                                                    {...item.props}>
                                                    {trb(item.title)}
                                                </Button>
                                            ))}
                                    </div>
                                </>
                            )}
                            {props.errors && (
                                <Alert
                                    className="mb-3 col-12"
                                    style={{paddingLeft: '4rem'}}
                                    message={trb(i18.ErrorTitles.PaymentErrors)}
                                    description={
                                        <ul>
                                            {props.errors &&
                                                props.errors.map((item, index) => (
                                                    <li key={index}>
                                                        {trb(translationGroups.ErrorCode + item.ErrorCode)}
                                                    </li>
                                                ))}
                                            {appEnvSME && props.DepositAmountNotValid && (
                                                <li>
                                                    {trb(i18.NotificationTitles.DepositAmountNotValid)}
                                                </li>
                                            )}
                                        </ul>
                                    }
                                    type="error"
                                    showIcon
                                />
                            )}
                        </form>
                    )
                }}
            />
        </div>
    )
}
