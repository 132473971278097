import React from 'react';
import {connect} from "react-redux";

import {MonitoringActions} from "../../Redux";
import {history, routerPath} from "../../Config";

import {checkJsonProp, i18, translationGroups, trb, formatDate, numberFormat} from "../../Utilities";
import {ContentLoader, ContentNoData, Number} from '../../Components';

class LogsContainer extends React.Component {
    constructor(props){
        super(props);
    }

    componentDidMount = () => {
        const {dispatch} = this.props;

        dispatch(MonitoringActions.getSessionLogs());
    }

    render(){
        const {sendLogs, sessionLogs} = this.props;
        /*const sendLogs = false,
              sessionLogs = [{
                  Id: '123',
                  Level: 'level',
                  TimeStamp: '2020-10-10 12:50:30',
                  Exception: 'Exception',
                  ResponseCode: 'ResponseCode',
                  Created: '2020-10-10 12:50:30',
                  Ended: '2020-10-10 12:50:30',
                  RequestUrl: 'https://kreda.lt',
                  RequestExplanation: 'RequestExplanation',
                  ClientId: '64144556',
                  UserCode: '64144556',
                  ElapsedMilliseconds: '123456',
                  Arguments: 'Arguments',
                  AuthData: 'AuthData',
                  Ip: '123.123.123.123',
                  Session: 'Session'
              }];*/

        if (sendLogs){
            return <ContentLoader/>;
        }

        if (!sessionLogs || sessionLogs.length === 0){
            return <ContentNoData/>;
        }

        return <table className="eb_table eb_table_logs my-3">
            <thead>
                <tr className="eb_table_head">
                    <th>{trb(i18.Labels.LogsId)}</th>
                    <th>{trb(i18.Labels.LogsLevel)}</th>
                    <th>{trb(i18.Labels.LogsTimeStamp)}</th>
                    <th>{trb(i18.Labels.LogsException)}</th>
                    <th>{trb(i18.Labels.LogsResponseCode)}</th>
                    <th>{trb(i18.Labels.LogsCreated)}</th>
                    <th>{trb(i18.Labels.LogsEnded)}</th>
                    <th>{trb(i18.Labels.LogsRequestUrl)}</th>
                    <th>{trb(i18.Labels.LogsRequestExplanation)}</th>
                    <th>{trb(i18.Labels.LogsClientId)}</th>
                    <th>{trb(i18.Labels.LogsUserCode)}</th>
                    <th>{trb(i18.Labels.LogsElapsedMilliseconds)}</th>
                    <th>{trb(i18.Labels.LogsArguments)}</th>
                    <th>{trb(i18.Labels.LogsIp)}</th>
                    <th>{trb(i18.Labels.LogsSession)}</th>
                </tr>
            </thead>
            <tbody>
            {sessionLogs.map((item, index) =>
                <tr className="eb_table_row" key={index}>
                    <td>{checkJsonProp(item, 'Id')}</td>
                    <td>{checkJsonProp(item, 'Level')}</td>
                    <td>{formatDate(checkJsonProp(item, 'TimeStamp'))}</td>
                    <td>{checkJsonProp(item, 'Exception')}</td>
                    <td>{checkJsonProp(item, 'ResponseCode')}</td>
                    <td>{formatDate(checkJsonProp(item, 'Created'))}</td>
                    <td>{formatDate(checkJsonProp(item, 'Ended'))}</td>
                    <td>{checkJsonProp(item, 'RequestUrl')}</td>
                    <td>{checkJsonProp(item, 'RequestExplanation')}</td>
                    <td>{checkJsonProp(item, 'ClientId')}</td>
                    <td>{checkJsonProp(item, 'UserCode')}</td>
                    <td><Number value={checkJsonProp(item, 'ElapsedMilliseconds')} format={numberFormat().number}/></td>
                    <td>{checkJsonProp(item, 'Arguments')}</td>
                    <td>{checkJsonProp(item, 'Ip')}</td>
                    <td style={{width: '210px'}}>{checkJsonProp(item, 'Session')}</td>
                </tr>
            )}
            </tbody>
        </table>
    }
}

function mapStateToProps(state) {
    const {sendLogs, sessionLogs} = state.MonitoringReducers;
    return {
        sendLogs,
        sessionLogs
    };
}

const connectedLogsContainer = connect(mapStateToProps)(LogsContainer);
export {connectedLogsContainer as LogsContainer};
