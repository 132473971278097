import React from 'react'
import { Button } from 'antd'
import { Form } from 'react-final-form'

import createDecorator from 'final-form-calculate'
import { checkCookieForUser } from '../Functions'
import { FormField, FormFieldType, LanguageSelect } from '@components'
import {
  appEnvMano,
  checkForWhiteSpaces,
  composeValidators,
  i18,
  required,
  trb
} from '@utilities'
import { LoginCountdown } from './LoginCountdown'

export const LoginForm = ({
  authCookie,
  LoginIdentity,
  RememberMe,
  UID,
  submit,
  submitting,
  submitError,
  seconds,
  secondsRemaining,
  confirming,
  withLang,
  currentForm
}) => {
  const decorators = createDecorator({
    field: 'LoginCode',
    updates: value => {
      let isFullLogin = true
      if (value && value.length > 5) {
        isFullLogin = checkCookieForUser(value, authCookie)
      }
      return { isFullLogin }
    }
  })

  const initialValues = {
    UID: UID,
    LoginCode: UID,
    isFullLogin: false,
    LoginIdentity: LoginIdentity,
    RememberMe: RememberMe
  }

  const onSubmit = data => {
    let LoginSystem

    switch (currentForm) {
      case 1:
        LoginSystem = 'MSignature'
        break
      case 2:
        LoginSystem = 'SmartId'
        break
      case 3:
        LoginSystem = 'Sms'
        break
      case 4:
        LoginSystem = 'BioFace'
        break
      default:
        LoginSystem = 'MSignature'
        break
    }
    data.LoginSystem = LoginSystem
    submit(data)
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[decorators]}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        const { isFullLogin } = values

        return (
          <form onSubmit={handleSubmit} id={`LoginForm${currentForm}`}>
            <div className="form-group mb-4">
              {false && withLang && <LanguageSelect withoutLabels />}
              <FormField
                title={i18.Labels.IdentificationUID}
                name={appEnvMano && currentForm !== 4 ? 'UID' : 'LoginCode'}
                component={FormFieldType.input}
                inputType="tel"
                validate={composeValidators(required, checkForWhiteSpaces)}
                size="large"
                disabled={submitting}
                autoFocus={true}
              />
              {appEnvMano && submitError && currentForm !== 4 && (
                <div className="form-field-error">{submitError}</div>
              )}
            </div>
            {!appEnvMano && currentForm !== 4 && (
              <div className="d-none">
                <FormField
                  title=""
                  name="isFullLogin"
                  component={FormFieldType.checkbox}
                  disabled={submitting}
                />
              </div>
            )}
            {!appEnvMano && isFullLogin !== false && (
              <div className="form-group">
                <FormField
                  title={i18.Labels.LoginIdentity}
                  name="LoginIdentity"
                  component={FormFieldType.input}
                  validate={composeValidators(required, checkForWhiteSpaces)}
                  size="large"
                  disabled={submitting}
                />
              </div>
            )}
            {!appEnvMano && isFullLogin !== false && (
              <div className="form-group mb-4">
                <FormField
                  name="RememberMe"
                  component={FormFieldType.checkbox}
                  translate={i18.Labels.RememberMe}
                  disabled={submitting}
                />
              </div>
            )}
            {!appEnvMano && submitError && (
              <div className="form-group mb-4">
                <div className="form-field-error">{trb(submitError)}</div>
              </div>
            )}
            {confirming && (
              <LoginCountdown
                seconds={seconds}
                secondsRemaining={secondsRemaining}
              />
            )}
            <div className="text-right">
              <Button
                id={`LoginForm${currentForm}SubmitButton`}
                type="primary"
                htmlType="submit"
                size="large"
                loading={submitting}>
                {trb(i18.Buttons.SignIn)}
              </Button>
            </div>
          </form>
        )
      }}
    />
  )
}
