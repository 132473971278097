import React from 'react';
import {Button} from "antd";
import {Form} from 'react-final-form';

import {FormField, FormFieldType} from '../../../../Components'
import {i18, trb, required} from "../../../../Utilities";
import {LoginCountdown} from "../";

export const LoginFormSMSConfirm = (props) => {
    return <Form
        onSubmit={props.submit}
        render={({handleSubmit}) => {
            return (
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                        <FormField
                            title={i18.Labels.IdentificationSMSCode}
                            name="CC_CC"
                            component={FormFieldType.input}
                            inputType="password"
                            validate={required}
                            size="large"
                            disabled={props.submitting}
                            autoFocus={true}
                            className="form-field-with-left-label"
                        />
                        {props.submitError && <div className="form-field-error">{trb(submitError)}</div>}
                    </div>
                    {props.confirming &&
                    <LoginCountdown
                        seconds={props.seconds}
                        secondsRemaining={props.secondsRemaining}
                    />
                    }
                    <div className="text-right">
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={props.submitting}
                        >{trb(i18.Buttons.Approve)}</Button>
                    </div>
                </form>
            )
        }}
    />;
};
