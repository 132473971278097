import createDecorator from "final-form-calculate";

export const DepositDecorator = (props) => {
    let time = [];
    return [
        createDecorator(
            {
                field: 'SubProductID',
                updates: (value, field, values) => {
                    if (value) {
                        props.getPDR(values);
                    }
                    return {};
                }
            }, {
                field: 'ProductTypeID',
                updates: async (value, field, values) => {
                    const { Interest } = values;
                    let res;
                    if (value) {
                        res = await props.getCIR(values);
                    }
                    return {
                        Interest: res ? res.InterestText : Interest,
                    };
                }
            }, {
                field: 'Period',
                updates: async (value, field, values, prevValues) => {
                    const { Interest } = values;
                    let res;
                    time.push(value);

                    if (value) {
                        res = await props.getCIR(values);
                    }

                    const foundIndex = time.findIndex(item => item === value);
                    if (foundIndex > -1) {
                        time = time.filter((item, index) => index > foundIndex);
                        return {
                            Interest: res ? res.InterestText : Interest,
                        };
                    }

                    return {};
                }
            }, {
                field: 'CurrencyID',
                updates: async (value, field, values) => {
                    const { Interest } = values;
                    let res;
                    if (value) {
                        res = await props.getCIR(values);
                    }
                    return {
                        Interest: res ? res.InterestText : Interest,
                    };
                }
            }, {
                field: 'InitialAccountID',
                updates: async (value, field, values) => {
                    const { Interest } = values;
                    let res;
                    if (value) {
                        res = await props.getCIR(values);
                    }
                    return {
                        Interest: res ? res.InterestText : Interest,
                    };
                }
            }
        )
    ];
};
