import React from "react";
import {currencyTextValue, i18, trb} from "@utilities";
import {Button} from "antd";
import PropTypes from "prop-types";

const ButtonGroup = Button.Group;

export const PaymentUploadTable = ({data, buttons}) => {

    return <>
        <h3 className="eb_content_subtitle">{trb(i18.Labels.Summary)}</h3>
        <div className="eb_table mb-3 w-100">
            <div className="row eb_table_head">
                <div className="col-md-3 text-right"/>
                <div className="col-md-3 text-right"/>
                <div className="col-md-3 text-right"/>
                <div className="col-md-3 text-right"/>
            </div>
            <div className="row eb_table_row ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="">{trb(i18.Labels.UploadDateTime)}</div>
                    <div className="eb_fw_600">{data?.UploadDateTime}</div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="">{trb(i18.Labels.FileName)}</div>
                    <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                         className="eb_fw_600">{data?.UploadFileName}</div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="">{trb(i18.Labels.FileId)}</div>
                    <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                         className="eb_fw_600">{data?.FileHeaderId}</div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="">{trb(i18.Labels.PaymentCount)}</div>
                    <div className="eb_fw_600">{data?.TotalPayments}</div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="">{trb(i18.Labels.PaymentSum)}</div>
                    <div className="eb_fw_600">{currencyTextValue(data?.TotalAmount, data?.Currency)}</div>
                </div>
            </div>
        </div>
        <h3 className="eb_content_subtitle">{trb(i18.Labels.Payments)}</h3>
        <div className="eb_table mb-3 w-100">
            <div className="row eb_table_head">
                <div className="col-md-3">{trb(i18.Labels.Payer)}</div>
                <div className="col-md-3 ">{trb(i18.Labels.Payee)}</div>
                <div className="col-md-4 ">{trb(i18.Labels.DestinationsCode)}</div>
                <div className="col-md-2 text-right">{trb(i18.Labels.Amount)}</div>
            </div>
            {data?.PaymentsList?.map((item) => (
                <div className="row eb_table_row " key={item.PaymentID}>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="">{item.DebtorName}</div>
                        <div className="">{item.DebtorIBAN?.replace(/.{4}(?=.)/g, '$& ')}</div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="">{item.CreditorName}</div>
                        <div className="">{item.CreditorIBAN?.replace(/.{4}(?=.)/g, '$& ')}</div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div className="">{item.PaymentPurpose}</div>
                        <div className="">{item.UniqPayCode}</div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-2 text-lg-right">
                        <div className="">{currencyTextValue(item?.Amount, item?.Currency)}</div>
                    </div>
                </div>))}
            <div className="mb-3 text-right">
                <ButtonGroup>
                    {buttons.map((item, index) =>
                        <Button
                            key={index}
                            onClick={item.func}
                            {...item.props}
                        >{trb(item.title)}</Button>
                    )}
                </ButtonGroup>
            </div>
        </div>
    </>;

};


PaymentUploadTable.propTypes = {
    data: PropTypes.shape({
        PaymentsList: PropTypes.arrayOf(PropTypes.shape({
            DebtorName: PropTypes.string,
            DebtorIBAN: PropTypes.string,
            CreditorName: PropTypes.string,
            CreditorIBAN: PropTypes.string,
            PaymentPurpose: PropTypes.string,
            UniqPayCode: PropTypes.string,
            AmountFormatted: PropTypes.string,
        })),
        UploadDateTime: PropTypes.string,
        UploadFileName: PropTypes.string,
        TotalPayments: PropTypes.number,
        TotalAmountFormatted: PropTypes.string,
    }),
    buttons: PropTypes.array
}