import {
    requestEBank,
    aPIPaths,
    handleResponse,
    methods,
    requestEBankAnonymous,
    requestEBankJSON,
    requestEBankFile,
    handleResponseWithCookies, handleResponseLogin, handleErrorLogin, store
} from '../../Config'
import {getToken} from "./reducers";

export const UACServices = {
    postESAR,
    postESACR,
    postSMSR,
    postFullLogin,
    postSimpleLogin,
    postCheckLogin,
    postSMSAR,
    getMACR,
    getMALR,
    postATR,
    getLogout,
    postCPRQ,
    postGPR,
    postSMSGETPWD,
    getGGDataMY,
    getRFT,
    getESARGET,
    postESARSM,
    getLoginMessage
};

function postESAR(data) {
    return requestEBankAnonymous(aPIPaths.post_UAC_ESAR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postESARSM(data) {
    return requestEBankAnonymous(aPIPaths.post_UAC_ESARSM, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postESACR(data) {
    return requestEBankAnonymous(aPIPaths.post_UAC_ESACR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSMSR(data = null) {
    return requestEBankJSON(aPIPaths.post_UAC_SMSR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postFullLogin(data = null) {
    // axios.defaults.withCredentials = true
    return requestEBankJSON(aPIPaths.post_UAC_FullLogin, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSimpleLogin(data = null) {
    // axios.defaults.withCredentials = true
    return requestEBankJSON(aPIPaths.post_UAC_SimpleLogin, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postCheckLogin(data = null) {
    // axios.defaults.withCredentials = true
    return requestEBankJSON(aPIPaths.post_UAC_CheckLogin, {
        method: methods.post,
        data: data,
    }).then(handleResponseLogin)
      .catch(handleErrorLogin);
}

function postSMSAR(data = null) {
    return requestEBankJSON(aPIPaths.post_UAC_SMSAR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function getMACR(data = null) {
    return requestEBank(aPIPaths.get_UAC_MACR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function getMALR() {
    return requestEBank(aPIPaths.get_UAC_MALR, {method: methods.post}).then(handleResponse);
}

function getLogout() {
    return requestEBank(aPIPaths.get_UAC_Logout, {method: methods.post}).then(handleResponse);
}

function postATR() {
    let user = store.getState().IframeStorageReducer?.user;
    const token = getToken(user)
    if (token) {
        if (requestEBank) {
            requestEBank.defaults.headers.common['Authorization'] = token;
            requestEBankJSON.defaults.headers.common['Authorization'] = token;
            requestEBankFile.defaults.headers.common['Authorization'] = token;
        }
    }
    if(!requestEBank.defaults.headers.common['Authorization']) {
        return null;
    }
    return requestEBank(aPIPaths.post_UAC_ATR, {
        method: methods.post
    }).then(handleResponse);
}

function postCPRQ(data) {
    return requestEBankJSON(aPIPaths.post_UAC_CPRQ, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postGPR(data) {
    return requestEBank(aPIPaths.post_UAC_GPR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSMSGETPWD(data) {
    return requestEBank(aPIPaths.post_UAC_SMSGETPWD, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function getGGDataMY() {
    return requestEBank(aPIPaths.get_UAC_GetGGDataMY).then(handleResponse);
}

function getRFT(refreshToken) {
    return requestEBank(aPIPaths.get_UAC_RFT + '/?refreshToken=' + refreshToken, {method: methods.post}).then(handleResponse);
}

function getESARGET() {
    return requestEBank(aPIPaths.get_UAC_ESARGET).then(handleResponse);
}

function getLoginMessage() { ///'https://run.mocky.io/v3/a4de68d5-9d18-4ddd-92e1-3ef37072875d'
    return requestEBank(aPIPaths.get_Login_Message).then(handleResponse);
}