import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {routerPath} from '@config'
import {i18, trb, Payment} from '@utilities'
import {MenuActions} from '@redux'
import {
    GroupPayment,
    UnsignedPaymentsContainer,
    PaymentFee,
    PaymentForex,
    PaymentGroups,
    PaymentInternational,
    PaymentNew,
    PaymentOwn,
    PaymentTemplates,
    PaymentUploading,
    PaymentView
} from './'
import {
    CalculatorOutlined,
    GlobalOutlined,
    RetweetOutlined,
    StarOutlined,
    UnorderedListOutlined,
    UploadOutlined
} from "@ant-design/icons";

export const PaymentsContainer = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const toggleReset = () => {
        setReset(prevState => !prevState)
    }

    const [reset, setReset] = useState(false)
    const [menuArray] = useState([

            {
                title: i18.PaymentTypes.NewPayment,
                icon: <GlobalOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.paymentNew}` ,
                customCurrentFunc: toggleReset
            },
            {
                title: i18.PaymentTypes.OwnAccounts,
                icon: <RetweetOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.paymentOwn}` ,
                active: true
            },
            {
                title: i18.ContentMenu.InternationalPayment,
                type: 'legacy',
                path: `${routerPath.payments}/${Payment.INTERNATIONAL}`
            },
            {
                title: i18.ContentMenu.NoSignetPayment,
                icon: <CalculatorOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.unsigned}`
            },
        // TODO: commented out group payments
        //     {
        //         title: i18.ContentMenu.GroupPayment,
        //         icon: <UnorderedListOutlined/>,
        //         type: 'legacy',
        //         path: `${routerPath.payments}/${routerPath.paymentGroup}`
        //     },
            {
                title: i18.ContentMenu.ForexExchange,
                icon: <UnorderedListOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.paymentForex}`
            },
            {
                title: i18.ContentMenu.PaymentTemplates,
                icon: <StarOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.paymentTemplates}`
            },
            {
                title: i18.ContentMenu.PaymentUploading,
                icon: <UploadOutlined/>,
                type: 'legacy',
                path: `${routerPath.payments}/${routerPath.paymentUploading}`
            }
        ]
    )

    useEffect(() => {
        if (location.pathname === routerPath.payments) {
            navigate(
                routerPath.payments + '/new-payment')

        }

        dispatch(MenuActions.setContentMenu(menuArray, routerPath.payments))
        dispatch(MenuActions.setTitle(trb(i18.Container.PaymentsContainer)))

        return () => dispatch(MenuActions.setContentMenu(null))
    }, [])

    return (
        <>
            <Routes>
                <Route path={routerPath.paymentGroup} element={<GroupPayment/>}/>
                <Route path={routerPath.paymentGroups} element={<PaymentGroups/>}/>
                <Route
                    path={routerPath.paymentTemplates+'/*'}
                    element={<PaymentTemplates/>}
                />

                <Route
                    path={routerPath.unsigned+'/*'}
                    element={<UnsignedPaymentsContainer />}
                />

                <Route
                    path={routerPath.paymentUploading}
                    element={<PaymentUploading/>}
                />

                {/* <Route
            path={routerPath.payment}
            element={props => <Payment {...props} reset={this.state.reset} />}
          /> */}

                <Route path={routerPath.paymentOwn} element={<PaymentOwn/>}/>
                <Route path={routerPath.paymentNew} element={<PaymentNew/>}/>
                <Route path={routerPath.paymentFee} element={<PaymentFee/>}/>
                <Route path={routerPath.paymentInternational} element={<PaymentInternational/>}/>
                <Route path={routerPath.paymentForex} element={<PaymentForex/>}/>
                {/*<Route path={routerPath.payments} element={*/}
                {/*    <PaymentView*/}
                {/*        match={props.match}*/}
                {/*        reset={reset}*/}
                {/*        location={props.location}*/}
                {/*    />}*/}
                {/*/>*/}
            </Routes>
        </>
    )
}
