import PropTypes from 'prop-types';

import {i18} from "@utilities";
import {FormPreviewSwitch} from "../FormPreviewSwitch";

export const OwnAccountsPreview = (props) => {
    if (!props?.Payment) {
        return null;
    }

    return {
        parts: [
            {
                fields: [
                    {
                        title: i18.Labels.FromAccount,
                        value: props.InitialAccount
                    }, {
                        title: i18.Labels.ToAccount,
                        value: props.DestinationAccount
                    }, {
                        title: i18.Labels.Amount,
                        value: props.Payment.AmountFormatted,
                    }, {
                        title: i18.Labels.Commission,
                        value: props.Payment.CommissionFeeFormatted,
                    }
                ]
            }
        ]
    }
};

FormPreviewSwitch.propTypes = {
    data: PropTypes.object.isRequired
};