import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { OperationApprovalMobile, OperationApprovalSMS } from './'

class OperationApproval extends React.Component {
  constructor(props) {
    super(props)

    this.approveType = this.approveType.bind(this)
    this.resend = this.resend.bind(this)
  }

  approveType = type => {
    const { sendSUOR, sendSUORSMS, sendAPESCR, sendAPSMSR } = this.props,
      send = sendSUOR || sendSUORSMS || sendAPESCR || sendAPSMSR
    if (
      type &&
      (type.toUpperCase() === 'SMS' || type.toUpperCase() === 'BIOFACE')
    ) {
      return (
        <div className="w-100">
          <OperationApprovalSMS
            RQ_ID={this.props.RQ_ID}
            submit={this.props.submitSmsApprove}
            resend={this.resend}
            cancel={this.props.cancelApprove}
            send={send}
            approveSum={this.props.approveSum}
            horizontal={this.props.horizontal}
            approveCount={this.props.approveCount}
          />
        </div>
      )
    } else if (type === 'eSign') {
      return (
        <div className="w-100">
          <OperationApprovalMobile
            code={this.props.code}
            RQ_ID={this.props.RQ_ID}
            submit={this.props.submitMobileApprove}
            resend={this.resend}
            cancel={this.props.cancelApprove}
            send={send}
            approveSum={this.props.approveSum}
            horizontal={this.props.horizontal}
            approveCount={this.props.approveCount}
            errorCode={this.props.errorCode}
          />
        </div>
      )
    } else {
      return <div />
    }
  }

  resend = () => {
    this.approveType()
  }

  render() {
    const { AuthType } = this.props
    return this.approveType(AuthType)
  }
}

function mapStateToProps(state) {
  const { sendSUOR, sendSUORSMS } = state.OMCReducers,
    { sendAPESCR, sendAPSMSR } = state.PMCReducers

  return {
    sendSUOR,
    sendSUORSMS,
    sendAPESCR,
    sendAPSMSR
  }
}

const connectedOperationApproval = connect(mapStateToProps)(OperationApproval)
export { connectedOperationApproval as OperationApproval }

OperationApproval.propTypes = {
  cancelApprove: PropTypes.func.isRequired,
  successApprove: PropTypes.func.isRequired,
  submitSmsApprove: PropTypes.func,
  submitMobileApprove: PropTypes.func,
  errorCode: PropTypes.string
}
