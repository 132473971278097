import {
  validateRegex,
  required,
  checkMaxLength,
  trb,
  i18,
  checkMinLength,
  checkAllLettersAndNumbers,
  checkCAPLettersAndNumbers,
  EUR,
  USD,
  KRW,
  CAD,
  CNY,
  AUD,
  INR,
  GBP,
  SGD,
  MYR,
  NOK,
  DKK,
  PLN,
  SEK,
  HUF,
  RON,
  NAME_VALIDATOR
} from '@utilities'

export const getBeneficiaryAddressValidation = (currency, target2Payment) => {
  const regexINR = /^[ ",:?/{}!@#'|%A-z0-9&();_.-]{1,200}$/
  const regexAUD = /^.{1,255}$/
  switch (currency) {
    case INR:
      return [
        validateRegex(
          regexINR,
          trb(i18.PaymensValidations.INRBeneficiaryAddressError)
        )
      ]
    case AUD:
      return [
        required,
        validateRegex(
          regexAUD,
          trb(i18.PaymensValidations.AUDBeneficiaryAddressError)
        )
      ]
    case GBP:
    case SGD:
    case CNY:
    case NOK:
    case DKK:
    case SEK:
    case PLN:
    case HUF:
    case RON:
      return [required, checkMaxLength(200)]
    case EUR:
      return target2Payment
        ? [required, checkMaxLength(35)]
        : [checkMaxLength(200)]
    case MYR:
    case KRW:
      return [checkMaxLength(200)]
    case USD:
    case CAD:
      return [required, checkMaxLength(255)]
    default: {
      return [required, checkMaxLength(255)]
    }
  }
}

export const getBeneficiaryCityValidation = (currency, target2Payment) => {
  switch (currency) {
    case INR:
    case GBP:

    case MYR:
    case KRW:
    case CNY:
      return [checkMaxLength(50)]
    case EUR:
      return target2Payment
        ? [required, checkMaxLength(35)]
        : [checkMaxLength(50)]
    case AUD:
    case USD:
    case CAD:
      return [required, checkMaxLength(255)]
    case SGD:
    case NOK:
    case DKK:
    case SEK:
    case PLN:
    case HUF:
    case RON:
      return [required, checkMaxLength(50)]
    default:
      return [required, checkMaxLength(50)]
  }
}

export const getBeneficiaryPostCodeValidation = (currency, target2Payment) => {
  switch (currency) {
    case EUR:
      return target2Payment
        ? [required, checkMaxLength(35)]
        : [checkMaxLength(15)]
    case INR:
    case GBP:
    case MYR:
    case SGD:
    case KRW:
    case CNY:
    case NOK:
    case DKK:
    case SEK:
    case PLN:
    case HUF:
    case RON:
      return [checkMaxLength(15)]
    case AUD:
    case USD:
    case CAD:
      return [required, checkMaxLength(15)]
    default:
      return [required, checkMaxLength(15)]
  }
}

export const getBeneficiaryBankNameValidation = (currency, target2Payment) => {
  const regexCNY = /^[A-z0-9., -]{1,100}$/

  switch (currency) {
    case CNY:
      return [
        validateRegex(
          regexCNY,
          trb(i18.PaymensValidations.CNYBeneficiaryBankNameError)
        )
      ]
    case EUR:
      return target2Payment
        ? [required, checkMaxLength(255)]
        : [checkMaxLength(255)]
    default:
      return [checkMaxLength(255)]
  }
}

export const getBeneficiaryBankCodeValidation = (currency, target2Payment) => {
  const regex3Symbols = /^[0-9]{3}$/
  const regex4Symbols = /^[0-9]{4}$/
  const regex45Symbols = /^[0-9]{4,5}$/

  switch (currency) {
    case AUD:
      return [required, checkMaxLength(11)]
    case EUR:
      return target2Payment
        ? [checkAllLettersAndNumbers, required, checkMaxLength(11), checkMinLength(8)]
        : [checkMaxLength(11)]
    case INR:
    case GBP:
    case USD:
      return [checkMaxLength(11), checkMinLength(8)]
    case KRW:
    case CAD:
      return [
        required,
        validateRegex(
          regex3Symbols,
          trb(i18.PaymensValidations.BeneficiaryBankCode3SymbolsError)
        ), checkMaxLength(11), checkMinLength(8)
      ]
    case NOK:
      return [
        required,
        validateRegex(
          regex4Symbols,
          trb(i18.PaymensValidations.BeneficiaryBankCode4SymbolsError)
        ),checkMaxLength(11), checkMinLength(8)
      ]
    case SEK:
      return [
        required,
        validateRegex(
          regex45Symbols,
          trb(i18.PaymensValidations.BeneficiaryBankCode45SymbolsError)
        ),checkMaxLength(11), checkMinLength(8)
      ]
    default:
      return [checkMaxLength(11), checkMinLength(8), validateRegex(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidBICCodeError))]
  }
}

export const getBeneficiaryIdentificationValueValidation = currency => {
  const regexNumbersLetters255 = /^[A-Za-z0-9\s-.]{1,255}$/
  const regexNumbersLettersSpecSymbols255 = /^[A-Za-z0-9\s-.():_/]{1,255}$/
  const regexIdentificationValueCNY = /^[A-Z0-9]{18}$|^[A-Z0-9]{9}$|^[A-Z0-9]{8}-[A-Z0-9]$/

  switch (currency) {
    case INR:
      return [
        validateRegex(
          regexNumbersLetters255,
          trb(
            i18.PaymensValidations
              .BeneficiaryIdentificationValueNumbersLetters255Error
          )
        )
      ]
    case AUD:
    case GBP:
    case EUR:
    case MYR:
    case USD:
    case SGD:
    case KRW:
    case CAD:
    case NOK:
    case DKK:
    case PLN:
    case HUF:
    case RON:
      return [
        validateRegex(
          regexNumbersLettersSpecSymbols255,
          trb(
            i18.PaymensValidations
              .BeneficiaryIdentificationValueNumbersLettersSpecSymbols255Error
          )
        )
      ]
    case CNY:
      return [
        required,
        validateRegex(
          regexIdentificationValueCNY,
          trb(i18.PaymensValidations.CNYBeneficiaryIdentificationValueError)
        )
      ]
    default:
      return [checkMaxLength(255)]
  }
}

export const getPurposeValidation = currency => {
  const regexLettersNumbersSymbols = /^[a-zA-Z0-9_\.,\-\s]+$/
  const regexLettersNumbers = /^[a-zA-Z0-9_\s]+$/
  const regexCNYNoSpecialSymbols = /^[^|\\]*$/
  switch (currency) {
    case INR:
      return [
        required,
        validateRegex(
          regexLettersNumbersSymbols,
          trb(i18.PaymensValidations.PurposeLetterNumbersSymbolsError)
        )
      ]
    case AUD:
    case GBP:
    case USD:
      return [
        required,
        validateRegex(
          regexLettersNumbersSymbols,
          trb(i18.PaymensValidations.PurposeLetterNumbersSymbolsError)
        )
      ]
    case EUR:
    case MYR:
    case SGD:
    case CAD:
    case NOK:
    case DKK:
    case PLN:
    case HUF:
    case RON:
      return [
        required,
        validateRegex(
          regexLettersNumbers,
          trb(i18.PaymensValidations.PurposeLetterNumbersError)
        )
      ]
    case KRW:
      return [required]
    case CNY:
      return [
        required,
        checkMaxLength(20),
        validateRegex(
          regexCNYNoSpecialSymbols,
          trb(i18.PaymensValidations.CNYPurposeSpecialCharacterError)
        )
      ]
    default:
      return [checkMaxLength(255)]
  }
}

export const checkIBANByCurrency = currency => {
  const regexKRWBeneficiaryAccountNumber = /^[0-9a-zA-Z.,_-\s]*$/
  switch (currency) {
    case AUD: {
      return [required, checkAllLettersAndNumbers, checkMaxLength(9)]
    }
    case USD: {
      return [required, checkCAPLettersAndNumbers, checkMaxLength(50)]
    }
    case INR: {
      return [
        required,
        checkAllLettersAndNumbers,
        checkMinLength(6),
        checkMaxLength(20)
      ]
    }
    case SGD: {
      return [required, checkAllLettersAndNumbers]
    }
    case CAD: {
      return [required, checkCAPLettersAndNumbers, checkMaxLength(50)]
    }
    case CNY: {
      return [required, checkCAPLettersAndNumbers, checkMaxLength(64)]
    }
    case MYR: {
      return [required, checkAllLettersAndNumbers, checkMaxLength(20)]
    }
    case KRW: {
      return [
        required,
        validateRegex(
          regexKRWBeneficiaryAccountNumber,
          trb(i18.PaymensValidations.KRWBeneficiaryAccountNumberError)
        ),
        checkMaxLength(50)
      ]
    }
    default: {
      return [required, checkMaxLength(255)]
    }
  }
}

export const getBeneficiaryContactNumberValidation = currency => {
  const regexCNYNoSpecialSymbols = /^[^|\\]*$/
  const regexKRWDigitsSpecialSymbols = /^[0-9+ -]+$/
  switch (currency) {
    case CNY:
      return [
        required,
        validateRegex(
          regexCNYNoSpecialSymbols,
          trb(i18.PaymensValidations.CNYContactNumberError)
        ),
        checkMaxLength(16)
      ]
    case KRW:
      return [
        required,
        validateRegex(
          regexKRWDigitsSpecialSymbols,
          trb(i18.PaymensValidations.KRWContactNumberError)
        ),
        checkMaxLength(17)
      ]
    default: {
      return [required]
    }
  }
}
