import React from 'react'
import { appEnvMano, appEnvSME, checkJsonProp, i18, numberFormat, trb } from '../../../Utilities'
import { Number } from '../../Number'

export const FeePreviewRender = (props) => {
  const { data } = props.data
  if (!data.oldData && !data.oldData.feePayment) {
    return null
  }


  const feeModule = checkJsonProp(data, 'oldData.feePayment.feeModule')
  const rowsSingle = checkJsonProp(feeModule, 'rowsSingle'),
    headers = checkJsonProp(feeModule, 'headers'),
    rows = checkJsonProp(feeModule, 'rows'),
    values = checkJsonProp(data, 'oldData.feePaymentData')
  return <>
    {data.oldData && data.oldData.Payment.CustomersCode &&
    <div className={(appEnvSME || appEnvMano) ?
      'row form-preview-field-wrapper'
      :
      'row form-preview-field-wrapper col-12'}
    >
      <div className="form-preview-field-title col-sm-12 col-md-3 text-left text-md-right">
        {trb(i18.Labels.IdentificationCode)}
      </div>
      <div className="form-preview-field-value col-sm-12 col-md-9 pl-md-0">
        {data.oldData.Payment.CustomersCode}
      </div>
    </div>
    }
    {rowsSingle && rowsSingle.map((item, index) =>
      item.fields.map((fieldItem, fieldIndex) =>
        <React.Fragment key={index}>
          {fieldItem.selected ? (
            <div
              className={(appEnvSME || appEnvMano) ?
                'row form-preview-field-wrapper'
                :
                'row form-preview-field-wrapper col-12'}
              key={fieldIndex}
            >
              <div
                className={(appEnvSME || appEnvMano) ?
                  'col-3 form-preview-field-title text-left text-md-right'
                  :
                  'form-preview-field-title col-sm-12 col-md-3 text-left text-md-right'}
              >
                {item.fields[0].title}
              </div>
              <div
                className={(appEnvSME || appEnvMano) ? 'col-9 form-preview-field-value' : 'form-preview-field-value col-sm-12 col-md-9 pl-md-0'}>
                {values ? values[index] ? values[index][fieldItem.id] : null : null}
              </div>
            </div>
          ) : null
          }
        </React.Fragment>
      )
    )}
    {rows && rows.length > 0 &&
    <>
      <div
        className={(appEnvSME || appEnvMano) ?
          'row form-preview-field-wrapper d-none d-md-flex'
          :
          'row form-preview-field-wrapper d-none d-md-flex col-12'
        }
      >
        <div className="col-3" />
        {headers && headers.map((item, index) =>
          <React.Fragment key={index}>
            {item.title !== '' ?
              <div key={index}
                   className={(appEnvSME || appEnvMano) ?
                     'form-preview-field-title text-right'
                     :
                     'form-preview-field-title'
                   }
                   style={{ width: 110 }}>
                {item.title}
              </div>
              : null
            }
          </React.Fragment>
        )}
      </div>
      {rows.map((item, index) =>
        <div
          key={index}
          className={(appEnvSME || appEnvMano) ?
            'row form-preview-field-wrapper'
            :
            'row form-preview-field-wrapper col-12'
          }
        >
          <div
            className={(appEnvSME || appEnvMano) ?
              'col-3 form-preview-field-title text-left text-md-right'
              :
              'col-sm-12 col-md-3 form-preview-field-title text-left text-md-right'
            }
          >
            {item.fields[0].title}
          </div>
          {item.fields.map((fieldItem, fieldIndex) =>
            <React.Fragment key={fieldIndex}>
              {fieldItem.selected &&
              <div
                key={fieldIndex}
                className={(appEnvSME || appEnvMano) ?
                  'form-preview-field-value d-flex d-md-block text-right'
                  :
                  'form-preview-field-value d-flex d-md-block'
                }
                style={{ width: 110 }}
              >
                {headers && headers[fieldIndex - 1].title !== '' ?
                  <div className={(appEnvSME || appEnvMano) ?
                    'form-preview-field-title d-block d-md-none col-sm-6 col-md-3 text-right'
                    :
                    'form-preview-field-title d-block d-md-none col-md-3 text-right'
                  }
                  >
                    {headers[fieldIndex - 1].title}
                  </div>
                  : null
                }
                {values && values[index] &&
                <div className="form-preview-field-value">
                  <Number
                    value={values[index][fieldItem.id]}
                    format={
                      fieldItem.id === 'A' ?
                        numberFormat().currency
                        : fieldItem.id === 'TA' ?
                        numberFormat().coefficient4decimal
                        : numberFormat().number
                    }
                  />
                </div>
                }
              </div>
              }
            </React.Fragment>
          )}
        </div>
      )}
    </>
    }
  </>
}
