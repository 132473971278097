import React from 'react'
import PropTypes from 'prop-types'

import { MessageResult } from '@components'
import { i18, translationGroups } from '@utilities'
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

export const PaymentUnsuccess = ({ resetPayment, close, error }) => {
  const buttons = []
  if (resetPayment)
    buttons.push({
      title: i18.Buttons.NewPaymentInstruction,
      func: resetPayment,
      props: {
        icon: null
      }
    })
  if (close)
    buttons.push({
      title: i18.Buttons.Close,
      func: close,
      props: {
        type: 'primary',
        icon: <CloseCircleOutlined />
      }
    })
  return (
    <MessageResult
      status="error"
      title={i18.MessageTitles.PaymentUnsuccess}
      subTitle={
        error
          ? translationGroups.ErrorCode + error
          : i18.Messages.PaymentUnsuccess
      }
      buttons={buttons}
    />
  )
}

PaymentUnsuccess.propTypes = {
  close: PropTypes.func.isRequired,
  resetPayment: PropTypes.func.isRequired
}
