import React from 'react';
import {connect} from "react-redux";
import {Button, Modal} from "antd";

import {ClickEServices, i18, trb, appEnvSME} from "@utilities";
import {ContentLoader} from "@components";

const EServices = (props) => {
    const {sendGGDataMy} = props;

    return <>
        <Button
            type={appEnvSME ? null : 'link'}
            className="eb_header_eServices text-click"
            onClick={sendGGDataMy ? null : ClickEServices}
            disabled={sendGGDataMy}
        >
            <img
                src={`${process.env.REACT_APP_BASE_PATH_IMG}images/logos/evartai.png`}
                alt={trb(i18.eServices.eGates)}
                className='mr-2'
            />
            {trb(i18.eServices.eGates)}
        </Button>
        <Modal
            title={null}
            visible={sendGGDataMy}
            footer={null}
            zIndex="1021"
            closable={false}
            centered={true}
          >
              <ContentLoader/>
        </Modal>
    </>;
};

function mapStateToProps(state) {
    const {GGDataMy, sendGGDataMy} = state.UACReducers;
    return {
        GGDataMy,
        sendGGDataMy
    };
}

const connectedEServices = connect(mapStateToProps)(EServices);
export {connectedEServices as EServices};
