import axios from 'axios';
import {apiElkus, appRoot} from './paths'
import qs from "qs";

export const contentTypes = {
    ajax: 'application/json',
    form: 'application/x-www-form-urlencoded; charset=UTF-8',
    jsonPatch: 'application/json-patch+json',
    file: false,
};

export const methods = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
};

export const axiosLanguage = function (language) {
    requestEBankAnonymous.defaults.headers.common['Accept-Language'] = language ? language : localStorage.getItem('language');
    requestEBank.defaults.headers.common['Accept-Language'] = language ? language : localStorage.getItem('language');
};

export const responseTypes = {
    arrayBuffer: 'arraybuffer'
};

export const requestEBankAnonymous = axios.create({
    withCredentials: true,
    baseURL: apiElkus,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': '*/*',
        //'crossDomain': true
    },
    /*transformRequest: [function (data) {
        const json = {
            jsonData: JSON.stringify(data),
        };
        return qs.stringify(json);
    }]*/
});

export const requestEBankAnonymousRoot = axios.create({
    withCredentials: false,
    baseURL: appRoot,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': '*/*',
        //'crossDomain': true
    },
});

export const requestEBank = axios.create({
    baseURL: apiElkus,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': '*/*',
    },
    /*transformRequest: [function (data) {
        const json = {
            jsonData: JSON.stringify(data),
        };
        return qs.stringify(json);
    }]*/
});

export const requestEBankJSON = axios.create({
    baseURL: apiElkus,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json, text/plain, */*',
    },
    /*transformRequest: [function (data) {
        return JSON.stringify(data);
    }]*/
});

export const requestEBankFile = axios.create({
    baseURL: apiElkus,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data; charset=UTF-8',
        'Accept': 'application/json, text/plain, */*',
        //'processData': false
    },
});
