import { aMCServices } from './services'
import { AMCConstants } from './constants'
import moment from 'moment'
import { MenuConstants } from '@redux'

export const AMCActions = {
  getALR,
  postADR,
  postLLO,
  postSOD,
  postCAINF,
  postBCA,
  postACCLMTS,
  getCompany,
  postACCSXLSX,
  postADRPrint,
  postADROD,
  getAccountOverdraft,
  setAccountOverdraft,
  getForexCurrency,
  getForexRate,
  clearForexRate,
  confirmForexRate,
  postForexDraft
}

function getALR() {
  return dispatch => {
    dispatch(request())

    return aMCServices.getALR().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.GET_ALR_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.GET_ALR_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.GET_ALR_ERROR, error }
  }
}

function postLLO() {
  return dispatch => {
    dispatch(request())

    return aMCServices.postLLO().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_LLO_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_LLO_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_LLO_ERROR, error }
  }
}

function postADR(data) {
  const filter = {
    AccountID: data.AccountID ? data.AccountID : 0,
    FilterID: data.FilterID,
    FilterBeginDate: data.FilterBeginDate
      ? moment(data.FilterBeginDate).format('YYYY-MM-DD')
      : data.FilterBeginDate,
    FilterEndDate: data.FilterEndDate
      ? moment(data.FilterEndDate).format('YYYY-MM-DD')
      : data.FilterEndDate
  }

  const json = Object.assign(filter, {
    PageNumberToShow: data.PageNumberToShow
  })

  return dispatch => {
    dispatch(request())

    return aMCServices.postADR(json).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_ADR_REQUEST }
  }

  function success(data) {
    return {
      type: AMCConstants.POST_ADR_SUCCESS,
      payload: data,
      filter: filter
    }
  }

  function failure(error) {
    return { type: AMCConstants.POST_ADR_ERROR, error }
  }
}

function postADRPrint(data) {
  const filter = {
    AccountID: data.AccountID ? data.AccountID : 0,
    FilterID: data.FilterID ?? 7,
    FilterBeginDate: data.FilterBeginDate,
    FilterEndDate: data.FilterEndDate,
    IsForPrint: true
  }

  return dispatch => {
    dispatch(request())

    return aMCServices.postADR(filter).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_ADR_PRINT_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_ADR_PRINT_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_ADR_PRINT_ERROR, error }
  }
}

function postSOD(data) {
  const json = {
    ID: data.ID
  }
  return dispatch => {
    dispatch(request())

    return aMCServices.postSOD(json).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_SOD_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_SOD_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_SOD_ERROR, error }
  }
}

function postCAINF() {
  return dispatch => {
    dispatch(request())

    return aMCServices.postCAINF().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_CAINF_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_CAINF_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_CAINF_ERROR, error }
  }
}

function postBCA(data) {
  return dispatch => {
    dispatch(request())

    return aMCServices.postBCA(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_BCA_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_BCA_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_BCA_ERROR, error }
  }
}

function postACCLMTS(data) {
  return dispatch => {
    dispatch(request())

    return aMCServices.postACCLMTS(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_ACCLMTS_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_ACCLMTS_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_ACCLMTS_ERROR, error }
  }
}

function getCompany() {
  return dispatch => {
    dispatch(request())

    return aMCServices.getCompany().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.GET_COMPANY_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.GET_COMPANY_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.GET_COMPANY_ERROR, error }
  }
}

function postACCSXLSX(data, language) {
  return dispatch => {
    dispatch(request())

    return aMCServices.postACCSXLSX(data, language).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_AMC_ACCSXLSX_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_AMC_ACCSXLSX_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_AMC_ACCSXLSX_ERROR, error }
  }
}

function postADROD(data) {
  const filter = {
    AccountID: data.AccountID ? data.AccountID : 0,
    FilterID: data.FilterID,
    FilterBeginDate: data.FilterBeginDate
      ? moment(data.FilterBeginDate).format('YYYY-MM-DD')
      : data.FilterBeginDate,
    FilterEndDate: data.FilterEndDate
      ? moment(data.FilterEndDate).format('YYYY-MM-DD')
      : data.FilterEndDate
  }

  const json = Object.assign(filter, {
    PageNumberToShow: data.PageNumberToShow
  })

  return dispatch => {
    dispatch(request())

    return aMCServices.postADROD(json).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_ADROD_REQUEST }
  }

  function success(data) {
    return {
      type: AMCConstants.POST_ADROD_SUCCESS,
      payload: data,
      filter: filter
    }
  }

  function failure(error) {
    return { type: AMCConstants.POST_ADROD_ERROR, error }
  }
}

function getAccountOverdraft(accountId) {
  return dispatch => {
    dispatch(request())

    return aMCServices.getAccountOverdraft(accountId).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.GET_ACCOUNT_OVERDRAFT_REQUEST }
  }

  function success(data) {
    return {
      type: AMCConstants.GET_ACCOUNT_OVERDRAFT_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: AMCConstants.GET_ACCOUNT_OVERDRAFT_ERROR, error }
  }
}

function setAccountOverdraft(data) {
  return {
    type: AMCConstants.GET_ACCOUNT_OVERDRAFT_SUCCESS,
    payload: data
  }
}

function getForexCurrency(currency) {
  return dispatch => {
    dispatch(request())

    return aMCServices.getForexCurrency(currency).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.GET_FOREX_CURRENCY_REQUEST }
  }

  function success(data) {
    return {
      type: AMCConstants.GET_FOREX_CURRENCY_SUCCESS,
      payload: data,
      currency: currency
    }
  }

  function failure(error) {
    return { type: AMCConstants.GET_FOREX_CURRENCY_ERROR, error }
  }
}

function getForexRate(data) {
  return dispatch => {
    dispatch(request())

    return aMCServices.getForexRate(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return {
      type: AMCConstants.GET_FOREX_RATE_REQUEST,
      payload: data
    }
  }

  function success(data) {
    return { type: AMCConstants.GET_FOREX_RATE_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.GET_FOREX_RATE_ERROR, error }
  }
}

function clearForexRate() {
  return dispatch => {
    dispatch({type: AMCConstants.CLEAR_FOREX_RATE});
  };
}

function confirmForexRate(id, data) {
  return dispatch => {
    dispatch(request())

    return aMCServices.postConfirmForexRate(id, data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_FOREX_CONFIRM_REQUEST }
  }

  function success(data) {
    return { type: AMCConstants.POST_FOREX_CONFIRM_SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: AMCConstants.POST_FOREX_CONFIRM_ERROR, error }
  }
}

function postForexDraft(payload) {
  return dispatch => {
    dispatch(request())

    return aMCServices.postForexDraft(payload).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: AMCConstants.POST_FOREX_DRAFT_REQUEST }
  }

  function success(data) {
    return {
      type: AMCConstants.POST_FOREX_DRAFT_SUCCESS,
      payload: { ...data, ...payload }
    }
  }

  function failure(error) {
    return { type: AMCConstants.POST_FOREX_DRAFT_ERROR, error }
  }
}
