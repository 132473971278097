import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, required, requiredNotUndefined, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCClientTax = (props) => {

    let ClientTaxPayer = checkJsonProp(props.data, 'clientTaxPayer') && checkJsonProp(props.data, 'clientTaxPayer').length ? checkJsonProp(props.data, 'clientTaxPayer') : [{data:{}}];
    ClientTaxPayer = ClientTaxPayer.map((item) => {
        item.data.clientTaxPayerId = checkJsonProp(item, 'data.clientTaxPayerId') === 0
                                  || checkJsonProp(item, 'data.clientTaxPayerId') === ''
                                  || checkJsonProp(item, 'data.clientTaxPayerId') === null
                                      ? undefined
                                      : checkJsonProp(item, 'data.clientTaxPayerId')

        item.data.clientTaxPayerId = item.data.clientTaxPayerId === undefined && item.data.clientTaxPayerHid == 1 ? null : item.data.clientTaxPayerId
        return item;
    })
    const ClientProfile = checkJsonProp(props.data, 'clientProfile');

    const initialData = {clientTaxPayer: ClientTaxPayer, clientProfile: ClientProfile};

    const mapData = [{
        title: i18.Labels.ClientTax, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCClientTax,
        canDuplicate: true,
        duplicateName: 'clientTaxPayer',
        maxRows: props.classifikators.TAXC.length - 1,
        elements: [{
            title: i18.Labels.ClientTaxPayerType,
            name: 'clientTaxPayer.$index.clientTaxPayerType',
            component: FormFieldType.select,
            options: props.classifikators.TAXC,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12 col-md-4',
            noInline: true,
            validate: required,
        },{
            title: i18.Labels.ClientTaxPayerCountry,
            name: 'clientTaxPayer.$index.data.clientTaxPayerCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-12 col-md-4',
            noInline: true,
            validate: required,
        },{
            title: i18.Labels.ClientTaxPayerId,
            name: 'clientTaxPayer.$index.data.clientTaxPayerId',
            component: FormFieldType.input,
            className: 'col-12 col-md-4',
            noInline: true,
            validate: requiredNotUndefined,
        },{
            translate: i18.Labels.ClientTaxPayerHid,
            name: 'clientTaxPayer.$index.data.clientTaxPayerHid',
            component: FormFieldType.checkboxOnly,
            className: 'col-12 col-md-11 offset-md-1 mt-2 mb-3',
        }]
    }, {
        elements: [{
            translate: i18.Labels.СlientTaxPayerConfirm,
            name: 'clientProfile.0.data.clientTaxPayerConfirm',
            component: FormFieldType.checkboxOnly,
            className: 'col-12 col-md-11 offset-md-1 mt-2 mb-3',
            //validate: required,
        }]
    }];

    const decorators = [
        createDecorator({
            field: /clientTaxPayer\.*.*clientTaxPayerType/,
            updates: (value, name, allValues) => {
                props.classifikators.TAXC.map((item, index)=>{
                    item.disabled = checkJsonProp(allValues, 'clientTaxPayer').some((addItem, addIndex) => {
                        return addItem.clientTaxPayerType == item.value;
                    }) ? true : false;
                    item.disabled = item.disabled || item.value == value ? true : false;
                    return item;
                })
                return {}
            }
        },{
            field: /clientTaxPayer\.*.*clientTaxPayerHid/,
            updates: (value, name, allValues, prevValues) => {
                const toField = name.replace('clientTaxPayerHid', 'clientTaxPayerId');
                return Object.keys(prevValues).length == 0
                    ? {}
                    : { [toField]: value ? null : undefined }
            }
        })
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};