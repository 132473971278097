import React from 'react';
import Icon from '@ant-design/icons';
import {checkJsonProp} from './';

export const SmeIcons = {
    // Need () to return and height="1em" and width="1em" for change size
    // And can use any svg
    home: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.639 19.524" fill="none" stroke="currentColor" id="home">
        <path data-name="Path 1" d="M17.133 9.875v9.149H3.683V9.875"/>
        <path data-name="Path 2" d="M.701 8.138L10.601.5l9.331 7.638" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    payment: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.001 18.001" fill="none" stroke="currentColor" id="payments">
      <g data-name="Group 102">
        <path data-name="Subtraction 10" d="M16.244 13.451h0a8.506 8.506 0 00-3.635-12.146 5.639 5.639 0 012.89-.8 6.274 6.274 0 016 6.5 6.348 6.348 0 01-5.255 6.448z"/>
          <g data-name="Ellipse 4" transform="translate(0 .001)">
            <circle cx="9" cy="9" r="9" stroke="none"/>
            <circle cx="9" cy="9" r="8.5"/>
          </g>
      </g>
    </svg>,
    invoice: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.229 19.809" id="logs">
      <g data-name="Group 587" fill="none" stroke="currentColor">
        <path data-name="Path 472" d="M7.431.5H3.364A2.843 2.843 0 00.5 3.321v13.167a2.843 2.843 0 002.864 2.821h10.5a2.843 2.843 0 002.864-2.821V9.02"/>
        <path data-name="Path 343" d="M16.729 6.209V.5" strokeLinecap="round"/>
        <path data-name="Path 473" d="M10.714.5h6.014" strokeLinecap="round"/>
        <path data-name="Path 474" d="M16.729.495L9.173 8.05"/>
      </g>
    </svg>,
    operations: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.887" id="calendar">
      <g data-name="Group 445" fill="none" stroke="currentColor">
        <g data-name="Rectangle 165" transform="translate(0 .887)">
          <rect width="19" height="18" rx="3" stroke="none"/>
          <rect x=".5" y=".5" width="18" height="17" rx="2.5"/>
        </g>
        <path data-name="Line 597" d="M.784 7.444h17.482"/>
        <path data-name="Line 598" d="M6.612 3.947V0"/>
        <path data-name="Line 599" d="M12.439 3.947V0"/>
      </g>
    </svg>,
    deposit: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.686 19" fill="none" id="deposits">
      <g data-name="Group 593" fill="none">
        <path data-name="Path 519" d="M5.295 7.055l3.187 2.934 5.908-5.451" stroke="currentColor"/>
        <g data-name="Path 522">
          <path d="M2.398 12.64C.141 8.753-.455 1.416.326 1.416A32.11 32.11 0 019.144 0a37.3 37.3 0 019.242 1.416c.781 0 .048 7.337-2.209 11.224-1.081 1.86-3.969 6.272-7.033 6.36-2.689.083-5.567-4.331-6.746-6.36z"/>
          <path d="M9.06808961 17.9999981c.01484013 0 .02989007-.00023843.04729939-.00076867 2.09228039-.05957031 4.57182026-3.06696033 6.19464016-5.86156082.82323074-1.41764927 1.55110931-3.60604954 1.99701118-6.00408936.35137558-1.88970756.41094399-3.26386451.3666649-3.87795066C16.43777001 1.92209816 12.6987821.99999809 9.1438495.99999809c-3.53556442 0-6.98986816.91790962-8.12046432 1.24894905-.05865097.6026764-.02539635 1.98564911.30210495 3.89339161.41179847 2.39879036 1.1180191 4.58400059 1.93757915 5.99532986 2.09982968 3.6160202 4.32417965 5.86232948 5.80502033 5.86232948m-9.5e-7.9999981c-2.6671133 0-5.50251293-4.35003567-6.66978931-6.36015702C.14106859 8.7527485-.4546498 1.41619873.32621873 1.41619873c0 0 4.30317116-1.41620064 8.81763077-1.41620064 4.51445961 0 9.24023056 1.41620064 9.24023056 1.41620064.78086853 0 .04796028 7.33654976-2.20928001 11.22364044-1.07895088 1.85801983-3.96697044 6.2717495-7.03095055 6.35898018-.02511024.00078201-.05061912.00117684-.07576084.00117684z" fill="currentColor"/>
        </g>
      </g>
    </svg>,
    caretLeft: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.768 14.118" id="caret-left">
      <path data-name="Path 353" d="M7.415.354L.707 7.059l6.708 6.706" fill="none" stroke="currentColor"/>
    </svg>,
    caretRight: () => <svg transform="rotate(180)" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.768 14.118" id="caret-right">
      <path data-name="Path 353" d="M7.415.354L.707 7.059l6.708 6.706" fill="none" stroke="currentColor"/>
    </svg>,
    triangleDown: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 13" id="triangle">
      <g data-name="Polygon 1" fill="none">
        <path d="M9.7 10.232a2 2 0 01-3.407 0L1.872 3.048A2 2 0 013.579 0h8.842a2 2 0 011.7 3.048z"/>
        <path d="M8.00000095 10.18391228c.16802025 0 .58734036-.0463705.85165978-.4758997l4.42086983-7.18392086c.2775402-.45100975.10735035-.85788917.02129077-1.01188946-.08605003-.15400982-.34337997-.51220035-.87295055-.51220035H3.57913113c-.52957058 0-.78690052.35819053-.87295056.51220035-.08605957.15400029-.25624943.56087971.02128983 1.01188946l4.42087078 7.18392086c.26431942.4295292.68365002.4758997.85165977.4758997m0 1.00000286c-.65641689 0-1.31283474-.31726837-1.7033205-.9518032L1.87581062 3.04819201C1.0557909 1.71565247 2.01449108.00000191 3.57913112.00000191h8.84173966c1.56464005 0 2.52334023 1.71565056 1.7033205 3.04819011L9.7033205 10.23211193c-.3904848.63453484-1.0469017.9518032-1.70331955.9518032z" fill="#b2b2b2"/>
      </g>
    </svg>,
    ringbell: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.999" id="bell">
      <g data-name="Group 403" fill="none" stroke="currentColor">
        <path data-name="Subtraction 7" d="M9.003 17.499a2 2 0 01-1.891-2.653h3.782a2 2 0 01-1.891 2.653z"/>
        <path data-name="Union 3" d="M.5 14.846s2.458-1.873 2.458-3.974V6.659c-.007-.115-.011-.232-.011-.35A5.937 5.937 0 019 .5a5.936 5.936 0 016.055 5.809q0 .209-.015.414v4.1c0 2.1 2.458 4.024 2.458 4.024z" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>,
    settings: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" id="settings">
      <g data-name="Icon feather-settings" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path data-name="Path 461" d="M15.054 11.5A3.554 3.554 0 1111.5 7.946a3.554 3.554 0 013.554 3.554z"/>
        <path data-name="Path 462" d="M18.9 14.5a1.65 1.65 0 00.33 1.82l.06.06a2.0011122 2.0011122 0 11-2.83 2.83l-.06-.06a1.663 1.663 0 00-2.82 1.18v.17a2 2 0 01-4 0v-.09A1.65 1.65 0 008.5 18.9a1.65 1.65 0 00-1.82.33l-.06.06a2.0011122 2.0011122 0 11-2.83-2.83l.06-.06a1.663 1.663 0 00-1.18-2.82H2.5a2 2 0 010-4h.09A1.65 1.65 0 004.1 8.5a1.65 1.65 0 00-.33-1.82l-.06-.06a2.0011122 2.0011122 0 112.83-2.83l.06.06a1.65 1.65 0 001.82.33h.08a1.65 1.65 0 001-1.51V2.5a2 2 0 014 0v.09a1.663 1.663 0 002.82 1.18l.06-.06a2.0011122 2.0011122 0 112.83 2.83l-.06.06a1.65 1.65 0 00-.33 1.82v.08a1.65 1.65 0 001.51 1h.17a2 2 0 010 4h-.09a1.65 1.65 0 00-1.51 1z"/>
      </g>
    </svg>,
    logout: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.099 25.727" id="logout">
      <g data-name="Component 52 – 16">
        <g data-name="Group 149">
          <g data-name="Group 410">
            <path data-name="Path 467" d="M19.25980837 20.80515486a9.626 9.626 0 01-9.33738575 1.60234245 9.822 9.822 0 116.39546083-18.5737669 10.151 10.151 0 013.33158802 1.95941044" fill="none" stroke="currentColor"/>
          </g>
        </g>
        <g data-name="Group 412" fill="none" stroke="currentColor">
          <path data-name="Path 341" d="M21.337 18.238l4.261-4.936-4.261-4.935" strokeLinejoin="round"/>
          <path data-name="Line 1" d="M25.239 13.207H11.625"/>
        </g>
      </g>
    </svg>,
    dashboardPaymentTemplate: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.872 42.379" id="dashboard-blanks">
      <g data-name="Group 592" fill="none" stroke="currentColor">
        <path data-name="Path 520" d="M13.239 1.478H6.652A6.106 6.106 0 00.5 7.538v28.281a6.106 6.106 0 006.152 6.059h22.556a6.106 6.106 0 006.152-6.06v-11.74"/>
        <path data-name="Path 521" d="M25.293 1.153l2.526 5.1a1.174 1.174 0 00.884.64l5.648.818a1.169 1.169 0 01.651 2l-4.087 3.968a1.167 1.167 0 00-.338 1.035l.965 5.6a1.174 1.174 0 01-1.7 1.233l-5.052-2.645a1.179 1.179 0 00-1.093 0l-5.052 2.645a1.174 1.174 0 01-1.7-1.233l.965-5.6a1.167 1.167 0 00-.338-1.035l-4.087-3.968a1.169 1.169 0 01.651-2l5.648-.818a1.174 1.174 0 00.884-.64l2.526-5.1a1.177 1.177 0 012.099 0z" strokeMiterlimit="10"/>
      </g>
    </svg>,
    dashboardNewPayment: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" data-name="Group 103" viewBox="0 0 50 43" id="dashboard-payments">
      <g data-name="Group 98">
        <g data-name="Ellipse 4" transform="translate(19)" fill="#fff" stroke="currentColor">
          <ellipse cx="15.5" cy="16.5" rx="15.5" ry="16.5" stroke="none"/>
          <ellipse cx="15.5" cy="16.5" rx="15" ry="16" fill="none"/>
        </g>
      </g>
      <g data-name="Group 97">
        <g data-name="Ellipse 4" fill="#fff" stroke="currentColor">
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none"/>
          <circle cx="21.5" cy="21.5" r="21" fill="none"/>
        </g>
      </g>
    </svg>,
    dashboardInvoice: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 41" id="dashboard-logs">
      <g data-name="Group 427" stroke="currentColor">
        <g data-name="Rectangle 130" fill="#fff">
          <rect width="35" height="41" rx="3" stroke="none"/>
          <rect x=".5" y=".5" width="34" height="40" rx="2.5" fill="none"/>
        </g>
        <path data-name="Line 2" fill="none" d="M25.813 10.318H8.955"/>
        <path data-name="Path 343" d="M20.46 17.678H8.955" fill="none"/>
      </g>
    </svg>,
    dashboardOperations: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38.688" id="dashboard-calendar">
      <g data-name="Group 446" fill="none" stroke="currentColor">
        <g data-name="Rectangle 165" transform="translate(0 1.688)">
          <rect width="38" height="37" rx="8" stroke="none"/>
          <rect x=".5" y=".5" width="37" height="36" rx="7.5"/>
        </g>
        <path data-name="Line 597" d="M.784 15.68H37.5"/>
        <path data-name="Line 598" d="M13.078 6.188V0"/>
        <path data-name="Line 599" d="M24.605 6.188V0"/>
      </g>
    </svg>,
    depositFirstStep: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.363 43.124" id="shield">
      <g data-name="Group 599">
        <g data-name="Group 277">
          <text data-name="1" transform="translate(15.064 24.359)" fill="currentColor" fontSize="0.4em" fontFamily="'Open Sans', sans-serif">
            <tspan x="0" y="0">1</tspan>
          </text>
        </g>
        <g data-name="Group 597">
          <g data-name="Path 522" fill="none">
            <path d="M5.18 28.688C.304 19.865-.983 3.214.704 3.214A66.362 66.362 0 0119.751 0a77.039 77.039 0 0119.96 3.214c1.687 0 .1 16.652-4.772 25.474-2.331 4.217-8.57 14.236-15.188 14.436-5.808.188-12.026-9.83-14.571-14.436z"/>
            <path d="M19.58736333 42.124c.04342079 0 .0874691-.00070953.13373947-.00222015 1.34888077-.0403595 2.80010032-.57146836 4.31334114-1.5785904 1.3450203-.89516067 2.7467289-2.17037964 4.1661892-3.79024124 2.68196105-3.06059646 4.82435035-6.66951752 5.8626995-8.54833793 1.91480255-3.46471024 3.5740509-8.77791024 4.55232239-14.57724953.39758682-2.35699082.65581894-4.67449951.727108-6.52561188.07179642-1.86395264-.06342697-2.68500137-.14485168-2.99870682C37.69223126 3.65144293 28.4753619.99959891 19.7510024.99959891c-8.7380085 0-17.2259655 2.66812896-18.54409218 3.10073852-.08664322.30190659-.24164963 1.11640549-.19984818 3.0042305.04108048 1.85488129.26428986 4.17175293.62850952 6.5238018.89951325 5.80883027 2.51039124 11.12166024 4.41959 14.57624055 1.31902313 2.3866806 3.40941238 5.84506797 5.87752151 8.76716804C14.7796031 40.34239142 17.42655286 42.124 19.58736333 42.124m.00000381 1c-5.76124191 0-11.8860035-9.87330627-14.40743446-14.43568993C.30437955 19.86630767-.98227206 3.21395057.70404156 3.21395057c.000103 0-.000103 0 0 0 0 0 9.29526139-3.21435166 19.04696084-3.21435166 9.75169181 0 19.95981025 3.21435165 19.95981025 3.21435165 1.6867714-.00001144.10359955 16.65181923-4.77225113 25.47435951-2.33063888 4.21714974-8.5690689 14.2350216-15.18755912 14.43301964-.05423928.00177765-.10932732.00267029-.16363526.00267029z" fill="currentColor"/>
          </g>
        </g>
      </g>
    </svg>,
    depositSecondStep:() => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.363 43.124" id="shield">
    <g data-name="Group 599">
      <g data-name="Group 277">
        <text data-name="1" transform="translate(15.064 24.359)" fill="currentColor" fontSize="0.4em" fontFamily="'Open Sans', sans-serif">
          <tspan x="0" y="0">2</tspan>
        </text>
      </g>
      <g data-name="Group 597">
        <g data-name="Path 522" fill="none">
          <path d="M5.18 28.688C.304 19.865-.983 3.214.704 3.214A66.362 66.362 0 0119.751 0a77.039 77.039 0 0119.96 3.214c1.687 0 .1 16.652-4.772 25.474-2.331 4.217-8.57 14.236-15.188 14.436-5.808.188-12.026-9.83-14.571-14.436z"/>
          <path d="M19.58736333 42.124c.04342079 0 .0874691-.00070953.13373947-.00222015 1.34888077-.0403595 2.80010032-.57146836 4.31334114-1.5785904 1.3450203-.89516067 2.7467289-2.17037964 4.1661892-3.79024124 2.68196105-3.06059646 4.82435035-6.66951752 5.8626995-8.54833793 1.91480255-3.46471024 3.5740509-8.77791024 4.55232239-14.57724953.39758682-2.35699082.65581894-4.67449951.727108-6.52561188.07179642-1.86395264-.06342697-2.68500137-.14485168-2.99870682C37.69223126 3.65144293 28.4753619.99959891 19.7510024.99959891c-8.7380085 0-17.2259655 2.66812896-18.54409218 3.10073852-.08664322.30190659-.24164963 1.11640549-.19984818 3.0042305.04108048 1.85488129.26428986 4.17175293.62850952 6.5238018.89951325 5.80883027 2.51039124 11.12166024 4.41959 14.57624055 1.31902313 2.3866806 3.40941238 5.84506797 5.87752151 8.76716804C14.7796031 40.34239142 17.42655286 42.124 19.58736333 42.124m.00000381 1c-5.76124191 0-11.8860035-9.87330627-14.40743446-14.43568993C.30437955 19.86630767-.98227206 3.21395057.70404156 3.21395057c.000103 0-.000103 0 0 0 0 0 9.29526139-3.21435166 19.04696084-3.21435166 9.75169181 0 19.95981025 3.21435165 19.95981025 3.21435165 1.6867714-.00001144.10359955 16.65181923-4.77225113 25.47435951-2.33063888 4.21714974-8.5690689 14.2350216-15.18755912 14.43301964-.05423928.00177765-.10932732.00267029-.16363526.00267029z" fill="currentColor"/>
        </g>
      </g>
    </g>
  </svg>,
    depositThirdStep: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.363 43.124" id="shield-check">
      <g data-name="Group 596" fill="none">
        <path data-name="Path 519" d="M12.915 15.902l6.717 6.184 12.452-11.488" stroke="currentColor"/>
        <g data-name="Path 522">
          <path d="M5.18 28.688C.304 19.865-.983 3.214.704 3.214A66.362 66.362 0 0119.751 0a77.039 77.039 0 0119.96 3.214c1.687 0 .1 16.652-4.772 25.474-2.331 4.217-8.57 14.236-15.188 14.436-5.808.188-12.026-9.83-14.571-14.436z"/>
          <path d="M19.58736333 42.124c.04342079 0 .0874691-.00070953.13373947-.00222015 1.34888077-.0403595 2.80010032-.57146836 4.31334114-1.5785904 1.3450203-.89516067 2.7467289-2.17037964 4.1661892-3.79024124 2.68196105-3.06059646 4.82435035-6.66951752 5.8626995-8.54833793 1.91480255-3.46471024 3.5740509-8.77791024 4.55232239-14.57724953.39758682-2.35699082.65581894-4.67449951.727108-6.52561188.07179642-1.86395264-.06342697-2.68500137-.14485168-2.99870682C37.69223126 3.65144293 28.4753619.99959891 19.7510024.99959891c-8.7380085 0-17.2259655 2.66812896-18.54409218 3.10073852-.08664322.30190659-.24164963 1.11640549-.19984818 3.0042305.04108048 1.85488129.26428986 4.17175293.62850952 6.5238018.89951325 5.80883027 2.51039124 11.12166024 4.41959 14.57624055 1.31902313 2.3866806 3.40941238 5.84506797 5.87752151 8.76716804C14.7796031 40.34239142 17.42655286 42.124 19.58736333 42.124m.00000381 1c-5.76124191 0-11.8860035-9.87330627-14.40743446-14.43568993C.30437955 19.86630767-.98227206 3.21395057.70404156 3.21395057c.000103 0-.000103 0 0 0 0 0 9.29526139-3.21435166 19.04696084-3.21435166 9.75169181 0 19.95981025 3.21435165 19.95981025 3.21435165 1.6867714-.00001144.10359955 16.65181923-4.77225113 25.47435951-2.33063888 4.21714974-8.5690689 14.2350216-15.18755912 14.43301964-.05423928.00177765-.10932732.00267029-.16363526.00267029z" fill="currentColor"/>
        </g>
      </g>
    </svg>,
    paymentFirstStep: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.361 47.729" id="step">
      <g data-name="Group 97">
        <g data-name="Group 277">
          <text data-name="1" transform="translate(15.5 33)" fill="currentColor" fontSize="0.35em" fontFamily="'Open Sans', sans-serif">
            <tspan x="0" y="0">1</tspan>
          </text>
        </g>
        <g data-name="Ellipse 4" transform="translate(0 6.729)" fill="none" stroke="currentColor">
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
          <circle cx="20.5" cy="20.5" r="20"/>
        </g>
      </g>
      <path data-name="Path 496" d="M24.401 7.101c.263232-.117216 1.466726-.567698 1.466726-.567698a15.9183108 15.9183108 0 0110.65774.24843 15.4080444 15.4080444 0 018.797532 8.349134 15.9363202 15.9363202 0 01-6.336215 20.03749" fill="none" stroke="currentColor" strokeWidth="1.00052"/>
    </svg>,
    paymentSecondStep: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.361 47.729" id="step">
      <g data-name="Group 97">
        <g data-name="Group 277">
          <text data-name="1" transform="translate(15.5 33)" fill="currentColor" fontSize="0.35em" fontFamily="'Open Sans', sans-serif">
            <tspan x="0" y="0">2</tspan>
          </text>
        </g>
        <g data-name="Ellipse 4" transform="translate(0 6.729)" fill="none" stroke="currentColor">
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
          <circle cx="20.5" cy="20.5" r="20"/>
        </g>
      </g>
      <path data-name="Path 496" d="M24.401 7.101c.263232-.117216 1.466726-.567698 1.466726-.567698a15.9183108 15.9183108 0 0110.65774.24843 15.4080444 15.4080444 0 018.797532 8.349134 15.9363202 15.9363202 0 01-6.336215 20.03749" fill="none" stroke="currentColor" strokeWidth="1.00052"/>
    </svg>,
    paymentThirdStep: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.361 47.729" id="step-check">
      <g data-name="Group 529">
        <g data-name="Group 97">
          <g data-name="Ellipse 4" transform="translate(0 6.729)" fill="none" stroke="currentColor">
            <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
            <circle cx="20.5" cy="20.5" r="20"/>
          </g>
        </g>
        <path data-name="Path 496" d="M24.005 7.101c.263232-.117216 1.466726-.567698 1.466726-.567698a15.9183108 15.9183108 0 0110.65774.24843 15.4080444 15.4080444 0 018.797532 8.349134 15.9363202 15.9363202 0 01-6.336215 20.03749" fill="none" stroke="currentColor" strokeWidth="1.00052"/>
      </g>
      <path data-name="Path 497" d="M11.249 26.894l5.425 5.33 14.285-13.8" fill="none" stroke="currentColor"/>
    </svg>,
    toggle: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.895 14.085" id="bars">
      <g data-name="Group 671" fill="none" stroke="currentColor">
        <path data-name="Line 611" d="M25.895.5H0"/>
        <path data-name="Line 612" d="M25.895 7.043H0"/>
        <path data-name="Line 613" d="M13.821 13.585H0"/>
      </g>
    </svg>,
    close: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.27 17.27" id="x">
      <g data-name="Group 671" fill="none" stroke="currentColor">
        <path data-name="Line 611" d="M16.91656214.35343786L.354 16.916"/>
        <path data-name="Line 612" d="M16.91656214 16.91656214L.354.354"/>
      </g>
    </svg>,
    print: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.999 13.999" id="print">
      <g data-name="Group 380">
        <g data-name="Group 331" fill="none">
          <g data-name="Subtraction 4">
            <path d="M12.998 13.999h-10a3 3 0 01-3-3v-6h16v6a3 3 0 01-3 3z"/>
            <path d="M12.99828125 12.99874219c1.10351563 0 2.00097656-.89697266 2.00097656-1.99902344V5.99923047H1.00023438v5.00048828c0 1.10205078.89648437 1.99902344 1.99902343 1.99902344h9.99902344m0 1H2.99925781c-1.65332031 0-2.99902343-1.34521485-2.99902343-2.99902344V4.99923047H15.9992578v6.00048828c0 1.6538086-1.34667968 2.99902344-3.00097656 2.99902344z" fill="currentColor"/>
          </g>
          <g data-name="Rectangle 170" stroke="currentColor">
            <path stroke="none" d="M2.999 0h10v6h-10z"/>
            <path d="M3.499.5h9v5h-9z"/>
          </g>
        </g>
      </g>
    </svg>,
    datepicker: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.887" id="calendar">
      <g data-name="Group 445" fill="none" stroke="currentColor">
        <g data-name="Rectangle 165" transform="translate(0 .887)">
          <rect width="19" height="18" rx="3" stroke="none"/>
          <rect x=".5" y=".5" width="18" height="17" rx="2.5"/>
        </g>
        <path data-name="Line 597" d="M.784 7.444h17.482"/>
        <path data-name="Line 598" d="M6.612 3.947V0"/>
        <path data-name="Line 599" d="M12.439 3.947V0"/>
      </g>
    </svg>,
    userSelectIcon: () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" width="36.873" height="36.873" viewBox="0 0 36.873 36.873">
      <g id="userIcon" data-name="userIcon" transform="translate(-300.42 -128.707)">
        <path id="userIconFill" data-name="userIconFill" d="M50.373,31.937A18.437,18.437,0,1,1,31.937,13.5,18.437,18.437,0,0,1,50.373,31.937Z" transform="translate(286.92 115.207)" fill="#fff"/>
        <g id="userIconPerson" data-name="userIconPerson" transform="translate(-65.172 -12.5)">
          <path id="userIconPersonIcon" data-name="userIconPersonIcon" d="M376.708,207.276c1.691-8.339,12.83-8.4,14.463,0" transform="translate(0 -40.776)" fill="none" stroke="#b2b2b2" strokeMiterlimit="10" strokeWidth="1"/>
          <circle id="userIconPersonIconFill" data-name="userIconPersonIconFill" cx="2.924" cy="2.924" r="2.924" transform="translate(381.015 152)" fill="none" stroke="#b2b2b2" strokeMiterlimit="10" strokeWidth="1"/>
        </g>
      </g>
    </svg>,
    plusIcon: () => <svg height="1em" width="1em"  xmlns="http://www.w3.org/2000/svg" id="Сгруппировать_232" data-name="Сгруппировать 232" viewBox="0 0 26.714 26.714">
      <line id="Линия_594" data-name="Линия 594" y2="26.714" transform="translate(13.357)" fill="none" stroke="currentColor" strokeWidth="1"/>
      <line id="Линия_595" data-name="Линия 595" y2="26.714" transform="translate(26.714 13.357) rotate(90)" fill="none" stroke="currentColor" strokeWidth="1"/>
    </svg>,
  bin: () =>
<svg id="X" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
  <rect id="Rectangle_257" data-name="Rectangle 257" width="38" height="38" rx="19" fill="#f7f7f7"/>
  <path id="Path_526" data-name="Path 526" d="M12.129,4l-.063.655-.8,8.3a2.643,2.643,0,0,1-2.6,2.686h-5.2a2.643,2.643,0,0,1-2.6-2.686L0,4" transform="translate(13.428 9.655)" fill="none" stroke="#b2b2b2" stroke-width="1"/>
  <rect id="Rectangle_253" data-name="Rectangle 253" width="3.852" height="1.541" transform="translate(17.52 11.747)" fill="none" stroke="#b2b2b2" stroke-width="1"/>
  <path id="Path_528" data-name="Path 528" d="M-3639.833,8608.5h14.721" transform="translate(3651.96 -8595.212)" fill="none" stroke="#b2b2b2" stroke-width="1"/>
</svg>,
  submit: () =>
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
  <g id="Group_758" data-name="Group 758" transform="translate(-1520 -286)">
    <rect id="Rectangle_256" data-name="Rectangle 256" width="38" height="38" rx="19" transform="translate(1520 286)" fill="#2B64F5"/>
    <path id="Path_645" data-name="Path 645" d="M-6902.868,10593.52l6.149,6.149-6.149,6.149" transform="translate(8439.151 -10294.697)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>


}

export const CustomIcon = (props) => {
    const {type, icon, style, className, size, onClick} = props,
    newStyle = Object.assign(size ? {fontSize: `${size}px`, lineHeight: `${size}px`} : {}, {color: 'inherit', ...style});

    const handleClick = () => {
      if (onClick && typeof onClick === 'function') {
        onClick();
      }
    };

    switch (type) {
      case 'custom':
        return <Icon onClick={handleClick} className={className} style={newStyle} component={checkJsonProp(SmeIcons, icon)}/>;
      case 'legacy':
        return icon;
      case 'normal':
        return icon;
      default:
        return icon;
    }
}
