import { PMCActions } from '@redux'
import { firstTwoLetters } from '@utilities'
import { isValidIBAN } from 'ibantools'

export const clearIBANResultsInfo = dispatch => {
  dispatch(PMCActions.clearCheckIBANMember())
}

export const getIbanLength = async (value, dispatch) => {
  return await dispatch(
    PMCActions.getLengthIBAN({
      iban: `${value.slice(0, 2)}`,
      value: value.slice(0, 2)
    })
  )
}

export const checkIsIbanValid = async (value, dispatch) => {
  return await dispatch(PMCActions.checkIbanValid({ iban: value, value }))
}

export const getIBANMemberInfo = async (value, dispatch) => {
  return await dispatch(PMCActions.postCheckIBANMember(value))
}

export const IBANLength = async (
  value,
  checkIBANLength,
  checkIBANResult,
  dispatch
) => {
  if (
    value?.length >= 2 &&
    (checkIBANLength?.ibanData?.value ||
      value.slice(0, 2) !== checkIBANLength?.ibanData?.value) &&
    (!checkIBANLength ||
      (checkIBANLength !== 'loading' &&
        checkIBANLength.ibanData?.value !== value.slice(0, 2))) &&
    firstTwoLetters.test(value.slice(0, 2))
  ) {
    clearIBANResultsInfo(dispatch)
    return (await getIbanLength(value, dispatch))?.payload?.ibanLenght
  } else if (checkIBANResult) {
    clearIBANResultsInfo(dispatch)
  }
  return checkIBANLength
}

export const IBANValid = async (
  value,
  ibanLength,
  checkIBANValid,
  dispatch
) => {
  if (
    value?.length === ibanLength?.ibanLenght &&
    (!checkIBANValid ||
      (checkIBANValid !== 'loading' &&
        checkIBANValid.ibanData?.value !== value))
  ) {
    return await checkIsIbanValid(value, dispatch)
  }
  return true
}

export const IBANMemberInfo = async (
  value,
  ibanValid,
  checkIBANResult,
  dispatch
) => {
  if (ibanValid && isValidIBAN(value)) {
    return (await getIBANMemberInfo(value, dispatch))?.payload
  }
  return checkIBANResult
}
