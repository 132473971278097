import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuActions } from '@redux'
import { i18, trb, appEnvSME, CustomIcon } from '@utilities'
import { Container } from '@components'
import { Steps } from 'antd'
import {
  DepositsFillContainer,
  DepositsSignContainer,
  DepositsSuccessContainer
} from './Components'

const Step = Steps.Step

class DepositsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      formValues: null
    }
  }

  componentDidMount = () => {
    const { dispatch } = this.props

    dispatch(MenuActions.setTitle(trb(i18.Container.DepositContainer)))
  }


  nextStep = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }))
  }

  previousStep = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep - 1 }))
  }

  setFormValues = values => {
    this.setState({ formValues: values })
    this.nextStep()
  }

  render() {
    return (
      <>
        <div className="eb_content_subtitle">
          {trb(i18.ContentTitles.NewDeposit)}
        </div>
        <Container gradient={this.state.currentStep === 1} w75>
          <Steps
              direction="horizontal"
            current={this.state.currentStep}
            className={`mb-3 mb-md-5 ${!appEnvSME &&
              'd-none d-md-flex'} eb_steps eb_deposit_steps`}>
            <Step
              title={appEnvSME ? null : trb(i18.Steps.DepositFill)}
              description={
                appEnvSME ? null : trb(i18.Steps.DepositFillDescription)
              }
              icon={
                appEnvSME ? (
                  <div>
                    <CustomIcon
                      size={40}
                      type="custom"
                      icon="depositFirstStep"
                    />
                  </div>
                ) : null
              }
            />

            <Step
              icon={
                <div>
                  <CustomIcon
                    size={40}
                    type="custom"
                    icon="depositSecondStep"
                  />
                </div>
              }
            />

            {/*<Step title={trb(i18.Steps.DepositPreview)} description={trb(i18.Steps.DepositPreviewDescription)}/>*/}
            <Step
              title={appEnvSME ? null : trb(i18.Steps.DepositSuccess)}
              description={
                appEnvSME ? null : trb(i18.Steps.DepositSuccessDescription)
              }
              icon={
                appEnvSME ? (
                  <div>
                    <CustomIcon
                      size={40}
                      type="custom"
                      icon="depositThirdStep"
                    />
                  </div>
                ) : null
              }
            />
          </Steps>

          {this.state.currentStep === 0 && (
            <DepositsFillContainer
              setFormValues={this.setFormValues}
              formValues={this.state.formValues}
            />
          )}

          {/*{this.state.currentStep === 1 &&*/}
          {/*<DepositsPreviewContainer nextStep={this.nextStep} previousStep={this.previousStep} formValues={this.state.formValues}/>*/}
          {/*}*/}

          {this.state.currentStep === 1 && (
            <DepositsSignContainer
              data={this.state.formValues}
              nextStep={this.nextStep}
            />
          )}

          {this.state.currentStep === 2 && (
            <DepositsSuccessContainer
              data={this.state.formValues}
              buttons={[]}
            />
          )}
        </Container>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { ALR, sendALR } = state.AMCReducers
  const { language } = state.languageReducer
  const { translations } = state.translationReducer
  return {
    ALR,
    sendALR,
    language,
    translations
  }
}

const connectedDepositsContainer = connect(mapStateToProps)(DepositsContainer)
export { connectedDepositsContainer as DepositsContainer }
