import React from 'react';
import PropTypes from "prop-types";

import {i18, trb, appEnvSME, checkMax} from "@utilities";
import {ContentNoData, FormField, FormFieldType} from "@components";

export const LimitsList = (props) => {
    const {data, title, edit} = props;

    function renderAccountLimits(item, index) {
        if (edit) {
            return <div className="row eb_table_row" key={index}>
                <div className="col-12 col-md-3">
                    <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.AccountNumber)}</div>
                    {item.AccIBAN}
                </div>
                <div className="col-12 col-md-3 text-left text-md-right">
                    <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationLimit)}</div>
                    <FormField
                        name={`${props.type}.${index}.OLimit`}
                        component={FormFieldType.number}
                        className="mb-0"
                        validate={checkMax(500000, true)}
                    />
                </div>
                <div className="col-12 col-md-3 text-left text-md-right">
                    <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationDayLimit)}</div>
                    <FormField
                        name={`${props.type}.${index}.DLimit`}
                        component={FormFieldType.number}
                        className="mb-0"
                        validate={checkMax(2000000, true)}
                    />
                </div>
                <div className="col-12 col-md-3 text-left text-md-right">
                    <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationMonthLimit)}</div>
                    <FormField
                        name={`${props.type}.${index}.MLimit`}
                        component={FormFieldType.number}
                        className="mb-0"
                        validate={checkMax(61000000, true)}
                    />
                </div>
            </div>
        } else {
            return (
                <div className="row eb_table_row" key={index}>
                    <div className="col-12 col-md-3">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.AccountNumber)}</div>
                        {item.AccIBAN}
                    </div>
                    <div className="col-12 col-md-3 text-left text-md-right">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationLimit)}</div>
                        {item.OLimitUsed} <span className="text-black-50">({item.OLimit})</span>
                    </div>
                    <div className="col-12 col-md-3 text-left text-md-right">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationDayLimit)}</div>
                        {item.DLimitUsed} <span className="text-black-50">({item.DLimit})</span>
                    </div>
                    <div className="col-12 col-md-3 text-left text-md-right">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.OperationMonthLimit)}</div>
                        {item.MLimitUsed} <span className="text-black-50">({item.MLimit})</span>
                    </div>
                </div>
            );
        }
    }

    return <div className="form-part">
        {data && data.length > 0 ?
          <>
            <div className={`form-part-title ${appEnvSME && 'mb-5 text-gray-basic'}`}>{trb(title)}</div>
                    <div className="eb_table_wrapper">
                        <div className="eb_table">
                            <div className="row eb_table_head d-none d-md-flex">
                                <div className="col-3">{!appEnvSME && trb(i18.Labels.AccountNumber)}</div>
                                <div className="col-3 text-right">{trb(i18.Labels.OperationLimit)}</div>
                                <div className="col-3 text-right">{trb(i18.Labels.OperationDayLimit)}</div>
                                <div className="col-3 text-right">{trb(i18.Labels.OperationMonthLimit)}</div>
                            </div>
                            {data.map((item, index) =>
                              renderAccountLimits(item, index)
                            )}
                        </div>
                    </div>
        </>
        :
          <ContentNoData/>
        }
    </div>
}

PropTypes.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};
