import React, {useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { Button, Progress } from 'antd'

import {FeePreviewRender, Number} from '../'
import {
    appEnvSME,
    i18,
    Payment,
    TARGET2_PAYMENT_SYSTEM,
    trb
} from '@utilities'
import {
    DepositPreview,
    ForexExchangePreview,
    InternationalPaymentPreview,
    NewPaymentPreview,
    OwnAccountsPreview
} from './'
import {FeePaymentPreview} from './FormPreviews'
import moment from 'moment'
import {useSelector} from "react-redux";

const ButtonGroup = Button.Group

export const FormPreviewSwitch = props => {
    const aml = useSelector(state => state.AMLReducer)
    if (!props.name || !props.data) {
        return null
    }
    let currentForm = null

    switch (props.name) {
        case 'deposit':
            currentForm = DepositPreview(props.data)
            break
        case Payment.OWN_ACCOUNTS:
            currentForm = OwnAccountsPreview(props.data)
            break
        case Payment.NEW:
            currentForm = NewPaymentPreview(props)
            break
        case Payment.FEE:
            currentForm = FeePaymentPreview(props.data)
            break
        case Payment.FOREX_EXCHANGE:
            currentForm = ForexExchangePreview(props.data)
            break
        case Payment.INTERNATIONAL:
            currentForm = InternationalPaymentPreview({...props.data, ...aml})
            break
        default:
            Sentry.captureMessage('Form preview type not found')
    }

    const [passedTime, setPassedTime] = useState(0)
    const [totalTime, setTotalTime] = useState()
    useEffect(() => {
        let intervalId = null
        if (
            props.name === Payment.FOREX_EXCHANGE &&
            props.data.forexDraftInfo &&
            !props.success
        ) {
            const from = moment(new Date())
            const till = moment(props.data.forexDraftInfo.ValidTill)
            const duration = moment.duration(till.diff(from))._milliseconds / 1000

            setTotalTime(duration)
            intervalId = setInterval(() => {
                setPassedTime(prevCount => prevCount + 1)
            }, 1000)
        }

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        if (passedTime >= totalTime && props.edit) {
            props.edit()
        }
    }, [passedTime])

    const addProgressTimer = () => {
        return (
            <Progress
                type="circle"
                percent={Math.min((100 / totalTime) * (totalTime - passedTime), 99.99)}
                format={() =>
                    `${Math.round(totalTime - passedTime)} ${trb(
                        i18.Labels.SecondsShort
                    )}`
                }
                width={100}
            />
        )
    }

    const FieldInfo = itemField => {

        let firstLine;
        let secondLine;
        switch (itemField.type) {
            case 'number':
                firstLine = <Number value={itemField.value} format={itemField.format}/>
                secondLine = itemField.secondLine ? <Number
                    value={itemField.secondLine}
                    format={itemField.secondLineFormat}
                /> : null
                break;
            case 'translate':
                firstLine = trb(itemField.translate + itemField.value);
                secondLine = itemField.secondLine ? trb(itemField.translate + itemField.secondLine) : null
                break;
            default:
                if (itemField.title === 'Labels.ExecutionDate' && props.name !== Payment.INTERNATIONAL) {
                    firstLine = moment(itemField.value).format('YYYY-MM-DD')
                } else {
                    firstLine = itemField.value;
                    secondLine = itemField.secondLine;
                }
        }

        return (
            <>
                <div
                    className={`form-preview-field-title ${
                        appEnvSME
                            ? 'col-12 text-gray-basic eb_fs_14'
                            : 'col-sm-12 col-md-3 text-left text-md-right'
                    }`}>
                    {trb(itemField.title)}
                </div>
                <div
                    className={`form-preview-field-value ${
                        appEnvSME
                            ? 'col-12 text-black eb_fs_18 text-break'
                            : 'col-sm-12 col-md-9 pl-md-0'
                    }`}>
                    {firstLine}
                </div>
                {itemField.secondLine ? (
                    <div
                        className={`form-preview-field-value ${
                            appEnvSME
                                ? 'col-12 text-black eb_fs_18'
                                : 'col-sm-12 col-md-9 pl-md-0'
                        }`}>
                        {secondLine}
                    </div>
                ) : null}
            </>
        )
    }

    const Target2PaymentPreview = () => {
        return (
            <div className="form-preview-part row">
                {currentForm?.parts?.map((item, index) =>
                    item.visible === undefined || item?.visible === true ? (
                        <div
                            className={`px-0 mx-0 col-12 ${
                                appEnvSME ? 'col-md-6 mb-md-4' : ''
                            }`}
                            key={index}>
                            {item?.fields?.map((itemField, indexField) =>
                                (itemField.visible === undefined ||
                                    itemField.visible === true) &&
                                (!appEnvSME ||
                                    (itemField.value !== null &&
                                        itemField.value !== undefined &&
                                        itemField.value !== '')) ? (
                                    <div
                                        className="form-preview-field-wrapper row col-12 px-0 mx-0"
                                        key={indexField}>
                                        {FieldInfo(itemField)}
                                    </div>
                                ) : null
                            )}
                        </div>
                    ) : null
                )}
            </div>
        )
    }

    return (
        <div className="form-preview">
            {appEnvSME && currentForm?.title && (
                <div className="form-preview-title">{trb(currentForm.title)}</div>
            )}
            {props.data?.Payment?.PaymentSystem === TARGET2_PAYMENT_SYSTEM
                ? Target2PaymentPreview()
                : currentForm?.parts?.map((item, index) =>
                    item.visible === undefined || item?.visible === true ? (
                        <div className="form-preview-part row" key={index}>
                            {/*{item.title && !appEnvSME && <div className="form-preview-part-title col-12">{trb(item.title)}</div>}*/}
                            {item.module && item.moduleComponent === 'FeeModule' ? (
                                <FeePreviewRender data={props}/>
                            ) : (
                                item?.fields?.map((itemField, indexField) =>
                                    (itemField.visible === undefined ||
                                        itemField.visible === true) &&
                                    (!appEnvSME ||
                                        (itemField.value !== null &&
                                            itemField.value !== undefined &&
                                            itemField.value !== '')) ? (
                                        <div
                                            className={`form-preview-field-wrapper row ${
                                                appEnvSME ? 'col-12 col-md-6 mb-md-4' : 'col-12'
                                            }`}
                                            key={indexField}>
                                            {FieldInfo(itemField)}
                                        </div>
                                    ) : null
                                )
                            )}
                        </div>
                    ) : null
                )}
            <div className="d-flex justify-content-end mb-3 ">
                <div className="d-none d-sm-block align-self-center">
                    <ButtonGroup>
                        {props?.buttons?.map((item, index) => (
                            <Button
                                key={index}
                                onClick={item.func}
                                icon={item.icon ? item.icon : null}
                                {...item.props}>
                                {trb(item.title)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
            <div className="mb-3 text-right d-block d-sm-none">
                <div className="d-flex justify-content-center mb-2">
                    {!props.success && totalTime && props.name !== Payment.FOREX_EXCHANGE ? addProgressTimer() : null}
                </div>
                {props?.buttons?.map((item, index) => (
                    <Button
                        className="mb-2"
                        key={index}
                        onClick={item.func}
                        block
                        {...item.props}>
                        {trb(item.title)}
                    </Button>
                ))}
            </div>
        </div>
    )
}

FormPreviewSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    buttons: PropTypes.array
}
