import {dateFormat} from './formats';

import {store} from '@redux/Config/store';

let language = store.getState().languageReducer.language;

const getLanguage = () => language = store.getState().languageReducer.language;
store.subscribe(getLanguage);

export const Sleep = m => new Promise(r => setTimeout(r, m))

export const ToJavaScriptDate =(value, options = null, dateLocale = null)=>{
    const pattern = /Date\(([^)]+)\)/;
    const results = pattern.exec(value);
    const date = new Date(parseFloat(results[1]));
    options = options ? options : dateFormat().date;

    let splitLang = dateLocale ? dateLocale.split('-') : language.split('-');
    const locales = [splitLang[0], splitLang[1], language];

    return new Intl.DateTimeFormat(locales, options).format(date);
};

export const ToJavaScriptDate2 =(value, options = null, dateLocale = null)=>{
    const date = Date.parse(value);
    options = options ? options : dateFormat().date;

    let splitLang = dateLocale ? dateLocale.split('-') : language.split('-');
    const locales = [splitLang[0], splitLang[1], language];

    return new Intl.DateTimeFormat(locales, options).format(date);
};

export const formatDate = (d, options = null, dateLocale = null) => {
    if (!d) {
        return null;
    }

    const date = d ? new Date(d) : null;
    options = options ? options : dateFormat().date;

    let splitLang = dateLocale ? dateLocale.split('-') : language.split('-');
    const locales = [splitLang[0], splitLang[1], language];

    if (isNaN(date.getDate()))
        return d;

    return new Intl.DateTimeFormat(locales, options).format(date);
};

export const formatCurrentDate = (day) => {
    const date = new Date();

    if (day) {
        date.setDate(date.getDate() + (day));
    }

    return date;
};

Date.prototype.toLocalISOString = function () {
    const tzo = -this.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
};

Date.prototype.toLocalISODateString = function () {
    const pad = function (num) {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate());
};

export const dateYearIncrease = (date, years) => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
};

export const dateYearDecrease = (date, years) => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() - years);
    return newDate;
};

