import React from 'react'
import { appEnvMano, i18, trb } from '@utilities'

export const LoginTypeSwitch = ({
  loginTypes,
  clickChangeForm,
  currentForm,
  submitting
}) => (
  <div className="bbs_login_panel_buttons">
    {loginTypes.MSign && (
      <button
        className={currentForm === 1 ? `active` : ''}
        onClick={() => clickChangeForm(1)}
        disabled={submitting}>
        {appEnvMano ? trb(i18.Labels.MSignature) : trb(i18.Labels.MSignature)}
      </button>
    )}
    {loginTypes.SmartID && (
      <button
        className={currentForm === 2 ? `active` : ''}
        onClick={() => clickChangeForm(2)}
        disabled={submitting}>
        {trb(i18.Labels.SmartID)}
      </button>
    )}
    {loginTypes.SMS && (
      <button
        className={currentForm === 3 ? `active` : ''}
        onClick={() => clickChangeForm(3)}
        disabled={submitting}>
        SMS
      </button>
    )}
    {loginTypes.Biometric && (
      <button
        className={currentForm === 4 ? `active` : ''}
        onClick={() => clickChangeForm(4)}
        disabled={submitting}>
        {trb(i18.Labels.Biometric)}
      </button>
    )}
  </div>
)
