import React from "react";
import PropTypes from "prop-types";
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {Button, Popconfirm, Tooltip} from "antd";

import {i18, trb, appEnvSME} from "../../../../../Utilities";

const ButtonGroup = Button.Group;

export const PaymentGroupList = (props) => {
    return (
        <div className="eb_table w-100">
            <div className="row eb_table_head d-none d-md-flex">
                <div className="col-3">{trb(i18.Labels.Title)}</div>
                <div className="col-6">{trb(i18.Labels.PaymentGroupReceivers)}</div>
                <div className="col-3">{!appEnvSME && trb(i18.Labels.Actions)}</div>
            </div>
            {props.data.map((item, index) =>
                <div className="row eb_table_row" key={index}>
                    <div className="col-12 col-md-3 eb_fw_600">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.Title)}</div>
                        {item.Name}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.PaymentGroupReceivers)}</div>
                        {item.TmpltsInfs.map((item, index) =>
                            <div key={index}><span className="eb_fw_600">{item?.RecipientName}</span>, {item.RecipientAccountNumber}</div>
                        )}
                    </div>
                    <div className="col-12 col-md-3 text-left text-md-right text-nowrap">
                        <div className='d-block d-md-none text-black-50'>{trb(i18.Labels.Actions)}</div>
                        <ButtonGroup className="hover-action rounded-button">
                            <Popconfirm
                                placement="bottomRight"
                                title={trb(i18.PopConfirms.DoYouWantDeletePaymentGroup)}
                                onConfirm={() => props.clickDelete(item.ID)}
                                okText={trb(i18.Buttons.Yes)}
                                cancelText={trb(i18.Buttons.Cancel)}
                            >
                                <Tooltip placement="topRight" title={trb(i18.Tooltips.PaymentGroupDelete)}>
                                    <Button>
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip placement="topRight" title={trb(i18.Tooltips.PaymentGroupEdit)}>
                                <Button
                                    onClick={() => props.clickEdit(item.ID)}
                                >
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="topRight" title={trb(i18.Tooltips.PaymentGroupUse)}>
                                <Button
                                    onClick={() => props.clickExecute(item.ID)}
                                >
                                    <CheckOutlined />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                </div>
            )}
        </div>
    );
};

PaymentGroupList.propTypes = {
    data: PropTypes.array.isRequired,
    clickDelete: PropTypes.func.isRequired,
    clickEdit: PropTypes.func.isRequired,
    clickExecute: PropTypes.func.isRequired,
};
