import { useState, useEffect } from 'react'

/**
 * Custom hook which returns draftjs lib input field value.
 * Use on mobile devices, because draftjs library do not fully support mobile devices
 * https://draftjs.org/docs/advanced-topics-issues-and-pitfalls/
 * 
 * @param id - input field or parrent DOM element id
 * @param nodeName - nodeName to search
 * @returns {inputText} - input field value  
 */

export const draftjsInputField = (id, nodeName) => {
  const [inputText, setInputText] = useState(null)

  useEffect(() => {
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver

    const observer = new MutationObserver((mutations, observer) => {
      const nodes = document.getElementById(id)?.getElementsByTagName(nodeName)
      let node = null
      if (nodes) {
        for (node of nodes) {
          if (
            (node.getAttribute('data-text') === 'true' ||
              node.getAttribute('data-offset-key')) &&
            node?.firstChild.nodeName === '#text'
          ) {
            const newValue =
              node?.firstChild.textContent ?? node?.firstChild.innerText
            setInputText(newValue)
            observer.disconnect()
          }
        }
      }
    })

    observer.observe(document.getElementById(id), {
      subtree: true,
      attributes: true,
      childList: true,
      characterData: true
    })
  })

  return inputText
}
