import React from 'react';
import {AutoComplete} from 'antd';

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '../';
import {trb, checkJsonProp, formFieldId} from '@utilities';

const { Option } = AutoComplete;

export class AutocompleteFormField extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            active: !this.props.input.value && this.props.input.value !== 0
              && !this.props.defaultValue && this.props.defaultValue !== 0,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            (this.props.input.value && this.props.input.value !== prevProps.input.value)
          ||
            (this.props.defaultValue  && this.props.defaultValue !== prevProps.defaultValue)
        ) {
            this.setState({
                active: false
            })
        }
    }

    onFocus = (e) => {
        if (!this.props.input.value && this.props.input.value !== 0) {
            this.setState({
                active: false
            })
        }
    }

    onBlur = (e) => {
        if (!this.props.input.value && this.props.input.value !== 0) {
            this.setState({
                active: true
            })
        }
    }

    onSelect = (e) => {
        this.setState({
            active: false
        });
        if (e && typeof(this.props.onSelect) === 'function') this.props.onSelect(e);
    }

    onSelectWithHighlight = (value) => {
        this.setState({
            active: false
        });
        const {options, optionsKey, optionsValue} = this.props,
        newValue = checkJsonProp(options.find(item => checkJsonProp(item, optionsKey) === value), optionsValue);
        this.props.input.onChange(newValue ? newValue : value);

        if (this.props.onSelect) this.props.onSelect(newValue);
    }

    onValueChange = (value) => {
        this.props.input.onChange(value);
    }

    render(){
        const {
            title,
            titleWithoutTranslate,
            className,
            inline,
            placeholder,
            disabled,
            optionsLabel,
            optionsValue,
            optionsKey,
            options,
            highlightOne,
            onSearch,
            noFilter,
            meta,
            input
        } = this.props,
        {active} = this.state;
        const limitElements = parseInt(this.props.limitElements),
        onSelect = highlightOne ? this.onSelectWithHighlight : this.onSelect,
        formatOptions = () => {
            let res = options && optionsLabel && optionsValue && optionsKey
            ? options.map(item => {
                return <Option key={checkJsonProp(item, optionsKey)} value={highlightOne ? checkJsonProp(item, optionsKey).toString() : checkJsonProp(item, optionsValue).toString()}>
                    {checkJsonProp(item, optionsLabel)}
                  </Option>
                })
            : options
            res = res.slice(0, limitElements);
            return res;
        };

        return <div id={formFieldId(input.name, 'AutoCompleteWrapper')} className={FormFieldWrapperClasses(className, inline, disabled, active)}>
            {title && <div className={inline ? 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0' : 'form-field-title col-12'}>{titleWithoutTranslate ? title : trb(title)}</div>}
            <div className={FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline)}>
                <AutoComplete
                    id={formFieldId(input.name, 'AutoComplete')}
                    allowClear
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="off"
                    dataSource={options}
                    placeholder={trb(placeholder)}
                    disabled={disabled}
                    name={input.name}
                    value={input.value}
                    onChange={this.onValueChange}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    filterOption={noFilter ? null : (input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    optionLabelProp="value"
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                >
                    {formatOptions()}
                </AutoComplete>
            </div>
            <div id={formFieldId(input.name, 'AutoCompleteError')} className={inline ? 'form-field-error col-3' : 'form-field-error col-12'}>{meta.touched && (meta.error || meta.submitError)}</div>
        </div>
    }
};
