import React, {useEffect, useState} from 'react';
import {Switch} from 'antd';

import {checkJsonProp, i18, requiredNotUndefined, translationGroups, trb} from "@utilities";
import {FormField, FormFieldType} from "@components";

export const AccountsManagementSpy = ({data, permissions, form, values, submitting}) => {

    const [currentAccounts, setCurrentAccounts] = useState(null)

    const permissionsOptions = [
        {
            label: "None",
            value: 0
        }, {
            label: "Preview",
            value: 1
        }, {
            label: "Create",
            value: 2
        }, {
            label: "Approve",
            value: 3
        }
    ];

    useEffect(() => {
        const ClientPermissions = permissions?.find(e => e.ID === data.ClientID)?.PermissionsList;
        form.reset({
            ClientID: data.ClientID,
            ClientPermissions
        });
        setCurrentAccounts(ClientPermissions);
    }, [data.ClientID])

    useEffect(() => {
        setCurrentAccounts(data.ClientPermissions)
    }, [data.ClientPermissions])

    if (!checkJsonProp(data, 'ClientID')) {
        return null;
    }


    const onChange = (index, value) => {
        const oldValue = values.ClientPermissions[index].Permission
        const newValue = oldValue !== 0 && oldValue === value ? 0 : value
        form.change(`ClientPermissions.${index}.Permission`, newValue);
    }

    return <div className="row pl-3 px-md-3 mb-3">
        <div className="col-12 row">
            <div className="col-12 col-md-5">
                <div className="form-part-title">{trb(i18.ContentSubTitles.AccountsAndPermissions)}</div>
            </div>
            <div className="d-none d-md-flex col-7 row text-md-right px-0 mx-0">
                {permissionsOptions.map(({label}, index) =>
                    <div className="col-3" key={index}>
                        <div
                            className="text-black">{trb(`${translationGroups.AccountPerrmissions}${label}`)}</div>
                    </div>
                )}
            </div>
        </div>
        {currentAccounts?.map((item, index) =>
            <div className='col-12 row py-2 border-top' key={`${index}-${item.AccountNumber}`}>
                <div className="col-12 col-md-5 pt-2">
                    {item.AccountNumber}
                </div>
                <div className="col-12 col-md-7 row pt-4 pt-md-3 pb-1 text-center text-md-right px-0 mx-0">
                    {permissionsOptions.map(({value, label}, indexPermission) => <div className="col-3"
                                                                                      key={`${indexPermission}`}>
                            <div
                                className="text-black d-md-none">{trb(`${translationGroups.AccountPerrmissions}${label}`)}</div>
                            <Switch onChange={() => onChange(index, value)}
                                    checked={value === item.Permission}/>
                        </div>
                    )}
                </div>
            </div>
        )}
        {false && currentAccounts.map((item, index) =>
            <div className="col-6" key={index}>
                <FormField
                    name={'ClientPermissions.' + index + '.Permission'}
                    title={item.AccountNumber}
                    titleWithoutTranslate={true}
                    component={FormFieldType.select}
                    options={permissionsOptions}
                    optionsLabel="label"
                    optionsValue="value"
                    translate={translationGroups.AccountPerrmissions}
                    validate={requiredNotUndefined}
                    disabled={submitting}
                />
            </div>
        )}
    </div>
}

