import {OMCConstants} from './constants';

const initialState = {
    ODLPaging: {
        RowsCount: 0,
        OperationList: []
    },
    ODLPagination: {
        Current: 1
    },
    UOLR: {
        Operations: []
    }
}

export const OMCReducers = function (state = initialState, action) {
    switch (action.type) {
        case OMCConstants.POST_OMC_ROL_REQUEST:
            return {
                ...state,
                send: true,
                sendROL: true,
                ROL: null,
            };
        case OMCConstants.POST_OMC_ROL_SUCCESS:
            return {
                ...state,
                send: false,
                sendROL: false,
                ROL: action.payload,
            };
        case OMCConstants.POST_OMC_ROL_ERROR:
            return {
                ...state,
                send: false,
                sendROL: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_ODL_REQUEST:
            return {
                ...state,
                send: true,
                sendODL: true,
                ODL: null,
            };
        case OMCConstants.POST_OMC_ODL_SUCCESS:
            return {
                ...state,
                send: false,
                sendODL: false,
                ODL: action.payload,
            };
        case OMCConstants.POST_OMC_ODL_ERROR:
            return {
                ...state,
                send: false,
                sendODL: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_ODL_PAGING_REQUEST:
            return {
                ...state,
                send: true,
                sendODLPaging: true,
                ODLPagination: action.payload
            };
        case OMCConstants.POST_OMC_ODL_PAGING_SUCCESS:
            return {
                ...state,
                send: false,
                sendODLPaging: false,
                ODLPaging: action.payload,
            };
        case OMCConstants.POST_OMC_ODL_PAGING_ERROR:
            return {
                ...state,
                send: false,
                sendODLPaging: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_UOLR_REQUEST:
            return {
                ...state,
                send: true,
                sendUOLR: true,
                UOLR: null,
            };
        case OMCConstants.POST_OMC_UOLR_SUCCESS:
            return {
                ...state,
                send: false,
                sendUOLR: false,
                UOLR: action.payload,
            };
        case OMCConstants.POST_OMC_UOLR_ERROR:
            return {
                ...state,
                send: false,
                sendUOLR: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_DUOR_REQUEST:
            return {
                ...state,
                send: true,
                sendDUOR: true,
                DUOR: null,
            };
        case OMCConstants.POST_OMC_DUOR_SUCCESS:
            return {
                ...state,
                send: false,
                sendDUOR: false,
                DUOR: action.payload,
            };
        case OMCConstants.POST_OMC_DUOR_ERROR:
            return {
                ...state,
                send: false,
                sendDUOR: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_PSUOR_REQUEST:
            return {
                ...state,
                send: true,
                sendPSUOR: true,
                PSUOR: null,
            };
        case OMCConstants.POST_OMC_PSUOR_SUCCESS:
            return {
                ...state,
                send: false,
                sendPSUOR: false,
                PSUOR: action.payload,
            };
        case OMCConstants.POST_OMC_PSUOR_ERROR:
            return {
                ...state,
                send: false,
                sendPSUOR: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_SUORSMS_REQUEST:
            return {
                ...state,
                send: true,
                sendSUORSMS: true,
                SUORSMS: null,
            };
        case OMCConstants.POST_OMC_SUORSMS_SUCCESS:
            return {
                ...state,
                send: false,
                sendSUORSMS: false,
                SUORSMS: action.payload,
            };
        case OMCConstants.POST_OMC_SUORSMS_ERROR:
            return {
                ...state,
                send: false,
                sendSUORSMS: false,
                errors: action.error
            };
        case OMCConstants.POST_OMC_SUOR_REQUEST:
            return {
                ...state,
                send: true,
                sendSUOR: true,
                SUOR: null,
            };
        case OMCConstants.POST_OMC_SUOR_SUCCESS:
            return {
                ...state,
                send: false,
                sendSUOR: false,
                SUOR: action.payload,
            };
        case OMCConstants.POST_OMC_SUOR_ERROR:
            return {
                ...state,
                send: false,
                sendSUOR: false,
                errors: action.error
            };
        case OMCConstants.REMOVE_OMC_UOLR_COMPLETED:
            return {
                ...state,
                UOLR: {
                    ...state.UOLR,
                    Operations: state.UOLR?.Operations?.filter((e) => {
                        return !action.payload.includes(e.ID);
                    })
                },
            };
        default:
            return state;
    }
};
