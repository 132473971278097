import React from 'react'
import { Button, Result } from 'antd'
import { i18, trb } from '@utilities'
import DOMPurify from 'dompurify'
import { history, routerPath } from '@config'
import { LoginHeader } from '@containers'
import { LoginFooter } from '../Login/Components/LoginFooter'
import { LanguageActions } from '@redux'
import { useDispatch } from 'react-redux'
import {useNavigate} from "react-router-dom";

export const IdentificationError = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    React.useEffect(() => {
        dispatch(LanguageActions.setLanguage('en-GB'));
    }, [])

    return (
        <div className='bbs_login'>
            <div className='bbs_login_container'>
                <LoginHeader />
                <div className='bbs_login_content'>
                    <Result
                        status='error'
                        title={trb(i18.ContentTitles.IdentificationError)}
                        subTitle={
                            <div
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(trb(i18.HTML.IdentificationError)) }}
                            />
                        }
                        extra={[
                            <Button
                                size='large'
                                type='primary'
                                key='login'
                                onClick={() => navigate(routerPath.login)}
                            >
                                {trb(i18.Buttons.IdentificationError)}
                            </Button>
                        ]}
                    />
                </div>
                <LoginFooter />
            </div>
        </div>
    )
}
