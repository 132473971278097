export const FormFieldType = {
    radio: 'radio',
    checkbox: 'checkbox',
    checkboxOnly: 'checkboxOnly',
    date: 'date',
    select: 'select',
    selectSearch: 'selectSearch',
    input: 'input',
    inputOnly: 'inputOnly',
    inputPassword: 'inputPassword',
    inputSearch: 'inputSearch',
    textarea: 'textarea',
    number: 'number',
    amountCurrency: 'amountCurrency',
    autocomplete: 'autocomplete',
    html: 'html',
    numberLikeRadio: 'numberLikeRadio',
};
