import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    i18,
    required,
    checkEmail,
    checkMaxLength,
    checkMinLength,
    checkPhonePrefix,
    checkPhoneNumber,
    composeValidators,
    checkJsonProp,
    checkJsonExist
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCInformationAboutClient = (props) => {

    let ClientPhone = checkJsonProp(props.data, 'clientPhone') && checkJsonProp(props.data, 'clientPhone').length
                        ? checkJsonProp(props.data, 'clientPhone')
                        : [{clientPhoneType: '', data: {}}];
    ClientPhone = ClientPhone.map((item, index) => {
        if (item.data && item.data.clientPhoneNo){
            item.data.clientPhoneNoStr = item.data ? item.data.clientPhoneNo.slice(4) : null;
            item.data.clientPhoneNoPrefix = item.data ? item.data.clientPhoneNo.slice(0,4) : null;
        }
        return item;
    });

    let ClientMail = checkJsonProp(props.data, 'clientMail') && checkJsonProp(props.data, 'clientMail').length
                        ? checkJsonProp(props.data, 'clientMail')
                        : [{clientEmailType: 'MAILL1', data: {}}];
    ClientMail = ClientMail && ClientMail.map((item, index) => {
        item.clientEmailType = item.clientEmailType ? item.clientEmailType : props.classifikators.MAILL.filter((item)=>!item.disabled)[0].value;
        return item;
    });

    const ClientProfile = checkJsonProp(props.data, 'clientProfile'),
          ClientAddress = checkJsonProp(props.data, 'clientAddress'),
          initialData = {clientProfile: ClientProfile, clientAddress: ClientAddress, clientPhone: ClientPhone, clientMail: ClientMail};

    const mapData = [{
        title: i18.Labels.InformationAboutClient, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCInformationAboutClient,
        elements: [{
            title: i18.Labels.ClientFullName,
            name: `clientProfile.0.data.clientFullName`,
            component: FormFieldType.input,
            className: 'col-12',
            disabled: true,
        },{
            title: i18.Labels.ClientId,
            name: `clientProfile.0.data.clientId`,
            component: FormFieldType.input,
            className: 'col-12',
            disabled: true,
        },{
            title: i18.Labels.ClientBirthDate,
            name: `clientProfile.0.data.clientBirthDate`,
            component: FormFieldType.date,
            className: 'col-12',
            disabled: true,
        },{
            title: i18.Labels.ClientVatCode,
            name: `clientProfile.0.data.clientVatCode`,
            component: FormFieldType.input,
            className: 'col-12',
        },{
            title: i18.Labels.ClientEmployeeQty,
            name: `clientProfile.0.data.clientEmployeeQty`,
            component: FormFieldType.input,
            className: 'col-12',
        }],
    }, {
        title: i18.Labels.ClientAddress, //i18.Labels.ClientOtherAddress
        canDuplicate: true,
        duplicateName: 'clientAddress',
        duplicateDefault: {data:{addressCountry: 'LT'}},
        maxRows: props.classifikators.ADRT.length - 1,
        elements: [{
            title: i18.Labels.AddressType,
            name: `clientAddress.$index.addressType`,
            component: FormFieldType.select,
            options: props.classifikators.ADRT,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12',
            validate: required,
        },{
            title: i18.Labels.AddressCountry,
            name: `clientAddress.$index.data.addressCountry`,
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            duplicateEnabled: true,
            disabled: true,
            className: 'col-12',
            validate: required,
        },{
            title: i18.Labels.AddressMunicipality,
            name: `clientAddress.$index.data.addressMunicipality`,
            component: FormFieldType.select,
            options: props.classifikators.DIST,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-12',
            validate: required,
        },{
            title: i18.Labels.AddressCity,
            name: `clientAddress.$index.data.addressCity`,
            component: FormFieldType.input,
            className: 'col-12',
            validate: required,
        },{
            title: i18.Labels.AddressStreet,
            name: `clientAddress.$index.data.addressStreet`,
            component: FormFieldType.input,
            className: 'col-12',
            validate: required,
        },{
            title: i18.Labels.AddressHouse,
            name: `clientAddress.$index.data.addressHouse`,
            component: FormFieldType.input,
            className: 'col-6 col-md-4 offset-md-0',
            classNameTitle: 'col-6 col-md-9 text-right',
            classNameField: 'col-6 col-md-3 pl-0',
        },{
            title: i18.Labels.AddressFlat,
            name: `clientAddress.$index.data.addressFlat`,
            component: FormFieldType.input,
            className: 'col-6 col-md-4',
            classNameTitle: 'col-6 col-md-9 text-right',
            classNameField: 'col-6 col-md-3 pl-0',
        },{
            title: i18.Labels.AddressPostCode,
            name: `clientAddress.$index.data.addressPostCode`,
            component: FormFieldType.input,
            className: 'col-12',
            classNameTitle: 'col-3 text-right pr-3',
            classNameField: 'col-9 col-md-2 pl-0',
            maxLength: 8,
            validate: composeValidators(checkMaxLength(8), required),
        }]
    },{
        title: i18.Labels.ClientPhone, // : i18.Labels.OtherClientPhone,
        canDuplicate: true,
        duplicateName: 'clientPhone',
        maxRows: props.classifikators.PHON.length - 1,
        elements: [{
            title: i18.Labels.ClientPhoneType,
            name: `clientPhone.$index.clientPhoneType`,
            component: FormFieldType.select,
            options: props.classifikators.PHON,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12 col-md-4 offset-md-1',
            classNameError: 'col-12 text-md-right',
            validate: required,
        },{
            title: i18.Labels.ClientPhoneNo,
            name: `clientPhone.$index.data.clientPhoneNo`,
            component: FormFieldType.input,
            className: 'col-12 d-none',
        },{
            title: i18.Labels.ClientPhoneNo,
            name: `clientPhone.$index.data.clientPhoneNoStr`,
            component: FormFieldType.input,
            className: 'col-12 col-md-6 text-right p-md-0 addon-unclocked',
            addonBefore: {
                maxLength: 4,
                name: "clientPhone.$index.data.clientPhoneNoPrefix",
                className: "col-12 m-0",
                component: FormFieldType.inputOnly,
                validate: composeValidators(required, checkPhonePrefix, checkMinLength(4), checkMaxLength(4)),
            },
            classNameTitle: 'col-3 text-right',
            classNameField: 'col-9 col-md-6',
            classNameError: 'col-12 col-md-7 offset-md-5 text-left pl-1',
            maxLength: 8,
            validate: composeValidators(required, checkPhoneNumber, checkMinLength(8), checkMaxLength(8)),
        }],
    },{
        title: i18.Labels.ClientEmail,
        canDuplicate: true,
        duplicateName: 'clientMail',
        duplicateDefault: {clientEmailType: 'MAILL$index', data: {}},
        maxRows: props.classifikators.MAILL.length - 1,
        elements: [{
            title: i18.Labels.ClientEmail,
            name: 'clientMail.$index.clientEmailType',
            component: FormFieldType.select,
            options: props.classifikators.MAILL,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12 d-none',
        },{
            title: i18.Labels.ClientEmail,
            name: `clientMail.$index.data.clientEmail`,
            component: FormFieldType.input,
            className: 'col-12',
            validate: composeValidators(required, checkEmail),
        }]
    }];



    const decorators = [
        createDecorator(
            {
                field: 'clientMail',
                updates: (value, name, allValues, prevValues) => {
                    const diff = allValues.clientMail.filter((newItem)=>{
                        return prevValues.clientMail != undefined && prevValues.clientMail.some((prevItem) => {
                            return Object.is(newItem, prevItem);
                        }) ? false : true;
                    })
                    const index = value && typeof value == "object" &&
                                  checkJsonExist(allValues, 'clientMail') && checkJsonExist(prevValues, 'clientMail') &&
                                  allValues.clientMail.length != prevValues.clientMail.length
                                      ? value.indexOf(diff[0])
                                      : 0;
                    if (index > 0){
                        props.classifikators.MAILL.every((item) => {
                            if (item.value == value[index].clientEmailType){
                                if (item.disabled){
                                    let accIndex;
                                    props.classifikators.MAILL.filter((item, index) => {
                                        if (!item.disabled){
                                            accIndex = index;
                                            return true;
                                        }
                                        return false;
                                    });
                                    value[index].clientEmailType = props.classifikators.MAILL[accIndex].value;
                                    props.classifikators.MAILL[accIndex].disabled = true;
                                    return false;
                                }
                                else{
                                    item.disabled = true;
                                }
                            }
                            return true;
                        });
                    }
                    return {};
                }
            },{
                field: /clientMail\.*.*clientEmailType/,
                updates: (value, name, allValues) => {
                    props.classifikators.MAILL.map((item, index)=>{
                        item.disabled = checkJsonProp(allValues, 'clientMail').some((addItem, addIndex) => {
                            return addItem.clientEmailType == item.value;
                        }) ? true : false;
                        item.disabled = item.disabled || item.value == value ? true : false;
                        return item;
                    })
                    return {}
                }
            },{
                field: /clientPhone\.*.*clientPhoneType/,
                updates: (value, name, allValues) => {
                    props.classifikators.PHON.map((item, index)=>{
                        item.disabled = checkJsonProp(allValues, 'clientPhone').some((addItem, addIndex) => {
                            return addItem.clientPhoneType == item.value;
                        }) ? true : false;
                        item.disabled = item.disabled || item.value == value ? true : false;
                        return item;
                    })
                    return {}
                }
            },{
                field: /clientAddress\.*.*addressType/,
                updates: (value, name, allValues) => {
                    props.classifikators.ADRT.map((item, index)=>{
                        item.disabled = checkJsonProp(allValues, 'clientAddress').some((addItem, addIndex) => {
                            return addItem.addressType == item.value;
                        }) ? true : false;
                        item.disabled = item.disabled || item.value == value ? true : false;
                        return item;
                    })
                    return {}
                }
            },{
                field: /clientPhone\.*.*clientPhoneNoStr/,
                updates: (value, name, allValues) => {
                    if (value !== undefined && value !== null){
                        const toField = name.replace('clientPhoneNoStr', 'clientPhoneNo')
                        const prefixField = name.replace('clientPhoneNoStr', 'clientPhoneNoPrefix')
                        const prefixValue = checkJsonProp(allValues, prefixField);
                        return {
                          [toField]: prefixValue+value
                        }
                    }
                    return {}
                  }
            },{
                field: /clientPhone\.*.*clientPhoneNoPrefix/,
                updates: (value, name, allValues) => {
                    if (value !== undefined && value !== null){
                        const toField = name.replace('clientPhoneNoPrefix', 'clientPhoneNo')
                        const numberField = name.replace('clientPhoneNoPrefix', 'clientPhoneNoStr')
                        const numberValue = checkJsonProp(allValues, numberField);
                        return {
                          [toField]: value+numberValue
                        }
                    }
                    return {}
                }
            }
        )
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};
