import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {
    appEnvMano,
    appEnvSME,
    AUD,
    BIC,
    CAD,
    checkEmail,
    checkIBAN,
    checkMaxLength,
    classifikatorsMap,
    CNY,
    currencyFormat,
    EUR,
    i18,
    INR,
    KRW,
    logError,
    numberFormat, PaymentType,
    required,
    requiredAndApprove,
    requiredNotUndefined,
    translationGroups,
    trb,
    USD
} from '@utilities'
import {filterFormOptions, FormFieldType, paymentSystemType} from '@components'
import {payerPayeeType, paymentPurposeType} from '../FormStaticOptions'
import {useDispatch, useSelector} from 'react-redux'
import {AMCActions, PMCActions} from '@redux'
import {
    checkIBANByCurrency,
    getBeneficiaryAddressValidation,
    getBeneficiaryBankCodeValidation,
    getBeneficiaryBankNameValidation,
    getBeneficiaryCityValidation,
    getBeneficiaryContactNumberValidation,
    getBeneficiaryIdentificationValueValidation,
    getBeneficiaryPostCodeValidation,
    getPurposeValidation
} from './PaymentFormValidations'

export const currenciesWithoutIBANCheck = [
    'AUD', // australia
    'CAD', // canada
    'CNY', // china
    'INR', // india
    'KRW', // south korea
    'MYR', // malaysia
    'SGD', // singapore
    'USD' // usa
]

export const NewPaymentForm = (props, PaymentTypes) => {
    const dispatch = useDispatch();
    const { checkIBANResult } = useSelector((state) => state.PMCReducers);
    // console.log(checkIBANResult);
    const currentTemplates = props.PaymentTemplates.filter(template =>
            template.PaymentType !== PaymentType.INTERNATIONAL
        ),
        {
            // checkIBANResult,
            historicalPayments,
            sendCheckIBAN,
            checkIBANLength,
            checkIBANValid,
            PVR
        } = props,
        isInternalPayment = checkIBANResult?.Bic?.BIC === 'USDFLT22XXX',
        SepaInst = checkIBANResult?.Participant?.SepaInst && PVR?.ErrorCode !== 'ChangePaymentSystem',
        BankName = checkIBANResult?.Bic?.BankName;
    const {target2Data} = useSelector(state => state.PMCReducers)
    const {accountOverdraftInfo} = useSelector(state => state.AMCReducers)

    const [currency, setCurrency] = useState(null)

    const checkCurrency = (value, values) => {
        if (!values?.Payment?.Currency) {
            return
        }
        if (values?.Payment?.Currency !== currency) {
            setCurrency(values.Payment.Currency)
            values.Payment.AmountStr =
                values.Payment.AmountStr || values?.Payment?.AmountSell || null
        }
        return checkCurrencyCorrect() ? null : i18.Validations.IncorrectCurrency
    }

    const [initialAccount, setInitialAccount] = useState(null)
    const checkAccountId = value => {
        if (value !== initialAccount) {
            setInitialAccount(value)
        }
    }

    useEffect(() => {
        const getAccountOverdraftInfo =  account => {
            try {
                if (account?.hasOverdraftProduct) {
                    dispatch(AMCActions.getAccountOverdraft(initialAccount))
                } else {
                    dispatch(AMCActions.setAccountOverdraft(null))
                }
            } catch (error) {
                logError(error)
            }
        }
        if (initialAccount) {
            const account = props.InitialAccounts.find(
                item => item.value === initialAccount
            )
            getAccountOverdraftInfo(account)
        }
    }, [initialAccount])

    const {
        countryCurrenciesResult,
        paymentPurposeCode,
        sendPaymentPurposeCode,
        beneficiaryIdentificationCodeTypes,
        sendBeneficiaryIdentificationCodeTypes,
        beneficiaryIdentificationCodeTypesErrors,
        beneficiaryIdentificationCodeTypesErrorsCnt
    } = useSelector(state => state.PMCReducers)

    const {language} = useSelector(state => state.languageReducer)

    const {CISO} = useSelector(
        state =>
            state?.translationReducer?.translations?.find(
                el => el.culture === language && el.context === 'BBS.Classificators'
            )?.Groups ?? []
    )

    const isNIUMPayment = () => {
        return (
            appEnvSME &&
            !isCurrencyEUR() &&
            (currenciesWithoutIBANCheck.includes(currency) ||
                checkIBANResult ||
                checkIBANResult?.Participant?.SepaSCT === false)
        )
    }
    const isTarget2Payment = () => {
        return (
            appEnvSME &&
            isCurrencyEUR() &&
            ((checkIBANResult?.Participant &&
                    !checkIBANResult.Participant.SepaSCT &&
                    !checkIBANResult.Participant.SepaInst &&
                    !checkIBANResult.Participant.SepaSDD) ||
                target2Data?.target2)
        )
    }

    useEffect(()=>{
        dispatch(PMCActions.setTarget2Payment(null))
    },[])

    useEffect(() => {
        const getCurrencies = async () => {
            dispatch(PMCActions.GetCountryCurrencies())
        }
        if (!countryCurrenciesResult) {
            getCurrencies()
        }
    }, [countryCurrenciesResult])

    useEffect(() => {
        const getPaymentPurposeCode = async () => {
            dispatch(PMCActions.GetPaymentPurposeCode())
        }
        if (!paymentPurposeCode && !sendPaymentPurposeCode) {
            getPaymentPurposeCode()
        }
    }, [paymentPurposeCode, sendPaymentPurposeCode])

    useEffect(() => {
        const getBeneficiaryIdentificationCodeTypes = async () => {
            dispatch(PMCActions.GetBeneficiaryIdentificationCodeTypes())
        }
        if (
            !beneficiaryIdentificationCodeTypes &&
            currency !== EUR &&
            !sendBeneficiaryIdentificationCodeTypes &&
            (!beneficiaryIdentificationCodeTypesErrors ||
                beneficiaryIdentificationCodeTypesErrorsCnt < 3)
        ) {
            getBeneficiaryIdentificationCodeTypes()
        }
    }, [
        currency,
        beneficiaryIdentificationCodeTypes,
        sendBeneficiaryIdentificationCodeTypes,
        beneficiaryIdentificationCodeTypesErrors,
        beneficiaryIdentificationCodeTypesErrorsCnt
    ])

    const hiddenCurrencies = ['KRW', 'INR', 'MYR', 'SGD']
    const getCurrenciesOptions = () => {
        const arr = []
        countryCurrenciesResult.forEach(({Country, Currencies}) => {
            Currencies.forEach(itm => {
                if (!hiddenCurrencies.includes(itm)) {
                    arr.push({
                        label: Currencies.length > 1 ? `${itm} (${Country})` : itm,
                        value: Currencies.length > 1 ? `${Country}_${itm}` : itm
                    })
                }
            })
        })
        return arr
    }

    const isCurrencyEUR = () => {
        return currency === EUR || currency?.indexOf(`_${EUR}`) !== -1
    }

    const checkCurrencyCorrect = () => {
        if (isCurrencyEUR()) {
            return true
        }
        if (checkIBANResult) {
            const curr =
                currency.indexOf('_') === -1 ? currency : currency.split('_')[1]
            const item = countryCurrenciesResult.find(el =>
                el.Currencies.includes(curr)
            )
            return item && item.Country === checkIBANResult.Country.Alpha2
        }
        return true
    }

    const preselectInitialAccount = () => {
        const accounts = props.InitialAccounts?.filter(itm => !itm.disabled)
        return accounts.length === 1 ? accounts[0].value : null
    }

    const checkOverdraftValue = (value, values) => {
        values.Payment.OverdraftValue = `${
            accountOverdraftInfo?.totalAvailable
                ? currencyFormat(accountOverdraftInfo.totalAvailable)
                : 0
        } EUR`
    }

    return {
        parts: [
            {
                title: i18.PaymentParts.Payer,
                fields: [
                    {
                        name: 'Payment.PaymentType',
                        component: FormFieldType.input,
                        value: 4,
                        className: 'd-none'
                    },
                    {
                        title: i18.Labels.PaymentTemplates,
                        name: 'Payment.Template',
                        component: FormFieldType.selectSearch,
                        options: currentTemplates,
                        optionsLabel: 'TemplateName',
                        optionsValue: 'TemplateID'
                    },
                    {
                        title: i18.Labels.FromAccount,
                        name: 'Payment.InitialAccountID',
                        component: FormFieldType.select,
                        options: props.InitialAccounts,
                        optionsColor: appEnvSME ? 'color' : null,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        optionsDisabled: 'disabled',
                        validateCompose: appEnvSME,
                        validate: appEnvSME ? [required, checkAccountId] : null,
                        className: appEnvSME ? 'mobile' : '',
                        defaultValue: preselectInitialAccount()
                    },
                    {
                        title: i18.Labels.OverdraftAvailableAmount,
                        name: 'Payment.OverdraftValue',
                        validate: checkOverdraftValue,
                        component: FormFieldType.input,
                        disabled: true,
                        className:
                            initialAccount && accountOverdraftInfo?.totalAvailable
                                ? ''
                                : 'd-none'
                    }
                ]
            },
            {
                title: i18.PaymentParts.Payee,
                className: '',
                fields: [
                    {
                        title: i18.Labels.PayeeTitle,
                        name: 'Payment.Recipient',
                        component: FormFieldType.autocomplete,
                        options: historicalPayments,
                        optionsValue: 'PayeeName',
                        optionsLabel: 'display',
                        optionsKey: 'PaymentId',
                        onSearch: props.onFullTextSearch,
                        limitElements: 5,
                        validateCompose: true,
                        validate: [required, checkMaxLength(isTarget2Payment() ? 35 : 70)],
                        noFilter: true,
                        highlightOne: true
                    },
                    {
                        title: i18.Labels.PayeeAccount,
                        name: 'Payment.BeneficiaryAccountNumber',
                        component: FormFieldType.autocomplete,
                        options: historicalPayments,
                        optionsValue: 'PayeeAccountNumber',
                        optionsLabel: 'display',
                        optionsKey: 'PaymentId',
                        limitElements: 5,
                        validateCompose: currency && currenciesWithoutIBANCheck.includes(currency),
                        validate:  currenciesWithoutIBANCheck.includes(currency)
                                ? checkIBANByCurrency(currency)
                                : checkIBAN({
                                    dispatch,
                                    checkIBANResult,
                                    sendCheckIBAN,
                                    checkIBANLength,
                                    checkIBANValid,
                                    target2Data
                                }),
                        noFilter: true,
                        highlightOne: true
                    },
                    {
                        title: i18.Labels.Currency,
                        name: 'Payment.Currency',
                        component: FormFieldType.selectSearch,
                        validate: appEnvSME ? checkCurrency : null,
                        // TODO: commented as requested at 2022-07-20
                        options: [{label: EUR, value: EUR}],
                        // options: countryCurrenciesResult?.length
                        //   ? getCurrenciesOptions()
                        //   : [{ label: EUR, value: EUR }],
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        defaultValue: EUR,
                        allowClear: false,
                        className: appEnvSME ? null : 'd-none'
                    },
                    {
                        title: i18.Labels.Amount,
                        name: 'Payment.AmountStr',
                        component: FormFieldType.number,
                        validate: required,
                        hightlight: !appEnvSME || currency?.indexOf(EUR) !== -1,
                        disabled: appEnvSME && currency?.indexOf(EUR) === -1,
                        className:
                            appEnvSME && currency?.indexOf(EUR) === -1 ? 'd-none' : ''
                    }
                ]
            },

            {className: 'col-12 col-md-6', fields: []},
            {
                // new fields +++
                className: `d-flex row col-12 col-md-6 justify-content-start m-0 p-0 ${
                    isCurrencyEUR() ? 'd-none' : ''
                }`,
                fields:
                    !isCurrencyEUR() && initialAccount
                        ? [
                            {
                                name: 'Payment.remove',
                                component: FormFieldType.html,
                                html: `<p class="eb_fs_15">${trb(i18.Labels.WantBuy)}</p>`,
                                className:
                                    'col-12 col-md-4 m-0 p-1 pl-3 mt-sm-0 align-self-center'
                            },
                            {
                                title: i18.Labels.Amount,
                                name: 'Payment.AmountBuy',
                                component: FormFieldType.input,
                                className: 'col-12 col-md-8 m-0 p-1 mt-sm-0',
                                classNameTitle: 'form-field-title col-12 mt-1',
                                validate: required
                            },
                            {
                                title: i18.Labels.FixRate,
                                name: 'Payment.FixRateBuy',
                                component: FormFieldType.input,
                                disabled: true,
                                className: 'col-12 col-md-4 m-0 p-1 mt-sm-0 d-none'
                            },

                            {
                                name: 'Payment.remove',
                                component: FormFieldType.html,
                                html: `<p class="eb_fs_15">${trb(i18.Labels.WantSell)}</p>`,
                                className:
                                    'col-12 col-md-4 m-0 p-1 pl-3 mt-sm-0 align-self-center'
                            },
                            {
                                title: i18.Labels.Amount,
                                name: 'Payment.AmountSell',
                                component: FormFieldType.input,
                                className: 'col-12 col-md-8 m-0 p-1 mt-sm-0',
                                classNameTitle: 'form-field-title col-12 mt-1',
                                validate: required
                            },
                            {
                                title: i18.Labels.FixRate,
                                name: 'Payment.FixRateSell',
                                component: FormFieldType.input,
                                disabled: true,
                                className: 'col-12 col-md-4 m-0 p-1 mt-sm-0 d-none'
                            },
                            {
                                name: 'Payment.remove',
                                component: FormFieldType.html,
                                html: `<p class="eb_fs_15">${trb(i18.Labels.FixRate)}</p>`,
                                className:
                                    'col-12 col-md-4 m-0 p-1 pl-3 mt-sm-0 align-self-center'
                            },
                            {
                                title: `${EUR} / ${currency}`,
                                name: 'Payment.FixRate',
                                component: FormFieldType.input,
                                className: 'col-12 col-md-8 m-0 p-1 mt-sm-0',
                                classNameTitle: 'form-field-title col-12 mt-1',
                                disabled: true
                            },
                            {
                                name: 'Payment.remove',
                                component: FormFieldType.html,
                                html: `<p class="eb_fs_15">${trb(i18.Labels.Commission)}</p>`,
                                className:
                                    'd-none col-12 col-md-4 m-0 p-1 pl-3 mt-sm-0 align-self-center'
                            },
                            {
                                title: i18.Labels.Commission,
                                name: 'Payment.Commission',
                                component: FormFieldType.number,
                                className: 'd-none col-12 col-md-8 m-0 p-1 mt-sm-0',
                                classNameField: 'px-1',
                                disabled: true,
                                format: numberFormat().currency
                            },
                            {
                                name: 'Confirm',
                                component: FormFieldType.checkbox,
                                translate: i18.Labels.AgreeWithFixRate,
                                validate: requiredAndApprove,
                                className: appEnvSME
                                    ? 'col-12 col-md-12 mb-md--6 pt-0 pl-4'
                                    : null,
                                classNameField: appEnvSME
                                    ? 'col-12 text-justify mobile font-size-12'
                                    : null
                            }
                        ]
                        : []
            },
            {className: '', fields: []},
            {
                className: '',
                fields: [
                    {
                        title: i18.Labels.PurposeType,
                        name: 'Payment.IsPurposeSelected',
                        component: FormFieldType.radio,
                        className: isNIUMPayment() ? 'd-none' : '',
                        options: isTarget2Payment() ?
                            paymentPurposeType.map(payment => payment.label === 'Code' ? {
                                ...payment,
                                disabled: true
                            } : payment) :
                            paymentPurposeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PaymentPurposeTypes,
                        defaultValue: paymentPurposeType[0].value,
                        validate: isNIUMPayment() ? null : requiredNotUndefined,
                        inline: true,
                        classNameField:                              'col-10 col-md-7 mt-3 mt-md-0 small-height',
                        classNameTitle: 'col-12 col-md-5 pl-4 pr-0'
                    },
                    {
                        title: i18.Labels.Purpose,
                        name: 'Payment.Purpose',
                        component: FormFieldType.input,
                        validateCompose: isNIUMPayment(),
                        validate: isNIUMPayment()
                            ? getPurposeValidation(currency)
                            : required
                    },
                    {
                        title: i18.Labels.PaymentUniqueCode,
                        name: 'Payment.CustomersCode',
                        component: FormFieldType.input,
                        className: currency !== EUR || isTarget2Payment() ? 'd-none' : ''
                    },
                    currency === USD
                        ? {
                            title: i18.Labels.PurposeCode,
                            name: 'Payment.PurposeCode',
                            component: FormFieldType.selectSearch,
                            className: isNIUMPayment() && currency === USD ? '' : 'd-none',
                            options: paymentPurposeCode?.map(el => {
                                return {
                                    PurposeDescription: `${el.PurposeCode}: ${el.PurposeDescription}`,
                                    PurposeCode: el.PurposeCode
                                }
                            }),
                            optionsLabel: 'PurposeDescription',
                            optionsValue: 'PurposeCode'
                        }
                        : {
                            title: i18.Labels.PurposeCode,
                            name: 'Payment.PurposeCode',
                            component: FormFieldType.input,
                            className:
                                isNIUMPayment() &&
                                (currency === CNY || currency === INR || currency === KRW)
                                    ? ''
                                    : 'd-none',
                            validateCompose:
                                isNIUMPayment() &&
                                (currency === CNY || currency === INR || currency === KRW),
                            validate:
                                isNIUMPayment() &&
                                (currency === CNY || currency === INR || currency === KRW)
                                    ? [required]
                                    : null
                        },
                    {
                        title: i18.Labels.PaymentType,
                        name: 'Payment.PaymentSystem',
                        component: FormFieldType.radio,
                        className: appEnvSME ? undefined : 'd-none',
                        options:
                            paymentSystemType.map((item) => ({
                                ...item,
                                disabled: (item.value === 'INST' || isInternalPayment) && !SepaInst,
                            })),
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PaymentTypeSepa,
                        inline: true,
                        visible: !appEnvSME || isTarget2Payment(),
                        defaultValue: paymentSystemType[0].value,
                        notify: SepaInst ? i18.NotificationTitles.PaymentTypeCanChangeToInst : null,
                        validate: required,
                        classNameField:  'col-10 col-md-7 mt-3 mt-md-0 small-height' ,
                        classNameTitle: 'col-12 col-md-5 pl-4 pr-0'
                    },
                    {
                        name: 'Payment.IsAdditionalPaymentInformationSelected',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.AdditionalPaymentInformation,
                        inline: true,
                        visible: appEnvSME
                    },
                    /* {
                      title: i18.Labels.RemitPurposeCode,
                      name: 'Payment.RemitPurposeCode',
                      component: FormFieldType.input,
                      validate: isNIUMPayment() && currency === USD ? required : null,
                      className: isNIUMPayment() && currency === USD ? null : 'd-none'
                    }, */
                    {
                        title: i18.Labels.BeneficiaryCountryCode,
                        name: 'Payment.BeneficiaryCountryCode',
                        component: FormFieldType.selectSearch,
                        className: isNIUMPayment() || isTarget2Payment() ? '' : 'd-none',
                        options: classifikatorsMap(CISO),
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: isNIUMPayment() || isTarget2Payment() ? required : null
                    },
                    /* {
                      title: i18.Labels.CountryName,
                      name: 'Payment.CountryName',
                      component: FormFieldType.input,
                      validate: isNIUMPayment() ? required : null,
                      className: isNIUMPayment() ? null : 'd-none'
                    }, */
                    {
                        title: i18.Labels.BeneficiaryState,
                        name: 'Payment.BeneficiaryState',
                        component: FormFieldType.input,
                        validateCompose:
                            isNIUMPayment() &&
                            (currency === USD || currency === CAD || currency === AUD),
                        validate:
                            isNIUMPayment() &&
                            (currency === USD || currency === CAD || currency === AUD)
                                ? [required, checkMaxLength(50)]
                                : null,
                        className:
                            isNIUMPayment() &&
                            (currency === USD || currency === CAD || currency === AUD)
                                ? null
                                : 'd-none'
                    },
                    {
                        title: i18.Labels.BeneficiaryCity,
                        name: 'Payment.BeneficiaryCity',
                        validateCompose: isNIUMPayment() || isTarget2Payment(),
                        component: FormFieldType.input,
                        validate:
                            isNIUMPayment() || isTarget2Payment()
                                ? getBeneficiaryCityValidation(currency, isTarget2Payment())
                                : null,
                        className: isNIUMPayment() || isTarget2Payment() ? null : 'd-none'
                    },
                    {
                        title: i18.Labels.BeneficiaryAddress,
                        name: 'Payment.BeneficiaryAddress',
                        component: FormFieldType.input,
                        validateCompose: isNIUMPayment() || isTarget2Payment(),
                        validate:
                            isNIUMPayment() || isTarget2Payment()
                                ? getBeneficiaryAddressValidation(currency, isTarget2Payment())
                                : null,
                        className: isNIUMPayment() || isTarget2Payment() ? null : 'd-none'
                    },
                    {
                        title: i18.Labels.BeneficiaryPostcode,
                        name: 'Payment.BeneficiaryPostCode',
                        component: FormFieldType.input,
                        className: isNIUMPayment() || isTarget2Payment() ? null : 'd-none',
                        validateCompose: isNIUMPayment() || isTarget2Payment(),
                        validate:
                            isNIUMPayment() || isTarget2Payment()
                                ? getBeneficiaryPostCodeValidation(currency, isTarget2Payment())
                                : null
                    },
                    {
                        title: i18.Labels.BeneficiaryAccountType,
                        name: 'Payment.BeneficiaryAccountType',
                        component: FormFieldType.select,
                        options: [
                            {
                                label: 'Company',
                                value: 'Company'
                            },
                            {
                                label: 'Individual',
                                value: 'Individual'
                            }
                        ],
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: isNIUMPayment() ? required : null,
                        translate: translationGroups.AccountType,
                        className: isNIUMPayment() ? null : 'd-none'
                    },
                    {
                        title: i18.Labels.BeneficiaryIdentificationType,
                        name: 'Payment.BeneficiaryIdentificationType',
                        component: FormFieldType.selectSearch,
                        className: isNIUMPayment() ? '' : 'd-none',
                        options: beneficiaryIdentificationCodeTypes?.map(el => {
                            return {label: el, value: el}
                        }),
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: currency === CNY ? required : null
                    },
                    {
                        title: i18.Labels.BeneficiaryIdentificationValue,
                        name: 'Payment.BeneficiaryIdentificationValue',
                        component: FormFieldType.input,
                        validateCompose: isNIUMPayment(),
                        validate: isNIUMPayment()
                            ? getBeneficiaryIdentificationValueValidation(currency)
                            : null,
                        className: isNIUMPayment() ? null : 'd-none'
                    },
                    {
                        title: i18.Labels.BeneficiaryContactNumber,
                        name: 'Payment.BeneficiaryContactNumber',
                        component: FormFieldType.input,
                        className:
                            appEnvSME &&
                            isNIUMPayment() &&
                            (currency === KRW || currency === CNY)
                                ? null
                                : 'd-none',
                        validateCompose: currency === KRW || currency === CNY,
                        validate:
                            appEnvSME &&
                            isNIUMPayment() &&
                            (currency === KRW || currency === CNY)
                                ? getBeneficiaryContactNumberValidation(currency)
                                : null
                    },
                    {
                        title: i18.Labels.BeneficiaryEmail,
                        name: 'Payment.BeneficiaryEmail',
                        component: FormFieldType.input,
                        className:
                            appEnvSME && isNIUMPayment() && currency === KRW
                                ? null
                                : 'd-none',
                        validateCompose: currency === KRW,
                        validate:
                            appEnvSME && isNIUMPayment() && currency === KRW
                                ? [required, checkEmail]
                                : null
                    },
                    {
                        title: i18.Labels.BankName,
                        name: 'Payment.BankName',
                        component: FormFieldType.input,
                        className:
                            currenciesWithoutIBANCheck.includes(currency) ||
                            isTarget2Payment()
                                ? ''
                                : 'd-none',
                        /* visible: isTarget2Payment()
                          ? true
                          : !currenciesWithoutIBANCheck.includes(currency),
                       */ defaultValue: BankName,
                        validateCompose: isNIUMPayment() || isTarget2Payment(),
                        validate:
                            isNIUMPayment() || isTarget2Payment()
                                ? getBeneficiaryBankNameValidation(currency, isTarget2Payment())
                                : null
                    },
                    {
                        title: i18.Labels.RecipientBankCountry,
                        name: 'Payment.RecipientBankCountry',
                        component: FormFieldType.selectSearch,
                        className: isTarget2Payment() ? '' : 'd-none',
                        options: classifikatorsMap(CISO),
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: isTarget2Payment() ? required : null
                    },
                    {
                        title: i18.Labels.BIC,
                        name: 'Payment.BIC',
                        component: FormFieldType.input,
                        className: isNIUMPayment() || isTarget2Payment() ? '' : 'd-none',
                        validateCompose: isNIUMPayment() || isTarget2Payment(),
                        validate:
                            isNIUMPayment() || isTarget2Payment()
                                ? getBeneficiaryBankCodeValidation(currency, isTarget2Payment())
                                : null
                    },
                    {
                        title: i18.Labels.RoutingCode,
                        name: 'Payment.RoutingCode',
                        component: FormFieldType.input,
                        defaultValue: BIC,
                        className:
                            appEnvSME &&
                            (isNIUMPayment() || isTarget2Payment()) &&
                            currency !== KRW
                                ? null
                                : 'd-none',
                        disabled: true
                    },
                    {
                        title: i18.Labels.CorrespondentBankBic,
                        name: 'Payment.CorrespondentBankBic',
                        component: FormFieldType.input,
                        className: isTarget2Payment() ? '' : 'd-none',
                        validateCompose: isTarget2Payment(),
                        validate: isTarget2Payment()
                            ? getBeneficiaryBankCodeValidation(currency, isTarget2Payment())
                            : null
                    },
                    {
                        name: 'ConfirmTarget2',
                        component: FormFieldType.checkbox,
                        html: `<div>
                <div>
                  <p class="eb_fs_15 mb-0">
                    ${trb(i18.Labels.AgreeWithTarget2PaymentRates)}
                  </p>
                </div>
              </div>`,
                        validate: isTarget2Payment() ? requiredAndApprove : null,
                        className: isTarget2Payment()
                            ? 'col-12 col-md-12 pt-0 pl-0 mb-0'
                            : 'd-none',
                        classNameField: isTarget2Payment()
                            ? 'col-12 text-left font-size-12'
                            : null,
                        classNameError: 'text-left pl-3'
                    }
                    /* {
                      title: i18.Labels.RoutingCodeValue,
                      name: 'Payment.RoutingCodeValue',
                      component: FormFieldType.input,
                      className: appEnvSME && isNIUMPayment() ? null : 'd-none',
                      maxLength: 40
                    } */
                    // new fields ---
                ]
            },
            {
                className:
                    !appEnvSME || isNIUMPayment() || isTarget2Payment()
                        ? 'd-none'
                        : 'col-12 col-md-4 offset-md-6 row',
                fields: [
                    {
                        name: 'Payment.IsAdditionalPaymentInformationSelected',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.AdditionalPaymentInformation,
                        inline: true,
                        className: 'col-12 col-md-12 text-left px-2 mx-2 px-md-0 mx-md-0',
                        classNameField: 'text-left eb_fs_12 text-gray-basic',
                        customChecks: appEnvSME
                    }
                ]
            },
            {
                title: i18.PaymentParts.PayerInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-block d-md-none' : 'col-12',
                fields: [
                    {
                        title: i18.Labels.PayerType,
                        name: 'Payment.PayerType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.PayerIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.PayerType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.PayerType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.PayerIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.PayerType'
                    },
                    {
                        title: i18.Labels.PayerAddress,
                        name: 'Payment.PayerAddress',
                        component: FormFieldType.input,
                        visible: 'Payment.PayerType',
                        validate: checkMaxLength(140)
                    }
                ]
            },
            {
                title: i18.PaymentParts.InitialPayerInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-block d-md-none' : 'col-12',
                fields: [
                    {
                        title: i18.Labels.InitPayerType,
                        name: 'Payment.InitPayerType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.InitTitle,
                        name: 'Payment.InitPayerName',
                        component: FormFieldType.input,
                        visible: 'Payment.InitPayerType',
                        validate: checkMaxLength(70)
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.InitPayerIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.InitPayerType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.InitPayerType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.InitPayerIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.InitPayerType'
                    }
                ]
            },
            {
                title: i18.PaymentParts.PayeeInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-block d-md-none' : 'col-12',
                fields: [
                    {
                        title: i18.Labels.PayeeType,
                        name: 'Payment.PayeeType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.PayeeIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.PayeeType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.PayeeType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.PayeeIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.PayeeType'
                    },
                    {
                        title: i18.Labels.PayeeAddress,
                        name: 'Payment.PayeeAddress',
                        component: FormFieldType.input,
                        visible: 'Payment.PayeeType',
                        validate: checkMaxLength(140)
                    }
                ]
            },
            {
                title: i18.PaymentParts.InitialPayeeInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-block d-md-none' : 'col-12',
                fields: [
                    {
                        title: i18.Labels.FinalPayeeType,
                        name: 'Payment.FinalPayeeType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.FinalPayeeName,
                        name: 'Payment.FinalPayeeName',
                        component: FormFieldType.input,
                        visible: 'Payment.FinalPayeeType',
                        validate: checkMaxLength(70)
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.FinalPayeeIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.FinalPayeeType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.FinalPayeeType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.FinalPayeeIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.FinalPayeeType'
                    }
                ]
            },
            {
                title: i18.Labels.PaymentType,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 d-block d-md-none' : 'col-12',
                fields: [
                    {
                        title: i18.Labels.PaymentType,
                        name: 'Payment.SEPAPaymentType',
                        component: FormFieldType.select,
                        options: props.SEPAPaymentTypes.filter(
                            item => item.Code !== 'UBIL'
                        ),
                        translate: translationGroups.PaymentTypes,
                        optionsLabel: 'Code',
                        optionsValue: 'Code',
                        inline: true
                    }
                ]
            },

            {
                className: appEnvSME
                    ? 'col-12 col-md-6 offset-md-6 d-md-none'
                    : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PaymentTemplateName,
                        name: 'Payment.TemplateName',
                        component: FormFieldType.input,
                        visible: 'Payment.SaveTemplate',
                        validate: required
                    }
                    // {
                    //   name: 'Payment.SaveTemplate',
                    //   component: FormFieldType.checkbox,
                    //   translate: i18.Labels.SavePaymentTemplate,
                    //   inline: true,
                    //   visible: appEnvSME
                    // },
                ]
            },
            {
                className:
                    !appEnvSME || isNIUMPayment()
                        ? 'd-none'
                        : `col-7 col-md-2 pr-md-0 row mb-0 custom ${
                            isTarget2Payment() ? 'col-12 col-md-12' : ''
                        }`,
                fields: [
                    {
                        name: 'Payment.SaveTemplate',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.SavePaymentTemplate,
                        inline: true,
                        className:
                            'text-left text-md-right col-12 col-md-12 px-2 mx-2 px-md-0 mx-md-0 eb_fs_12 text-gray-basic',
                        classNameField: 'col-12 px-0 mx-0'
                    }
                ]
            },
            {
                className: appEnvSME
                    ? 'col-12 col-md-6 offset-md-6 d-none d-md-block'
                    : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PaymentTemplateName,
                        name: 'Payment.TemplateName',
                        component: FormFieldType.input,
                        visible: 'Payment.SaveTemplate',
                        validate: required
                    }
                    // {
                    //   name: 'Payment.SaveTemplate',
                    //   component: FormFieldType.checkbox,
                    //   translate: i18.Labels.SavePaymentTemplate,
                    //   inline: true,
                    //   visible: appEnvSME
                    // },
                ]
            },
            {
                title: i18.PaymentParts.PayerInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-none d-md-block' : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PayerType,
                        name: 'Payment.PayerType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.PayerIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.PayerType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.PayerType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.PayerIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.PayerType'
                    },
                    {
                        title: i18.Labels.PayerAddress,
                        name: 'Payment.PayerAddress',
                        component: FormFieldType.input,
                        visible: 'Payment.PayerType',
                        validate: checkMaxLength(140)
                    }
                ]
            },
            {
                title: i18.PaymentParts.InitialPayerInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-none d-md-block' : 'd-none',
                fields: [
                    {
                        title: i18.Labels.InitPayerType,
                        name: 'Payment.InitPayerType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.InitTitle,
                        name: 'Payment.InitPayerName',
                        component: FormFieldType.input,
                        visible: 'Payment.InitPayerType',
                        validate: checkMaxLength(70)
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.InitPayerIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.InitPayerType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.InitPayerType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.InitPayerIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.InitPayerType'
                    }
                ]
            },
            {
                title: i18.PaymentParts.PayeeInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-none d-md-block' : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PayeeType,
                        name: 'Payment.PayeeType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.PayeeIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.PayeeType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.PayeeType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.PayeeIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.PayeeType'
                    },
                    {
                        title: i18.Labels.PayeeAddress,
                        name: 'Payment.PayeeAddress',
                        component: FormFieldType.input,
                        visible: 'Payment.PayeeType',
                        validate: checkMaxLength(140)
                    }
                ]
            },
            {
                title: i18.PaymentParts.InitialPayeeInformation,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 col-md-6 d-none d-md-block' : 'd-none',
                fields: [
                    {
                        title: i18.Labels.FinalPayeeType,
                        name: 'Payment.FinalPayeeType',
                        component: FormFieldType.radio,
                        options: payerPayeeType,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        translate: translationGroups.PayerPayeeTypes
                    },
                    {
                        title: i18.Labels.FinalPayeeName,
                        name: 'Payment.FinalPayeeName',
                        component: FormFieldType.input,
                        visible: 'Payment.FinalPayeeType',
                        validate: checkMaxLength(70)
                    },
                    {
                        title: i18.Labels.IdentificationType,
                        name: 'Payment.FinalPayeeIdentTypeCode',
                        component: FormFieldType.select,
                        options: props.PayPersonTypes,
                        optionsFunction: filterFormOptions,
                        optionsFunctionParams: 'Payment.FinalPayeeType',
                        optionsLabel: 'Text',
                        optionsValue: 'Code',
                        visible: 'Payment.FinalPayeeType'
                    },
                    {
                        title: i18.Labels.IdentificationCode,
                        name: 'Payment.FinalPayeeIdentTypeValue',
                        component: FormFieldType.input,
                        visible: 'Payment.FinalPayeeType'
                    }
                ]
            },
            {
                title: i18.Labels.PaymentType,
                visible: 'Payment.IsAdditionalPaymentInformationSelected',
                className: appEnvSME ? 'col-12 d-none d-md-block' : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PaymentType,
                        name: 'Payment.SEPAPaymentType',
                        component: FormFieldType.select,
                        options: props.SEPAPaymentTypes.filter(
                            item => item.Code !== 'UBIL'
                        ),
                        translate: translationGroups.PaymentTypes,
                        optionsLabel: 'Code',
                        optionsValue: 'Code',
                        inline: true
                    }
                ]
            },
            {
                className: !appEnvSME ? null : 'd-none',
                fields: [
                    {
                        title: i18.Labels.PaymentTemplateName,
                        name: 'Payment.TemplateName',
                        component: FormFieldType.input,
                        visible: 'Payment.SaveTemplate',
                        validate: required
                    },
                    {
                        name: 'Payment.SaveTemplate',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.SavePaymentTemplate,
                        inline: true,
                        visible: appEnvSME
                    }
                ]
            }
        ]
    }
}

NewPaymentForm.propTypes = {
    data: PropTypes.object.isRequired
}
