import React, {useEffect, useState} from 'react'
import {Button, Collapse, Modal, Switch} from 'antd'
import {i18, trb} from '@utilities'
import moment from 'moment'

const {Panel} = Collapse;

const YES = 'yes'
const NO = 'no'

const ADVERTISEMENT = 'cookielawinfo-checkbox-advertisment'
const ANALYTICS = 'cookielawinfo-checkbox-analytics'
const FUNCTIONAL = 'cookielawinfo-checkbox-functional'
const NECESSARY = 'cookielawinfo-checkbox-necessary'
const OTHERS = 'cookielawinfo-checkbox-others'
const PERFORMANCE = 'cookielawinfo-checkbox-performance'
const VIEWED_POLICY = 'viewed_cookie_policy'

const initialCookiesMap = {
    [ADVERTISEMENT]: YES,
    [ANALYTICS]: YES,
    [FUNCTIONAL]: YES,
    [NECESSARY]: YES,
    [OTHERS]: YES,
    [PERFORMANCE]: YES,
    [PERFORMANCE]: YES,
    [VIEWED_POLICY]: YES,
}


export const CookiePolicy = () => {
    const [modalVisible, setModalVisible] = useState(false)
    const [cookieUpdated, setCookieUpdated] = useState(false)
    const [policyAvailable, setPolicyAvailable] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [cookieMap, setCookieMap] = useState(initialCookiesMap)
    const checkIfAllCookiesSet = () => {
        const cookies = document.cookie?.split('; ') ?? []
        let allSet = true;
        Object.keys(initialCookiesMap).forEach((key) => {
            if (-1 === cookies.findIndex((cookie) => cookie.startsWith(key))) {
                allSet = false;
            }
        })
        return allSet;
    }

    useEffect(() => {
        setPolicyAvailable(checkIfAllCookiesSet())
    }, [cookieUpdated])

    const handleSave = () => {
        const exp = moment().add(1, 'month').format('dd, DD MMM YYYY HH:mm:ss UTC')
        Object.keys(cookieMap).forEach((key) => {
            document.cookie = `${key}=${cookieMap[key]}; expires=${exp}; secure`
        });

        setCookieUpdated(!cookieUpdated)
    }

    const handleClickPopUpOpen = () => {
        setModalVisible(true);
        const exp = moment().add(1, 'month').format('dd, DD MMM YYYY HH:mm:ss UTC')
        document.cookie = `${VIEWED_POLICY}=${cookieMap[VIEWED_POLICY]}; expires=${exp}; secure`
    }

    const handleClickAgreeModal = () => {
        handleSave();
        setModalVisible(false)
        setCookieUpdated(!cookieUpdated)
    }

    const handleCloseModal = () => {
        setModalVisible(false)
    }

    return (
        <>
            <div style={policyAvailable ? {display: 'none'} : {}} className='cookiePolicy'>
                <div style={{paddingLeft: 20}} className='container flex_block'>
                    <div className='text' dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesText)}} ></div>
                    <div className='buttons'>
                        <a onClick={handleClickPopUpOpen} className='cookiePolicy__agree btn open_cookie_type_popup'>
                            {trb(i18.Buttons.ChangeSettings)}
                        </a>
                        <a onClick={handleSave} className='cookiePolicy__agree btn btn_submit'>
                            {trb(i18.Buttons.AgreeWithAll)}
                        </a>
                    </div>
                </div>
            </div>
            {modalVisible && <Modal
                width={800}
                zIndex={999999}
                centered
                style={{top: 0}}
                bodyStyle={{paddingTop: 0, paddingBottom: 0}}
                open={modalVisible}
                title={<div className='main_heading'>{trb(i18.Labels.CookiesSettings)}</div>}
                closable={true}
                onCancel={() => handleCloseModal()}
                className='cookies_modal'
                footer={[
                    <div className="popup_cookies text-right">
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleClickAgreeModal}>
                            <span>{trb(i18.Buttons.Agree)}</span>
                        </Button>
                    </div>
                ]}
            >
                <div className='container flex_block'>
                    <div className='page_text'>
                        {showMore ? trb(i18.Labels.PrivacyOverviewText) : trb(i18.Labels.PrivacyOverviewText).substring(0, trb(i18.Labels.PrivacyOverviewText).length / 3) + '...'}
                        <a className="d-block mt-3 mb-3"
                           onClick={() => setShowMore(!showMore)}> {showMore ? trb(i18.Labels.ShowLess) : trb(i18.Labels.ShowMore)}</a>
                    </div>
                    <Collapse collapsible={'header'}>
                        <Panel header={trb(i18.Labels.CookiesNecessary)}
                               extra={<Switch defaultChecked disabled={true}/>}
                               key={1} >
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesNecessaryText)}} />
                        </Panel>
                        <Panel header={trb(i18.Labels.CookiesFunctional)}
                               extra={<Switch defaultChecked checked={cookieMap[FUNCTIONAL] === YES}
                                              onChange={(isOn) => setCookieMap({
                                                  ...cookieMap,
                                                  [FUNCTIONAL]: isOn ? YES : NO
                                              })}/>}
                               key={2}>
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesFunctionalText)}} />
                        </Panel>
                        <Panel header={trb(i18.Labels.CookiesPerformance)}
                               extra={<Switch defaultChecked checked={cookieMap[PERFORMANCE] === YES}
                                              onChange={(isOn) => setCookieMap({
                                                  ...cookieMap,
                                                  [PERFORMANCE]: isOn ? YES : NO
                                              })}/>}
                               key={3}>
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesPerformanceText)}} />
                        </Panel>
                        <Panel header={trb(i18.Labels.CookiesAnalytics)}
                               extra={<Switch defaultChecked checked={cookieMap[ANALYTICS] === YES}
                                              onChange={(isOn) => setCookieMap({
                                                  ...cookieMap,
                                                  [ANALYTICS]: isOn ? YES : NO
                                              })}/>}
                               key={4}>
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesAnalyticsText)}} />
                        </Panel>
                        <Panel header={trb(i18.Labels.CookiesAdvertisement)}
                               extra={<Switch defaultChecked checked={cookieMap[ADVERTISEMENT] === YES}
                                              onChange={(isOn) => setCookieMap({
                                                  ...cookieMap,
                                                  [ADVERTISEMENT]: isOn ? YES : NO
                                              })}/>}
                               key={5}>
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesAdvertisementText)}} />
                        </Panel>
                        <Panel header={trb(i18.Labels.CookiesOthers)}
                               extra={<Switch defaultChecked ochecked={cookieMap[OTHERS] === YES}
                                              onChange={(isOn) => setCookieMap({
                                                  ...cookieMap,
                                                  [OTHERS]: isOn ? YES : NO
                                              })}/>}
                               key={6}>
                            <span dangerouslySetInnerHTML={{__html: trb(i18.Labels.CookiesOthersText)}} />
                        </Panel>
                    </Collapse>
                </div>
            </Modal>
            }
        </>
    )
}
