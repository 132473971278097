import {DepositServices} from './services';
import {DepositConstants} from './constants';

export const DepositActions = {
    postDepositTermination,
    postDepositTerminationConfirm,
    cancelDepositTerminationConfirm,
    postDepositOffer,
    postConfirmDeposit,
    clearPostConfirmDepositData,
    postDepositDocument
};

function postDepositTermination(requestData) {
    return dispatch => {
        dispatch(request());

        return DepositServices.postDepositTermination(requestData)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_REQUEST, requestData: requestData};
    }

    function success(data) {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_SUCCESS, payload: data, requestData: requestData}
    }

    function failure(error) {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_ERROR, error}
    }
}

function postDepositTerminationConfirm(data) {
    return dispatch => {
        dispatch(request());

        return DepositServices.postDepositTerminationConfirm(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_CONFIRM_REQUEST};
    }

    function success(data) {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_CONFIRM_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DepositConstants.POST_DEPOSIT_TERMINATION_CONFIRM_ERROR, error}
    }
}
function cancelDepositTerminationConfirm() {
    return dispatch => {
        dispatch({type: DepositConstants.POST_DEPOSIT_TERMINATION_CONFIRM_CANCEL});
    }
}

function postDepositOffer(data) {
    return dispatch => {
        dispatch(request());

        return DepositServices.postDepositOffer(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DepositConstants.POST_DEPOSIT_OFFER_REQUEST};
    }

    function success(data) {
        return {type: DepositConstants.POST_DEPOSIT_OFFER_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DepositConstants.POST_DEPOSIT_OFFER_ERROR, error}
    }
}

function postConfirmDeposit(data) {
    return dispatch => {
        dispatch(request());

        return DepositServices.postConfirmDeposit(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DepositConstants.POST_CONFIRM_DEPOSIT_REQUEST};
    }

    function success(data) {
        return {type: DepositConstants.POST_CONFIRM_DEPOSIT_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DepositConstants.POST_CONFIRM_DEPOSIT_ERROR, error}
    }
}

function clearPostConfirmDepositData() {
    return dispatch => {
        dispatch({type: DepositConstants.POST_CONFIRM_DEPOSIT_CLEAR_DATA});
    }
}


function postDepositDocument(data) {
    return dispatch => {
        dispatch(request());
        
        return DepositServices.postDepositDocument(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DepositConstants.POST_DEPOSIT_DOCUMENT_REQUEST};
    }

    function success(data) {
        return {type: DepositConstants.POST_DEPOSIT_DOCUMENT_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DepositConstants.POST_DEPOSIT_DOCUMENT_ERROR, error}
    }
}