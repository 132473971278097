import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";
import {
    getToken,
    IFRAME_USER,
    iframeGet,
    JWEActions,
    requestEBank,
    requestEBankFile,
    requestEBankJSON,
    UACActions
} from "@redux";
import {checkDevEnv, Logout} from "@utilities";
import {useDispatch, useSelector} from "react-redux";


export const RefreshToken = () => {

    const dispatch = useDispatch();

    const [intervalId, setIntervalID] = useState(-1);

    const {JWEReducer, IframeStorageReducer, UACReducers} = useSelector(state => state)
    const {SMSAR} = UACReducers


    const ref = useRef(null)
    useImperativeHandle(ref, () => ({
        onTimeoutStorageCheck: () => storageCheck(),
        onTimeoutCheckActivity: () => checkToken(),
    }))

    const refreshToken = async ({RefreshToken}) => {
        try {
            await dispatch(UACActions.getRFT(RefreshToken))
        } catch (error) {
            clearInterval(intervalId)
            await Logout()
        }
    }

    const checkToken = async () => {
        const user = IframeStorageReducer?.user

        if (user === null) {
            return null
        }

        const Bearer = user?.Bearer

        if (!Bearer) {
            return
        }
        const BearerJson = jwtDecode(Bearer)
        const {exp, UserCode} = BearerJson // retrieve expiration timestamp
        const dateExp = new Date(exp * 1000) // calculate current timestamp
        const lastTokenTime = dateExp.getTime() - new Date().getTime() // calculate time left delta
        Sentry.setUser({username: UserCode})
        if (lastTokenTime <= process.env.REACT_APP_REFRESH_TOKEN_LEFT_TIME) { // if remaining time delta is less than REACT_APP_REFRESH_TOKEN_LEFT_TIME, refresh token
            refreshToken(user)
        }
    }

    const storageCheck = () => {
        if (!IframeStorageReducer?.user) {
            dispatch(iframeGet({key: IFRAME_USER}))
            setTimeout(() => ref?.current?.onTimeoutStorageCheck(), 500)
        }
        const {user} = IframeStorageReducer

        const token = getToken(user)

        if (token) {
            if (requestEBank) {
                requestEBank.defaults.headers.common.Authorization = token
                requestEBankJSON.defaults.headers.common.Authorization = token
                requestEBankFile.defaults.headers.common.Authorization = token
            }

            if (!JWEReducer.loading && user.Jwe && !JWEReducer.jweVerified && SMSAR?.Jwe !== user.Jwe && JWEReducer.isRemote === null) {
                if (checkDevEnv) {
                    const data = {
                        user: user,
                        JWEReducer: JWEReducer,
                        SMSAR: SMSAR
                    }

                    Sentry.captureMessage('JWEActions.postVerifyJWE', data)
                }

                dispatch(JWEActions.postVerifyJWE(user))
            }
        }
    }

    const handleVisibilityChange = (event) => {
        if (document.visibilityState === 'visible') {
            checkToken();
        }
    }

    useEffect(() => {
        setIntervalID(setInterval(
            () => ref?.current?.onTimeoutCheckActivity(),
            process.env.REACT_APP_CHECK_TOKEN_TIME
        ))

        storageCheck();

        document.addEventListener('visibilitychange', handleVisibilityChange)


        return () => {
            clearInterval(intervalId)
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    return (<></>)
}