import React, {memo, useCallback, useEffect, useState} from 'react';
import {Menu} from 'antd';
import {CustomIcon, getWindowSize, i18, trb} from '@utilities';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {routerPath} from "@config";

const {SubMenu, Item} = Menu;

export const PartMenu = memo((props) => {
    const {menuCollapsed, contentMenu, contentMenuPath, toggle, toggleMenu, media} = props
    const {mobileCheck} = getWindowSize()

    const [current, setCurrent] = useState(useLocation().pathname);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [mode, setMode] = useState("inline")

    useEffect(() => {
        const newMode = !mobileCheck ? "inline" : menuCollapsed ? "inline" : "horizontal"
        if (newMode !== mode) {
            setMode(newMode)
        }
    }, [mobileCheck, menuCollapsed])

    const location = useLocation();
    const navigate = useNavigate();
    const isBioFace = useSelector(state => state?.UACReducers?.ATR?.AuthType?.toUpperCase() === 'BIOFACE');

    useEffect(() => {
        saveSelectedLinks();
    }, [])

    useEffect(() => {
        if (current !== location.pathname) {
            saveSelectedLinks();
        }
    }, [location.pathname]);

    const saveSelectedLinks = useCallback(() => {
        setCurrent(location.pathname);
        const pathComponents = location.pathname.split('/', 3);
        if (pathComponents.length === 3) {
            setSelectedLinks([location.pathname, pathComponents.slice(0, 2).join('/')]);
        } else {
            setSelectedLinks([location.pathname]);
        }
    }, [location.pathname]);

    const clickMenu = (e) => {
        if (media) {
            toggle();
        }

        if (location.pathname.slice(0, 9) !== e.key) {
            navigate(e.key);
        }

        if (mobileCheck && menuCollapsed) {
            toggleMenu();
        }
    };

    const advancedMenu = [
        {
            title: i18.Menu.Summary,
            icon: 'home',
            type: 'custom',
            path: routerPath.index,
            hidden: mobileCheck,
        }, {
            title: i18.Menu.Payments,
            icon: 'payment',
            type: 'custom',
            path: routerPath.payments,
            submenu: [
                {
                    title: i18.PaymentTypes.OwnAccounts,
                    path: routerPath.payments + '/own-accounts'
                }, {
                    title: i18.PaymentTypes.NewPayment,
                    path: routerPath.payments + '/new-payment'
                }, {
                    title: i18.PaymentTypes.FeePayment,
                    path: routerPath.payments + '/fee-payment'
                }, {
                    title: i18.ContentMenu.NoSignetPayment,
                    path: routerPath.unsigned
                }, /* TODO: commented out group payments
                {
                    title: i18.ContentMenu.GroupPayment,
                    path: routerPath.paymentGroup
                }, {
                    title: i18.ContentMenu.PaymentGroups,
                    path: routerPath.paymentGroups
                },*/ {
                    title: i18.ContentMenu.PaymentTemplates,
                    path: routerPath.paymentTemplates
                }, {
                    title: i18.ContentMenu.PaymentUploading,
                    path: routerPath.paymentUploading
                }
            ]
        },
        {
            title: i18.Menu.Deposit,
            icon: 'deposit',
            type: 'custom',
            path: routerPath.deposits
        },
        {
            title: i18.Menu.Invoice,
            icon: 'invoice',
            type: 'custom',
            path: routerPath.invoice
        }, {
            title: i18.Menu.Operations,
            icon: 'operations',
            type: 'custom',
            path: routerPath.operations
        }, {
            title: i18.Menu.Settings,
            icon: 'settings',
            type: 'custom',
            path: routerPath.settings,
            submenu: [
                {
                    title: i18.ContentMenu.ChangePassword,
                    path: routerPath.settings + '/change-password',
                }, {
                    title: i18.ContentMenu.BlockCard,
                    path: routerPath.settings + '/block-card'
                }, {
                    title: i18.ContentMenu.AccountsLimits,
                    path: routerPath.settings + '/accounts-limits'
                }, {
                    title: i18.ContentMenu.AccountsManagement,
                    path: routerPath.settings + '/accounts-management'
                }
            ]
        }
    ];

    if (isBioFace) {
        const index = advancedMenu.findIndex((item) => item.title === i18.Menu.Deposit);
        if (index !== -1) {
            advancedMenu.splice(index, 1);
        }
    }


    const Icon = (props) => <CustomIcon icon={props.item.icon} type={props.item.type}/>;

    return (
        <div className="eb_menu">
            <Menu
                onClick={(e) => clickMenu(e)}
                theme='light'
                selectedKeys={selectedLinks}
                openKeys={[contentMenuPath]}
                mode={mode}
                style={{position: 'relative'}}
                inlineIndent={mobileCheck ? 0 : 32}
                id="SidebarMenu"
            >
                {advancedMenu.filter(item => !item.hidden).map((menu, index) => {
                        return menu.path.indexOf(contentMenuPath) > -1 && !mobileCheck && menuCollapsed
                            ? <SubMenu
                                key={menu.path}
                                id={`SubMenuItem${menu.path?.replace(/\//ig, "")}`}
                                mode="vertical"
                                onTitleClick={e => clickMenu(e)}

                                title={<span style={{paddingLeft: 32, display: 'flex', alignItems: 'center'}}><Icon
                                    item={menu}/><span>{trb(menu.title)}</span></span>}>
                                {contentMenu.map((item, index) => item.path !== routerPath.settingsChangePassword ?
                                    <Menu.Item id={`SubMenu${menu.path?.replace(/\//ig, "")}MenuItem${item.path?.replace(/\//ig, "")}`} key={item.path} className="d-inline-block">
                                        {menuCollapsed ? trb(item.title) : <Icon item={item}/>}
                                    </Menu.Item>
                                    :
                                    null
                                )}
                            </SubMenu>
                            : <Menu.Item  id={`MenuItem${menu.path?.replace(/\//ig, "")}`} className={mobileCheck ? 'm-0 px-3 d-inline-block' : "d-inline-block"}
                                         key={menu.path}>
                                <Icon item={menu}/><span>{!mobileCheck || menuCollapsed ? trb(menu.title) : null}</span>
                            </Menu.Item>
                    }
                )}
            </Menu>
        </div>
    );
})
