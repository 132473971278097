import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {aPIPaths, methods, requestEBankAnonymousRoot} from "@redux";


const getRemoteVersion = createAsyncThunk(
    'version/getRemoteVersion',
    async (thunkAPI) => {
        try {
            const response = await requestEBankAnonymousRoot(aPIPaths.get_Version + '?t=' + new Date().getTime(), {
                method: methods.get,
            })
            return response.data
        } catch (error) {
            return error?.response?.data ?? error
        }
    }
)

const initialState = {
    version: undefined,
    isNew: false,
    loading: false,
    error: null,
}

const VersionSlice = createSlice({
    name: 'version',
    initialState,
    extraReducers: builder => {
        builder.addCase(getRemoteVersion.fulfilled, (state, {payload}) => {
            if(state?.version === payload?.version) {
                return;
            }
            return {...state, ...payload, loading: false, error: null, isNew: state?.version !== payload?.version};
        })


    }
})

// Create actions
const VersionActions = {
    getRemoteVersion,
}

// Extract the action creators object and the reducer
const {reducer} = VersionSlice
// Extract and export each action creator by name
// Export the reducer, either as a default or named export
export {reducer as VersionReducer, VersionActions}