import React, {Component} from 'react';
import {connect} from "react-redux";

import {CMCActions, DepositActions} from "@redux";
import {ContentLoader, ContentNoData, FormDecoratorSwitch} from "@components";
import {DepositFill} from "./DepositsFill";
import {checkJsonProp} from "@utilities";
import {DepositsTerms} from "./DepositsTerms";

class DepositsFillContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                ProductTypeID: 4,
                AutoResume: "WithInterest",
                Confirm: false,
                InitialAccountID: null,
                SubProductID: null,
                CurrencyID: null,
                Amount: null,
                Interest: 0
            },
            decorator: FormDecoratorSwitch({
                name: 'deposits',
                getCIR: this.getCIR,
                getPDR: this.getPDR
            })
        }
    }

    submit = async values => {
        const { dispatch, setFormValues } = this.props
        this.setState({ submitting: true })
        this.setState({ errors: null })
    
        const response = await dispatch(DepositActions.postDepositOffer(values))
    
        if (response?.payload?.Response?.IsOk) {
          if (setFormValues) {
            setFormValues(values)
          }
        } else {
          this.setState({ errors: [] })
        }
        this.setState({ submitting: false })
      }

    componentDidMount() {
        const {dispatch, sendCIPTR, formValues} = this.props;

        if (formValues) {
            this.setState({...formValues});
        }

        if (!sendCIPTR && this.state.data.ProductTypeID) {
            dispatch(CMCActions.postCIPTR({ProductTypeID: this.state.data.ProductTypeID}));
        }
    }

    getPDR = (values) => {
        const {dispatch} = this.props;

        if (values.SubProductID && values.SubProductID !== this.state.SubProductID) {
            if (values.SubProductID === 39 || values.SubProductID === 40) {
                values.AutoResume = 'WithoutInterest';
            }
            this.setState({data: values});
            dispatch(CMCActions.postPDR(values));
        }
    };

    getCIR = async (values) => {
        const {dispatch} = this.props;

        if (values.ProductTypeID === this.state.ProductTypeID
            && values.SubProductID === this.state.SubProductID
            && values.Period === this.state.Period
            && values.CurrencyID === this.state.CurrencyID
            && values.InitialAccountID === this.state.InitialAccountID) {
            return;
        }

        if (!values.ProductTypeID || !values.SubProductID || !values.Period || !values.CurrencyID || !values.InitialAccountID) {
            return;
        }

        this.setState({
            data: values
        });

        const response = await dispatch(CMCActions.postCIR(values));
        if (response.type === "POST_CMC_CIR_SUCCESS") {
            const { InterestText } = response.payload;
            if (InterestText) {
                return { InterestText }
            }
        }
        return null;
    };
    render() {
        const {CIPTR, sendCIPTR, PDR, CIR, depositOffer } = this.props;

        if (sendCIPTR) {
            return <ContentLoader/>;
        }

        if (!CIPTR) {
            return <ContentNoData/>;
        }
        let propsName = DepositsTerms.substring(DepositsTerms.indexOf('{') + 1, DepositsTerms.indexOf('}'));
        const DepositsTermsEdited = DepositsTerms.replace("{" + propsName + "}", checkJsonProp(this.props, propsName));

        return <DepositFill
            submit={this.submit}
            submitting={this.state.submitting}
            data={this.state.data}
            errors={this.state.errors}
            decorator={this.state.decorator}
            CIPTR={CIPTR}
            PDR={PDR}
            CIR={CIR}
            depositOffer={depositOffer}
            DepositsTerms={DepositsTermsEdited}
        />
    }
}

function mapStateToProps(state) {
    const {CIPTR, sendCIPTR, PDR, CIR} = state.CMCReducers;
    const { depositOffer } = state.DepositReducers;
    const {COMPANY} = state.AMCReducers;
    const {language} = state.languageReducer;
    const {translations} = state.translationReducer;
    return {
        CIPTR,
        sendCIPTR,
        PDR,
        CIR,
        COMPANY,
        depositOffer,
        language,
        translations
    };
}

const connectedDepositsFillContainer = connect(mapStateToProps)(DepositsFillContainer);
export {connectedDepositsFillContainer as DepositsFillContainer};
