import {appEnvSME} from '@utilities/appEnv';

const FormFieldInputWrapperClassesSME = (error, submitError, touched, inline, className) => {
    if (touched && (error || submitError)) {
        return ('form-field-item has-error' +
        (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
            ? ' col-12 col-md-7'
            : ' col-12 col-md-9'
        : ' col-12'));
    } else {
        return ('form-field-item' +
        (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
            ? ' col-12 col-md-7'
            : ' col-12 col-md-9'
        : ' col-12'));
    }
};

const FormFieldInputWrapperClassesAll = (error, submitError, touched, inline, className) => {
    if (touched && (error || submitError)) {
        return ('form-field-item has-error' +
        (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
            ? ' col-12 col-md-7'
            : ' col-12 col-md-6 mb-0 px-3 px-md-0'
        : ' col-12'));
    } else {
        return ('form-field-item' +
        (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
            ? ' col-12 col-md-7'
            : ' col-12 col-md-6 mb-0 px-3 px-md-0'
        : ' col-12'));
    }
};

export const FormFieldInputWrapperClasses = appEnvSME ? FormFieldInputWrapperClassesSME : FormFieldInputWrapperClassesAll;