import React from 'react';
import {LoginCountdown} from "../LoginCountdown";
import {i18, trb} from "../../../../Utilities";
import {Button} from "antd";

export const LoginFormMSignConfirm = (props) => {
    return <>
        <div className="form-group mb-4">
            {trb(i18.Labels.ControlCode)} {props.code}
        </div>
        <LoginCountdown
            seconds={props.seconds}
            secondsRemaining={props.secondsRemaining}
        />
        <div className="text-right">
            <Button
                type="primary"
                size="large"
                onClick={() => props.loginCancel(null)}
            >{trb(i18.Buttons.Cancel)}</Button>
        </div>
    </>;
};