import React from 'react'
import PropTypes from 'prop-types'
import { RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { checkJsonExist, checkJsonProp } from '../../../Utilities'

const ButtonGroup = Button.Group

class EBTableExpandRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false
    }

    this.toggleContent = this.toggleContent.bind(this)
  }

  toggleContent() {
    this.setState({ show: !this.state.show })
  }

  test(item3, item) {
    const value = (item3.prefix ? item3.prefix : '') + checkJsonProp(item, item3.name)
    if (item3.func) {
      return item3.func(value)
    } else {
      return value
    }
  }

  render() {
    const { mapData, item, expended, click, buttons } = this.props
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, { item })
    })
    //eb_table_row_clickable
    return (
      <div
        className={'row eb_table_row ' + (expended ? 'eb_table_row_expand ' : '') + (this.state.show ? 'active ' : '') + (click ? 'eb_table_row_clickable ' : '')}
        onClick={(e) => click && click(e, item)}
      >
        {expended && <Button
          type="link"
          className="text-click"
          onClick={this.toggleContent}
        >
          <RightOutlined className="eb_fs_10" />
        </Button>}
        {mapData.map((item2, index2) =>
          <div className={item2.headerClassName} key={index2}>
            {item2.values.map((item3, index3) =>
              checkJsonExist(item, item3.name) &&
              <div key={item3.name}
                   className={item3.className}>
                {this.test(item3, item)}
              </div>
            )}
          </div>
        )}
        {buttons && item.CanDelete &&
        <ButtonGroup>
          {buttons && buttons.map((itemButton, index) =>
            <Button
              key={index}
              onClick={(e) => itemButton.func && itemButton.func(e, item)}
              {...itemButton.props}
            />
          )}
        </ButtonGroup>
        }
        <section className="eb_table_row_content col-12 order-12 pl-0 pl-lg-3">
          {this.state.show && children}
        </section>
      </div>
    )
  }
}

EBTableExpandRow.propTypes = {
  expended: PropTypes.bool,
  mapData: PropTypes.arrayOf(PropTypes.shape({
    headerClassName: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      prefix: PropTypes.string,
      postfix: PropTypes.string,
      name: PropTypes.string.isRequired,
      className: PropTypes.string,
      type: PropTypes.oneOf(['date', 'string', 'number']),
      format: PropTypes.string
    }))
  }))
}

export { EBTableExpandRow }
