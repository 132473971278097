import React from 'react'
import {connect} from "react-redux";

import {FeeActions} from "../../../Redux";
import {FeeModule} from "../FormModules";

class FeePaymentSpy extends React.Component {
    componentDidMount() {
        const {dispatch, feeModuleId} = this.props;

        if (feeModuleId) {
            dispatch(FeeActions.getFeePayment(feeModuleId));
        }

        this.requestFeeModule = this.requestFeeModule.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.feeModuleId && prevProps.feeModuleId !== this.props.feeModuleId) {
            this.requestFeeModule(this.props.feeModuleId);
        } else if (prevProps.feeModuleId && !this.props.feeModuleId) {
            // this.props.form.reset({
            //     Payment: {
            //         FeeProvider: null,
            //         InitialAccountID: this.props.values.Payment.InitialAccountID
            //     }
            // });
            this.props.values.Payment = {
                FeeProvider: null,
                InitialAccountID: this.props.values.Payment.InitialAccountID
            };
        }
    }

    requestFeeModule = (feeModuleId) => {
        this.props.dispatch(FeeActions.getFeePayment(feeModuleId)).then(response=>{
            this.props.values.Payment = {
                ...this.props.values.Payment,
                FeeProvider: feeModuleId,
                InitialAccountID: this.props.values.Payment.InitialAccountID,
                BeneficiaryAccountNumber: response.payload.payeeAccount,
                PayeeBank: response.payload.payeeBank,
                Recipient: response.payload.providerTitle,
                FeeCode: response.payload.code,
            };
            this.props.values.feePayment = {
                ...response.payload,
                feeModule: JSON.parse(response.payload.feeModule),
            };
        });/*.then(response => {
            this.props.form.reset({
                Payment: {
                    //FeeProvider: null,
                    InitialAccountID: this.props.values.Payment.InitialAccountID
                }
            });
            /*this.props.form.reset({
                Payment: {
                    //AmountStr: this.props.values.Payment.AmountStr,
                    ...this.props.values.Payment,
                    FeeProvider: feeModuleId,
                    InitialAccountID: this.props.values.Payment.InitialAccountID,
                    BeneficiaryAccountNumber: response.payload.payeeAccount,
                    PayeeBank: response.payload.payeeBank,
                    Recipient: response.payload.providerTitle,
                    FeeCode: response.payload.code,
                },
                feePayment: {
                    ...response.payload,
                    feeModule: JSON.parse(response.payload.feeModule),
                },
            });*/
        //});
    };

    render() {
        const {feePayment} = this.props;
        this.props.form.change('initiate', true);
        return <FeeModule data={feePayment}/>;

    }
}

function mapStateToProps(state) {
    const {sendFeePayment, feePayment} = state.FeeReducers;

    return {
        sendFeePayment, feePayment
    };
}

const connectedFeePaymentSpy = connect(mapStateToProps)(FeePaymentSpy);
export {connectedFeePaymentSpy as FeePaymentSpy};