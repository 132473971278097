import React, { useEffect, useState } from 'react'
import { routerPath } from '@config'
import { UACActions, UACConstants } from '@redux'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'

export const WaitingRoom = props => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const LoginCode = pathname?.split('/').pop()
    const LoginToken = localStorage.getItem('loginToken')
    const LoginSystem = 'BioFace'
    const Language = useSelector(state => state.languageReducer?.language)

    const [isLoading, setIsLoading] = useState(true)

    const doLogin = async () => {
        const result = await dispatch(
            UACActions.postCheckLogin({
                Language,
                LoginCode,
                LoginSystem,
                LoginToken
            })
        )

        setIsLoading(false)

        if (result && result.type === UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS) {
            navigate(routerPath.index)
        } else {
            navigate(routerPath.login)
        }
    }

    useEffect(() => {
        doLogin()
    }, [])

    return null
}
