import React from 'react';
import {Form} from "react-final-form";
import {DownloadOutlined, RightOutlined, ScheduleOutlined,} from '@ant-design/icons';
import {Avatar, Button} from "antd";

import {Container, FormField, FormFieldType} from "@components";
import {
    accountsColorObject,
    checkJsonProp,
    CustomIcon,
    formatCurrentDate,
    i18,
    required,
    translationGroups,
    trb
} from "@utilities";
import {Summary} from "./Summary";

const ButtonGroup = Button.Group;

export const InvoiceFilter = (props) => {
    const period = [
        {
            label: i18.Period.LastSevenDaysOperations,
            value: 2
        }, {
            label: i18.Period.LastThirtyDaysOperations,
            value: 3
        }, {
            label: i18.Period.FromBeginningCurrentMonth,
            value: 4
        }, {
            label: i18.Period.LastMonth,
            value: 6
        },
    ];

    const needShow = props.showPDF || props.showExcel || props.showPrint || false,
        summary = [
            {
                text: trb(i18.Labels.InvoiceInitialBalance), //'Pradinis likutis',
                name: 'InitialBalance.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.InvoiceTotalRevenue), //'Viso įplaukų',
                name: 'TotalIncome.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.InvoiceTotalCost), //'Viso išlaidų',
                name: 'TotalExpenditures.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.InvoiceFinalBalance), //'Galutinis likutis',
                name: 'FinalBalance.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.InvoiceBlocked), //'Blokuota',
                name: 'BlockedAmount.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.InvoiceReserved), //'Rezervuota',
                name: 'Reserved.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }, {
                text: trb(i18.Labels.DisposableBalance), //'Disponuojamas',
                name: 'DisposableBalance.0.Text',
                classNameTitle: 'text-left',
                classNameField: 'eb_fw_300',
            }
        ],
        {account} = props;

    return <Container id="InvoiceFilter" className="eb_content_card eb_invoice_filter" wide>
        <Form
            onSubmit={() => null}
            initialValues={props.initialData}
            decorators={props.decorator}
            render={({handleSubmit, values, valid, errors}) => {
                const choosenAccount = props.accounts && props.accounts.find(item => account?.AccountID === item?.value);
                return (
                    <form id="InvoiceFilterForm" className="w-100" onSubmit={handleSubmit}>
                        <div className="row invoice-account-container-row">
                            <div className="col-12 invoice-account-container">
                                <div className="d-md-none">
                                    <span className="eb_fs_24 text-black mb-1">
                                    {checkJsonProp(account, 'AccountTypeCode') && <Avatar
                                        size={17}
                                        style={{
                                            backgroundColor: accountsColorObject[checkJsonProp(account, 'AccountTypeCode')],
                                            marginRight: 10,
                                            marginBottom: 2,
                                        }}
                                    />}
                                        {checkJsonProp(choosenAccount, 'AmountTxt')}</span><br/>
                                    <span
                                        className="eb_fs_14 text-gray-basic">{checkJsonProp(account, 'AccountNumber')}</span>
                                    {account && <Summary className="mt-3" data={account} headers={summary}/>}
                                </div>
                                <div className="form-group mb-4">
                                    <FormField
                                        title={i18.Labels.Account}
                                        name="AccountID"
                                        component={FormFieldType.select}
                                        options={props.accounts}
                                        optionsLabel="label"
                                        optionsValue="value"
                                        optionsDisabled="disabled"
                                        optionsColor="color"
                                        validate={props.submitting ? null : required}
                                        disabled={true}//{props.submitting}
                                        inline={false}
                                        className="disabled-ghost m--4"
                                        classNameTitle="d-none"
                                        classNameField="col-12 mx--3"
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <div className="form-group mb-4">
                                    <FormField
                                        title={<CustomIcon icon="triangleDown" type="custom" size={15}/>}
                                        name="FilterID"
                                        component={FormFieldType.radio}
                                        disabled={props.submitting}
                                        options={period}
                                        translate={translationGroups.Period}
                                        optionsLabel="label"
                                        optionsValue="value"
                                        classNameField="col-12 col-md-11 pl-md-0 mr-md-0 period-filter pr-0 pr-md-3"
                                        classNameTitle="d-none d-md-flex col-1 pr-0 mr-0"
                                        // classNameField="col-12 pl-0 ml-0"
                                        //classNameTitle="position-absolute col-1 pl-0 ml-0"
                                        classNameItem="text-nowrap"
                                        styleTitle={{
                                            top: 10,
                                            left: 5,
                                            zIndex: 7
                                        }}
                                        autosize
                                        inline
                                        small
                                        titleWithoutTranslate
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="row mt-1">
                                    <div className="col-6">
                                        <FormField
                                            name="FilterBeginDate"
                                            component={FormFieldType.date}
                                            disabled={props.submitting}
                                            maxDate={values.FilterEndDate ? values.FilterEndDate : formatCurrentDate()}
                                            small
                                        />
                                    </div>
                                    <div className="col-6">
                                        <FormField
                                            name="FilterEndDate"
                                            component={FormFieldType.date}
                                            disabled={props.submitting || !values.FilterBeginDate}
                                            maxDate={formatCurrentDate()}
                                            minDate={values.FilterBeginDate ? values.FilterBeginDate : null}
                                            small
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="d-lg-none d-block text-right">*/}
                        {/*    <ButtonGroup className="rounded-button">*/}
                        {/*        {needShow && false && <Dropdown*/}
                        {/*            trigger={'click' || 'hover' || 'contextMenu'}*/}
                        {/*            overlay={*/}
                        {/*                <Menu>*/}
                        {/*                    {props.showSchedule &&*/}
                        {/*                    <Menu.Item>*/}
                        {/*                        <Button*/}
                        {/*                            type="link"*/}
                        {/*                            size='small'*/}
                        {/*                            onClick={props.showModalSchedule}*/}
                        {/*                            loading={props.submitting}*/}
                        {/*                            disabled={props.downloading || props.printing}*/}
                        {/*                        ><ScheduleOutlined />{trb(i18.Buttons.Schedule)}</Button>*/}
                        {/*                    </Menu.Item>*/}
                        {/*                    }*/}
                        {/*                    {props.showExcel &&*/}
                        {/*                    <Menu.Item>*/}
                        {/*                        <Button*/}
                        {/*                            type="link"*/}
                        {/*                            size='small'*/}
                        {/*                            onClick={() => valid ? props.downloadExcel(values) : null}*/}
                        {/*                            loading={props.submitting}*/}
                        {/*                            disabled={props.downloading || props.printing}*/}
                        {/*                        ><FileExcelOutlined />{trb(i18.Buttons.DownloadExcel)}</Button>*/}
                        {/*                    </Menu.Item>*/}
                        {/*                    }*/}
                        {/*                    {props.showExcel &&*/}
                        {/*                    <Menu.Item>*/}
                        {/*                    <Button*/}
                        {/*                        type="link"*/}
                        {/*                        size='small'*/}
                        {/*                        onClick={() => valid ? props.downloadXML(values) : null}*/}
                        {/*                        loading={props.submitting}*/}
                        {/*                        disabled={props.downloading || props.printing}*/}
                        {/*                    ><FileTextOutlined />{trb(i18.Buttons.DownloadXML)}</Button>*/}
                        {/*                    </Menu.Item>*/}
                        {/*                    }*/}
                        {/*                    {props.showPrint &&*/}
                        {/*                    <Menu.Item>*/}
                        {/*                        <Button*/}
                        {/*                            type="link"*/}
                        {/*                            size='small'*/}
                        {/*                            onClick={() => props.print()}*/}
                        {/*                            loading={props.printing}*/}
                        {/*                            disabled={props.submitting || props.downloading}*/}
                        {/*                        ><PrinterOutlined />{trb(i18.Buttons.Print)}</Button>*/}
                        {/*                    </Menu.Item>*/}
                        {/*                    }*/}
                        {/*                    {props.showPDF &&*/}
                        {/*                    <Menu.Item>*/}
                        {/*                        <Button*/}
                        {/*                            type="link"*/}
                        {/*                            size='small'*/}
                        {/*                            onClick={() => valid ? props.download(values) : null}*/}
                        {/*                            loading={props.downloading}*/}
                        {/*                            disabled={props.submitting || props.printing}*/}
                        {/*                        ><DownloadOutlined />{trb(i18.Buttons.DownloadPDF)}</Button>*/}
                        {/*                    </Menu.Item>*/}
                        {/*                    }*/}
                        {/*                </Menu>*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            <Button icon={<DashOutlined />}>{trb(i18.Buttons.InoviceFilterDropdown)}</Button>*/}
                        {/*        </Dropdown>*/}
                        {/*        }*/}
                        {/*        <Button*/}
                        {/*            type="primary"*/}
                        {/*            onClick={() => valid ? props.submit(values) : null}*/}
                        {/*            loading={props.submitting}*/}
                        {/*            disabled={props.downloading || props.printing}*/}
                        {/*        ><RightOutlined /></Button>*/}
                        {/*    </ButtonGroup>*/}
                        {/*</div>*/}
                        <div className="d-block d-md-none row">
                            <div className="col-12 text-right mb-3">
                                <ButtonGroup className="rounded-button static-color">
                                    <Button
                                        id="InvoiceFilterSubmitButtonMD"
                                        type="primary"
                                        onClick={() => valid ? props.submit(values) : null}
                                        loading={props.submitting}
                                        disabled={props.downloading || props.printing}
                                        className="hover-wide"
                                    ><RightOutlined/></Button>
                                </ButtonGroup>
                            </div>
                            <div className="col-12 d-flex justify-content-between mt-4">
                                <ButtonGroup className="rounded-button d-flex col-12 px-0">
                                    {/*<DownloadOutlined className="mr-1"/>*/}
                                    {/*{props.showSchedule &&*/}
                                    {/*<Button*/}
                                    {/*  type="default"*/}
                                    {/*  onClick={props.showModalSchedule}*/}
                                    {/*  //loading={props.submitting}*/}
                                    {/*  //disabled={props.downloading || props.printing}*/}
                                    {/*><ScheduleOutlined /></Button>*/}
                                    {/*}*/}
                                    {props.showPDF &&
                                        <Button
                                            id="InvoiceFilterDownloadPDFMD"
                                            type="default"
                                            onClick={() => valid ? props.download(values) : null}
                                            //loading={props.downloading}
                                            disabled={props.printing}
                                        >PDF</Button>
                                    }
                                    {props.showExcel &&
                                        <>
                                            <Button
                                                id="InvoiceFilterDownloadXLSMD"
                                                type="default"
                                                onClick={() => valid ? props.downloadExcel(values) : null}
                                                //loading={props.submitting}
                                                disabled={props.downloading || props.printing}
                                            >XLS</Button>
                                            <Button
                                                id="InvoiceFilterDownloadXMLMD"
                                                type="default"
                                                onClick={() => valid ? props.downloadXML(values) : null}
                                                //loading={props.submitting}
                                                disabled={props.downloading || props.printing}
                                            >XML</Button>
                                        </>
                                    }
                                    {/*{props.showPrint && <ButtonGroup className="rounded-button">*/}
                                    {/*    <Button*/}
                                    {/*      type="default"*/}
                                    {/*      onClick={() => props.print()}*/}
                                    {/*      loading={props.printing}*/}
                                    {/*      disabled={props.submitting || props.downloading}*/}
                                    {/*    ><CustomIcon type="custom" icon="print" /></Button>*/}
                                    {/*</ButtonGroup>}*/}
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="d-none d-md-block row">
                            <div className="col-12 text-right mb-3">
                                <ButtonGroup className="rounded-button static-color">
                                    <Button
                                        id="InvoiceFilterSubmitButtonLG"
                                        type="primary"
                                        onClick={() => valid ? props.submit(values) : null}
                                        loading={props.submitting}
                                        disabled={props.downloading || props.printing}
                                        className="hover-wide"
                                    ><RightOutlined/></Button>
                                </ButtonGroup>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <ButtonGroup className="rounded-button">
                                    <DownloadOutlined  id="InvoiceFilterDownloadLG" className="mr-1"/>
                                    {props.showSchedule &&
                                        <Button
                                            id="InvoiceFilterSchedule"
                                            type="default"
                                            onClick={props.showModalSchedule}
                                            //loading={props.submitting}
                                            //disabled={props.downloading || props.printing}
                                        ><ScheduleOutlined/></Button>
                                    }
                                    {props.showPDF &&
                                        <Button
                                            id="InvoiceFilterDownloadPDFLG"
                                            type="default"
                                            onClick={() => valid ? props.download(values) : null}
                                            //loading={props.downloading}
                                            disabled={props.printing}
                                        >PDF</Button>
                                    }
                                    {props.showExcel &&
                                        <>
                                            <Button
                                                id="InvoiceFilterDownloadXLSLG"
                                                type="default"
                                                onClick={() => valid ? props.downloadExcel(values) : null}
                                                //loading={props.submitting}
                                                disabled={props.downloading || props.printing}
                                            >XLS</Button>
                                            <Button
                                                id="InvoiceFilterDownloadXMLLG"
                                                type="default"
                                                onClick={() => valid ? props.downloadXML(values) : null}
                                                //loading={props.submitting}
                                                disabled={props.downloading || props.printing}
                                            >XML</Button>
                                        </>
                                    }
                                </ButtonGroup>
                                {props.showPrint && <ButtonGroup className="rounded-button">
                                    <Button
                                        id="InvoiceFilterSubmitButtonPrint"
                                        type="default"
                                        onClick={() => props.print()}
                                        loading={props.printing}
                                        disabled={props.submitting || props.downloading}
                                    ><CustomIcon type="custom" icon="print"/></Button>
                                </ButtonGroup>}
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    </Container>;
};
