import React from 'react';
import {Input} from 'antd';

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '../';
import {trb, formFieldId } from '../../../Utilities';

export class InputFormField extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            active: !this.props.input.value && this.props.input.value !== 0
              && !this.props.defaultValue && this.props.defaultValue !== 0,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.props.input.value && this.props.input.value !== prevProps.input.value)
        || (this.props.defaultValue  && this.props.defaultValue !== prevProps.defaultValue)) this.setState({
            active: false,
        });
    }

    onBlur = (e) => {
        if (!this.props.input.value && this.props.input.value !== 0) this.setState({
            active: true
        });
        this.props.input.onBlur(e);
        if (this.props.onBlur) this.props.onBlur(e.target.value);
    }

    onSelect = () => {
        this.setState({
            active: false
        });
    }

    render = () => {
        const {
            title,
            titleWithoutTranslate,
            className,
            classNameField,
            classNameTitle,
            classNameError,
            size,
            inline,
            placeholder,
            disabled,
            meta,
            inputType,
            autoFocus,
            addonBefore,
            suffix,
            maxLength,
            defaultValue,
            hightlight
        } = this.props,
        {active} = this.state;
        return <div id={formFieldId(this.props.input.name, 'InputWrapper')} className={FormFieldWrapperClasses(className, inline, disabled, active, false, hightlight)}>
            {title && <div className={
                classNameTitle
                ? 'form-field-title ' + classNameTitle
                : inline
                    ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
                        ? 'form-field-title col-12 col-md-5 text-left text-md-right pb-2 pb-md-0 pr-md-0'
                        : 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0'
                    : 'form-field-title col-12 d-flex align-items-center'
            }>{titleWithoutTranslate ? title : trb(title)}</div>}
            <div className={
                classNameField
                    ? 'form-field-item ' + classNameField
                    : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline, className)}>
                <Input
                    {...this.props.input}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="off"
                    size={size}
                    placeholder={trb(placeholder)}
                    disabled={disabled}
                    name={this.props.input.name}
                    value={this.props.input.value}
                    onChange={(e) => {
                        this.props.input.onChange(e);
                        this.props?.helperOnChange?.(e.target.value);
                    }}
                    type={inputType}
                    autoFocus={autoFocus}
                    addonBefore={addonBefore}
                    suffix={suffix}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    onBlur={this.onBlur}
                    onSelect={this.onSelect}
                    id={formFieldId(this.props.input.name, 'Input')}
                />
            </div>
            <div id={formFieldId(this.props.input.name, 'InputError')} className={
                classNameError
                    ? 'form-field-error ' + classNameError
                    : inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>{meta.touched && (meta.error || meta.submitError)}</div>
        </div>
    }
};
