import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

import { Number } from '@components'
import { i18, trb, CustomIcon } from '@utilities'
import { getWindowSize } from '@utilities/Hooks/windowSize'

import { OMCActions } from '@redux'
import { PAGINATION_DEFAULTS } from '@config'

export const OnGoingOperationsSmeContainer = () => {
    const dispatch = useDispatch()
    const { mobileCheck } = getWindowSize()
    const { sendODLPaging, ODLPaging, ODLPagination } = useSelector(state => state.OMCReducers)

    const [pagination, setPagination] = useState({
        Days: 30,
        PageNumber: 1,
        PageSize: PAGINATION_DEFAULTS.DASHBOARD_OPERATIONS_ONGOING.DEFAULT,
        OperationStatus: 1
    })

    useEffect(() => {
        dispatch(OMCActions.postODLPaging(pagination))
    }, [pagination])

    const handleFetch = (PageNumber, PageSize) => {
        setPagination({...pagination, PageNumber, PageSize})
    }

    const columns = [
            {
                title: '',
                dataIndex: 'ID',
                render: (el, item) => {
                    const value = parseFloat(item?.Amount?.[0]?.Value)
                    const colorClass = value > 0 ? '' : 'payment_type_active'

                    return (
                        <CustomIcon
                            className={`payment_type ${colorClass}`}
                            size={18}
                            type='custom'
                            icon='payment'
                        />
                    )
                },
                className: 'px-0',
                width: 1
            },
            {
                title: trb(i18.Labels.OperationsData),
                dataIndex: 'DateFormatted',
                className: 'eb_fw_300 text-nowrap'
            },
            {
                title: trb(i18.Labels.OperationsPayer),
                dataIndex: 'SenderName',
                className: 'eb_fw_300'
            },
            {
                title: trb(i18.Labels.OperationsPurpose),
                dataIndex: 'Description',
                className: 'eb_fw_300'
            },
            {
                title: `+ ${trb(i18.Labels.OperationsRevenue)} / - ${trb(
                    i18.Labels.OperationsExpenses
                )} / ${trb(i18.Labels.OperationsTaxes)}`,
                align: 'right',
                dataIndex: 'Amount.0.Value',
                render: (el, item) => {
                    const value = parseFloat(item?.Amount?.[0]?.Value)
                    return (
                        <span className='text-black text-nowrap float-right'>
              - <Number value={value} />
            </span>
                    )
                }
            }
        ],
        columnsMobile = [
            {
                title: trb(i18.Labels.OperationsPayer),
                dataIndex: 'SenderName',
                className: 'eb_fw_300',
            },
            {
                title: trb(i18.Labels.OperationsCosts),
                align: 'right',
                dataIndex: 'Amount.0.Value',
                render: (el, item) => {
                    const value = parseFloat(item?.Amount?.[0]?.Value)

                    return (
                        <span className='text-black text-nowrap float-right'> - <Number value={value} /></span>
                    )
                }
            }
        ];

    return (
        <Table
            id="OnGoingOperationsTable"
            loading={sendODLPaging}
            dataSource={ODLPaging.OperationList.map((item, index) => ({
                ...item,
                UIDForTableUpdate: index
            }))}
            rowKey='UIDForTableUpdate'
            columns={mobileCheck ? columnsMobile : columns}
            pagination={{
                showSizeChanger: true,
                pageSizeOptions: PAGINATION_DEFAULTS.DASHBOARD_OPERATIONS_ONGOING.SIZES,
                current: ODLPagination.PageNumber,
                pageSize: ODLPagination.PageSize,
                total: ODLPaging.RowsCount,
                onChange: (page, pageSize) => handleFetch(page, pageSize),
                onShowSizeChange: (_current, size) => handleFetch(1, size),
            }}
        />
    )
}
