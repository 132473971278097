import React from 'react'
import {connect} from 'react-redux'
import jwt_decode from 'jwt-decode'

import {UACActions, UACConstants} from '@redux'
import {routerPath} from '@config'

import {appEnvMano, checkJsonProp, ci, i18, translationGroups, trb, withRouter} from '@utilities'
import {LoginSmePage} from './LoginSmePage'
import {CheckVersion} from "@components";

const getTokenInfo = authCookieValue => {
    const decodedToken = jwt_decode(authCookieValue)
    const {TOKENS} = decodedToken
    const parsedTOKENS = JSON.parse(TOKENS)
    const jwtData = {
        TOKENS: parsedTOKENS,
        exp: decodedToken.exp
    }

    return jwtData
}

class LoginContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentForm: 1,

            UID: null,
            RQ_ID: null,
            submitting: false,
            submitError: null,

            confirm: false,
            confirming: false,

            error: false,

            seconds: 60,
            secondsSMS: 90,

            secondsRemaining: 60,
            secondsSMSRemaining: 90,
            // authCookie: 'SimpleAuth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUT0tFTlMiOiJbXCIyZDc1NmFiNjViOTk5NWRhZjA3ZmY0YmMwNjg5MTRhYWI4ODE0NTI4XCIsXCI1ZmI0ZWI1N2M2NmIwZGI0M2VkOTZlYmRhNWRhMzBlY2FhOGQ0Njk3XCJdIiwiZXhwIjoxNjM5NzUyMjM5LCJpc3MiOiJlLmtyZWRhLmx0IiwiYXVkIjoiU2ltcGxlTG9naW4ifQ.I5znb2V5fChW_5wFqirMYvydA5Wtz6tO6iU-GpbyiGk'
            // authCookie: null
            authCookie: document.cookie
        }

        this.clickChangeForm = this.clickChangeForm.bind(this)

        this.submit = this.submit.bind(this)
        this.submitSMS = this.submitSMS.bind(this)
        this.submitSMSConfirm = this.submitSMSConfirm.bind(this)

        this.loginError = this.loginError.bind(this)
        this.loginCancel = this.loginCancel.bind(this)
        this.tick = this.tick.bind(this)
        this.tickSMS = this.tickSMS.bind(this)
        this.submitMSingConfirm = this.submitMSingConfirm.bind(this)
        this.submitMSingConfirmNew = this.submitMSingConfirmNew.bind(this)
        this.newLoginMSignSmartId = this.newLoginMSignSmartId.bind(this)
        this.newLoginSms = this.newLoginSms.bind(this)
        this.submitSMSConfirmNew = this.submitSMSConfirmNew.bind(this)
        this.handleCheckLoginResponse = this.handleCheckLoginResponse.bind(this)
    }

    componentDidMount() {
        const {dispatch} = this.props

        dispatch(UACActions.getLoginMessage())
        let {authCookie} = this.state
        if (authCookie) {
            const COOKIE_NAME = 'SimpleAuth'
            const allCookiesArr = authCookie.split(';')
            let targetCookie = allCookiesArr.find(item =>
                item.replace(/ /g, '').startsWith(COOKIE_NAME)
            )
            targetCookie = targetCookie ? targetCookie.replace(/ /g, '') : ''
            const cookieName = targetCookie ? targetCookie.substr(0, 11) : ''
            if (cookieName === 'SimpleAuth=') {
                const authCookieValue = targetCookie.substr(11)
                const tokenInfo = getTokenInfo(authCookieValue)
                this.setState({
                    ...this.state,
                    authCookie: tokenInfo
                })
            } else {
                this.setState({
                    ...this.state,
                    authCookie: null
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentForm} = this.state
        const prevCurrentForm = prevState.currentForm
        const {language} = this.props
        const prevLanguage = prevProps && prevProps.language

        if (language !== prevLanguage) {
            this.setState({
                currentForm: 0
            })
        }
        if (currentForm === 0) {
            this.setState({
                currentForm: prevCurrentForm
            })
        }
    }

    getTokenInfo = authCookieValue => {
        const decodedToken = jwt_decode(authCookieValue)
        const {TOKENS} = decodedToken
        const parsedTOKENS = JSON.parse(TOKENS)
        const jwtData = {
            TOKENS: parsedTOKENS,
            exp: decodedToken.exp
        }

        return jwtData
    }

    clickChangeForm = form => {
        this.setState({
            submitting: false,
            submitError: null,
            currentForm: form,
            confirming: false,
            confirm: false
        })
    }

    //Old submit MSignature
    submit = async values => {
        const {dispatch, language} = this.props
        this.setState({
            UID: values.UID || values.LoginCode,
            submitting: true,
            submitError: null
        })

        const response = await dispatch(
            UACActions.postESAR({...values, Language: language})
        )

        if (response.payload && response.payload.AuthErrorMessage) {
            this.loginError(response)
        }

        if (response.payload && response.payload.IsSuccessfull) {
            const data = {
                UID: values.UID,
                RQ_ID: response.payload.RQ_ID,
                ISGG: false
            }

            this.setState({
                confirm: true,
                confirming: true,
                code: response.payload.ControlCode
            })

            const code = response.payload.ControlCode
            this.interval = setInterval(this.tick, 1000)

            if (code === '0000') {
                const response2 = await dispatch(UACActions.postESACR(data))
                if (response2.payload.IsSuccessfull) {
                    await this.checkLogin()
                }
            } else {
                this.setState({data: data})
                this.intervalMSing = setTimeout(this.submitMSingConfirm, 1000)
            }
        } else {
            this.loginError(response)
        }

        this.setState({submitting: false})
    }

    //Old submit SmartId
    submitSmartId = async values => {
        const {dispatch, language} = this.props

        this.setState({
            UID: values.UID,
            submitting: true,
            submitError: null
        })

        const response = await dispatch(
            UACActions.postESARSM({...values, Language: language})
        ) //.then(response => {        });
        if (response.payload && response.payload.AuthErrorMessage) {
            this.loginError(response)
        }

        if (response.payload && response.payload.IsSuccessfull) {
            const data = {
                UID: values.UID,
                RQ_ID: response.payload.RQ_ID,
                ISGG: false
            }

            this.setState({
                confirm: true,
                confirming: true,
                code: response.payload.ControlCode
            })
            const code = response.payload.ControlCode
            this.interval = setInterval(this.tick, 1000)

            if (code === '0000') {
                const response2 = await dispatch(UACActions.postESACR(data))
                if (response2.payload.IsSuccessfull) {
                    await this.checkLogin()
                }
            } else {
                this.setState({data: data})
                this.intervalMSing = setTimeout(this.submitMSingConfirm, 1000)
            }
        } else {
            this.loginError(response)
        }

        this.setState({submitting: false})
    }

    //Old submit SMS
    submitSMS = async values => {
        const {dispatch, language} = this.props

        this.setState({
            UID: values.UID,
            submitting: true,
            submitError: null
        })
        if (!values.FIRST) {
            const response = await dispatch(
                UACActions.postSMSR({...values, Language: language})
            )
            if (response.payload && response.payload.AuthErrorMessage) {
                this.loginError(response)
            }

            if (response.payload && response.payload.IsSuccessfull) {
                this.setState({
                    confirm: true,
                    confirming: true,
                    RQ_ID: response.payload.RQ_ID ? response.payload.RQ_ID : null
                })
                this.interval = setInterval(this.tickSMS, 1000)
            } else {
                this.loginError(response)
            }

            this.setState({submitting: false})
        } else {
            const response = await dispatch(UACActions.postSMSGETPWD(values))
            if (response.payload && response.payload.AuthErrorMessage) {
                this.loginError(response)
            }

            if (response.payload && response.payload.IsSuccessfull) {
                // this.setState({
                //     confirm: true,
                //     confirming: true
                // });
                // this.interval = setInterval(this.tick, 1000);
                values.FIRST = false
                values.PWD = ''
            } else {
                this.loginError(response)
            }

            this.setState({submitting: false})
        }
    }

    //Old submit MSignature/SmartId confirm
    async submitMSingConfirm() {
        const {dispatch} = this.props

        const response = await dispatch(UACActions.postESACR(this.state.data))

        if (response?.payload?.AuthCheckResult === null && this.intervalMSing) {
            this.intervalMSing = setTimeout(this.submitMSingConfirm, 1000)
        }
        if (
            response?.payload?.AuthCheckResult === false &&
            response?.payload?.IsSuccessfull === false
        ) {
            this.loginCancel(
                response?.payload?.AuthErrorMessage
                    ? response?.payload?.AuthErrorMessage
                    : null
            )
        }

        if (response?.payload?.IsSuccessfull) {
            clearInterval(this.interval)
            clearInterval(this.intervalMSing)
            await this.checkLogin()
        }
    }

    async handleCheckLoginResponse(response) {
        if (response?.type === UACConstants.POST_UAC_CHECK_LOGIN_ERROR) {
            const {data} = response?.error || {}

            if (data && data.IsAuth === false) {
                this.loginCancel(data.LoginStatusCode ? data.LoginStatusCode : null)
            } else {
                this.loginCancel(
                    data && data.LoginStatusCode ? data.LoginStatusCode : null
                )
            }
        }

        if (
            response &&
            response.payload &&
            response.payload.Bearer === null &&
            response.payload.IsError === false &&
            this.intervalMSing
        ) {
            this.intervalMSing = setTimeout(this.submitMSingConfirmNew, 1000)
        } else if (
            response &&
            response.payload &&
            response.payload.IsError === true
        ) {
            this.loginCancel(
                response.payload.LoginStatusCode
                    ? response.payload.LoginStatusCode
                    : null
            )
        } else if (
            response &&
            response.payload &&
            response.payload.IsAuth === true &&
            response.payload.Bearer !== null
        ) {
            clearInterval(this.interval)
            clearInterval(this.intervalMSing)
            await this.checkLogin()
        }
    }

    //New submit MSignature/SmartId confirm
    async submitMSingConfirmNew() {
        const {dispatch} = this.props
        const response = await dispatch(UACActions.postCheckLogin(this.state.data))
        await this.handleCheckLoginResponse(response)
    }

    //Old submit SMS confirm
    submitSMSConfirm = async values => {
        const {dispatch} = this.props

        this.setState({
            submitting: true,
            submitError: null
        })

        const currentValues = {
            UID: this.state.UID,
            PWD: '',
            CC_CC: values.CC_CC,
            ISGG: false,
            RQ_ID: this.state.RQ_ID
        }

        const response = await dispatch(UACActions.postSMSAR(currentValues))

        if (response.payload && response.AuthErrorMessage) {
            this.loginError(response)
        }

        if (response.payload && response.payload.IsSuccessfull) {
            await this.checkLogin()
        } else {
            this.loginError(response)
        }

        this.setState({submitting: false})
    }

    //New submit SMS confirm
    submitSMSConfirmNew = async values => {
        const {dispatch, language} = this.props
        const {loginSMSData} = this.state
        const {CC_CC} = values

        this.setState({
            submitting: true,
            submitError: null
        })
        const currentValues = {
            ...loginSMSData,
            LoginAuthCode: CC_CC,
            Language: language
        }

        const response = await dispatch(UACActions.postCheckLogin(currentValues))
        await this.handleCheckLoginResponse(response)

        this.setState({submitting: false})
    }

    //Old check login
    checkLogin = async () => {

        const link = checkJsonProp(this.props, 'match.params.link')
        switch (link) {
            case 'LoginBankLink':
                this.setState({
                    //currentForm: 1,
                    UID: null,
                    submitting: true,
                    submitError: null,
                    confirm: true,
                    confirming: true,
                    error: false
                })
                clearInterval(this.interval)
                clearInterval(this.intervalMSing)
                const response = await this.props.dispatch(UACActions.getGGDataMy())
                if (checkJsonProp(response, 'payload.IsSuccessfull')) {
                    const ggData = checkJsonProp(response, 'payload.GGRedirData')
                    let form = document.createElement('form')
                    document.body.appendChild(form)
                    form.method = 'POST'
                    //form.target = "_blank";
                    form.action = ggData.RedirURL
                    form.appendChild(ci('SRC', ggData.CompanyCode))
                    form.appendChild(ci('TIME', ggData.DateTime))
                    form.appendChild(ci('PERSON_CODE', ggData.PersonCode))
                    form.appendChild(ci('PERSON_FNAME', ggData.FName))
                    form.appendChild(ci('PERSON_LNAME', ggData.LName))
                    form.appendChild(ci('SIGNATURE', ggData.Signature))
                    form.appendChild(ci('TYPE', 'BANK-01'))
                    form.submit()
                }
                sessionStorage.clear()
                localStorage.clear()

                break
            default:
                this.props.router.navigate(routerPath.index)

        }
    }

    newLoginSms = async values => {
        const {dispatch, language} = this.props
        const {
            isFullLogin,
            LoginCode,
            LoginPass,
            LoginIdentity,
            RememberMe,
            LoginSystem
        } = values

        this.setState({
            UID: values.LoginCode,
            submitting: true,
            submitError: null
        })

        if (!values.FIRST) {
            const response = isFullLogin
                ? await dispatch(
                    UACActions.postFullLogin({
                        LoginCode: LoginCode,
                        LoginPass: LoginPass,
                        LoginIdentity: LoginIdentity,
                        RememberMe: RememberMe,
                        LoginSystem: LoginSystem,
                        Language: language
                    })
                )
                : await dispatch(
                    UACActions.postSimpleLogin({
                        LoginCode: LoginCode,
                        LoginPass: LoginPass,
                        LoginSystem: LoginSystem,
                        Language: language
                    })
                )

            if (
                response &&
                response.type &&
                (response.type === 'POST_UAC_FULLLOGIN_SUCCESS' ||
                    response.type === 'POST_UAC_SIMPLELOGIN_SUCCESS')
            ) {
                this.setState({
                    confirm: true,
                    confirming: true,
                    loginSMSData: {
                        LoginCode: LoginCode,
                        LoginToken: response.payload.LoginSession,
                        LoginSystem: LoginSystem
                    }
                })
                this.interval = setInterval(this.tickSMS, 1000)
            } else if (
                response &&
                response.payload &&
                response.payload.LoginStatusCode
            ) {
                this.loginErrorNew(response)
            } else {
                this.loginErrorNew(response)
            }
        } else {
            const response = await dispatch(UACActions.postSMSGETPWD(values))
            if (response && response.payload && response.payload.AuthErrorMessage) {
                this.loginError(response)
            }
            if (response && response.payload && response.payload.IsSuccessfull) {
                values.FIRST = false
                values.PWD = ''
            } else {
                this.loginError(response)
            }
        }

        this.setState({submitting: false})
    }

    newLoginMSignSmartId = async values => {
        const {dispatch, language} = this.props
        const {
            isFullLogin,
            LoginCode,
            LoginPass,
            LoginIdentity,
            RememberMe,
            LoginSystem
        } = values

        this.setState({
            UID: LoginCode,
            isFullLogin: isFullLogin,
            LoginIdentity: LoginIdentity,
            RememberMe: RememberMe,
            submitting: true,
            submitError: null
        })

        const response = isFullLogin
            ? await dispatch(
                UACActions.postFullLogin({
                    LoginCode: LoginCode,
                    LoginPass: LoginPass,
                    LoginIdentity: LoginIdentity,
                    RememberMe: RememberMe,
                    LoginSystem: LoginSystem,
                    Language: language
                })
            )
            : await dispatch(
                UACActions.postSimpleLogin({
                    LoginCode: LoginCode,
                    LoginPass: LoginPass,
                    LoginSystem: LoginSystem,
                    Language: language
                })
            )
        if (
            response &&
            response.type &&
            (response.type === 'POST_UAC_FULLLOGIN_SUCCESS' ||
                response.type === 'POST_UAC_SIMPLELOGIN_SUCCESS')
        ) {
            let loginToken = response.payload.LoginSession

            if (LoginSystem === 'BioFace') {
                localStorage.setItem('loginToken', loginToken)
                const {redirectUrl} = response.payload
                window.location = redirectUrl
                return
            }

            const data = {
                LoginCode: LoginCode,
                LoginToken: loginToken,
                LoginSystem: LoginSystem,
                Language: language
            }
            this.setState({
                confirm: true,
                confirming: true,
                code: response.payload.LoginControlCode
            })

            this.interval = setInterval(this.tick.bind(this), 1000)
            this.setState({data: data})
            this.intervalMSing = setTimeout(
                this.submitMSingConfirmNew.bind(this),
                1000
            )
        } else if (
            response &&
            response.payload &&
            response.payload.LoginStatusCode
        ) {
            this.loginErrorNew(response)
        } else {
            this.loginErrorNew(response)
        }

        this.setState({submitting: false})
    }

    //new submit sms/MSignature/SmartId
    submitFullOrSimpleLogin = async values => {
        const {LoginSystem} = values
        try {
            if (LoginSystem === 'Sms') {
                const result = await this.newLoginSms(values)
            } else {
                const result = await this.newLoginMSignSmartId(values)
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    tick() {
        this.setState(prevState => {
            return {secondsRemaining: prevState.secondsRemaining - 1}
        })

        if (this.state.secondsRemaining <= 0) {
            this.loginCancel('Timeout')
        }
    }

    tickSMS() {
        this.setState(prevState => {
            return {secondsSMSRemaining: prevState.secondsSMSRemaining - 1}
        })

        if (this.state.secondsSMSRemaining <= 0) {
            this.loginCancel('Timeout')
        }
    }

    loginError = response => {
        clearInterval(this.interval)
        clearInterval(this.intervalMSing)

        this.setState({
            seconds: 60,
            secondsRemaining: 60,
            secondsSMS: 90,
            secondsSMSRemaining: 90,
            confirming: false,
            confirm: false,
            submitError:
                response && response.payload && response.payload.AuthErrorMessage
                    ? translationGroups.LoginErrors + response.payload.AuthErrorMessage

                    : i18.Labels.LoginError
        })

        sessionStorage.clear()
        localStorage.clear()
    }

    loginErrorNew = response => {
        clearInterval(this.interval)
        clearInterval(this.intervalMSing)

        this.setState({
            seconds: 60,
            secondsRemaining: 60,
            secondsSMS: 90,
            secondsSMSRemaining: 90,
            confirming: false,
            confirm: false,
            submitError:
                response && response.payload && response.payload.LoginStatusCode
                    ?
                    translationGroups.LoginErrors + response.payload.LoginStatusCode

                    : response &&
                    response.error &&
                    response.error.response &&
                    response.error.response.data &&
                    response.error.response.data.LoginStatusCode
                        ?
                        translationGroups.LoginErrors +
                        response.error.response.data.LoginStatusCode

                        : i18.Labels.LoginError
        })

        sessionStorage.clear()
        localStorage.clear()
    }

    loginCancel = LoginStatusCode => {
        clearInterval(this.interval)
        clearTimeout(this.intervalMSing)
        this.intervalMSing = null
        this.setState({
            seconds: 60,
            secondsRemaining: 60,
            secondsSMS: 90,
            secondsSMSRemaining: 90,
            confirming: false,
            confirm: false,
            submitError: LoginStatusCode
                ? translationGroups.LoginErrors + LoginStatusCode
                : i18.Labels.LoginCanceled
            // UID: LoginCode,
            // isFullLogin: isFullLogin,
            // LoginIdentity: LoginIdentity,
            // RememberMe: RememberMe,
        })
        sessionStorage.clear()
        localStorage.clear()
    }

    render() {
        const {
            translations,
            loginMessages,
            router: {location: {pathname}}
        } = this.props

        return (
            <>
                <LoginSmePage
                    translations={translations}
                    loginMessages={loginMessages}
                    scroll={this.scroll}
                    authCookie={this.state.authCookie}
                    UID={this.state.UID}
                    LoginIdentity={this.state.LoginIdentity}
                    clickChangeForm={this.clickChangeForm}
                    currentForm={
                        appEnvMano && pathname === routerPath.paymentsLogin
                            ? 3
                            : this.state.currentForm
                    }
                    submit={this.submitFullOrSimpleLogin.bind(this)}
                    submitSMS={this.submitFullOrSimpleLogin.bind(this)}
                    submitSMSConfirm={this.submitSMSConfirmNew.bind(this)}
                    loginCancel={this.loginCancel.bind(this)}
                    code={this.state.code}
                    submitting={this.state.submitting}
                    submitError={this.state.submitError}
                    submitSmartId={this.submitFullOrSimpleLogin.bind(this)}
                    confirm={this.state.confirm}
                    confirming={this.state.confirming}
                    seconds={this.state.seconds}
                    secondsRemaining={this.state.secondsRemaining}
                    secondsSMS={this.state.secondsSMS}
                    secondsSMSRemaining={this.state.secondsSMSRemaining}
                    loginTypes={{
                        MSign: pathname !== routerPath.paymentsLogin,
                        SmartID: pathname !== routerPath.paymentsLogin,
                        Biometric: true
                    }}/>
                <CheckVersion/>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {ESAR, ESARSM, ESACR, sendESACR, loginMessages} = state.UACReducers
    const {language} = state.languageReducer
    const {translations} = state.translationReducer

    return {
        ESAR,
        ESACR,
        ESARSM,
        sendESACR,
        translations,
        loginMessages,
        language
    }
}

const connectedLoginContainer = connect(mapStateToProps)(withRouter(LoginContainer))
export {connectedLoginContainer as LoginContainer}
