import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'antd'

import {
    formatDate, getWindowSize,
    i18,
    translationGroups,
    trb
} from '@utilities'

import { PAGINATION_DEFAULTS } from '@config'
import { Number } from '@components'

export const OperationsListContainer = ({ handleChangePagination }) => {
    const windowSize = getWindowSize();
    const { sendODLPaging, ODLPaging, ODLPagination } = useSelector(state => state.OMCReducers)

    const setStatus = (value) => {
        switch (value) {
            case 0:
                return trb(i18.PaymentStatuses.Ongoing)
            case 1:
                return trb(i18.PaymentStatuses.Completed)
            case 2:
                return trb(i18.PaymentStatuses.Rejected)
            case 3:
                return trb(i18.PaymentStatuses.InsufficientBalance)
            default:
                return trb(i18.PaymentStatuses.AllOperations)
        }
    }

    const isStatusCodeTranslatable = (statusCode) => {
        return ['AccountNotFound', 'NotEnoughFunds', 'AMLRejected', 'ValidationError'].includes(statusCode)
    }

    const columns = [
            {
                title: trb(i18.Labels.OperationsData),
                dataIndex: 'DateFormatted',
                render: value => <span className='text-nowrap'>{formatDate(value)}</span>
            },
            {
                title: trb(i18.Labels.OperationsPayer),
                dataIndex: 'SenderName',
                render: (value, item) => (
                    <>
                        <div className='text-nowrap'>{value}</div>
                        <div className='text-nowrap'>{item.SenderAccount}</div>
                    </>
                )
            },
            {
                title: trb(i18.Labels.OperationsPayee),
                dataIndex: 'ReceiverName',
                render: (value, item) => (
                    <>
                        <div className='text-nowrap'>{value}</div>
                        <div className='text-nowrap'>{item.ReceiverAccount}</div>
                    </>
                )
            },
            {
                title: trb(i18.Labels.OperationsDescription),
                dataIndex: 'Description',
                className: 'word-wrap'
            },
            {
                title: trb(i18.Labels.OperationStatus),
                dataIndex: 'Status',
                render: value => setStatus(value)
            },
            {
                title: trb(i18.Labels.StatusCode),
                render: (item) => isStatusCodeTranslatable(item.StatusCode) ? trb(translationGroups.OperationStatusCode + item.StatusCode) : item.StatusCode
            },
            {
                title: trb(i18.Labels.OperationsSum),
                render: (item) => <Number className="text-nowrap" value={item.Amount[0].Value} suffix={` ${item.Amount[0].Currency}`}/>,
                className: 'text-right'
            }
        ],
        columnsMobile = [
            {
                title: trb(i18.Labels.OperationsPayer),
                dataIndex: 'SenderName',
                render: (value, item) => (<span>{value}<br />{item.SenderAccount}</span>)
            },
            {
                title: trb(i18.Labels.OperationsSum),
                render: (item) => <Number className="text-nowrap" value={item.Amount[0].Value} suffix={` ${item.Amount[0].Currency}`}/>,
                className: 'text-right'
            }
        ]

    return (
        <Table
            loading={sendODLPaging}
            dataSource={ODLPaging.OperationList.map((item, index) => ({
                ...item,
                UIDForTableUpdate: index
            }))}
            rowKey='UIDForTableUpdate'
            columns={windowSize.mobileCheck ? columnsMobile : columns}
            className='mb-5 mx-0'
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: ODLPaging.RowsCount <= PAGINATION_DEFAULTS.OPERATIONS.SIZES[0],
                pageSizeOptions: PAGINATION_DEFAULTS.OPERATIONS.SIZES,
                current: ODLPagination.PageNumber,
                pageSize: ODLPagination.PageSize,
                total: ODLPaging.RowsCount,
                onChange: (page, size) => handleChangePagination({
                    Days: ODLPagination.Days,
                    PageNumber: page,
                    PageSize: size,
                    OperationStatus: ODLPagination.OperationStatus
                }),
                onShowSizeChange: (_current, size) => handleChangePagination({
                    Days: ODLPagination.Days,
                    PageNumber: 1,
                    PageSize: size,
                    OperationStatus: ODLPagination.OperationStatus
                })
            }}
        />
    )
}
