import React, { useEffect, useMemo } from 'react'
import { LoginFormSMS } from './'
import { LoginForm } from './LoginForm'
import { LoginTypeSwitch } from './LoginTypeSwitch'
import { LoginConfirmationSwitch } from './LoginConfirmationSwitch'

export const LoginPanel = props => {
  const {
    loginTypes,
    withLang,
    authCookie,
    LoginIdentity,
    RememberMe,
    clickChangeForm,
    currentForm,
    submitting,
    confirm,
    code,
    seconds,
    secondsRemaining,
    loginCancel,
    UID,
    submit,
    submitError,
    confirming,
    submitSmartId,
    submitSMSConfirm,
    secondsSMS,
    secondsSMSRemaining,
    submitSMS
  } = props

  const _ref = React.createRef()
  const scrollToMyRef = () => _ref.current.scrollIntoView(true)

  const submitType = useMemo(() => {
    switch (currentForm) {
      case 1:
        return submit
      case 2:
        return submitSmartId
      case 3:
        return submitSMS
      case 4:
        return submit
      default:
        return submit
    }
  }, [currentForm])

  useEffect(() => {
    if (confirm && window.innerWidth <= 426) scrollToMyRef()
    return () => window.scrollTo(0, 0)
  }, [])

  return (
    <div ref={_ref} className="bbs_login_panel">
      <LoginTypeSwitch
        loginTypes={loginTypes}
        clickChangeForm={clickChangeForm}
        currentForm={currentForm}
        submitting={submitting}
      />
      <div className="bbs_login_panel_content">
        {confirm ? (
          <LoginConfirmationSwitch
            currentForm={currentForm}
            submitting={submitting}
            code={code}
            seconds={seconds}
            secondsRemaining={secondsRemaining}
            loginCancel={loginCancel}
            submitError={submitError}
            confirming={confirming}
            submitSMSConfirm={submitSMSConfirm}
            secondsSMS={secondsSMS}
            secondsSMSRemaining={secondsSMSRemaining}
          />
        ) : (
          <>
            {currentForm === 3 ? (
              <LoginFormSMS
                authCookie={authCookie}
                loginType="Sms"
                UID={UID}
                submit={submitSMS}
                submitting={submitting}
                submitError={submitError}
                withLang={withLang}
              />
            ) : (
              <LoginForm
                authCookie={authCookie}
                LoginIdentity={LoginIdentity}
                RememberMe={RememberMe}
                UID={UID}
                submit={submitType}
                submitting={submitting}
                submitError={submitError}
                seconds={seconds}
                secondsRemaining={secondsRemaining}
                confirming={confirming}
                withLang={withLang}
                currentForm={currentForm}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
