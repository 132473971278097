import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingOutlined} from '@ant-design/icons';
import {Alert, Button, Modal, Upload} from 'antd';

import {CustomIcon, i18, numberFormat, translationGroups, trb} from "@utilities";
import {PaymentEditTable, PaymentUploadTable} from "./index";
import {Container, ContentLoader, Notification, Number, OperationApproval} from "@components";

import {BulkFileActions, OMCActions, PMCActions} from "@redux";

export const PaymentUploading = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null)
    const [warnings, setWarnings] = useState(null)
    const [AllowToSave, setAllowToSave] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false);
    const [approve, setApprove] = useState(false);
    const [fileGUID, setFileGUID] = useState(null);
    const [isSignLater, setSignLater] = useState(false);

    const {sendSUPR, UPR, send, sendUPR, errors, PUPR, sendPUPR, SUPR} = useSelector(state => state.PMCReducers);
    const {language} = useSelector((state) => state.languageReducer)
    const {loading, FilesList, error} = useSelector((state) => state.BulkFileReducer)
    const {AuthType} = useSelector((state) => state.UACReducers?.ATR)

    const File = FilesList.find((item) => item.FileGuid === fileGUID)
    
    useEffect(() => () => {
        dispatch(PMCActions.clearUPR());
        dispatch(PMCActions.clearPUPR());
        dispatch(PMCActions.clearSUPR());
    }, [])

    useEffect(() => {
        if (UPR && sendUPR === false && send === false) {
            if (UPR?.IsSuccessfull) {
                Notification({
                    type: 'success',
                    message: i18.NotificationTitles.FileUploadSuccess,
                    description: i18.NotificationMessages.FileUploadSuccess,
                });
                if (UPR?.ErrorCaptions?.[0]?.ErrorCode) {
                    setWarnings(UPR?.ErrorCaptions);
                }
                setAllowToSave(UPR?.AllowToSave);
            } else {
                Notification({
                    type: 'error',
                    message: i18.NotificationTitles.FileUploadError,
                    noTranslateDescription: true,
                    description: (<span dangerouslySetInnerHTML={{__html: trb(i18.NotificationMessages.FileUploadError)}}></span>),
                });
            }
        }
    }, [UPR])


    useEffect(() => {
        if (PUPR && sendPUPR === false && send === false) {
            if (PUPR?.IsSuccessfull) {
                Notification({
                    type: 'success',
                    message: i18.NotificationTitles.SavedSuccess,
                    description: i18.NotificationMessages.SavedSuccess
                });
                if (!fileGUID) {
                    dispatch(PMCActions.clearPUPR());
                    dispatch(PMCActions.clearSUPR());
                    dispatch(PMCActions.clearUPR());
                }
            } else {
                Notification({
                    type: 'error',
                    message: i18.NotificationTitles.SavedError,
                    description: i18.NotificationMessages.SavedError
                });
            }
        }
    }, [PUPR])

    const handleUpload = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        dispatch(PMCActions.postUPR(formData))
    };

    useEffect(() => {
        if (SUPR && sendSUPR === false && send === false) {
            if (SUPR?.IsSuccessfull) {
                setAllowToSave(SUPR?.AllowToSave)
                const paymentList = SUPR?.PaymentsList,
                    errorInPaymentList = paymentList && paymentList.length ? paymentList.reduce((summStr, rowItem, rowIndex) => {
                        return rowItem.Errors && rowItem.Errors.length ? <>{summStr}{rowItem.Errors.reduce((errorStr, item, itemIndex) => {
                            return <>
                                {errorStr}
                                <div>{rowIndex}: {item}</div>
                            </>;
                        }, <></>)}</> : summStr;
                    }, <>{trb(i18.NotificationMessages.UploadPaymentErrorInPaymentList)}</>) : null,
                    errorInPaymentListCount = paymentList && paymentList.length ? paymentList.filter((rowItem) => {
                        return rowItem.Errors && rowItem.Errors.length > 0;
                    }).length : 0;

                if (errorInPaymentListCount) {
                    Notification({
                        type: 'error',
                        message: i18.NotificationTitles.UpdateError,
                        description: errorInPaymentList,
                        noTranslateDescription: true,
                    });
                } else {
                    Notification({
                        type: 'success',
                        message: i18.NotificationTitles.UpdateSuccess,
                        description: i18.NotificationMessages.UpdateSuccess
                    });

                    if (SUPR?.ErrorCaptions?.[0]?.ErrorCode) {
                        setWarnings(SUPR?.ErrorCaptions)
                    }
                }
            } else {
                Notification({
                    type: 'error',
                    message: i18.NotificationTitles.UpdateError,
                    description: i18.NotificationMessages.UpdateError
                });

            }
        }
    }, [SUPR])

    const isFileSaved = FilesList.findIndex(item => item.FileGuid === fileGUID) !== -1

    const UpdateFile = (values) => {
        dispatch(PMCActions.postSUPR(values))
    };

    const Save = () => {
        setSignLater(true);
        const UploadFileID = UPR?.UploadFileID;
        dispatch(PMCActions.postPUPR({FileID: UploadFileID.toString()}))
    };

    const handlePreview = async () => {
        setSignLater(false);
        const UploadFileID = UPR?.UploadFileID;
        setFileGUID(UploadFileID)
        if (!isFileSaved) {
            await dispatch(PMCActions.postPUPR({FileID: UploadFileID.toString()}))
        }
        dispatch(BulkFileActions.getBulkPaymentsPreview(UploadFileID))
        setShowConfirm(true)
    }

    const clickApproveModal = async () => {
        await dispatch(BulkFileActions.postBulkPaymentsSign({FileGuid: fileGUID, Language: language}))
        setApprove(true)
    }

    const cancelApprove = () => {
        setApprove(false)
        dispatch(BulkFileActions.cancelLoading())
    }

    const successApprove = () => {
        setApprove(false)
        setShowConfirm(false)
        dispatch(BulkFileActions.removeFromList(fileGUID))
        setFileGUID(null);
        dispatch(PMCActions.clearPUPR());
        dispatch(PMCActions.clearSUPR());
        dispatch(PMCActions.clearUPR());
        Notification({
            type: 'success',
            message: i18.NotificationTitles.SuccessApprove,
            description: i18.NotificationMessages.SuccessApprove
        })
    }

    const BadApproveCode = ErrorCode => {
        let message = ErrorCode
        if (message?.replace) {
            message = message.replace('.', '')
        }
        // setShowConfirm(false)
        // setApprove(false)
        Notification({
            type: 'error',
            message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.BadApproveCode,
            description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.BadApproveCode
        })
    }

    const submitSmsApprove = async values => {
        try {
            const response = await dispatch(OMCActions.postSUORSMS(values))
            const ErrorCode = response?.payload?.ErrorCode
            if (response.payload.IsSuccessfull) {
                successApprove()
            } else {
                BadApproveCode(ErrorCode)
            }
        } catch (e) {

        }
    }

    const submitMobileApprove = async () => {
        if(error) return;
        const RQ_ID = File?.iSignToken

        const response = await dispatch(OMCActions.postSUOR({ iSignToken: RQ_ID }))

        const AuthType = response?.payload?.AuthType
        const IsSuccessfull = response?.payload?.IsSuccessfull
        const ErrorCode = response?.payload?.ErrorCode

        if (ErrorCode) {
            return false;
        }

        if (AuthType === null && IsSuccessfull === false) {
            return true
        }
        if (AuthType === 'false') {
            cancelApprove(ErrorCode)
        } else if (IsSuccessfull === true) {
            successApprove()
        }
        return false
    }

    const props = {
        accept: ".xml",
        multiple: false,
        disabled: send || sendUPR,
        // showUploadList: false,
        onRemove: fileParam => {
            setFile(null)
        },
        beforeUpload: fileParam => {
            setFile(fileParam)
            return false;
        },
        onChange: info => handleUpload(info),
        file,
    };

    const data = SUPR ?? UPR ?? PUPR


    if (sendSUPR) {
        return <ContentLoader/>;
    }

    return (
        <div>
            <div className="eb_content_subtitle">{trb(i18.ContentMenu.PaymentUploading)}</div>
            <Container>
                {(!data || !AllowToSave) && <div className="w-100">
                    <Upload.Dragger {...props} className="mb-5 pb-5" showUploadList={false}>
                        <div
                            className="eb_content_subtitle text-center mx-1 eb_fw_300 text-primary">{trb(i18.Labels.SelectFile)}</div>
                        <p className="ant-upload-drag-icon text-primary">
                            {send ? <LoadingOutlined/> : <CustomIcon type='custom' icon='plusIcon'/>}
                        </p>
                    </Upload.Dragger>
                    {/*<div className="eb_content_subtitle text-center mb-5 mx-1 text-gray-basic">{trb(i18.Labels.Or)}</div>*/}
                    {/*<Upload {...props} className="eb_custom_upload">*/}
                    {/*    <div className="eb_content_subtitle text-center mx-1 eb_fw_300">{trb(i18.Labels.SelectFile)}</div>*/}
                    {/*    <Button disabled={this.state.data} type="primary" size="large" shape="circle" style={{padding: 15}}>*/}
                    {/*        {this.state.submitting ? <LoadingOutlined /> : <CustomIcon type='custom' size={26} icon='plusIcon' />}*/}
                    {/*    </Button>*/}
                    {/*</Upload>*/}
                </div>}
                {data && AllowToSave === true && (
                    <>
                        <PaymentUploadTable data={data}
                                            buttons={[
                                                {
                                                    title: i18.Buttons.SafeOperation,
                                                    func: Save,
                                                    props: {
                                                        disabled: send,
                                                        loading: isSignLater && send
                                                    }
                                                },
                                                {
                                                    title: !isFileSaved ? i18.Buttons.ApproveUploadedPayments : i18.Buttons.Approve,
                                                    func: handlePreview,
                                                    props: {
                                                        type: "primary",
                                                        disabled: send,
                                                        loading: !isSignLater && send
                                                    }
                                                }
                                            ].filter(item =>
                                                item.title === i18.Buttons.SafeOperation ? !isFileSaved:true
                                            )}
                        />
                        {(warnings && !isFileSaved)  && <Alert
                            className="mb-3 w-50 align-self-center"
                            message={trb(i18.ErrorTitles.Warning)}
                            description={
                                <ul>
                                    {warnings.map((item, index) =>
                                        // <li key={index}>{item.ErrorCaption}</li>
                                        <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                                    )}
                                </ul>
                            }
                            type="warning"
                        />}
                    </>)}
                {data && AllowToSave === false && (<>
                    <PaymentEditTable data={data}
                                      submit={UpdateFile}
                                      buttons={[
                                          {
                                              title: i18.Buttons.UpdateFile,
                                              // func: this.Save,
                                              props: {
                                                  type: "primary",
                                                  htmlType: "submit",
                                                  disabled: send,
                                                  loading: send,

                                              }
                                          }
                                      ]}
                    />
                    {(warnings && !isFileSaved) && <Alert
                        className="mb-3 w-50 align-self-center"
                        message={trb(i18.ErrorTitles.Warning)}
                        description={
                            <ul>
                                {warnings.map((item, index) =>
                                    // <li key={index}>{item.ErrorCaption}</li>
                                    <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                                )}
                            </ul>
                        }
                        type="warning"
                    />}
                    {errors && <Alert
                        className="mb-3  w-50 align-self-center"
                        message={trb(i18.ErrorTitles.Warning)}
                        description={
                            <ul>
                                {errors.map((item, index) =>
                                    // <li key={index}>{item.ErrorCaption}</li>
                                    <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                                )}
                            </ul>
                        }
                        type="error"
                    />}

                </>)
                }
                {showConfirm && (<Modal
                    width={700}
                    centered
                    style={{top: 0}}
                    bodyStyle={{paddingTop: 0, paddingBottom: 0}}
                    visible={showConfirm}
                    title={<div className="eb_fw_600">
                        {trb(i18.Labels.PaymentSubmitModal)}
                    </div>}
                    onCancel={() => {
                        setShowConfirm(false)
                        setApprove(false)
                        dispatch(BulkFileActions.cancelLoading())
                    }}
                    closable
                    footer={[<Button.Group key="footer">
                        <Button
                            type="primary"
                            onClick={clickApproveModal}
                            disabled={(loading || approve)}
                        >
                            {trb(i18.Buttons.Submit)}
                        </Button>
                        <Button
                            type="default"
                            style={{marginLeft: 0}}
                            onClick={() => {
                                setShowConfirm(false)
                                setApprove(false)
                                dispatch(BulkFileActions.cancelLoading())
                            }}>
                            {trb(i18.Buttons.Cancel)}
                        </Button>
                    </Button.Group>]}
                >

                    <div className="px-0 pb-3 p-md-3">
                        {File?.Report?.map(({
                                                Amount,
                                                Commission,
                                                CommissionCurrency,
                                                Currency,
                                                Quantity,
                                                ExecutionDate,
                                                CutOffTime
                                            }, index) => <div className="row" key={index}>
                            <div
                                className="col-3 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                {trb(i18.Labels.PaymentsCount)}
                            </div>
                            <div
                                className="col-3 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                                <Number
                                    value={Quantity}
                                    format={numberFormat().number}
                                />
                            </div>
                            <div
                                className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                {trb(i18.Labels.PaymentsTotalAmount)}
                                <span
                                    className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                        <Number value={Amount}/>
                                    {` ${Currency}`}
                            </span>
                            </div>

                            <div
                                className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                {trb(i18.Labels.PaymentsTotalCommFee)}
                                <span
                                    className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                        <Number value={Commission}/>
                                    {` ${CommissionCurrency}`}
                    </span>
                            </div>

                        </div>)}
                    </div>
                    {(loading && !approve) && (<ContentLoader/>)}
                    {approve && (<div className="pb-3">
                        <OperationApproval
                            RQ_ID={File?.iSignToken}
                            AuthType={AuthType}
                            code={File?.ControlCode}
                            cancelApprove={cancelApprove}
                            successApprove={successApprove}
                            submitSmsApprove={submitSmsApprove}
                            submitMobileApprove={submitMobileApprove}
                            approveCount={File?.PaymentsCount}
                            errorCode={error}
                        />
                    </div>)}
                </Modal>)}
            </Container>
        </div>
    );

}
