import React from 'react';
import {connect} from 'react-redux';
import { ExclamationOutlined } from '@ant-design/icons';

import {checkJsonProp, i18, formatCurrentDate, trb, formatDate} from '../../Utilities';
import {history, routerPath} from '../../Config';
import moment from 'moment';

class KYCUpdatingMessages extends React.Component {

    render() {
        const {MALR, sendMALR} = this.props;
        if (sendMALR || !MALR) {
            return null;
        }
        const manager_id = checkJsonProp(MALR, 'ID'),
              ActiveAccount = checkJsonProp(MALR, 'ManagedAccounts') != null
              ? MALR.ManagedAccounts.find(x => x.IsActive === true)
              : null,
              accountType = checkJsonProp(ActiveAccount, 'Type'),
              account_id = checkJsonProp(ActiveAccount, 'ID'),
              ValidTill = checkJsonProp(ActiveAccount, 'ValidTill'),
              IsUpdating = checkJsonProp(ActiveAccount, 'IsUpdating'),
              IsAdmin = checkJsonProp(ActiveAccount, 'IsAdmin'),
              date = new Date(),
              date60 = new Date();

        date60.setDate(date.getDate() + 60);

        const data = {
            IsUpdating,
            ValidTill: moment(ValidTill),
            TodayDate: moment(date),
            DateUpdatingNeed: moment(date60),
            manager_id,
            account_id,
            IsAdmin,
        };

        let content;
        switch (accountType) {
            case 'p':
                content = this.FAMessages(data);
                break;
            case 'b':
                content = this.JaMessages(data);
                break;
            default:
                return null;
        }

        return content
        ? <a className="ant-alert ant-alert-error mb-3 pl-1 pr-3 py-2 d-flex align-items-center" style={{backgroundColor: 'white'}} onClick={() => history.push(routerPath.settings + '/kyc-renew')}>
            <ExclamationOutlined style={{fontSize: '3.5rem'}} />
            <div>{content}</div>
        </a>
        : null;
    }

    clickGoUpdatingKYC() {
        history.push(routerPath.settingsKYCRenew);
    }

    FAMessages(data) {
        const {IsUpdating, ValidTill, manager_id, account_id, TodayDate, DateUpdatingNeed} = data;
        if (IsUpdating) {
            return trb(i18.Messages.FaKycIsUpdating);
        }
        if(!ValidTill){
            return null;
        }

        const onClose = manager_id === account_id
            ? this.clickGoUpdatingKYC
            : null;
        const closeText = manager_id === account_id
            ? trb(i18.Buttons.UpdateKyc)
            : trb(i18.Buttons.Close);
        if (ValidTill.isBefore(TodayDate)) {
            return trb(i18.Messages.KycMustUpdatedPerson).replace('$validTill', formatDate(ValidTill));
        }
        if (ValidTill.isBefore(DateUpdatingNeed)) {
            return trb(i18.Messages.KycNeedUpdatedPerson).replace('$validTill', formatDate(ValidTill));
        }

        return null;
    }

    JaMessages(data) {
        const {IsUpdating, ValidTill, IsAdmin, TodayDate, DateUpdatingNeed} = data;
        if (IsUpdating) {
            return trb(i18.Messages.JaKycIsUpdating);
        }
        if(!ValidTill){
            return null;
        }
        const onClose = IsAdmin ? this.clickGoUpdatingKYC : null;
        const closeText = IsAdmin ? trb(i18.Buttons.UpdateKyc) : trb(
            i18.Buttons.Close);
        if (ValidTill.isBefore(TodayDate)) {
            return trb(i18.Messages.KycMustUpdatedCompany).replace('$validTill', formatDate(ValidTill));
        }
        if (ValidTill.isBefore(DateUpdatingNeed)) {
            return trb(i18.Messages.KycNeedUpdatedCompany).replace('$validTill', formatDate(ValidTill));
        }

        return null;
    }
}

function mapStateToProps(state) {
    const {MALR, sendMALR} = state.UACReducers;
    const {translations} = state.translationReducer;
    const {language} = state.languageReducer;
    return {
        MALR,
        sendMALR,
        translations,
        language
    };
}

const connectedKYCUpdatingMessages = connect(mapStateToProps)(
    KYCUpdatingMessages);
export {connectedKYCUpdatingMessages as KYCUpdatingMessages};
