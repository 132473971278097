import {i18, trb} from "@utilities";
import {Tabs} from "antd";
import {routerPath} from "@config";
import {matchPath, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Container} from "@components";
import React from "react";
import {UnsignedPaymentFileDetails, UnsignedPaymentFiles, UnsignedPayments} from "@containers";
import {LeftOutlined} from "@ant-design/icons";

const {TabPane} = Tabs

export const UnsignedPaymentsContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const matchUnsigned = matchPath({
        path: `${routerPath.payments}/${routerPath.unsigned}`,
        exact: true
    }, location.pathname);

    const matchFiles = matchPath({
        path: `${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`,
        exact: true
    }, location.pathname);

    const matchDetails = matchPath({
        path: `${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}/${routerPath.unsignedFileDetails}`,
        exact: true
    }, location.pathname);

    return (
        <Container>

            <Tabs
                defaultActiveKey={location.pathname}
                onChange={(path) => navigate(path)}
                className='mt--3 mb-3'
                activeKey={location.pathname}
            >
                {(matchUnsigned !== null || matchFiles !== null) && (
                    <TabPane tab={trb(i18.ContentTitles.UnsignedPayments)}
                             key={`${routerPath.payments}/${routerPath.unsigned}`}/>)}
                {(matchUnsigned !== null || matchFiles !== null) && (
                    <TabPane tab={trb(i18.ContentTitles.UnsignedPaymentFiles)}
                             key={`${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`}/>)}
                {matchDetails !== null && (
                    <TabPane tab={<><LeftOutlined className="back-arrow"/>{trb(i18.Labels.Back)}</>}
                             key={routerPath.unsignedFiles}/>)}
            </Tabs>


            <Routes>
                <Route path={routerPath.unsignedFiles+'/*'} element={
                    <Routes>
                        <Route path={routerPath.unsignedFileDetails} element={<UnsignedPaymentFileDetails/>} />
                        <Route index element={<UnsignedPaymentFiles/>} />
                    </Routes>

                }/>
                <Route index element={<UnsignedPayments/>}/>
            </Routes>
        </Container>
    )
}
