import PropTypes from 'prop-types';

import { i18, numberFormat, translationGroups, trb } from '../../../Utilities'
import {FormPreviewSwitch} from "../FormPreviewSwitch";

export const DepositPreview = (props) => {
    if (!props) {
        return null;
    }

    return {
        title: trb(i18.Labels.DepositPreview),
        parts: [
            {
                title: trb(i18.Labels.DepositConditions),
                fields: [
                    {
                        title: i18.Labels.DepositType,
                        value: props.ProductType
                    }, {
                        title: i18.Labels.InterestPay,
                        value: props.SubProduct,
                        type: 'translate',
                        translate: translationGroups.InterestPaymentTypes
                    }, {
                        title: i18.Labels.Term,
                        value: props.Period
                    }, {
                        title: i18.Labels.Amount,
                        value: props.Amount,
                        type: 'number',
                        format: numberFormat().currency
                    }, {
                        title: i18.Labels.Currency,
                        value: props.Currency
                    }, {
                        title: i18.Labels.FromAccount,
                        value: props.InitialAccount,
                    },{
                        title: i18.Labels.Interest,
                        value: props.Interest,
                        type: 'number',
                        format: numberFormat().percent
                    }, {
                        title: i18.Labels.AutoRenewDeposit,
                        value: props.AutoResume
                    }
                ]
            }
        ]
    }
};

FormPreviewSwitch.propTypes = {
    data: PropTypes.object.isRequired
};
