import React, {useEffect} from 'react'
import {matchPath, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {library} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import en_GB from 'antd/lib/locale/en_GB';

import {routerPath} from '@config'
import {IdentificationError, IdentificationSuccess, LoginContainer, Main, MainSme, WaitingRoom} from '@containers'
import {CookiePolicy, PageLoader, PrivateRoute} from '@components'
import {LanguageActions} from '@redux'
import {i18, trb} from '@utilities'
import {ConfigProvider} from 'antd'
import PropTypes from 'prop-types'

library.add(far)

export const App = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const {translations} = useSelector(state => state.translationReducer)
    const {language} = useSelector(state => state.languageReducer)
    const {JWEReducer} = useSelector(state => state)


    useEffect(() => {

        const {search} = location
        const params = new URLSearchParams(search)
        const lan = params.get('lan')
        if (lan) {
            dispatch(LanguageActions.setLanguage(lan))
        }

    }, [])


    useEffect(() => {
        const match = matchPath({path: routerPath.waitingRoom, exact: true}, location.pathname)

        if (window.location.pathname.indexOf('waitingRoom') !== -1) {
            return
        }

        if (JWEReducer.jweVerified && match) {
            navigate(routerPath.index)
        }
    }, [JWEReducer.jweVerified])

    if (JWEReducer.jweVerified === false) {
        return (<PageLoader/>)
    }

    const ANTDTranslation = {
        ...en_GB,
        locale: language === 'lt-LT' ? 'lt-LT' : 'en-GB',
        Pagination: {
            items_per_page: trb(i18.ANTDPagination.ItemsPerPage),
            jump_to: trb(i18.ANTDPagination.JumpTo),
            jump_to_confirm: trb(i18.ANTDPagination.JumpToConfirm),
            page: trb(i18.ANTDPagination.Page),
            prev_page: trb(i18.ANTDPagination.PrevPage),
            next_page: trb(i18.ANTDPagination.NextPage),
            prev_5: trb(i18.ANTDPagination.Prev5),
            next_5: trb(i18.ANTDPagination.Next5),
            prev_3: trb(i18.ANTDPagination.Prev3),
            next_3: trb(i18.ANTDPagination.Next3),
        },
        Empty: {
            description: trb(i18.ANTDEmpty.Description),
        }
    }

    return (
        <ConfigProvider locale={ANTDTranslation}>
            {translations?.length > 1 && (
                <>
                    <Routes>
                        <Route path={routerPath.login} element={<LoginContainer/>}/>
                        <Route exact path={routerPath.identificationError} element={<IdentificationError/>}/>
                        <Route exact path={routerPath.identificationSuccess} element={<IdentificationSuccess/>}/>
                        <Route
                            path={routerPath.loginLink}
                            element={<LoginContainer/>}
                        />
                        <Route
                            path={routerPath.waitingRoom}
                            element={<WaitingRoom/>}
                        />
                        <Route
                            path={routerPath.index + '*'}
                            element={<PrivateRoute component={Main}/>}
                        />

                    </Routes>
                    <CookiePolicy/>
                </>
            )}
        </ConfigProvider>
    )
}

App.propTypes = {
    translations: PropTypes.array,
}
