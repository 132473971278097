import React, {PureComponent} from 'react';
import {DatePicker} from 'antd';
import {connect} from "react-redux";

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '../';
import {trb, i18, appEnvSME, CustomIcon, formFieldId} from '@utilities';

import moment from 'moment';

class DateFormField extends PureComponent {
    constructor(props){
        super(props);

        this.state = {
            prevFormat: null,
        }
    }

    render() {
        const {
            language,
            title,
            titleWithoutTranslate,
            className,
            classNameError,
            classNameTitle,
            classNameField,
            inline,
            placeholder,
            minDate,
            maxDate,
            disabled,
            meta,
            dontAllowClear,
            input,
            small
        } = this.props
        let validDate = null;

        switch (language) {
            case 'ru-RU':
                moment.locale('ru', {
                    week: {
                        dow: 1,
                    }
                });
                break;
            case 'lt-LT':
                moment.locale('lt', {
                    week: {
                        dow: 1,
                    }
                });
                break;
            case 'en-GB':
                moment.locale('en', {
                    week: {
                        dow: 1,
                    }
                });
                break;
            default:
                moment.locale('lt', {
                    week: {
                        dow: 1,
                    }
                });
        }

        if (minDate && maxDate) {
            validDate = (current) => {
                return !current.isBetween(moment(minDate).endOf('day').subtract(1, 'day').endOf('day'), moment(maxDate).endOf('day'), null, '[]');
            };
        } else if (minDate) {
            validDate = (current) => {
                return current.isSameOrBefore(moment(maxDate).subtract(1, 'day').endOf('day'));
            };
        } else if (maxDate) {
            validDate = (current) => {
                return current.isSameOrAfter(moment(maxDate).endOf("day"));
            };
        } else {
            validDate = () => {
                return undefined;
            }
        }

        const currentFormat = language === 'lt-LT' ? 'YYYY-MM-DD' : moment.localeData().longDateFormat('L'),
        onChangeFormat = (moment, date) => {
            input.onChange(date);
        };
        let value;
        if (input.value) {
            value = moment(input.value) || moment(input.value, currentFormat);
        }
        else if(dontAllowClear){
            value = moment();
            input.onChange(value.format(currentFormat));
        }

        return <div id={formFieldId(input.name, 'DateWrapper')} className={FormFieldWrapperClasses(className, inline, disabled, false, small)}>
            {title && <div className={classNameTitle
              ?  'form-field-title ' + classNameTitle
              : inline ? 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0' : 'form-field-title col-12'}>{titleWithoutTranslate ? title : trb(title)}</div>}
            <div className={classNameField ? 'form-field-item ' + classNameField : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline)}>
                <DatePicker
                    id={formFieldId(input.name, 'Date')}
                    placeholder={trb(placeholder)}
                    disabled={disabled}
                    name={input.name}
                    value={value}
                    onChange={onChangeFormat}
                    disabledDate={validDate ?? undefined}
                    format={currentFormat}
                    showToday={false}
                    allowClear={!dontAllowClear}
                    onBlur={input.onBlur}
                    getPopupContainer={() => document.getElementById('eb_main')}
                    suffixIcon={appEnvSME ? <CustomIcon style={{color: '#b2b2b2', marginBottom: '2px'}} type="custom" icon="datepicker"/> : null}
                />
            </div>
            <div id={formFieldId(input.name, 'DateError')} className={inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>{meta.touched && (meta.error || meta.submitError)}</div>
        </div>
    };
}

function mapStateToProps(state) {
    const {language} = state.languageReducer;

    return {
        language
    };
}

const connectedDateFormField = connect(mapStateToProps)(DateFormField);
export {connectedDateFormField as DateFormField};
