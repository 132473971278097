import PropTypes from 'prop-types'
import {appEnvSME, currencyFormat, i18, required} from '@utilities'
import {FormFieldType} from '@components'

export const OwnAccountsForm = ({
                                    accountOverdraftInfo,
                                    InitialAccounts,
                                    destinationAcountId,
                                    checkAcountId,
                                    initialAccount,
                                    DestinationAccount,
                                    checkDestinationAcountId
                                }) => {

    const checkOverdraftValue = (value, values) => {
        values.Payment.OverdraftValue = `${
            accountOverdraftInfo?.totalAvailable
                ? currencyFormat(accountOverdraftInfo.totalAvailable)
                : 0
        } EUR`
    }

    return {
        parts: [
            {
                title: i18.Labels.Payer,
                fields: [
                    {
                        name: 'Payment.PaymentType',
                        component: FormFieldType.input,
                        value: 4,
                        className: 'd-none'
                    },
                    {
                        title: i18.Labels.FromAccount,
                        name: 'Payment.InitialAccountID',
                        component: FormFieldType.select,
                        options: InitialAccounts,
                        optionsColor: appEnvSME ? 'color' : null,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        optionsDisabled: 'disabled',
                        validateCompose: true,
                        validate: [required, checkAcountId],
                        className: appEnvSME ? 'mobile' : ''
                    },
                    {
                        title: i18.Labels.OverdraftAvailableAmount,
                        name: 'Payment.OverdraftValue',
                        validate: checkOverdraftValue,
                        component: FormFieldType.input,
                        disabled: true,
                        className:
                            initialAccount && accountOverdraftInfo?.totalAvailable
                                ? ''
                                : 'd-none'
                    }
                ]
            },
            {
                title: i18.Labels.Payee,
                fields: [
                    {
                        title: i18.Labels.ToAccount,
                        name: 'Payment.DestinationAccountID',
                        component: FormFieldType.select,
                        options: DestinationAccount.filter(
                            itm => itm.value !== initialAccount
                        ),
                        optionsColor: appEnvSME ? 'color' : null,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        optionsDisabled: 'disabled',
                        validateCompose: true,
                        validate: [required, checkDestinationAcountId],
                        className: appEnvSME ? 'mobile' : ''
                    },
                    {
                        title: i18.Labels.Amount,
                        name: 'Payment.AmountStr',
                        component: FormFieldType.number,
                        hightlight: true,
                        validate: required
                    }
                ]
            }
        ]
    }
}

OwnAccountsForm.propTypes = {
    data: PropTypes.object.isRequired
}
