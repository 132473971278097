import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AMCActions, PMCActions, UACActions, UACConstants} from '@redux'
import {history, routerPath} from "@config";

import {PageLoader} from "@components";
import {checkJsonProp} from "@utilities";
import jwtDecode from 'jwt-decode'
import * as Sentry from "@sentry/browser";
import {useNavigate} from "react-router-dom";

export const PrivateRoute = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [userVerified, setUserVerified] = useState(false)

    const {IframeStorageReducer, UACReducers, PMCReducers, AMCReducers} = useSelector(state => state);

    const {sendATR, ATR, sendMALRInitial} = UACReducers;
    const {sendPTRInitial} = PMCReducers;
    const {sendCOMPANYInitial} = AMCReducers;

    const handleComponentMounted = async () => {
        const user = IframeStorageReducer?.user;

        if (IframeStorageReducer?.user === undefined) {
            return;
        }

        if (userVerified) {
            return
        }

        if (!IframeStorageReducer.user?.Bearer) {
            navigate(routerPath.login);
        } else {
            const response = await dispatch(UACActions.postATR());
            if (response && response.type && response.type === UACConstants.POST_UAC_ATR_ERROR) {
                navigate(routerPath.login);
                return;
            }
            if (response && response.type && response.type === UACConstants.POST_UAC_ATR_SUCCESS && checkJsonProp(response, 'payload.IsSuccessfull') === false) {
                sessionStorage.clear();
                navigate(routerPath.login);
                return;
            }

            setUserVerified(true)
            if (response && response.type && response.type === UACConstants.POST_UAC_ATR_SUCCESS && checkJsonProp(response, 'payload.IsSuccessfull') === true) {
                await dispatch(AMCActions.getCompany());
                await dispatch(PMCActions.postPTR())
                await dispatch(UACActions.getMALR())

            }
            const {UserCode} = jwtDecode(user?.Bearer)
            Sentry.setUser({username: UserCode})
        }
    }

    useEffect(() => {
        handleComponentMounted()
    }, [IframeStorageReducer?.user])


    let Com = props.component;

    const user = IframeStorageReducer?.user;

    if (!user?.Bearer || sendATR !== false || !ATR || sendPTRInitial || !userVerified) {
        return <PageLoader/>;
    }

    if (sendPTRInitial || sendCOMPANYInitial || sendMALRInitial) {
        return <PageLoader/>;
    }


    return (<Com {...props} />);

}
