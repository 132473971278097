import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IBANLength, IBANValid, IBANMemberInfo } from './actions'
import { AMLActionsInternationPaymentCurrency, fetchAMLInternationalPaymentCurrency } from '@redux'

export const InternationalPaymentSpy = ({ values: { Payment }, ...rest }) => {
  const dispatch = useDispatch()
  const { checkIBANResult, checkIBANLength, checkIBANValid, historicalPayments } = useSelector(
    state => state.PMCReducers
  )

  useEffect(() => {
    const onIBANChanged = async value => {
      const ibanLength = await IBANLength(
        value,
        checkIBANLength,
        checkIBANResult,
        dispatch
      )

      const ibanValid = await IBANValid(
        value,
        ibanLength,
        checkIBANValid,
        dispatch
      )

      const ibanMemberInfo = await IBANMemberInfo(
        value,
        ibanValid,
        checkIBANResult,
        dispatch
      )

      /* TODO: some fields could be filled
      if (ibanMemberInfo) {
        rest.form.change('Payment.BIC', ibanMemberInfo?.Bic?.BIC)
        rest.form.change('Payment.BankName', ibanMemberInfo?.Bic?.BankName)
        rest.form.change(
          'Payment.RecipientBankCountry',
          ibanMemberInfo?.Country.Alpha2
        )
      } else {
        rest.form.change('Payment.BIC', '')
        rest.form.change('Payment.BankName', '')
        rest.form.change('Payment.RecipientBankCountry', '')
      }
      */
    }

    if (Payment?.BeneficiaryAccountNumber) {
      const value = Payment?.BeneficiaryAccountNumber
      onIBANChanged(value)
    }

    if(Payment?.Recipient && historicalPayments?.length) {
      const recipient = historicalPayments.find((payment)=> payment.PayeeAccountNumber === Payment?.BeneficiaryAccountNumber)?.PayeeName
      if(recipient && recipient !== rest.form.getState().values?.Payment?.Recipient) {
        rest.form.change('Payment.Recipient', recipient)
      }
    }

  }, [Payment?.BeneficiaryAccountNumber])

  useEffect(()=> {
    if(Payment?.Recipient && historicalPayments?.length) {
      const iban = historicalPayments.find((payment)=> payment.PayeeName === Payment?.Recipient)?.PayeeAccountNumber
      if(iban && iban !== rest.form.getState().values?.Payment?.BeneficiaryAccountNumber) {
        rest.form.change('Payment.BeneficiaryAccountNumber', iban)
      }
    }
  },[ Payment?.Recipient]);

  useEffect(()=> {
    if(!isNaN(Number(Payment?.Template))){
      const template = rest.templates.find(item => item.TemplateID === Payment.Template);
      if(!template){
        return;
      }
      rest.form.change('Payment.Recipient', template?.Payment?.Recipient)
      rest.form.change('Payment.BeneficiaryAccountNumber', template?.Payment?.BeneficiaryAccountNumber)
      rest.form.change('Payment.Purpose', template?.Payment?.Purpose)
      rest.form.change('Payment.BeneficiaryAddress', template?.Payment?.BeneficiaryAddress)
      rest.form.change('Payment.RecipientBankCountry', template?.Payment?.RecipientBankCountry)
      rest.form.change('Payment.BeneficiaryCity', template?.Payment?.BeneficiaryCity)
      rest.form.change('Payment.BeneficiaryPostCode', template?.Payment?.BeneficiaryPostCode)
      rest.form.change('Payment.CorrespondentBankBic', template?.Payment?.CorrespondentBankBic)
    }
  }, [Payment?.Template])

  useEffect(() => {
    if (rest.forexPayments && Payment?.InitialAccountID) {
      const currency = rest.forexPayments.find((e) => e.AccountID === Payment.InitialAccountID)?.CurrencyName;
      rest.form.change('Payment.Currency', currency || '')

      if (currency) {
        dispatch(fetchAMLInternationalPaymentCurrency(currency));
      }
    }
  }, [Payment?.InitialAccountID])

  return null
}
