import React from 'react';
import {Card} from 'antd';

import {appEnvSME} from '../../Utilities';

export const Container = (props) => (appEnvSME ?
    <Card id={props.id ?? ""} className={`eb_content_card
      ${props.gradient ? 'eb_content_card_gradient' : ''}
      ${props.wide ? 'eb_content_card_wide' : ''} 
      ${props.w75 ? 'w75' : ''}
      `}
    >
      {props.children}
    </Card>
    : <>{props.children}</>
);
