import React from 'react';
import PropTypes from 'prop-types';

import {checkJsonExist, checkJsonProp, dateFormat, formatCurrentDate, formatDate, i18, trb} from '../../../Utilities';
import {ContentNoData, ContentLoader} from '../../';

export const PrintInvoiceDetail = (props) => {
    if (props.sending) {
        return <ContentLoader/>;
    }

    if (!props.data) {
        return <ContentNoData/>;
    }

    if (!props.COMPANY) {
        return null;
    }

    const date = formatDate(formatCurrentDate(), dateFormat().dateTime);

    function header() {
        return <table className="eb_print_header">
            <tbody>
            <tr>
                <td className="eb_print_header_icon">
                    <div>
                        <img src={`${process.env.REACT_APP_BASE_PATH_IMG}images/logos/${process.env.REACT_APP_LOGO}`} alt={process.env.REACT_APP_TITLE}/>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="eb_print_header_inner">
                        <tbody>
                        <tr>
                            <td colSpan={2}><b>{checkJsonProp(props.COMPANY, 'OrgName')}</b></td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.CompanyCode)}</th>
                            <td>{checkJsonProp(props.COMPANY, 'OrgCode')}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.CompanyAddress)}</th>
                            <td>{checkJsonProp(props.COMPANY, 'OrgAddress')}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.VatCode)}</th>
                            <td>{checkJsonProp(props.COMPANY, 'VATCode')}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.CompanyEmail)}</th>
                            <td>{checkJsonProp(props.COMPANY, 'OrgEMail')}</td>
                        </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table className="eb_print_header_inner">
                        <tbody>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.FormattedDateAndTime)}</th>
                            <td>{date}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.OperationDate)}</th>
                            <td>{checkJsonProp(props, 'data.PaymentDateFormatted')}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.Client)}</th>
                            <td>{props.data.ClientName}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.ClientCode)}</th>
                            <td>{props.data.ClientCode}</td>
                        </tr>
                        <tr>
                            <th className="text-right">{trb(i18.PrintLabels.ClientAddress)}</th>
                            <td className="text-nowrap">{props.data.ClientAddress}</td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    };

    return <div className="eb_print_wrapper">
        {header()}
        <table className="eb_print_table">
            <tbody>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.DocumentNumber)}</div>
                    {checkJsonExist(props, 'data.OperationGroup') &&
                    <b>{checkJsonProp(props, 'data.OperationGroup')}</b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.ClientIBAN)}</div>
                    {checkJsonExist(props, 'data.PayerAccountNumber') &&
                    <b>{checkJsonProp(props, 'data.PayerAccountNumber')}</b>
                    }
                </td>
                <td colSpan={2}>
                    <div>{trb(i18.PrintLabels.PayerName)}</div>
                    {checkJsonExist(props, 'data.PayerName') &&
                    <b>{checkJsonProp(props, 'data.PayerName')}</b>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.PayerIdentCode)}</div>
                    {checkJsonExist(props, 'data.PayerIdentCode') &&
                    <b>{checkJsonProp(props, 'data.PayerIdentCode')}</b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.PayerAddress)}</div>
                    {checkJsonExist(props, 'data.PayerAddress') &&
                    <b>{checkJsonProp(props, 'data.PayerAddress')} </b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.PayerBank)}</div>
                    {checkJsonExist(props, 'data.PayerBank') &&
                    <b>{checkJsonProp(props, 'data.PayerBank')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.InitPayerIdentTypeValue)}</div>
                    {checkJsonExist(props, 'data.InitPayerIdentTypeValue') &&
                    <b>{checkJsonProp(props, 'data.InitPayerIdentTypeValue')} </b>
                    }
                </td>
                <td colSpan={2}>
                    <div>{trb(i18.PrintLabels.InitPayerName)}</div>
                    {checkJsonExist(props, 'data.InitPayerName') &&
                    <b>{checkJsonProp(props, 'data.InitPayerName')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.PayeeAccountNumber)}</div>
                    {checkJsonExist(props, 'data.PayeeAccountNumber') &&
                    <b>{checkJsonProp(props, 'data.PayeeAccountNumber')} </b>
                    }
                </td>
                <td colSpan={2}>
                    <div>{trb(i18.PrintLabels.PayeeName)}</div>
                    {checkJsonProp(props, 'data.PayeeName') &&
                    <b>{checkJsonProp(props, 'data.PayeeName')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.PayeeIdentCode)}</div>
                    {checkJsonExist(props, 'data.PayeeIdentCode') &&
                    <b>{checkJsonProp(props, 'data.PayeeIdentCode')} </b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.PayeeAddress)}</div>
                    {checkJsonExist(props, 'data.PayeeAddress') &&
                    <b>{checkJsonProp(props, 'data.PayeeAddress')} </b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.PayeeBank)}</div>
                    {checkJsonExist(props, 'data.PayeeBank') &&
                    <b>{checkJsonProp(props, 'data.PayeeBank')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <div>{trb(i18.PrintLabels.FinalPayeeIdentTypeValue)}</div>
                    {checkJsonExist(props, 'data.FinalPayeeIdentTypeValue') &&
                    <b>{checkJsonProp(props, 'data.FinalPayeeIdentTypeValue')} </b>
                    }
                </td>
                <td colSpan={2}>
                    <div>{trb(i18.PrintLabels.FinalPayeeName)}</div>
                    {checkJsonExist(props, 'data.FinalPayeeName') &&
                    <b>{checkJsonProp(props, 'data.FinalPayeeName')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <div>{trb(i18.PrintLabels.PaymentAmount)}</div>
                    {checkJsonExist(props, 'data.PaymentAmountFormatted') &&
                    <b>{checkJsonProp(props, 'data.PaymentAmountFormatted')} </b>
                    }
                </td>
                <td>
                    <div>{trb(i18.PrintLabels.CommFeeFormatted)}</div>
                    {checkJsonExist(props, 'data.CommFeeFormatted') &&
                    <b>{checkJsonProp(props, 'data.CommFeeFormatted')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td colSpan={3}>
                    <div>{trb(i18.PrintLabels.PaymentPurpose)}</div>
                    {checkJsonExist(props, 'data.PaymentPurpose') &&
                    <b>{checkJsonProp(props, 'data.PaymentPurpose')} </b>
                    }
                </td>
            </tr>
            <tr>
                <td colSpan={3}>
                    <div>{trb(i18.PrintLabels.PaymentUniqueCode)}</div>
                    {checkJsonExist(props, 'data.PaymentUniqueCode') &&
                    <b>{checkJsonProp(props, 'data.PaymentUniqueCode')} </b>
                    }
                </td>
            </tr>
            </tbody>
        </table>
    </div>
};

PrintInvoiceDetail.propTypes = {
    sending: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};
