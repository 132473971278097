export const EUR = 'EUR'
export const USD = 'USD'
export const KRW = 'KRW'
export const CAD = 'CAD'
export const CNY = 'CNY'
export const AUD = 'AUD'
export const INR = 'INR'
export const GBP = 'GBP'
export const MYR = 'MYR'
export const SGD = 'SGD'
export const NOK = 'NOK'
export const DKK = 'DKK'
export const PLN = 'PLN'
export const SEK = 'SEK'
export const HUF = 'HUF'
export const RON = 'RON'

export const BIC = 'BIC'
export const SHA = 'SHA'
export const SORT_CODE = 'SORT CODE'

export const PaymentType = {
    SEPA: 2,
    TARGET2: 6,
    INTERNATIONAL: 7
}

export const INTERNATIONAL_PAYMENT_SYSTEM = 'X4'
export const TARGET2_PAYMENT_SYSTEM = 'X3'
export const SEPA_PAYMENT_SYSTEM = 'SepaSct'

export const Payment = {
    OWN_ACCOUNTS: 'own-accounts',
    NEW: 'new-payment',
    FEE: 'fee-payment',
    INTERNATIONAL: 'international-payment',
    FOREX_EXCHANGE: 'forex-exchange'
}

