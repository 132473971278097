import { DepositConstants } from './constants'

const initialState = {}

export const DepositReducers = function(state = initialState, action) {
  switch (action.type) {
    case DepositConstants.POST_DEPOSIT_TERMINATION_REQUEST:
      return {
        ...state,
        send: true,
        sendDeposit: true,
        depositTerminationData: null,
        depositTerminationRequest: action.requestData,
        depositTerminationConfirm: null
      }
    case DepositConstants.POST_DEPOSIT_TERMINATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendDeposit: false,
        depositTerminationData: action.payload,
        depositTerminationRequest: action.requestData
      }
    case DepositConstants.POST_DEPOSIT_TERMINATION_ERROR:
      return {
        ...state,
        send: false,
        sendDeposit: false,
        errors: action.error
      }
    case DepositConstants.POST_DEPOSIT_TERMINATION_CONFIRM_REQUEST:
      return {
        ...state,
        send: true,
        sendDeposit: true,
        depositTerminationConfirm: null
      }

    case DepositConstants.POST_DEPOSIT_OFFER_REQUEST:
      return {
        ...state,
        send: true,
        sendDepositOffer: true,
        depositOffer: null
      }
    case DepositConstants.POST_DEPOSIT_OFFER_SUCCESS:
      return {
        ...state,
        send: false,
        sendDepositOffer: false,
        depositOffer: action.payload
      }
    case DepositConstants.POST_DEPOSIT_OFFER_ERROR:
      return {
        ...state,
        send: false,
        sendDepositOffer: false,
        errors: action.error
      }
    case DepositConstants.POST_CONFIRM_DEPOSIT_REQUEST:
      return {
        ...state,
        send: true,
        sendConfirmDeposit: true,
        confirmDepositData: null
      }

    case DepositConstants.POST_CONFIRM_DEPOSIT_SUCCESS:
      return {
        ...state,
        send: false,
        sendConfirmDeposit: false,
        confirmDepositData: action.payload
      }
    case DepositConstants.POST_CONFIRM_DEPOSIT_ERROR:
      return {
        ...state,
        send: false,
        sendConfirmDeposit: false,
        errors: action.error
      }
    case DepositConstants.POST_CONFIRM_DEPOSIT_CLEAR_DATA:
      return {
        ...state,
        confirmDepositData: null
      }

    case DepositConstants.POST_DEPOSIT_DOCUMENT_REQUEST:
      return {
        ...state,
        send: true,
        sendDepositDocument: true,
        depositDocument: null
      }
    case DepositConstants.POST_DEPOSIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        send: false,
        sendDeposittDocument: false,
        depositDocument: action.payload.pdfBytes
      }
    case DepositConstants.POST_DEPOSIT_DOCUMENT_ERROR:
      return {
        ...state,
        send: false,
        sendDepositDocument: false,
        errors: action.error
      }

    default:
      return state
  }
}
