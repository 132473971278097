export const routerPath = {
  accounts: '/accounts',
  deposits: '/deposits',
  index: '/',
  invoice: '/invoice',
  login: '/login',
  identificationError: '/failIdentification',
  identificationSuccess: '/successIdentification',
  loginLink: '/login/:link',
  waitingRoom: '/waitingRoom/:id',
  paymentsLogin: '/paymentsLogin',
  operations: '/operations',
  payments: '/payments',
  payment: ':paymentType',
  paymentOwn: 'own-accounts',
  paymentNew: 'new-payment',
  paymentFee: 'fee-payment',
  paymentInternational: 'international-payment',
  paymentForex: 'forex-exchange',
  paymentGroup: 'group-payment',
  paymentGroups: 'groups',
  paymentUploading: 'uploading',
  unsigned: 'unsigned',
  unsignedFiles: 'unsigned-files',
  unsignedFileDetails: ':FileGuid',
  paymentTemplates: 'templates',
  paymentTemplatesNew: ':templateType',
  settings: '/settings',
  settingsAccountsLimits: 'accounts-limits',
  settingsAccountsManagement: 'accounts-management',
  settingsBlockCard: 'block-card',
  settingsChangePassword: 'change-password',
  settingsKYCRenew: 'kyc-renew',
  messages: '/messages',
  messagesNew: 'New',
  messagesInbox: 'Inbox',
  messagesBroadCast: 'BroadCast',
  logs: '/logs'
}
