import React, {Component} from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from 'antd';

import {trb} from '@utilities';
import './openingButtons.css';


class OpeningButtons extends Component {
    constructor(props) {
        super(props);

        this.clickOpeningButton = this.clickOpeningButton.bind(this);
    }

    clickOpeningButton = (e, fn, params) => {
        e.domEvent.stopPropagation();
        fn(params);
    };

    render() {
        const {buttons} = this.props;

        const menu = <Menu>
            {buttons.map((item, index) =>
                <Menu.Item
                    key={index}
                    onClick={(e) => this.clickOpeningButton(e, item.function, this.props.params)}
                ><span className="eb_fs_14">{item.icon}</span>{trb(item.text)}</Menu.Item>
            )}
        </Menu>;

        return (
            <Dropdown
                overlayClassName="d-print-none"
                overlay={menu}
                placement="bottomRight"
                onClick={(e) => e.stopPropagation()}
            >
                {this.props.title ? (
                    <Button
                        type={this.props.type ? this.props.type : null}
                        size={this.props.size ? this.props.size : null}
                        icon={this.props.icon ? this.props.icon : null}
                    >{this.props.title}</Button>
                ) : (
                    <Button shape="circle"><SettingOutlined /></Button>
                )}
            </Dropdown>
        );
    }
}

export {OpeningButtons};
