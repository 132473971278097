import {MonitoringServices} from "./services";
import {MonitoringConstants} from "./constants";

export const MonitoringActions = {
    getSessionLogs
};

function getSessionLogs() {
    return dispatch => {
        dispatch(request());

        return MonitoringServices.getSessionLogs()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: MonitoringConstants.GET_SESSION_LOGS_REQUEST}
    }

    function success(data) {
        return {type: MonitoringConstants.GET_SESSION_LOGS_SUCCESS, payload: data};
    }

    function failure(error) {
        return {type: MonitoringConstants.GET_SESSION_LOGS_ERROR, error};
    }
}