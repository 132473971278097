import moment from 'moment'
import sha1 from 'sha1'

export const checkCookieForUser = (LoginCode, authCookie) => {
  let result = true;
  if (authCookie && authCookie.TOKENS && authCookie.TOKENS.length > 0 && authCookie.exp) {
    const { TOKENS, exp } = authCookie;
    const unixNow = moment().valueOf();
    const date = moment(exp * 1000).format('YYYY-MM-DD');
    const encodedLoginCode = sha1(LoginCode);
    if (exp * 1000 > unixNow) {
      TOKENS.forEach(token => {
        if (token === encodedLoginCode) {
          result = false;
        }
      })
    }
  }
  return result;
}
