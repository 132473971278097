import thunk from 'redux-thunk'
import * as History from 'history'
import {serverErrorsMiddleware} from '@redux/Middleware'
import {configureStore} from "@reduxjs/toolkit";
import {rootReducer} from './rootReducer';

export const history = History.createBrowserHistory()
let initialState = {};
const enhancers = []
const middleware = [thunk, serverErrorsMiddleware]

const config = {reducer: rootReducer(history), enhancers, middleware}

if (process.env.NODE_ENV === 'test') {

    const testingState = {
        translationReducer: {
            translations: [{}, {}]
        },
        UACReducers: {
            sendATR: false,
            ATR: {}
        }
    }

    config.preloadedState = {...initialState,...testingState}
}

export const store = configureStore(config)


