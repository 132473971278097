import React from 'react';
import {connect} from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import {LanguageSelect} from "@components";
import {checkJsonProp, i18, Logout, trb, appEnvMano} from "@utilities";
import {EServices} from "../PartSwapMenu/Components/PartHeader/Components";

const PartTop = (props) => {
    if(!props.COMPANY){
        return null;
    }
   return (
       <div className="eb_top">
           <div className="eb_inner d-flex flex-column flex-md-row flex-wrap flex-sm-nowrap justify-content-between align-items-stretch align-items-md-center pt-2">
               <div className='mb-2 text-center text-md-left'>{checkJsonProp(props.COMPANY, 'OrgName')}</div>
               <div className='mb-2 d-flex justify-content-between d-sm-block text-right'>
                   {!appEnvMano && <EServices/>}
                   {appEnvMano && <LanguageSelect/>}
                   <Button
                       className="text-click"
                       onClick={() => Logout()}
                   >{trb(i18.Buttons.Logout)} <LogoutOutlined /></Button>
               </div>
           </div>
       </div>
   );
};

function mapStateToProps(state) {
    const {COMPANY} = state.AMCReducers;
    return {
        COMPANY
    };
}

const connectedPartTop = connect(mapStateToProps)(PartTop);
export {connectedPartTop as PartTop};
