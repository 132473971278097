export const AMCConstants = {
  GET_ALR_REQUEST: 'GET_ALR_REQUEST',
  GET_ALR_SUCCESS: 'GET_ALR_SUCCESS',
  GET_ALR_ERROR: 'GET_ALR_ERROR',

  POST_LLO_REQUEST: 'POST_LLO_REQUEST',
  POST_LLO_SUCCESS: 'POST_LLO_SUCCESS',
  POST_LLO_ERROR: 'POST_LLO_ERROR',

  POST_ADR_REQUEST: 'POST_ADR_REQUEST',
  POST_ADR_SUCCESS: 'POST_ADR_SUCCESS',
  POST_ADR_ERROR: 'POST_ADR_ERROR',

  POST_ADR_PRINT_REQUEST: 'POST_ADR_PRINT_REQUEST',
  POST_ADR_PRINT_SUCCESS: 'POST_ADR_PRINT_SUCCESS',
  POST_ADR_PRINT_ERROR: 'POST_ADR_PRINT_ERROR',

  POST_SOD_REQUEST: 'POST_SOD_REQUEST',
  POST_SOD_SUCCESS: 'POST_SOD_SUCCESS',
  POST_SOD_ERROR: 'POST_SOD_ERROR',

  POST_CAINF_REQUEST: 'POST_CAINF_REQUEST',
  POST_CAINF_SUCCESS: 'POST_CAINF_SUCCESS',
  POST_CAINF_ERROR: 'POST_CAINF_ERROR',

  POST_BCA_REQUEST: 'POST_BCA_REQUEST',
  POST_BCA_SUCCESS: 'POST_BCA_SUCCESS',
  POST_BCA_ERROR: 'POST_BCA_ERROR',

  POST_ACCLMTS_REQUEST: 'POST_ACCLMTS_REQUEST',
  POST_ACCLMTS_SUCCESS: 'POST_ACCLMTS_SUCCESS',
  POST_ACCLMTS_ERROR: 'POST_ACCLMTS_ERROR',

  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

  POST_AMC_ACCSXLSX_REQUEST: 'POST_AMC_ACCSXLSX_REQUEST',
  POST_AMC_ACCSXLSX_SUCCESS: 'POST_AMC_ACCSXLSX_SUCCESS',
  POST_AMC_ACCSXLSX_ERROR: 'POST_AMC_ACCSXLSX_ERROR',

  POST_ADROD_REQUEST: 'POST_ADROD_REQUEST',
  POST_ADROD_SUCCESS: 'POST_ADROD_SUCCESS',
  POST_ADROD_ERROR: 'POST_ADROD_ERROR',

  GET_ACCOUNT_OVERDRAFT_REQUEST: 'GET_ACCOUNT_OVERDRAFT_REQUEST',
  GET_ACCOUNT_OVERDRAFT_SUCCESS: 'GET_ACCOUNT_OVERDRAFT_SUCCESS',
  GET_ACCOUNT_OVERDRAFT_ERROR: 'GET_ACCOUNT_OVERDRAFT_ERROR',

  GET_FOREX_CURRENCY_REQUEST: 'GET_FOREX_CURRENCY_REQUEST',
  GET_FOREX_CURRENCY_SUCCESS: 'GET_FOREX_CURRENCY_SUCCESS',
  GET_FOREX_CURRENCY_ERROR: 'GET_FOREX_CURRENCY_ERROR',

  GET_FOREX_RATE_REQUEST: 'GET_FOREX_RATE_REQUEST',
  GET_FOREX_RATE_SUCCESS: 'GET_FOREX_RATE_SUCCESS',
  GET_FOREX_RATE_ERROR: 'GET_FOREX_RATE_ERROR',

  CLEAR_FOREX_RATE: 'CLEAR_FOREX_RATE',

  POST_FOREX_CONFIRM_REQUEST: 'POST_FOREX_CONFIRM_REQUEST',
  POST_FOREX_CONFIRM_SUCCESS: 'POST_FOREX_CONFIRM_SUCCESS',
  POST_FOREX_CONFIRM_ERROR: 'POST_FOREX_CONFIRM_ERROR',

  POST_FOREX_DRAFT_REQUEST: 'POST_FOREX_DRAFT_REQUEST',
  POST_FOREX_DRAFT_SUCCESS: 'POST_FOREX_DRAFT_SUCCESS',
  POST_FOREX_DRAFT_ERROR: 'POST_FOREX_DRAFT_ERROR'
}
