import { checkJsonProp } from './'
import { TranslationContexts, store } from '@redux'

function getClassificatorsFn() {
  const state = store.getState()
  const { translations } = state.translationReducer
  const { language } = state.languageReducer

  return checkJsonProp(
    translations.find(
      e =>
        e.context === TranslationContexts.classificators &&
        e.culture === language
    ),
    'Groups'
  )
}

export const getClassificators = () => {
  return getClassificatorsFn()
}

export const classifikatorsMap = obj =>
  obj
    ? Object.entries(obj).map(item => {
        return { label: item[1], value: item[0] }
      })
    : null
