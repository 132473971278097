import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Button, Card} from 'antd'

import {i18, trb} from '@utilities'
import {ContentMenuMobile, Notification} from "@components";
import {CopyOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";


export const PartContent = ({children}) => {

    const contentRef = React.createRef()

    const {
        contentMenu,
        contentMenuPath,
        contentTitle,
        menuCollapsed
    } = useSelector(state => state.MenuReducer)

    const {COMPANY} = useSelector(state => state.AMCReducers);


    const updateDimensions = () => {
        const termNode = document.getElementsByClassName('form-field-terms')[0]
        const otherNode = document.getElementsByClassName('form-field-wrapper')[0]

        if (termNode && otherNode && contentRef.current) {
            const marginSize =
                ((contentRef.current.clientWidth - otherNode.clientWidth) / 2) * -1
            termNode.style.marginRight = `${marginSize}px`
            termNode.style.marginLeft = `${marginSize}px`
        }
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions)

        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])


    useEffect(() => {
        const interval = setInterval(() => {
            updateDimensions()
        }, 10)
        setTimeout(() => clearInterval(interval), 300)
    }, [menuCollapsed])

    return (
        <div className="eb_main" id="eb_main">
            <div className="eb_inner d-flex">
                <div className="eb_content_wrapper w-100">
                    <div className="eb_content" ref={contentRef}>
                        {contentTitle && (
                            <div
                                className={`eb_content_title ${window.location.pathname ===
                                '/' && 'eb_content_title_dashboard'} text-black eb_fw_400 d-flex flex-column`}>
                                <ContentMenuMobile/>
                            </div>
                        )}
                        {children}
                        <div className="text-gray-basic eb_fw_300 mt-5 footer-copyright">
                            {trb(i18.Labels.FooterCopyright, {yyyy: new Date().getFullYear()})}
                        </div>
                    </div>
                </div>
                <div className="eb_banners" id="ebBanners">
                    <Card
                        id="ebBannersLogo"
                        className="mx-4 mt-0 mb-3"
                        title={trb(i18.Labels.Suggestions)}
                        bordered={false}>
                        <img
                            className="w-100"
                            src={`${process.env.REACT_APP_BASE_PATH_IMG}images/banners/banner.jpg`}
                        />
                       
                    </Card>
                    <Card
                        id="ebBannersContacts"
                        className="mx-4 mb-3 mt-0"
                        title={trb(i18.Labels.Contacts)}
                        bordered={false}
                    >
                        <div className="w-100">
                            <div>
                                <Button id="ebBannersPhoneNumberButton" type="link" href={`tel: ${COMPANY?.PhoneNumber}`} className="px-0">
                                    <div className="d-flex align-items-center">
                                        <PhoneOutlined className="pr-3"/>
                                        {COMPANY?.PhoneNumber}
                                        <span
                                            id="ebBannersPhoneNumberCopy"
                                            className="pl-3"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                navigator.clipboard.writeText(COMPANY?.PhoneNumber)
                                                Notification({
                                                    type: 'success',
                                                    message: i18.NotificationTitles.Copied
                                                });
                                            }}
                                        >
                        <CopyOutlined/>
                      </span>
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button id="ebBannersEMailButton" type="link" href={`mailto: ${COMPANY?.OrgEMail}`} className="px-0">
                                    <div className="d-flex align-items-center">
                                        <MailOutlined className="pr-3"/>
                                        {COMPANY?.OrgEMail}
                                        <span
                                            id="ebBannersEMailCopy"
                                            className="pl-3"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                navigator.clipboard.writeText(COMPANY?.OrgEMail)
                                                Notification({
                                                    type: 'success',
                                                    message: i18.NotificationTitles.Copied
                                                });
                                            }}
                                        >
                        <CopyOutlined/>
                      </span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}