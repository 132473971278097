import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IBANLength, IBANMemberInfo, IBANValid} from './actions'
import {INTERNATIONAL_PAYMENT_SYSTEM, PaymentType, SEPA_PAYMENT_SYSTEM, TARGET2_PAYMENT_SYSTEM} from "@utilities";
import {useLocation, useNavigate} from "react-router-dom";

export const TemplateSpy = ({values: {Payment}, ...rest}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const {checkIBANResult, checkIBANLength, checkIBANValid} = useSelector(
        state => state.PMCReducers
    )

    useEffect(() => {
        if (Payment.PaymentSystem === INTERNATIONAL_PAYMENT_SYSTEM) {
            return;
        }
        const onIBANChanged = async value => {

            const ibanLength = await IBANLength(
                value,
                checkIBANLength,
                checkIBANResult,
                dispatch
            )

            const ibanValid = await IBANValid(
                value,
                ibanLength,
                checkIBANValid,
                dispatch
            )

            const ibanMemberInfo = await IBANMemberInfo(
                value,
                ibanValid,
                checkIBANResult,
                dispatch
            )


            if (ibanMemberInfo?.Participant?.SepaInst ||
                ibanMemberInfo?.Participant?.SepaSCT ||
                ibanMemberInfo?.Participant?.SepaSDD) {
                rest.form.change('Payment.BIC', ibanMemberInfo?.Bic?.BIC)
                rest.form.change('Payment.BankName', ibanMemberInfo?.Bic?.BankName)
                rest.form.change('Payment.RecipientBankCountry', ibanMemberInfo?.Country.Alpha2)
                rest.form.change('Payment.PaymentType', PaymentType.SEPA);
                rest.form.change('Payment.PaymentSystem', SEPA_PAYMENT_SYSTEM);
                // if (!location.pathname.includes(PAYMENT_TEMPLATE_NEW)) {
                //     navigate(`${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`.replace(':templateType', PAYMENT_TEMPLATE_NEW),{state: {data: {...rest.data, Payment:{...rest.data.Payment, BeneficiaryAccountNumber: value}}}})
                // }
            } else {

                /*
                rest.form.change('Payment.BIC', '')
                rest.form.change('Payment.BankName', '')
                rest.form.change('Payment.RecipientBankCountry', '')
                */


                if (rest?.data?.Payment?.PaymentType === PaymentType.TARGET2) {
                    rest.form.change('Payment.PaymentSystem', TARGET2_PAYMENT_SYSTEM);
                    rest.form.change('Payment.PaymentType', PaymentType.TARGET2);
                } else {
                    rest.form.change('Payment.PaymentSystem', INTERNATIONAL_PAYMENT_SYSTEM);
                    rest.form.change('Payment.PaymentType', PaymentType.INTERNATIONAL);
                    // if (!location.pathname.includes(PAYMENT_TEMPLATE_INTERNATIONAL)) {
                    //     navigate(`${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`.replace(':templateType', PAYMENT_TEMPLATE_INTERNATIONAL), {state: {data: {...rest.data, Payment:{...rest.data.Payment, BeneficiaryAccountNumber: value}}}})
                    // }

                }
            }
        }

        if (Payment?.BeneficiaryAccountNumber) {
            const value = Payment?.BeneficiaryAccountNumber
            onIBANChanged(value)
        }
    }, [Payment?.BeneficiaryAccountNumber])

    useEffect(() => {
        if (rest?.data?.Payment?.PaymentType === PaymentType.SEPA && (checkIBANResult?.Participant?.SepaSCT || checkIBANResult?.Participant?.SepaInst)) {
            rest.form.change('Payment.PaymentSystem', 'SepaSct');
        } else if (rest?.data?.Payment?.PaymentType === PaymentType.SEPA) {
            rest.form.change('Payment.PaymentSystem', TARGET2_PAYMENT_SYSTEM);
        }
    }, [checkIBANResult])

    return null
}
