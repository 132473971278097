import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Table } from 'antd'

import { ContentLoader, ContentNoData } from '@components'
import {
  appEnvSME,
  checkJsonProp,
  dateFormat,
  formatDate,
  getClassificators,
  i18,
  ToJavaScriptDate2,
  trb
} from '@utilities'
import { ChatHeader, ChatMessages } from '../Inbox/Components'
import { MessagingActions } from '@redux'
import {DeleteOutlined} from "@ant-design/icons";

const ButtonGroup = Button.Group


class BroadCastContainer extends Component {

  constructor(props) {
    super(props)
    this.state = { chatOpened: false, reset: false }
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(MessagingActions.getBroadCastList())
  }

  convertDate(value) {
    return ToJavaScriptDate2(value, dateFormat().dateTime)
  }

  clickChat = async (e, item) => {
    e.stopPropagation()
    const { dispatch } = this.props

    await dispatch(MessagingActions.getBroadCastMessage(item.MessageId))
    this.setState({ chatOpened: true })
  }

  deleteMessage = async (e, item) => {
    e.stopPropagation()
    const { dispatch } = this.props
    await dispatch(MessagingActions.deleteBroadCastMessage(item.MessageId))
    await dispatch(MessagingActions.getBroadCastList())
    await dispatch(MessagingActions.getNewMessagesCount())
    this.setState({ chatOpened: false })
  }

  onClose = () => {
    this.setState({
      chatOpened: false
    })
    const { dispatch } = this.props
    dispatch(MessagingActions.getBroadCastList())
    dispatch(MessagingActions.getNewMessagesCount())
  }

  render() {
    const { BroadCastList, sendBroadCastList, BroadCastMessage } = this.props
    if (sendBroadCastList) {
      return <ContentLoader />
    }
    if (!BroadCastList || !BroadCastList.BroadCastMessages || BroadCastList.BroadCastMessages.lenght === 0) {
      return <ContentNoData />
    }

    const MSGL = getClassificators() ? getClassificators().MSGL : {},
      keysMSGL = Object.keys(MSGL),
      checkMSGL = (subject) => keysMSGL.find(item => item == subject) !== undefined ? true : false

      const columns = appEnvSME ?
        [
          {
            title: trb(i18.Chat.DateCreated),
            dataIndex: 'DateCreated',
            render: (el) => <span className="text-nowrap">{formatDate(el)}</span>,
            width: 200
          },
          {
            title: trb(i18.Chat.Subject),
            dataIndex: 'Subject',
            render: (subject) => checkMSGL(subject) ? MSGL[subject] : subject
          }
        ]
        :
        [
          {
            title: trb(i18.Chat.Subject),
            dataIndex: 'Subject',
            render: (subject) => checkMSGL(subject) ? MSGL[subject] : subject,
            width: 200
          },
          {
            title: trb(i18.Chat.DateCreated),
            dataIndex: 'DateCreated',
            render: (el) => <span className="text-nowrap">{formatDate(el)}</span>,
            width: 150,
          },
          {
            title: '',
            dataIndex: 'ChatId',
            render: (el, record) => {
              return record.CanDelete ?
                <ButtonGroup>
                  {buttonDeleteChat && buttonDeleteChat.map((itemButton, index) =>
                    <Button
                      key={index}
                      onClick={(e) => itemButton.func && itemButton.func(e, record)}
                      {...itemButton.props}
                    />
                  )}
                </ButtonGroup>
                :
                null
            },
            width: 10
          }
        ];

    const buttonDeleteChat = [{
        title: i18.Buttons.Submit,
        func: this.deleteMessage,
        props: {
          icon: <DeleteOutlined />,
          type: 'primary',
          htmlType: 'submit'
        }
      }],
      buttonDeleteMessage = [{
        title: i18.Buttons.Submit,
        func: this.deleteMessage,
        props: {
          icon: <DeleteOutlined />,
          type: 'primary',
          htmlType: 'submit'
        }
      }]

    return <>
        <Table
          id="BroadCastMessagesTable"
          dataSource={BroadCastList.BroadCastMessages}
          columns={columns}
          showHeader
          pagination={false}
          className="w-100"
          onRow={(item, index) => {
            return {
              onClick: e => {
                this.clickChat(e, item)
              }
            }
          }}
        />
      <Drawer
        width={1000}
        onClose={this.onClose}
        visible={this.state.chatOpened}
      >
        <div className="bbs_tab_content_inner" id="BroadCastMessagesDrawer">
          <ChatHeader data={BroadCastMessage}
                      Subject={checkMSGL(checkJsonProp(BroadCastMessage, 'Subject')) ? MSGL[BroadCastMessage.Subject] : checkJsonProp(BroadCastMessage, 'Subject')} />
          <ChatMessages data={BroadCastMessage} buttons={buttonDeleteMessage} />
        </div>
      </Drawer>
    </>
  }
}

function mapStateToProps(state) {
  const { BroadCastList, sendBroadCastList, BroadCastMessage, sendBroadCastMessage } = state.MessagingReducers
  return {
    BroadCastList,
    sendBroadCastList,
    BroadCastMessage,
    sendBroadCastMessage
  }
}

const connectedBroadCastContainer = connect(mapStateToProps)(BroadCastContainer)
export { connectedBroadCastContainer as BroadCastContainer }
