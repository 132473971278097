import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Card, Col, Pagination, Row, Table} from 'antd'
import {DownloadOutlined, PrinterOutlined} from '@ant-design/icons'

import {AMCActions, DMCActions, DMCConstants, PrintActions} from '@redux'
import {
    appEnvSME,
    blobToFileDownload,
    checkJsonExist,
    checkJsonProp,
    CustomIcon,
    formatDate,
    getWindowSize,
    i18,
    logError,
    numberFormat,
    translationGroups,
    trb
} from '@utilities'
import {
    ContentLoader,
    ContentNoData,
    EBTableExpand,
    EBTableRowSelectionContent,
    EBTableRowSelectionContentButtons,
    Notification,
    Number,
    Print
} from '@components'
import {Summary} from './Summary'

import moment from 'moment'
import {useLocation} from "react-router-dom";

export const InvoiceListContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const windowSize = getWindowSize()
    const {ADR, COMPANY, sendADR, sendSOD, filterData, ADROD} = useSelector(
        state => state.AMCReducers
    )
    const {ALR} = useSelector(state => state.AMCReducers)
    const {MALR} = useSelector(state => state.UACReducers)
    const {language} = useSelector(state => state.languageReducer)

    const [loadingPrint, setLoadingPrint] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [itemClicked, setItemClicked] = useState(null)
    const [overdraftInfoDesc, setOverdraftInfoDesc] = useState(null)
    const [loadOverdraftInfo, setLoadOverdraftInfo] = useState(false)
    const [adroLoaded, setAdroLoaded] = useState(null);

    useEffect(() => {
        if (location.state?.accountId && location.state?.accountId!== ADR?.AccountWithDetails?.AccountID) {
            reloadData(location.state?.accountId)
        }
    }, [location, ADR, ADROD])


    const reloadData = AccountID => {
        const aDRData = {
            AccountID,
            FilterID: 2,
            FilterBeginDate: '',
            FilterEndDate: ''
        }
        dispatch(AMCActions.postADR(aDRData))
        setAdroLoaded(true)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let overdraftItem = null;
        // if(location?.state?.accountId){
        //     overdraftItem = ADR?.Accounts?.find(
        //         itm => itm.AccountID === location?.state?.accountId
        //     )
        //     debugger;
        // } else

        {
            const accountNr = ADR?.AccountWithDetails?.AccountNumber?.replace(/ /gi, '')

            overdraftItem = ADR?.Accounts?.find(
                itm => itm.CurrentAccountIban === accountNr
            )
        }

        if (overdraftItem && overdraftItem.AccountID !== ADROD?.AccountWithDetails?.AccountID) {
            setLoadOverdraftInfo(true)


            const aDRData = {
                AccountID: overdraftItem.AccountID,
                FilterID: 2,
                FilterBeginDate: '',
                FilterEndDate: ''
            }

            dispatch(AMCActions.postADROD(aDRData))
        } else {
            applyADR(ADR)
        }
    }, [ADR])

    useEffect(() => {
        if(!ADROD) {
            return;
        }
        setAdroLoaded(true)

        applyADR(ADROD);
    }, [ADROD])

    const makeArraysSameSize = (arr1, arr2) => {
        const smallerArr = arr1.length > arr2.length ? arr2 : arr1
        const biggerArr = arr1.length < arr2.length ? arr2 : arr1
        const diff = biggerArr.length - smallerArr.length
        for (let i = 0; i < diff; i++) {
            smallerArr.push({
                Caption: '',
                Value: '',
                ValueDecimal: ''
            })
        }
    }


    const applyADR= (source)=>{
        if(!source) return;
        const {
            ContractDesc1,
            ContractDesc2,
            AccountWithDetails
        } = source
        if (ContractDesc1?.length && ContractDesc2?.length) {
            if (ContractDesc1.length !== ContractDesc2.length) {
                makeArraysSameSize(ContractDesc1, ContractDesc2)
            }
            setOverdraftInfoDesc({
                ContractDesc1,
                ContractDesc2,
                AccountWithDetails: {
                    AccountTypeCode: AccountWithDetails.AccountTypeCode
                }
            })
            setLoadOverdraftInfo(false)
        }
    }
    const changePage = page => {
        const values = Object.assign(filterData ?? {}, {PageNumberToShow: page})

        dispatch(AMCActions.postADR(values))
    }

    const downloadPayment = async item => {
        setLoadingDownload(true)

        try {
            const response = await dispatch(DMCActions.postSPPDF(item, language))
            if (response?.type === DMCConstants.POST_DMC_SPPDF_SUCCESS) {
                blobToFileDownload('payment.pdf', response.payload, 'application/pdf')
            }
        } catch (error) {
            logError(error)
        } finally {
            setLoadingDownload(false)
        }
    }

    const printPayment = item => {
        setLoadingPrint(true)

        dispatch(AMCActions.postSOD(item)).then(response => {
            setLoadingPrint(false)

            if (response.payload) {
                dispatch(
                    PrintActions.setContent(
                        <Print
                            type='InvoiceDetail'
                            data={response.payload}
                            sending={sendSOD}
                        />
                    )
                )
            } else {
                Notification({
                    type: 'error',
                    message: i18.NotificationTitles.UnexpectedError,
                    description: i18.NotificationMessages.UnexpectedErrorReportAdmin
                })
            }
        })
    }

    const depositInfo = () => {
        let {ContractDesc1, ContractDesc2, AccountWithDetails} =
        overdraftInfoDesc ?? ADR ?? {}
        const AccountTypeCode = AccountWithDetails?.AccountTypeCode

        if (AccountTypeCode === '4' && ContractDesc2.length) {
            ContractDesc2 = ContractDesc2.filter(
                item => item.Caption !== 'delayLoanPrincipal'
            )
        }
        if (AccountTypeCode === '3' || AccountTypeCode === '4') {
            ContractDesc2 = ContractDesc2.filter(
                item => item.Caption !== 'delayTotal'
            )
        }

        if (!ContractDesc1?.length || !ContractDesc2.length) {
            return null
        }

        const lgClass = (12 / (ContractDesc1.length + ContractDesc2.length)) >> 0
        let lgLastClass =
                12 - (ContractDesc1.length + ContractDesc2.length) * lgClass,
            lgClassArray = Array.apply(null, {
                length: ContractDesc1.length + ContractDesc2.length
            }).map(() => lgClass),
            index = ContractDesc1.length + ContractDesc2.length - 1

        while (lgLastClass) {
            lgClassArray[index] = lgClassArray[index] + 1
            lgLastClass--
            index =
                index === 0
                    ? ContractDesc1.length + ContractDesc2.length - 1
                    : index - 1
        }

        const getLabel = x => {
            const result = i18.Labels[x]
            if (result) {
                return result
            }
            return x
        }

        const getValue = item => {
            const ValueDecimal = item?.ValueDecimal
            const Value = item?.Value
            if (ValueDecimal || ValueDecimal === 0) {
                return ValueDecimal
            }
            return Value
        }

        return (
            <Card id="DepositInfoCard">
                <Row className="w-100">
                    {ContractDesc1.map((item, index) => {
                        const value = getValue(item)

                        if (value !== null && item.Caption) {
                            return (
                                <Col xs={24} md={12} lg={8} xl={6} key={index} className="py-2">
                                    <div className='text-black-70'>
                                        {trb(getLabel(item?.Caption))}
                                    </div>
                                    <div className='text-black eb_fw_600'>
                                        {typeof value === 'number' ? (
                                            <Number
                                                format={
                                                    item.Caption === 'delayDays'
                                                        ? numberFormat().number
                                                        : numberFormat().currency
                                                }
                                                value={value}
                                            />
                                        ) : (
                                            value
                                        )}
                                    </div>
                                </Col>
                            )
                        } else return null
                    })}
                    {ContractDesc2.map((item, index) => {
                        const value = getValue(item)

                        if (value !== null && item.Caption) {
                            return (
                                <Col xs={24} md={12} lg={8} xl={6} key={index} className="py-2">
                                    <div className='text-black-70'>
                                        {trb(getLabel(item?.Caption))}
                                    </div>
                                    <div className='text-black eb_fw_600'>
                                        {typeof value === 'number' ? (
                                            <Number
                                                format={numberFormat().currency}
                                                value={value}
                                            />
                                        ) : (
                                            ['RBF', 'LimitedRBF', 'CreditLine'].includes(value) ? (
                                                trb(translationGroups.CreditType + value)
                                            ) : (
                                                value
                                            )
                                        )}
                                    </div>
                                </Col>
                            )
                        } else return null
                    })}
                </Row>
            </Card>
        )
    }

    const blocksReservations = () => {
        let Reservations = ADR?.Reservations
        if (!Reservations) {
            return null
        }

        if (!Reservations.length) {
            return null
        }

        const headers = [
            {
                title: trb(i18.Labels.InvoiceData), //'Data',
                className: 'col-lg-2'
            },
            {
                title: trb(i18.Labels.InvoiceDescription), //'Aprašymas',
                className: 'col-lg-6 word-brake-all'
            },
            {
                title: trb(i18.Labels.InvoiceSum), //'Suma',
                className: 'col-lg-4'
            }
        ]

        const mapData = [
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-2 order-1 order-sm-1 order-md-1 order-lg-1',
                values: [
                    {
                        name: 'ReservationDateFormatted'
                    }
                ]
            },
            {
                headerClassName:
                    'col-12 col-md-12 col-lg-6 order-3 order-sm-3 order-md-3 order-lg-2',
                values: [
                    {
                        name: 'Description',
                        className: 'text-word-wrap'
                    }
                ]
            },
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-4 text-right order-2 order-sm-2 order-md-2 order-lg-3',
                values: [
                    {
                        name: 'AmountFormatted',
                        className: 'text-black-50'
                    }
                ]
            }
        ]

        Reservations = Reservations.filter(
            item => item.IsActive && Math.abs(item.Amount) > 0
        ).map((item) => {
            return {
                ...item,
                AmountFormatted: trb(i18.Labels.AmountCurrency, {
                    amount: item.AmountFormatted,
                    currency: item.Currency
                })
            }
        })

        return (
            <div id="InvoiceReservations">
                <h1 className='eb_content_subtitle'>{trb(i18.Labels.Reservations)}</h1>
                <EBTableExpand
                    mapData={mapData}
                    headers={headers}
                    data={Reservations}
                />
            </div>
        )
    }

    const blocksMoney = () => {
        if (!ADR?.Blocks) {
            return null
        }

        if (!ADR?.Blocks?.length) {
            return null
        }

        const headers = [
            {
                title: trb(i18.Labels.InvoiceData), //'Data',
                className: 'col-lg-2'
            },
            {
                title: trb(i18.Labels.InvoiceDescription), //'Aprašymas',
                className: 'col-lg-6 word-brake-all'
            },
            {
                title: trb(i18.Labels.InvoiceSum), //'Suma',
                className: 'col-lg-4'
            }
        ]

        const mapData = [
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-2 order-1 order-sm-1 order-md-1 order-lg-1',
                values: [
                    {
                        name: 'BlockDateFormatted'
                    }
                ]
            },
            {
                headerClassName:
                    'col-12 col-md-12 col-lg-6 order-3 order-sm-3 order-md-3 order-lg-2',
                values: [
                    {
                        name: 'Description',
                        className: 'text-word-wrap'
                    }
                ]
            },
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-4 text-right order-2 order-sm-2 order-md-2 order-lg-3',
                values: [
                    {
                        name: 'AmountFormatted',
                        className: 'text-black-50'
                    }
                ]
            }
        ]

        return (
            <div id="InvoiceBlocksPayment">
                <h1 className='eb_content_subtitle'>{trb(i18.Labels.BlocksPayment)}</h1>
                <EBTableExpand
                    id="BlocksPaymentTable"
                    className='w-100'
                    mapData={mapData}
                    headers={headers}
                    data={ADR?.Blocks}
                />
            </div>
        )
    }

    const notesInfo = () => {
        const ActiveAccount = MALR?.ManagedAccounts
                ? MALR.ManagedAccounts.find(x => x.IsActive === true)
                : null,
            IDF = checkJsonExist(ActiveAccount, 'IDF') ? ActiveAccount.IDF : false,
            typeCodesSee = [131, 132, 22, 23, 24, 3, 4, 133],
            typeCode = ADR?.AccountWithDetails?.AccountTypeCode
                ? parseInt(ADR.AccountWithDetails.AccountTypeCode)
                : null

        if (!typeCodesSee.includes(typeCode)) {
            return null
        }

        if (IDF) {
            return (
                <div id="InvoiceNotesInfo">
                    <strong>{trb(i18.Labels.Note)} </strong>
                    {trb(i18.Labels.BankText)}
                    <a href={COMPANY?.IDFLink} target='_blank' rel='noopener noreferrer'>
                        {COMPANY?.IDFLink}
                    </a>
                </div>
            )
        }

        return null
    }

    const handleItemClick = item => {
        setItemClicked(item)
    }

    const sortItems = (a, b) => {
        if (
            checkJsonProp(a, 'Income.0.Value') &&
            checkJsonProp(b, 'Income.0.Value')
        )
            return a.Income[0].Value > b.Income[0].Value
                ? -1
                : a.Income[0].Value < b.Income[0].Value
                    ? 1
                    : 0
        if (
            checkJsonProp(a, 'Expediture.0.Value') &&
            checkJsonProp(b, 'Expediture.0.Value')
        )
            return a.Expediture[0].Value > b.Expediture[0].Value
                ? 1
                : a.Expediture[0].Value < b.Expediture[0].Value
                    ? -1
                    : 0
        if (
            checkJsonProp(a, 'Income.0.Value') &&
            checkJsonProp(b, 'Expediture.0.Value')
        )
            return -1
        if (
            checkJsonProp(b, 'Income.0.Value') &&
            checkJsonProp(a, 'Expediture.0.Value')
        )
            return 1
    }

    if (sendADR) {
        return <ContentLoader/>
    }

    if (!ADR?.AccountWithDetails?.AccountDetails) {
        return <ContentNoData/>
    }

    const checkAccounts = ADR?.Accounts?.length

    if (!checkAccounts) {
        return <div className='eb_content_no_data'/>
    }

    let headers = appEnvSME
        ? [
            {
                title: trb(i18.Labels.InvoiceDate), //'Data',
                className: 'col-2'
            },
            {
                title: `${trb(i18.Labels.InvoicePayer)}`, //'Gavėjas - Mokėtojas / Mokėjimo paskirtis / Kodas',
                className: 'col-3'
            },
            {
                title: `${trb(i18.Labels.InvoiceDescription)}`, //'Gavėjas - Mokėtojas / Mokėjimo paskirtis / Kodas',
                className: 'col-4 word-brake-all'
            },
            {
                title: `${trb(i18.Labels.InvoiceAmount)}`, //'+ Pajamos / - Išlaidos / Mokesčiai',
                className: 'col-3 text-right'
            }
        ]
        : [
            {
                title: trb(i18.Labels.InvoiceSum), //'Data',
                className: 'col-lg-2'
            },
            {
                title: `${trb(i18.Labels.InvoicePayer)} / ${trb(
                    i18.Labels.InvoicePurpose
                )} / ${trb(i18.Labels.InvoiceCode)}`, //'Gavėjas - Mokėtojas / Mokėjimo paskirtis / Kodas',
                className: 'col-lg-6'
            },
            {
                title: `+ ${trb(i18.Labels.InvoiceRevenue)} / - ${trb(
                    i18.Labels.InvoiceExpenses
                )} / ${trb(i18.Labels.InvoiceTaxes)}`, //'+ Pajamos / - Išlaidos / Mokesčiai',
                className: 'col-lg-4'
            }
        ]

    const columns = [
            {
                title: '',
                dataIndex: 'ID',
                render: (el, item) => {
                    if (item?.Expediture?.[0]?.Value > 0)
                        return (
                            <CustomIcon
                                style={{marginLeft: 0, cursor: 'auto'}}
                                className='payment_type'
                                size={18}
                                type='custom'
                                icon='payment'
                            />
                        )
                    if (item?.Income?.[0]?.Value > 0)
                        return (
                            <CustomIcon
                                style={{marginLeft: 0, cursor: 'auto'}}
                                className='payment_type payment_type_active'
                                size={18}
                                type='custom'
                                icon='payment'
                            />
                        )
                }
            },
            {
                title: trb(i18.Labels.InvoiceDate),
                dataIndex: 'Date',
                render: element => (
                    <span className='text-nowrap'>{formatDate(element)}</span>
                ),
                sorter: (a, b) => moment(a.Date).valueOf() - moment(b.Date).valueOf()
            },
            {
                title: trb(i18.Labels.InvoicePayer),
                dataIndex: 'Pay_Rec',
                //render: (element) => <span className="eb_fw_600">{element}</span>,
                sorter: (a, b) =>
                    a.Pay_Rec > b.Pay_Rec ? -1 : a.Pay_Rec < b.Pay_Rec ? 1 : 0
            },
            {
                title: trb(i18.Labels.InvoiceDescription),
                dataIndex: 'Description',
                sorter: (a, b) =>
                    a.Description > b.Description
                        ? -1
                        : a.Description < b.Description
                            ? 1
                            : 0,
                className: 'word-brake-all',
                width: '40%'
            },
            {
                title: trb(i18.Labels.InvoiceAmount),
                align: 'right',
                dataIndex: 'Income.0.Value',
                sorter: sortItems,
                render: (element, item) => (
                    <>
                        <div className='text-primary text-nowrap float-right'>
                            {item?.Income?.[0]?.Text}
                        </div>
                        <div className='text-black text-nowrap float-right'>
                            {item?.Expediture?.[0]?.Text
                                ? `- ${item.Expediture[0].Text}`
                                : null}
                        </div>
                        <div className='text-black-50 text-nowrap float-right'>
                            {item?.PaymentFee?.[0]?.Text
                                ? ` ${trb(i18.Labels.InvoiceComissionFee)}: ${
                                    item.PaymentFee[0].Text
                                }`
                                : null}
                        </div>
                    </>
                )
            },
            {
                title: '',
                dataIndex: 'ID',
                render: (el, item) => {
                    const {Pay_RecBankAccount} = item
                    return (
                        <DownloadOutlined
                            style={
                                !Pay_RecBankAccount
                                    ? {visibility: 'hidden'}
                                    : {fontSize: 20, opacity: 0.65}
                            }
                            onClick={() => downloadPayment(item)}
                        />
                    )
                }
            }
        ],
        columnsMobile = [
            {
                title: trb(i18.Labels.InvoicePayer),
                dataIndex: 'Pay_Rec',
                sorter: (a, b) =>
                    a.Pay_Rec > b.Pay_Rec ? -1 : a.Pay_Rec < b.Pay_Rec ? 1 : 0
            },
            {
                title: trb(i18.Labels.InvoiceAmount),
                dataIndex: 'Income.0.Value',
                sorter: sortItems,
                render: (element, item) => (
                    <>
                        <div className='text-primary text-nowrap float-right'>
                            {item?.Income?.[0]?.Text ? `+ ${item.Income[0].Text}` : ''}
                        </div>
                        <div className='text-black text-nowrap float-right'>
                            {item?.Expediture?.[0]?.Text
                                ? `- ${item.Expediture[0].Text}`
                                : ''}
                        </div>
                        <div className='text-black-50 text-nowrap float-right'>
                            {item?.PaymentFee?.[0]?.Text
                                ? ` ${trb(i18.Labels.InvoiceComissionFee)}: ${
                                    item.PaymentFee[0].Text
                                }`
                                : ''}
                        </div>
                    </>
                )
            }
        ]


    const currentAccount = {
        ...ADR?.AccountWithDetails,
        BlockedAmount: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.BlockedAmount,
        DisposableBalance: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.DisposableBalance,
        AccountCurrency: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.AccountCurrency,
        NationalBalance: ALR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.AmountNationalCurrency
    }

    const summary = [
        {
            text: trb(i18.Labels.InvoiceInitialBalance), //'Pradinis likutis',
            name: 'InitialBalance.0.Value',
            suffix: 'InitialBalance.0.Currency',
        },
        {
            text: trb(i18.Labels.InvoiceTotalRevenue), //'Viso įplaukų',
            name: 'TurnOverIncome',
            suffix: 'AccountCurrency'
        },
        {
            text: trb(i18.Labels.InvoiceTotalCost), //'Viso išlaidų',
            name: 'TurnOverOutcome',
            suffix: 'AccountCurrency'
        },
        {
            text: trb(i18.Labels.InvoiceFinalBalance), //'Galutinis likutis',
            name: 'FinalBalance.0.Value',
            suffix: 'FinalBalance.0.Currency',
        },
        ...(currentAccount?.AccountCurrency !== process.env.REACT_APP_NATONIAL_CURRENCY) ? [{
            text: trb(i18.Labels.EquivalentBalance), // 'Ekvivalentas likutis',
            name: 'NationalBalance',
            suffix: process.env.REACT_APP_NATONIAL_CURRENCY
        }] : [],
        {
            text: trb(i18.Labels.InvoiceBlocked), //'Blokuota',
            name: 'BlockedAmount.0.Value',
            suffix: 'BlockedAmount.0.Currency'
        },
        {
            text: trb(i18.Labels.InvoiceReserved), //'Rezervuota',
            name: 'Reserved.0.Value',
            suffix: 'Reserved.0.Currency'
        },
        {
            text: trb(i18.Labels.DisposableBalance), //'Rezervuota',
            name: 'DisposableBalance.0.Value',
            suffix: 'DisposableBalance.0.Currency'
        }
    ]

    let mapData = appEnvSME
        ? [
            {
                headerClassName: 'col-2',
                values: [
                    {
                        name: 'Date'
                    }
                ]
            },
            {
                headerClassName: 'col-3',
                values: [
                    {
                        name: 'Pay_Rec',
                        className: 'eb_fw_600 text-word-wrap'
                    }
                ]
            },
            {
                headerClassName: 'col-6',
                values: [
                    {
                        name: 'Description',
                        className: 'text-word-wrap'
                    }
                ]
            },
            {
                headerClassName: 'col-1 text-right',
                values: [
                    {
                        name: 'Income.0.Text',
                        className: 'text-primary eb_fw_600 text-nowrap float-right'
                    },
                    {
                        name: 'Expediture.0.Text',
                        className: 'text-error eb_fw_600 text-nowrap float-right',
                        prefix: '-'
                    },
                    {
                        name: 'PaymentFee.0.Text',
                        className:
                            'text-black-50 d-none d-lg-block text-nowrap float-right',
                        prefix: ` ${trb(i18.Labels.InvoiceComissionFee)}: ` //' Komisinis mokestis: '
                    }
                ]
            }
        ]
        : [
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-2 order-1 order-lg-1 pl-4 pl-md-2',
                values: [
                    {
                        name: 'Date'
                    }
                ]
            },
            {
                headerClassName:
                    'col-12 col-md-12 col-lg-6 order-3 order-lg-2 pt-0 pt-md-2 pl-0 pl-md-2',
                values: [
                    {
                        name: 'PaymentFee.0.Text',
                        className: 'text-black-50 d-block d-lg-none',
                        prefix: ` ${trb(i18.Labels.InvoiceComissionFee)}: ` //' Komisinis mokestis: '
                    },
                    {
                        name: 'Pay_Rec',
                        postfix: ' — ',
                        className: 'eb_fw_600'
                    },
                    {
                        name: 'Description',
                        className: 'text-word-wrap'
                    }
                ]
            },
            {
                headerClassName:
                    'col-6 col-md-6 col-lg-4 text-right order-2 order-lg-3',
                values: [
                    {
                        name: 'Income.0.Text',
                        className: 'text-primary eb_fw_600'
                    },
                    {
                        name: 'Expediture.0.Text',
                        className: 'text-error eb_fw_600',
                        prefix: '-'
                    },
                    {
                        name: 'PaymentFee.0.Text',
                        className: 'text-black-50 d-none d-lg-block',
                        prefix: ` ${trb(i18.Labels.InvoiceComissionFee)}: ` //' Komisinis mokestis: '
                    }
                ]
            }
        ]

    const BTableRowSelectionContentMapData1 = [
        {
            headerClassName: 'col-6 col-md-4 col-lg-3 d-none d-md-block',
            textClassname: 'eb_fs_12 text-black-50 d-none d-md-block',
            text: trb(i18.Labels.InvoiceComissionFee), //'Komisinis mokestis',
            values: [
                {
                    name: 'PaymentFee.0.Text',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3 d-none d-md-block',
            textClassname: 'eb_fs_12 text-black-50 d-none d-md-block',
            text: trb(i18.Labels.InvoicePayFeeDesc), //'Komisinio mokesčio aprašymas',
            values: [
                {
                    name: 'PayFeeDesc',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: trb(i18.Labels.InvoiceUniquePaymentCode), //'Unikalus mokėjimo kodas',
            values: [
                {
                    name: 'UniquePaymentCode',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: trb(i18.Labels.InvoicePaymentType), //'Mokėjimo tipas',
            values: [
                {
                    name: 'SEPAPaymentType',
                    className: 'eb_fw_600'
                }
            ]
        }
    ]

    const BTableRowSelectionContentMapData2 = [
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: trb(i18.Labels.InvoicePaymentType), //'Pradinio mokėtojo kodas',
            values: [
                {
                    name: 'InitPayerIdentTypeValue',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: `${trb(i18.Labels.InvoicePayerName)} / ${trb(
                i18.Labels.InvoicePayerTitle
            )}`, //'Pradinio mokėtojo Vardas ir pavardė / Pavadinimas',
            values: [
                {
                    name: 'InitPayerName',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: trb(i18.Labels.InvoicePaymentType), //'Galutinio gavėjo kodas',
            values: [
                {
                    name: 'FinalPayeeIdentTypeValue',
                    className: 'eb_fw_600'
                }
            ]
        },
        {
            headerClassName: 'col-6 col-md-4 col-lg-3',
            textClassname: 'eb_fs_12 text-black-50',
            text: `${trb(i18.Labels.InvoicePayeeName)} / ${trb(
                i18.Labels.InvoicePayeeTitle
            )}`, //'Galutinio gavėjo Vardas ir pavardė / Pavadinimas',
            values: [
                {
                    name: 'FinalPayeeName',
                    className: 'eb_fw_600'
                }
            ]
        }
    ]

    const buttons = [
        {
            icon: <PrinterOutlined/>,
            text: i18.Buttons.Print,
            onClick: printPayment,
            loading: loadingPrint
        },
        {
            icon: <DownloadOutlined/>,
            text: i18.Buttons.DownloadPDF,
            onClick: downloadPayment,
            loading: loadingDownload
        }
    ]

    const currentPage = parseInt(ADR?.AccountWithDetails?.PageNumberToShow)
    const numberOfPages = parseInt(ADR?.AccountWithDetails?.NumberOfPages)
    const accountDetails = ADR?.AccountWithDetails?.AccountDetails
    const dataSource = []
    accountDetails.forEach(item => {
        const {Pay_RecBankAccount, Pay_Rec} = item
        if (Pay_RecBankAccount && Pay_RecBankAccount.includes('.')) {
            dataSource.push({
                ...item,
                Pay_RecBankAccount: null,
                Pay_Rec:
                    Pay_Rec && Pay_Rec.includes('.')
                        ? Pay_Rec.substring(0, Pay_Rec.length - 13)
                        : Pay_Rec
            })
        } else {
            dataSource.push(item)
        }
    })

    return (
        <div
            className='py-3 p-md-0'
            style={
                appEnvSME
                    ? {maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto'}
                    : {}
            }>
            {loadOverdraftInfo ? <ContentLoader/> : depositInfo()}
            {appEnvSME ? (
                <>
                    <Table
                        id="InvoiceListTable"
                        pagination={false}
                        dataSource={dataSource}
                        columns={windowSize.mobileCheck ? columnsMobile : columns}
                        className='my-5 mx-0'
                    />
                </>
            ) : (
                <>
                    <EBTableExpand
                        wide
                        mapData={mapData}
                        headers={headers}
                        data={ADR?.AccountWithDetails?.AccountDetails}
                        expended={!appEnvSME}
                        itemClick={handleItemClick}>
                        <EBTableRowSelectionContent
                            item={itemClicked}
                            mapData={BTableRowSelectionContentMapData1}
                        />
                        <EBTableRowSelectionContent
                            item={itemClicked}
                            mapData={BTableRowSelectionContentMapData2}
                        />
                        <EBTableRowSelectionContentButtons
                            headerClassName={'mt-3 text-right'}
                            buttons={buttons}
                        />
                    </EBTableExpand>
                </>
            )}
            {numberOfPages !== 1 && (
                <Pagination
                    current={currentPage}
                    onChange={changePage}
                    total={numberOfPages * 10}
                    showSizeChanger={false}
                    className='text-center my-3'
                />
            )}
            {blocksMoney()}
            {blocksReservations()}
            {!windowSize.mobileCheck && (
                <Summary data={currentAccount} headers={summary}/>
            )}
            {notesInfo()}
        </div>
    )
}
