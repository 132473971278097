import PropTypes from 'prop-types';

import {
    i18,
    numberFormat,
    required,
    requiredAndApprove,
    translationGroups,
    checkMax,
    checkMin,
    appEnvSME,
    trb, getWindowSize
} from "@utilities";
import {FormFieldType} from "../../FormField";

export const DepositForm = (props) => {
    const windowSize = getWindowSize()
    return {
        //title: 'Indėlio sutarties sudarymas',
        parts: [
            {
                className: 'col-12',
                fields: [
                    {
                        title: i18.Labels.DepositType,
                        name: 'ProductTypeID',
                        component: FormFieldType.radio,
                        options: [
                            {
                                label: 'TermDeposit',
                                value: 4,
                            }
                        ],
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        inline: true,
                        translate: translationGroups.DepositTypes,
                        validate: required,
                        classNameTitle: appEnvSME ? 'col-12 col-md-4' : null,
                        classNameField: appEnvSME ? 'col-12 col-md-7 mt-2 mt-md-0 offset-md-1 text-right' : null,
                    }, {
                        title: i18.Labels.InterestPay,
                        name: 'SubProductID',
                        component: FormFieldType.radio,
                        options: props.InterestPaymentTypes ? props.InterestPaymentTypes : null,
                        optionsLabel: 'Caption',
                        optionsValue: 'ID',
                        translate: translationGroups.InterestPaymentTypes,
                        inline: true,
                        validate: required,
                        classNameTitle: appEnvSME ? 'col-12 col-md-4' : null,
                        classNameField: appEnvSME ? 'col-12 col-md-7 mt-2 mt-md-0 offset-md-1 text-right' : null,
                    }, {
                        title: i18.Labels.Term,
                        name: 'Period',
                        // component: FormFieldType.select,
                        // options: props.Periods ? props.Periods : null,
                        // optionsLabel: 'Caption',
                        // optionsValue: 'Value',
                        component: FormFieldType.number,
                        formatNumber: numberFormat().number,
                        validateCompose: true,
                        validate: [required, checkMax(60, true), checkMin(1, true)],
                        notify: appEnvSME ? null : i18.NotificationTitles.WriteOnlyNumbers,
                    }, {
                        title: i18.Labels.Amount,
                        name: 'Amount',
                        component: FormFieldType.number,
                        formatNumber: numberFormat().number,
                        validate: required,
                        hightlight: props.DepositAmountNotValid ? false : true, 
                        error: appEnvSME ? null : props.DepositAmountNotValid ? i18.NotificationTitles.DepositAmountNotValid : null,
                        className: !appEnvSME ? null : props.DepositAmountNotValid ? "deposits-input-custom-error" : null,
                    }, {
                        title: i18.Labels.Currency,
                        name: 'CurrencyID',
                        component: FormFieldType.select,
                        options: appEnvSME
                            ? [{
                                Title: 'EUR',
                                ID: 2,
                            }]
                            : props.Currencies ? props.Currencies : null,
                        optionsLabel: 'Title',
                        optionsValue: 'ID',
                        validate: required
                    }, {
                        title: i18.Labels.FromAccount,
                        name: 'InitialAccountID',
                        component: FormFieldType.select,
                        options: props.accounts,
                        optionsColor: appEnvSME ? 'color' : null,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: required,
                        className: appEnvSME ? 'mobile' : ''
                    }, {
                        title: i18.Labels.Interest,
                        name: 'Interest',
                        component: FormFieldType.number,
                        formatNumber: numberFormat().percent,
                        disabled: true,
                        validate: required,
                        visible: appEnvSME,
                    }, {
                        title: i18.Labels.Interest,
                        name: 'Interest',
                        component: FormFieldType.numberLikeRadio,
                        formatNumber: numberFormat().percent,
                        inline: true,
                        visible: !appEnvSME,
                        className: appEnvSME ? 'justify-content-between' : '',
                        classNameTitle: appEnvSME ? 'col-12 col-md-4 position-static' : 'col-12 col-md-9 position-static',
                        classNameField: appEnvSME ? 'col-6 col-md-8 text-right mt-2 mt-md-0 custom-interest-field' : 'col-6 col-md-3 pl-md-4 text-right mt-2 mt-md-0',
                        classNameNumber: 'text-center',
                    }, {
                        title: i18.Labels.AutoRenewDeposit,
                        name: 'AutoResume',
                        component: windowSize.mobileCheck && appEnvSME ? FormFieldType.select : FormFieldType.radio,
                        options: [
                            {
                                label: 'WithInterest',
                                value: 'WithInterest',
                                disabled: props.data.SubProductID === 39 || props.data.SubProductID === 40,
                            }, {
                                label: 'WithoutInterest',
                                value: 'WithoutInterest'
                            }, {
                                label: 'NotApplicable',
                                value: 'NotApplicable'
                            }
                        ],
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        optionsDisabled: 'disabled',
                        inline: !windowSize.mobileCheck || !appEnvSME,
                        translate: translationGroups.DepositRenew,
                        validate: required,
                        classNameTitle: appEnvSME && !windowSize.mobileCheck ? 'col-12 col-md-4' : null,
                        classNameField: appEnvSME && !windowSize.mobileCheck ? 'col-12 col-md-8 text-right mt-2 mt-md-0' : null,
                    },
                    {
                        name: 'DepositAdditionalInformation',
                        component: FormFieldType.html,
                        html:
                        `<ol class="deposit-custom-ol-list p-0 my-4">
                            <li>${trb(i18.DepositTypes.DepositAdditionalInformation1)}</li>
                            <li>${trb(i18.DepositTypes.DepositAdditionalInformation2)}</li>
                            <li>${trb(i18.DepositTypes.DepositAdditionalInformation3)}</li>
                            <li>${trb(i18.DepositTypes.DepositAdditionalInformation4)}</li>
                            <li>${trb(i18.DepositTypes.DepositAdditionalInformation5)}</li>
                        </ol>`
                      },
                    {
                        name: 'terms',
                        component: FormFieldType.html,
                        html: props.html,
                        componentOptions: {
                            autosize: {
                                minRows: 8,
                                maxRows: 8
                            }
                        },
                        disabled: true,
                        className: 'form-field-terms'
                    }, {
                        name: 'Confirm',
                        component: FormFieldType.checkbox,
                        translate: i18.Labels.AgreeWithTerms,
                        validate: requiredAndApprove,
                        className: appEnvSME ? 'col-12 col-md-12 mb-md--5 pt-3' : null,
                        classNameField: appEnvSME ? 'col-12 text-left text-nowrap mobile font-size-12' : null,
                    }
                ]
            }
        ]
    }
};

DepositForm.propTypes = {
    data: PropTypes.object.isRequired
};
