import React, {useEffect, useState} from 'react'
import {Select} from 'antd'

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '@components'
import {trb, formFieldId} from '@utilities'

const {Option} = Select

export const SelectSearchFormField = props => {
    const [active, setActive] = useState(
        !props.input?.value && !props?.defaultValue
    )

    useEffect(() => {
        if (!!props?.input?.value) {
            setActive(false)
        }
    }, [props.input.value])

    const onBlur = e => {
        if (!props.input.value && props.input.value !== 0) {
            setActive(true)
        }
        if (e) props.input.onBlur(e)
    }

    const onSelect = e => {
        setActive(false)
        if (e && typeof props.onSelect === 'function') props.onSelect(e)
    }

    const onValueChange = value => {
        props.input.onChange(value)
        props.helperOnChange?.(value)
    }

    const onSearch = value => {
        setActive(false)
        if (typeof props.onSearch === 'function') props.onSearch(value)
    }

    const {
        title,
        titleWithoutTranslate,
        className,
        classNameField,
        classNameTitle,
        classNameError,
        inline,
        placeholder,
        disabled,
        options,
        optionsLabel,
        optionsValue,
        optionsDisabled,
        translate,
        meta,
        small,
        allowClear
    } = props

    const checkOption = (value, input) => {
        const optionValue = options.find(e => e[optionsValue] === value)
        if (optionValue) {
            return value
        } else {
            input.onChange(undefined)
        }
    };

    const renderOptions = options => options.map((item, index) => (
        <Option
            id={formFieldId(props.input.name, `SelectSearchOption${item[optionsValue]}`)}
            disabled={item[optionsDisabled]}
            value={item[optionsValue]}
            key={index}>
            {translate
                ? trb(translate + item[optionsLabel])
                : item[optionsLabel]}
        </Option>
    ))

    return (
        <div
            id={formFieldId(props.input.name, 'SelectSearchWrapper')}
            className={FormFieldWrapperClasses(
                className,
                inline,
                disabled,
                active,
                small
            )}>
            {title && (
                <div
                    className={
                        classNameTitle
                            ? 'form-field-title ' + classNameTitle
                            : inline
                                ? (className && className.indexOf('col-12') === -1) ||
                                (className &&
                                    className.indexOf('col-12') !== -1 &&
                                    className.indexOf('col-md-') !== -1)
                                    ? 'form-field-title col-12 col-md-5 text-left text-md-right pb-2 pb-md-0 pr-md-0'
                                    : 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0'
                                : 'form-field-title col-12'
                    }>
                    {titleWithoutTranslate ? title : trb(title)}
                </div>
            )}
            <div
                className={
                    classNameField
                        ? 'form-field-item ' + classNameField
                        : FormFieldInputWrapperClasses(
                            meta.error,
                            meta.submitError,
                            meta.touched,
                            inline,
                            className
                        )
                }>
                <Select
                    id={formFieldId(props.input.name, 'SelectSearch')}
                    allowClear={allowClear ?? true}
                    showSearch
                    placeholder={trb(placeholder)}
                    disabled={disabled}
                    name={props.input.name}
                    value={checkOption(props.input.value, props.input)}
                    onChange={onValueChange}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.props?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    onBlur={onBlur}
                    // popupClassName="eb_new_custom_dropdown"
                    defaultActiveFirstOption={false}
                    // getPopupContainer={() => document.getElementById('eb_main')}
                    // dropdownAlign={
                    //     {
                    //         points: ['tl', 'bl'],
                    //         offset: [0, 0],
                    //         targetOffset: ['0%', '0%'],
                    //         overflow: {adjustX: false, adjustY: false}
                    //     }
                    // }
                >
                    {options && renderOptions(options)}
                </Select>
            </div>
            <div
                id={formFieldId(props.input.name, 'SelectSearchError')}
                className={
                    classNameError
                        ? 'form-field-error ' + classNameError
                        : inline
                            ? 'form-field-error col-12 col-md-3'
                            : 'form-field-error col-12'
                }>
                {meta.touched && (meta.error || meta.submitError)}
            </div>
        </div>
    )
}
