import React, {Component} from 'react';
import {Form} from "react-final-form";
import {Button} from "antd";
import {ContentState, EditorState} from "draft-js";
import {CloseOutlined} from '@ant-design/icons'
import {stateToHTML} from "draft-js-export-html";

import {ChatInput} from "@components";
import {appEnvSME, CustomIcon, i18, trb} from '@utilities'
import PropTypes from "prop-types";

const ButtonGroup = Button.Group

class NewChatMessage extends Component {

    state = { visible: false, editorState: EditorState.createEmpty() }

    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    onClose = () => {
        this.setState({
            visible: false
        })
        this.props.form.resetFields()
    }

    onChangeInput = (editorState) => {
        this.setState({ editorState })
    }

    submit = () => {
        const contentState = this.state.editorState.getCurrentContent()

        if (contentState.hasText()) {
            const htmlString = stateToHTML(contentState)
            this.props.submit({ message: htmlString })
        }
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''), undefined)
        this.setState({ editorState: EditorState.moveFocusToEnd(editorState) })
    }


    render() {
        return (<div style={!appEnvSME ? {width: '98%' } : {}} className='form'>
                <Form
                    onSubmit={this.submit}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form className='mb-3' onSubmit={handleSubmit} id="MessagesDrawerNewMessageForm">
                                <div className='form-part'>
                                    {/*<FormField name="message" component={FormFieldType.textarea}/>*/}
                                    <ChatInput name='message' editorState={this.state.editorState}
                                               setEditorState={this.onChangeInput} />
                                </div>
                                <div className='new-message-wrapper mb-3'>
                                    <Button
                                        id="MessagesDrawerNewMessageSubmitButton"
                                        className='mr-3 submit'
                                        size='large'
                                        shape='circle'
                                        htmlType='submit'
                                        disabled={submitting}
                                    >
                                        <CustomIcon type='custom' icon='submit' />
                                    </Button>
                                    <Button
                                        id="MessagesDrawerNewMessageDeleteButton"
                                        shape='circle'
                                        size='large'
                                        onClick={this.props.closeChat}
                                        type='link'
                                    >
                                        <CustomIcon type='custom' icon='bin' />
                                    </Button>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>)
    }

}

export { NewChatMessage }


NewChatMessage.propTypes = {
    deleteMessage: PropTypes.func,
}