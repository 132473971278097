import React from 'react'
import { LoginFormMSignConfirm, LoginFormSMSConfirm } from './'

export const LoginConfirmationSwitch = ({
  currentForm,
  submitting,
  code,
  seconds,
  secondsRemaining,
  loginCancel,
  submitError,
  confirming,
  submitSMSConfirm,
  secondsSMS,
  secondsSMSRemaining
}) => {
  switch (currentForm) {
    case 1:
      return (
        <LoginFormMSignConfirm
          code={code}
          seconds={seconds}
          secondsRemaining={secondsRemaining}
          loginCancel={loginCancel}
        />
      )
    case 2:
      return (
        <LoginFormMSignConfirm
          code={code}
          seconds={seconds}
          secondsRemaining={secondsRemaining}
          loginCancel={loginCancel}
        />
      )
    case 3:
      return (
        <LoginFormSMSConfirm
          submit={submitSMSConfirm}
          submitting={submitting}
          submitError={submitError}
          seconds={secondsSMS}
          secondsRemaining={secondsSMSRemaining}
          confirming={confirming}
          loginCancel={loginCancel}
        />
      )
    case 4:
      return null
    default:
      return (
        <LoginFormMSignConfirm
          code={code}
          seconds={seconds}
          secondsRemaining={secondsRemaining}
          loginCancel={loginCancel}
        />
      )
  }
}
