import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, required, requiredAny, translationGroups, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCPepLinks = (props) => {

    let ClientProfile = checkJsonProp(props.data, 'clientProfile');
    ClientProfile[0].data.clientPoliticsMember = checkJsonProp(ClientProfile, '0.data.clientPoliticsMember') == 1;

    let ClientPoliticsPosition = checkJsonProp(props.data, 'clientPoliticsPosition')
              ? checkJsonProp(props.data, 'clientPoliticsPosition')
              : [{
                  ClientPoliticsPosList: 'PEN1',
                  data: {}
              }];
    ClientPoliticsPosition[0].ClientPoliticsPosList = ClientPoliticsPosition[0].ClientPoliticsPosList ? ClientPoliticsPosition[0].ClientPoliticsPosList : 'PEN1';

    const ClientPolitics = checkJsonProp(props.data, 'clientPolitics') && checkJsonProp(props.data, 'clientPolitics').length
              ? checkJsonProp(props.data, 'clientPolitics')
              : [{
                  clientPoliticsList: 'PEPLIST1',
                  data: {
                      clientPoliticsName: '',
                      clientPoliticsCountry: '',
                      clientPoliticsLink: '',
                  }
              }],
          initialData = {clientProfile: ClientProfile, clientPolitics: ClientPolitics, clientPoliticsPosition: ClientPoliticsPosition};

    const mapData = [
        {
            messageBefore: i18.NotificationMessages.KYCPepLinks,
            title: i18.Labels.ClientPoliticsMember,
            noteFirst: i18.Labels.ClientPoliticsMemberFirstNote,
            noteSecond: i18.Labels.ClientPoliticsMemberSecondNote,
            elements: [{
                name: 'clientProfile.0.data.clientPoliticsMember',
                component: FormFieldType.radio,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
                optionsLabel: 'label',
                optionsValue: 'value',
                translate: translationGroups.Labels,
                className: 'col-10 offset-1',
            }]
        },{
            showWhen: 'clientProfile.0.data.clientPoliticsMember',
            showIs: true,
            elements: [{
                title: i18.Labels.ClientPoliticsPosList,
                name: 'clientPoliticsPosition.0.ClientPoliticsPosList',
                component: FormFieldType.select,
                options: props.classifikators.PEN,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12 col-md-4 d-none',
            },{
                translate: i18.Labels.ClientPoliticsMinist,
                name: 'clientPoliticsPosition.0.data.clientPoliticsMinist',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 ml-2 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsParl,
                name: 'clientPoliticsPosition.0.data.clientPoliticsParl',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsJutge,
                name: 'clientPoliticsPosition.0.data.clientPoliticsJutge',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsMay,
                name: 'clientPoliticsPosition.0.data.clientPoliticsMay',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsAudit,
                name: 'clientPoliticsPosition.0.data.clientPoliticsAudit',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsAmb,
                name: 'clientPoliticsPosition.0.data.clientPoliticsAmb',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsJoint,
                name: 'clientPoliticsPosition.0.data.clientPoliticsJoint',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsMJoint,
                name: 'clientPoliticsPosition.0.data.clientPoliticsMJoint',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.clientPoliticsInternOrg,
                name: 'clientPoliticsPosition.0.data.clientPoliticsInternOrg',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientPoliticsPolPart,
                name: 'clientPoliticsPosition.0.data.clientPoliticsPolPart',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-10 pl-1 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.clientPoliticsPosList,
                name: `clientPoliticsPosition.0.clientPoliticsPosList`,
                component: FormFieldType.select,
                options: props.classifikators.PEN,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12 d-none',
                disabled: true,
            }],
        },{
            elements: [{
                name: 'clientPoliciticsValid',
                component: FormFieldType.checkbox,
                className: 'col-12 only-error text-center m-2',
                validate: requiredAny,
            }]
        },{
            showWhen: `clientPolitics.$index.clientPoliticsList`,
            showIs: ['PEPLIST2', 'PEPLIST3'],
            canDuplicate: true,
            duplicateName: 'clientPolitics',
            duplicateDefault: {
                clientPoliticsList: null,
                data: {
                    clientPoliticsName: '',
                    clientPoliticsCountry: '',
                    clientPoliticsLink: '',
                }
            },
            elements: [{
                title: i18.Labels.ClientPoliticsList,
                name: `clientPolitics.$index.clientPoliticsList`,
                component: FormFieldType.radio,
                options: props.classifikators.PEPLIST,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12',
                validate: required,
                alwaysShow: true,
            },{
                title: i18.Labels.ClientPoliticsName,
                name: `clientPolitics.$index.data.clientPoliticsName`,
                component: FormFieldType.input,
                className: 'col-12 col-md-4',
                validate: required,
                noInline: true,
            },{
                title: i18.Labels.ClientPoliticsCountry,
                name: `clientPolitics.$index.data.clientPoliticsCountry`,
                component: FormFieldType.selectSearch,
                options: props.classifikators.CISO,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12 col-md-4',
                validate: required,
                noInline: true,
            },{
                title: i18.Labels.ClientPoliticsLink,
                name: `clientPolitics.$index.data.clientPoliticsLink`,
                component: FormFieldType.select,
                options: props.classifikators.PEPLINK,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12 col-md-4',
                validate: required,
                noInline: true,
            }]
        }
    ];

    const decorators = [
        createDecorator({
                field: 'clientProfile.0.data.clientPoliticsMember',
                updates:  {
                    'clientPolitics.0.clientPoliticsList': (value, allValues) => value ? 'PEPLIST2' : null,
                    'clientPolitics': (value, allValue, prevValue) => !value ? [] : ClientPolitics,
                }
            },{
                field: /\.*/,
                updates: {
                    'clientPoliciticsValid': (value, allValues) => {
                        const arrPoliticsPositions = checkJsonProp(allValues, 'clientPoliticsPosition.0.data') ? Object.entries(checkJsonProp(allValues, 'clientPoliticsPosition.0.data')).some((item) => item[1]) : false;
                        /*const arrPolitics = checkJsonProp(allValues, 'clientPolitics') && checkJsonProp(allValues, 'clientPolitics').length > 0 ? checkJsonProp(allValues, 'clientPolitics').some((row) => {
                            row.data = checkJsonProp(row, 'data') ? row.data : {};
                            return Object.entries(row).every((item) => item[1])
                                   && (((row.clientPoliticsList == 'PEPLIST2' || row.clientPoliticsList == 'PEPLIST3') && Object.entries(row.data).length >= 3)
                                      || row.clientPoliticsList == 'PEPLIST1');
                        }) : false;*/
                        return (checkJsonProp(allValues, 'clientProfile.0.data.clientPoliticsMember') === true && arrPoliticsPositions)// && arrPolitics)
                        || checkJsonProp(allValues, 'clientProfile.0.data.clientPoliticsMember') === false
                        || false;
                    }
                }
            },
        )
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.PepLinks} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};