import React from 'react';
import {Alert, Button} from "antd";
import {connect} from "react-redux";
import {Form} from "react-final-form";

import {AMCActions} from "../../../../Redux";
import {ContentLoader, ContentNoData, FormField, FormFieldType, Notification} from "../../../../Components";
import {i18, required, translationGroups, trb} from '../../../../Utilities';

const ButtonGroup = Button.Group;

class SettingsBlockCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            errors: null,
            inline: !window.matchMedia("(max-width: 600px)").matches
        };
        window.addEventListener('resizeeee', (event) => {
            this.setState({
                inline: !window.matchMedia("(max-width: 600px)").matches
            })
        });

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch((AMCActions.postCAINF()));
    }

    submit = async values => {
        this.setState({
            submitting: true,
            errors: null
        });

        await this.props.dispatch(AMCActions.postBCA(values)).then(response => {
            this.setState({
                submitting: false
            });

            if (response.payload && response.payload.IsSuccessfull) {
                Notification({
                    type: 'success',
                    message: i18.NotificationTitles.CardBlocked,
                    description: i18.NotificationMessages.CardBlockedSuccessfully
                });
                this.props.dispatch((AMCActions.postCAINF()));
            } else {
                this.setState({errors: response.payload.ErrorCaptions});
            }
        });
    };

    render() {
        const {sendCAINF, CAINF} = this.props;

        if (sendCAINF) {
            return <ContentLoader/>;
        }

        if (!CAINF) {
            return <ContentNoData/>;
        }

        const acc = CAINF.CardsAccounts.filter(x=>x.IsActive === true);

        return <>
            <div className="eb_content_title">{trb(i18.ContentTitles.SettingsBlockCard)}</div>
            <Form
                onSubmit={this.submit}
                render={({handleSubmit, form, valid, values}) => {
                    return (
                        <form
                            className="mb-3"
                            onSubmit={event => {
                                valid ? handleSubmit(event).then(() => form.reset()) : handleSubmit(event)
                            }}
                        >
                            <div className="form-part">
                                <FormField
                                    name="ID"
                                    title={i18.Labels.CreditCardToBlock}
                                    component={FormFieldType.select}
                                    options={acc}
                                    optionsLabel="Number"
                                    optionsValue="ID"
                                    inline={this.state.inline}
                                    validate={required}
                                />
                            </div>
                            {this.state.errors &&
                            <Alert
                                className="mb-3"
                                message={trb(i18.ErrorTitles.CardBlockError)}
                                description={
                                    <ul>
                                        {this.state.errors.map((item, index) =>
                                            <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)} </li>
                                        )}
                                    </ul>
                                }
                                type="error"
                            />
                            }
                            <div className="mb-3 text-right">
                                <ButtonGroup>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={this.state.submitting}
                                    >{trb(i18.Buttons.BlockCard)}</Button>
                                </ButtonGroup>
                            </div>
                        </form>
                    )
                }}
            />
        </>;
    };
}

function mapStateToProps(state) {
    const {sendCAINF, CAINF} = state.AMCReducers;
    return {
        sendCAINF,
        CAINF
    };
}

const connectedSettingsBlockCard = connect(mapStateToProps)(SettingsBlockCard);
export {connectedSettingsBlockCard as SettingsBlockCard};