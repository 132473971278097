export const replaceAll = (str, find, replace) => {
    if (!str) {
        return str;
    }
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

const escapeRegExp = (str) => {
    if (!str) {
        return str;
    }
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};