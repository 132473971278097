import {routerPath, history} from "@config";
import {checkJsonProp} from "@utilities";

export const serverErrorsMiddleware = store => next => action => {
    const {type} = action,
        matches = /(.*)_(ERROR)/.exec(type);

    let result = next(action);

    if (!matches) {
        return result;
    }

    if (checkJsonProp(result, 'error.response.status') === 401) {
        localStorage.clear();
        sessionStorage.clear();
        history.push(routerPath.login);
        location.reload();
        return;
    }

    return result
};
