import React from 'react'
import PropTypes from 'prop-types'
import { checkJsonProp } from '../../../Utilities'

export const EBTableRowSelectionContent = (props) => {
  const { mapData, item } = props

  return <div className='row mb-2'>
    {mapData.map((item2, index2) =>
      <>
        {item2.values.map((item3, index3) => {
            const needShow = checkJsonProp(item, item3.name)
            if (needShow) {
              return (
                <div className={item2.headerClassName} key={index3}>
                  <div className={item2.textClassName}>{item2.text}</div>
                  <div className={item3.className}>{item3.prefix}{checkJsonProp(item, item3.name)}{item3.postfix}</div>
                </div>
              )
            } else return null
          }
        )}
      </>
    )}
  </div>
}

EBTableRowSelectionContent.propTypes = {
  mapData: PropTypes.arrayOf(PropTypes.shape({
    headerClassName: PropTypes.string,
    textClassName: PropTypes.string,
    text: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      prefix: PropTypes.string,
      postfix: PropTypes.string,
      name: PropTypes.string.isRequired,
      className: PropTypes.string,
      type: PropTypes.oneOf(['date', 'string', 'number']),
      format: PropTypes.string
    }))
  }))
}
