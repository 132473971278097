import React from 'react'
import {connect} from 'react-redux'
import { NumericFormat } from 'react-number-format';

import {numberFormat} from '@utilities'
import {ContentLoader} from "@components";

const Number = props => {
    const regexChar160 = new RegExp(String.fromCharCode(160), 'g')

    let format = props.format
        ? {...props.format}
        : {...numberFormat().currencyWithMinus}
    let value =
        props.language === 'en-GB' && props.value
            ? parseFloat(props.value.toString().replace(regexChar160, ''))
            : props.value
    let props2 = {...props, value}
    let text = props.istext === 0 ? null : {displayType: 'text'}

    delete props2.format
    delete props2.dispatch

    return <>
        <NumericFormat {...props2} {...format} {...text} />
        {props.loading && (<ContentLoader title={'  '} className="number_input_loader"/>)}
    </>
}

function mapStateToProps(state) {
    const {language} = state.languageReducer
    return {
        language
    }
}

const connectedNumber = connect(mapStateToProps)(Number)
export {connectedNumber as Number}
