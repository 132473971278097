import {UACServices} from './services'
import {UACConstants} from './constants'
import * as Sentry from '@sentry/browser'
import {IFRAME_USER, iframeSave} from "@redux/Local";
import {store} from "@redux/Config";
import {JWEActions} from "@redux";

export const UACActions = {
    postESAR,
    postESACR,
    postSMSR,
    postFullLogin,
    postSimpleLogin,
    postCheckLogin,
    postSMSAR,
    getMACR,
    getMALR,
    deleteMALR,
    postATR,
    getLogout,
    postCPRQ,
    postGPR,
    postSMSGETPWD,
    getGGDataMy,
    getRFT,
    getESARGET,
    postESARSM,
    getLoginMessage,
    setIsRemoteOrigin
}

function postESAR(data) {
    return dispatch => {
        dispatch(request())

        //JSONUserAuthentication
        //UID required on esign
        const json = {
            // User identification code
            UID: data.UID,
            PWD: data.PWD,
            CC_CN: data.CC_CN,
            CC_CC: data.CC_CC,
            ControlCode: data.ControlCode,
            RQ_ID: data.RQ_ID,
            AuthErrorMessage: data.AuthErrorMessage,
            AuthCheckResult: data.AuthCheckResult,
            SMS_Code: data.SMS_Code,
            PhoneNumber: data.PhoneNumber,
            GGSRC: data.GGSRC,
            Language: data.Language
        }
        return UACServices.postESAR(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_ESAR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_ESAR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_ESAR_ERROR, error}
    }
}

function postESARSM(data) {
    return dispatch => {
        dispatch(request())

        //JSONUserAuthentication
        //UID required on esign
        const json = {
            // User identification code
            UID: data.UID,
            PWD: data.PWD,
            CC_CN: data.CC_CN,
            CC_CC: data.CC_CC,
            ControlCode: data.ControlCode,
            RQ_ID: data.RQ_ID,
            AuthErrorMessage: data.AuthErrorMessage,
            AuthCheckResult: data.AuthCheckResult,
            SMS_Code: data.SMS_Code,
            PhoneNumber: data.PhoneNumber,
            GGSRC: data.GGSRC,
            Language: data.Language
        }
        return UACServices.postESARSM(json).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_ESARSM_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_ESARSM_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_ESARSM_ERROR, error}
    }
}

function postESACR(data) {
    return dispatch => {
        dispatch(request())

        dispatch(iframeSave({key: IFRAME_USER, value: data}));

        //JSONUserAuthentication
        //UID and RQ_ID and ISGG  required on esign
        const json = {
            // User identification code
            UID: data.UID,
            PWD: data.PWD,
            CC_CN: data.CC_CN,
            CC_CC: data.CC_CC,
            ControlCode: data.ControlCode,
            RQ_ID: data.RQ_ID,
            AuthErrorMessage: data.AuthErrorMessage,
            AuthCheckResult: data.AuthCheckResult,
            SMS_Code: data.SMS_Code,
            PhoneNumber: data.PhoneNumber,
            GGSRC: data.GGSRC,
            ISGG: data.ISGG
        }

        return UACServices.postESACR(data).then(
            data => {
                dispatch(iframeSave({key: IFRAME_USER, value: data}));
                return dispatch(success(data));
            },
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_ESACR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_ESACR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_ESACR_ERROR, error}
    }
}

function postSMSR(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postSMSR(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_SMSR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_SMSR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_SMSR_ERROR, error}
    }
}

function postFullLogin(data) {
    return dispatch => {
        dispatch(request())
        Sentry.setUser({username: data.LoginCode})
        return UACServices.postFullLogin(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_FULLLOGIN_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_FULLLOGIN_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_FULLLOGIN_ERROR, error}
    }
}

function postSimpleLogin(data) {
    return dispatch => {
        dispatch(request())
        Sentry.setUser({username: data.LoginCode})
        return UACServices.postSimpleLogin(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_SIMPLELOGIN_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_SIMPLELOGIN_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_SIMPLELOGIN_ERROR, error}
    }
}

function postCheckLogin(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postCheckLogin(data).then(
            data => {
                dispatch(JWEActions.setIsRemoteToken(!!data.Jwe));
                dispatch(iframeSave({key: IFRAME_USER, value: data}));
                return dispatch(success(data));
            },
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_CHECK_LOGIN_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_CHECK_LOGIN_ERROR, error}
    }
}

function postSMSAR(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postSMSAR(data).then(
            data => {
                dispatch(iframeSave({key: IFRAME_USER, value: data}));
                return dispatch(success(data))
            },
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_SMSAR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_SMSAR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_SMSAR_ERROR, error}
    }
}

function getMACR(data) {
    const json = {
        ManagedAccountID: data.ManagedAccountID
    }
    return dispatch => {
        dispatch(request())

        return UACServices.getMACR(json).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_MACR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_MACR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_MACR_ERROR, error}
    }
}

function deleteMALR() {
    return dispatch => {
        dispatch(unmount())
    }

    function unmount() {
        return {type: UACConstants.GET_UAC_MALR_UNMOUNT}
    }
}

function getMALR() {
    return dispatch => {
        dispatch(request())

        return UACServices.getMALR().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_MALR_REQUEST}
    }

    function success(data) {
        Sentry.setUser({id: data?.ID})
        return {type: UACConstants.GET_UAC_MALR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_MALR_ERROR, error}
    }
}

function postATR() {
    return dispatch => {
        dispatch(request())

        return UACServices.postATR().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_ATR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_ATR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_ATR_ERROR, error}
    }
}

function getLogout() {
    return dispatch => {
        dispatch(request())

        return UACServices.getLogout().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_LOGOUT_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_LOGOUT_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_LOGOUT_ERROR, error}
    }
}

function postCPRQ(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postCPRQ(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_CPRQ_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_CPRQ_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_CPRQ_ERROR, error}
    }
}

function postGPR(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postGPR(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_GPR_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_GPR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_GPR_ERROR, error}
    }
}

function postSMSGETPWD(data) {
    return dispatch => {
        dispatch(request())

        return UACServices.postSMSGETPWD(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.POST_UAC_SMSGETPWD_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.POST_UAC_SMSGETPWD_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.POST_UAC_SMSGETPWD_ERROR, error}
    }
}

function getGGDataMy() {
    return dispatch => {
        dispatch(request())

        return UACServices.getGGDataMY().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_GGDATAMY_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_GGDATAMY_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_GGDATAMY_ERROR, error}
    }
}

function getRFT(refreshToken) {
    return dispatch => {
        dispatch(request())

        return UACServices.getRFT(refreshToken).then(
            data => {
                const user = { ...store.getState().IframeStorageReducer?.user };
                user.Bearer = data?.AccessToken;
                user.Jwe = data?.Jwe;
                dispatch(iframeSave({key: IFRAME_USER, value: user}));
                return dispatch(success(data))
            },
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_RFT_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_RFT_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_RFT_ERROR, error}
    }
}

function getESARGET() {
    return dispatch => {
        dispatch(request())

        return UACServices.getESARGET().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_UAC_ESARGET_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_UAC_ESARGET_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_UAC_ESARGET_ERROR, error}
    }
}

function getLoginMessage() {
    return dispatch => {
        dispatch(request())

        return UACServices.getLoginMessage().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        )
    }

    function request() {
        return {type: UACConstants.GET_LOGIN_MESSAGE_REQUEST}
    }

    function success(data) {
        return {type: UACConstants.GET_LOGIN_MESSAGE_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: UACConstants.GET_LOGIN_MESSAGE_ERROR, error}
    }
}

function setIsRemoteOrigin(isRemoteOrigin){
    return {type: UACConstants.SET_IS_REMOTE_ORIGIN, payload: isRemoteOrigin}
}