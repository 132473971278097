import { i18, trb, getClassificators } from '@utilities'
import moment from "moment/moment";

export const InternationalPaymentPreview = props => {
  if (!props) {
    return null
  }
  const CISO = getClassificators()?.CISO || {}

  return {
    parts: [
      {
        title: i18.PaymentParts.Payee,
        fields: [
          {
            title: i18.Labels.FromAccount,// 1
            value: props.InitialAccount
          },
          {
            title: i18.Labels.PayeeTitle, // 2
            value: props.Payment.Recipient
          },
          {
            title: i18.Labels.PayeeAccount, // 3
            value: props.Payment.BeneficiaryAccountNumber
          },
          {
            title: i18.Labels.Amount,// 4
            value: props.Payment.AmountFormatted
          },
          {
            title: i18.Labels.Currency,// 5
            value: props.Payment.Currency
          },
          {
            title: i18.Labels.BeneficiaryAddress, // 6
            value: props.Payment.BeneficiaryAddress
          },
          {
            title: i18.Labels.RecipientInternationalBankCountry,// 7
            value: CISO[props.Payment.RecipientBankCountry]
          },
          {
            title: i18.Labels.BeneficiaryCity, // 8
            value: props.Payment.BeneficiaryCity
          },
          {
            title: i18.Labels.BeneficiaryPostcode, // 9
            value: props.Payment.BeneficiaryPostCode
          },
          {
            title: i18.Labels.CorrespondentBankBicInternational, // 10
            value: props.Payment.CorrespondentBankBic,
          },
          {
            title: i18.Labels.CommissionFeeTitle, // 11
            value: trb(i18.Labels.CommissionFeeLabel, {CommissionFeeFormatted:props.Payment?.CommissionFeeFormatted,  CommissionCurrency:props.Payment?.CommissionCurrency}),
          },
          {
            title: i18.Labels.Purpose, // 12
            value: props.Payment.Purpose
          },
          {
            title: i18.Labels.BankTaxesPays, // 13
            value: trb(i18.Messages.SHARecipientAndPayer)
          },
          {
            title: i18.Labels.ExecutionDate, // 14
            value: !!props.internationalPayment?.executionDate ?
                `${trb(i18.Labels.ExecutionDateValue, {executionDate: moment(props?.internationalPayment?.executionDate).format('YYYY-MM-DD')})}`
                : '',
          }
        ]
      },
    ]
  }
}
