import React, {Component} from 'react';
import {Form} from "react-final-form";
import createDecorator from "final-form-calculate";

import { i18, required, requiredNotUndefined, translationGroups } from '../../../Utilities'
import {FormField, FormFieldType} from "../../../Components/FormField";
import { connect } from 'react-redux'

class OperationsFilter extends Component {
    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if (this.props.language !== nextProps.language) {
    //         return true
    //     } else return false;
    // }

    render() {
        const period = [
            {
                label: 'Days30',
                value: 30
            }, {
                label: 'Days60',
                value: 60
            }, {
                label: 'Days90',
                value: 90
            },
        ];

        const OperationTypesText = [
            {
                label: 'AllOperations',
                value: -1,
            }, {
                label: 'Ongoing',
                value: 0,
            }, {
                label: 'Completed',
                value: 1,
            }, {
                label: 'Rejected',
                value: 2,
            },
        ];
        return <Form
            onSubmit={() => null}
            keepDirtyOnReinitialize={true}
            initialValues={{
                OperationsType: this.props.OperationType ? this.props.OperationType : -1,
                Days: 30
            }}
            decorators={[
                createDecorator(
                    {
                        field: 'Days',
                        updates: (value) => {
                            if (this.props.changeDays) {
                                this.props.changeDays({'Days': value});
                            }
                            return {};
                        }
                    }, {
                        field: 'OperationsType',
                        updates: (value) => {
                            if (this.props.changeOperationType) {
                                this.props.changeOperationType({'OperationsType': value});
                            }
                            return {};
                        }
                    }
                )
            ]}
            render={({handleSubmit, values}) => {
                return (
                    <form className="mb-3" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-7">
                                <FormField
                                    title={i18.Labels.Period}
                                    name="Days"
                                    component={FormFieldType.radio}
                                    disabled={this.props.submitting}
                                    options={period}
                                    translate={translationGroups.Period}
                                    optionsLabel="label"
                                    optionsValue="value"
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-5">
                                <FormField
                                    title={i18.Labels.Account}
                                    name="OperationsType"
                                    component={FormFieldType.select}
                                    options={OperationTypesText}
                                    translate={translationGroups.PaymentStatuses}
                                    optionsLabel="label"
                                    optionsValue="value"
                                    validate={requiredNotUndefined}
                                    allowClear={false}
                                    disabled={this.props.submitting}
                                />
                            </div>
                        </div>
                    </form>
                )
            }}
        />;
    }
}

function mapStateToProps(state) {
    const { language } = state.languageReducer
    const {translations} = state.translationReducer

    return {
        language,
        translations
    }
}

const connectedOperationsFilter = connect(mapStateToProps)(OperationsFilter)
export { connectedOperationsFilter as OperationsFilter }
