import React from 'react';

import './index.less';

export const PageLoader = (props) => {
    return <div className="eb_page_loader">
        <div className="eb_page_loader_inner">
            <div className="eb_page_loader_logo">
                <img src={`${process.env.REACT_APP_BASE_PATH_IMG}images/logos/${process.env.REACT_APP_LOGO}`} alt={process.env.REACT_APP_TITLE}/>
            </div>
            <div className="eb_page_loader_progress">
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
            </div>
        </div>
    </div>
};