import createDecorator from "final-form-calculate";

export const NewPaymentDecorator = (props) => {
    return [
        createDecorator(
            {
                field: 'Payment.Template',
                updates: (value, field, values) => {
                    return {};
                }
            }
        )
    ];
};