import { i18, translationGroups, trb } from '@utilities'

export const OwnAccountsValidation = (
  { Payment },
  { contractDesc2, InitialAccounts }
) => {
  const { InitialAccountID, DestinationAccountID, AmountStr } = Payment
  const getMaximumPaymentAmount = () => {
    if (contractDesc2?.accountOverdraftInfo) {
      const { totalAvailable } = contractDesc2.accountOverdraftInfo
      return totalAvailable || 0
    }
    return (
      InitialAccounts?.find(x => x.value === InitialAccountID)?.amountValue || 0
    )
  }

  const errorFields = { Payment: {} }
  if (
    InitialAccountID &&
    DestinationAccountID &&
    InitialAccountID === DestinationAccountID
  ) {
    errorFields.Payment.InitialAccountID = trb(i18.Validations.SameAccountID)
    errorFields.Payment.DestinationAccountID = trb(
      i18.Validations.SameAccountID
    )
  }

  if (AmountStr > 0 && InitialAccountID) {
    const maxPaymentAmount = getMaximumPaymentAmount()
    if (maxPaymentAmount < AmountStr) {
      errorFields.Payment.AmountStr = trb(
        translationGroups.ErrorCode + 'PMC00004'
      )
    }
  }
  return errorFields
}
