import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    i18,
    required,
    requiredAll,
    checkJsonProp
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCBusinessType = (props) => {

    const ClientBasicBusiness = checkJsonProp(props.data, 'clientBasicBusiness') && checkJsonProp(props.data, 'clientBasicBusiness').length ? checkJsonProp(props.data, 'clientBasicBusiness') : [{}],
          ClientBasicRegion = checkJsonProp(props.data, 'clientBasicRegion') && checkJsonProp(props.data, 'clientBasicRegion').length ? checkJsonProp(props.data, 'clientBasicRegion') : [{}];

    let ClientBusPartner = checkJsonProp(props.data, 'clientBusPartner') && checkJsonProp(props.data, 'clientBusPartner').length ? checkJsonProp(props.data, 'clientBusPartner') : [{}];
    ClientBusPartner = ClientBusPartner.map((item) => {
        item.allNeed = (item.cientBusPartnerType && item.data && item.data.clientBusPartnerName && item.data.clientBusPartnerCountry) ||
                       (!checkJsonProp(item, 'cientBusPartnerType')
                       && !checkJsonProp(item, 'data.clientBusPartnerName')
                       && !checkJsonProp(item, 'data.clientBusPartnerCountry'))
                          ? true : false;
        return item;
    });

    const initialData = {clientBasicBusiness: ClientBasicBusiness, clientBasicRegion: ClientBasicRegion, clientBusPartner: ClientBusPartner};

    const mapData = [{
        title: i18.Labels.ClientBasicBusiness, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCBusinessType,
        canDuplicate: true,
        duplicateName: 'clientBasicBusiness',
        maxRows: props.classifikators.CBBSN.length - 1,
        elements: [{
            title: i18.Labels.ClientBasicBusinessType,
            name: 'clientBasicBusiness.$index.clientBasicBusinessType',
            component: FormFieldType.select,
            options: props.classifikators.CBBSN,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-6 pl-md-4',
            noInline: true,
            validate: required,
        },{
            title: i18.Labels.ClientBasicBusiness,
            name: 'clientBasicBusiness.$index.data.clientBasicBusiness',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CBB,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 pr-md-5',
            noInline: true,
            validate: required,
        }]
    },{
        title: i18.Labels.ClientBasicRegion,
        canDuplicate: true,
        duplicateName: 'clientBasicRegion',
        maxRows: props.classifikators.CBREGZ.length - 1,
        elements: [{
            title: i18.Labels.ClientBasicRegionType,
            name: 'clientBasicRegion.$index.clientBasicRegionType',
            component: FormFieldType.select,
            options: props.classifikators.CBREGZ,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-6 pl-md-4',
            noInline: true,
            validate: required,
        },{
            title: i18.Labels.ClientBasicRegion,
            name: 'clientBasicRegion.$index.data.clientBasicRegion',
            component: FormFieldType.select,
            options: props.classifikators.CBREG,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 pr-md-5',
            noInline: true,
            validate: required,
        }]
    },{
        title: i18.Labels.ClientBusPartner, //i18.Labels.ClientOtherAddress
        canDuplicate: true,
        duplicateName: 'clientBusPartner',
        maxRows: props.classifikators.CBP.length - 1,
        duplicateDefault: {cientBusPartnerType: undefined, allNeed: true, data: {clientBusPartnerName: undefined, clientBusPartnerCountry: undefined}},
        elements: [{
            title: i18.Labels.ClientBusPartnerType,
            name: 'clientBusPartner.$index.cientBusPartnerType',
            component: FormFieldType.select,
            options: props.classifikators.CBP,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-4 pl-md-4',
            noInline: true,
        },{
            title: i18.Labels.ClientBusPartnerName,
            name: 'clientBusPartner.$index.data.clientBusPartnerName',
            component: FormFieldType.input,
            className: 'col-4',
            noInline: true,
        },{
            title: i18.Labels.ClientBusPartnerCountry,
            name: 'clientBusPartner.$index.data.clientBusPartnerCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-4',
            noInline: true,
        },{
            name: 'clientBusPartner.$index.allNeed',
            component: FormFieldType.checkbox,
            checkedNumber: false,
            className: 'col-12 text-center m-2 only-error ',
            validate: requiredAll,
        }]
    }];

    const decorators = [
        createDecorator({
            field: /clientBasicBusiness\.*.*clientBasicBusinessType/,
            updates: (value, name, allValues) => {
                props.classifikators.CBBSN.map((item, index)=>{
                    item.disabled = checkJsonProp(allValues, 'clientBasicBusiness').some((addItem, addIndex) => {
                        return addItem.clientBasicBusinessType == item.value;
                    }) ? true : false;
                    item.disabled = item.disabled || item.value == value ? true : false;
                    return item;
                })
                return {}
            }
        },{
            field: /clientBasicRegion\.*.*clientBasicRegionType/,
            updates: (value, name, allValues) => {
                props.classifikators.CBREGZ.map((item, index)=>{
                    item.disabled = checkJsonProp(allValues, 'clientBasicRegion').some((addItem, addIndex) => {
                        return addItem.clientBasicRegionType == item.value;
                    }) ? true : false;
                    item.disabled = item.disabled || item.value == value ? true : false;
                    return item;
                })
                return {}
            }
        },{
            field: /clientBusPartner\.*.*cientBusPartnerType/,
            updates: (value, name, allValues) => {
                props.classifikators.CBP.map((item, index)=>{
                    item.disabled = checkJsonProp(allValues, 'clientBusPartner').some((addItem, addIndex) => {
                        return addItem.cientBusPartnerType == item.value;
                    }) ? true : false;
                    item.disabled = item.disabled || item.value == value ? true : false;
                    return item;
                })
                return {}
            }
        },{
            field: /clientBusPartner\.*.*/,
            updates: (value, name, allValues, prevValues) => {
                if (allValues && prevValues && allValues.length < prevValues.length) return {};
                if (value === undefined) return {};

                const arr = name.split('.'),
                      index = arr.length ? parseInt(arr[1]) : null,
                      another = (checkJsonProp(allValues, `clientBusPartner.${index}.cientBusPartnerType`)
                                && checkJsonProp(allValues, `clientBusPartner.${index}.data.clientBusPartnerName`)
                                && checkJsonProp(allValues, `clientBusPartner.${index}.data.clientBusPartnerCountry`))
                                || (!checkJsonProp(allValues, `clientBusPartner.${index}.cientBusPartnerType`)
                                && !checkJsonProp(allValues, `clientBusPartner.${index}.data.clientBusPartnerName`)
                                && !checkJsonProp(allValues, `clientBusPartner.${index}.data.clientBusPartnerCountry`)) ;

                return index > -1 && name.indexOf('allNeed') === -1 ? {
                    [`clientBusPartner.${index}.allNeed`]: another ? true : false,
                } : {}
            }
        })
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};