import React from 'react';
import PropTypes from 'prop-types';
import {Result, Button} from 'antd';

import {trb} from "../../Utilities";
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, WarningFilled} from "@ant-design/icons";

const ButtonGroup = Button.Group;

const IconMap = (iconClass)=> {
    return {
        success: <CheckCircleFilled className={iconClass}/>,
        error: <CloseCircleFilled className={iconClass}/>,
        info: <ExclamationCircleFilled className={iconClass}/>,
        warning: <WarningFilled className={iconClass}/>
    }
};

export const MessageResult = (props) => {
    let iconClass = null;

    switch (props.status) {
        case 'error':
            iconClass = 'text-error'

            break;
        case 'info':
            iconClass = 'text-primary'

            break;
        case 'success':
            iconClass = 'text-primary'

            break;
        case 'warning':
            iconClass = 'text-warning'

            break;
    }

    return (
        <Result
            status={props.status}
            title={trb(props.title)}
            subTitle={trb(props.subTitle)}
            icon={props.icon ? (<span className={iconClass} style={{fontSize: 72}}>{props.icon}</span>) : <span className={iconClass} style={{fontSize: 72}}>{IconMap(iconClass)[props.status]}</span>}
            extra={
                <React.Fragment>
                    <div className="mb-3 text-right d-none d-sm-block">
                        <ButtonGroup>
                            {props.buttons && props.buttons.map((item, index) =>
                                <Button
                                    key={index}
                                    onClick={item.func}
                                    {...item.props}
                                >{trb(item.title)}</Button>
                            )}
                        </ButtonGroup>
                    </div>
                    <div className="mb-3 text-right d-block d-sm-none">
                        {props.buttons && props.buttons.map((item, index) =>
                            <Button
                                className='mb-2'
                                key={index}
                                onClick={item.func}
                                block
                                {...item.props}
                            >{trb(item.title)}</Button>
                        )}
                    </div>
                </React.Fragment>
            }
        />
    );
};

MessageResult.propTypes = {
    status: PropTypes.oneOf(['success', 'error', 'info', 'warning']).isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    buttons: PropTypes.array,
};
