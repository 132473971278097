import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'

import { FormEntrySwitch } from '@components'
import { AMCActions } from '@redux'
import { useDispatch, useSelector } from 'react-redux'
import { currencyFormat, i18, Payment, translationGroups, trb } from '@utilities'

export const PaymentFill = props => {
    const dispatch = useDispatch()
    const AMLReducer = useSelector((state) => state.AMLReducer)

    const { PTR } = props

    const InitialAccountsData = PTR?.InitialAccounts,
        DestinationAccountData = PTR?.DestinationAccounts,
        PaymentTemplates = PTR?.PaymentTemplates,
        SEPAPaymentTypes = PTR?.SEPAPaymentTypes

    let tempTypeCode = null
    const InitialAccounts =
        InitialAccountsData &&
        InitialAccountsData.map(item => {
            if (tempTypeCode !== item.AccountTypeNameToView) {
                const label = {
                    label: trb(
                        translationGroups.AccountTypeCode + item.AccountTypeNameToView
                    ),
                    value: item.AccountTypeCode,
                    amountValue: item?.Amounts?.[0]?.Value,
                    disabled: true
                }

                const data = {
                    label: props.paymentType === Payment.INTERNATIONAL ? `${item.AccountNumber}` : `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})${
                        item.RelatedProductNumber
                            ? ` ${item.RelatedProductNumber} (${currencyFormat(
                                item.RelatedProductValue
                            )})`
                            : ''
                    }`,
                    value: item.AccountID,
                    key: item.AccountID,
                    amountValue: item?.Amounts?.[0]?.Value,
                    hasOverdraftProduct: !!item.RelatedProductNumber

                }

                tempTypeCode = item.AccountTypeNameToView

                return [label, data]
            } else {
                return {
                    label: props.paymentType === Payment.INTERNATIONAL ? `${item.AccountNumber}` : `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})${
                        item.RelatedProductNumber
                            ? ` ${item.RelatedProductNumber} (${currencyFormat(
                                item.RelatedProductValue
                            )})`
                            : ''
                    }`,
                    value: item.AccountID,
                    key: item.AccountID,
                    amountValue: item?.Amounts?.[0]?.Value,
                    hasOverdraftProduct: !!item.RelatedProductNumber
                }
            }
        }).reduce(function(flat, current) {
            return flat.concat(current)
        }, [])

    const InitialAccountsResponse = InitialAccounts

    const DestinationAccount =
        DestinationAccountData &&
        DestinationAccountData.map(item => {
            if (tempTypeCode !== item.AccountTypeNameToView) {
                const label = {
                    label: item.AccountTypeNameToView,
                    value: item.AccountTypeCode,
                    amountValue: item?.Amounts?.[0]?.Value,
                    disabled: true
                }

                const data = {
                    label: `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})`,
                    value: item.AccountID,
                    amountValue: item?.Amounts?.[0]?.Value
                }

                tempTypeCode = item.AccountTypeNameToView

                return [label, data]
            } else {
                return {
                    label: `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})`,
                    value: item.AccountID,
                    amountValue: item?.Amounts?.[0]?.Value
                }
            }
        }).reduce(function(flat, current) {
            return flat.concat(current)
        }, [])

    const DestinationAccountResponse = DestinationAccount

    const buttons = [
        {
            title: i18.Buttons.Preview,
            props: {
                //icon: "eye",
                type: 'primary',
                htmlType: 'submit',
                disabled: props.submitting || props.submitDisabled,
                loading: props.submitting
            }
        }
    ]
    // if (props.errors){
    //     buttons.unshift({
    //         title: i18.Buttons.NewPaymentInstruction,
    //         func: props.resetPayment,
    //         props: {
    //             icon: "new"
    //         }
    //     })
    // }

    const getAccountOverdraftInfo = async account => {
        if (account?.hasOverdraftProduct) {
            await dispatch(AMCActions.getAccountOverdraft(account.value))
        } else {
            dispatch(AMCActions.setAccountOverdraft(null))
        }
    }

    const [initialAccount, setInitialAccount] = useState(null)
    const checkAcountId = value => {
        if (value !== initialAccount) {
            setInitialAccount(value)
        }
    }

    useEffect(()=> {
        if (initialAccount) {
            const account = InitialAccounts.find(item => item.value === initialAccount)
            getAccountOverdraftInfo(account)
        }
    },[initialAccount])

    const [destinationAcountId, setDestinationAcountId] = useState(null)

    const checkDestinationAcountId = value => {
        if (value !== destinationAcountId) {
            setDestinationAcountId(value)
        }
    }

    const { accountOverdraftInfo } = useSelector(state => state.AMCReducers)

    const formatAccountOptions = (options, noSums, allAccountAllowed = false, showRelatedProduct = false) => {
        return options
            .filter(acc => acc.Amounts[0].Value !== 0 || allAccountAllowed)
            .map(acc => {
                let relatedProductStr = ''
                if (showRelatedProduct && acc.RelatedProductNumber) {
                    relatedProductStr = ` ${acc.RelatedProductNumber} (${currencyFormat(acc.RelatedProductValue)})`
                }
                return {
                    label: `${acc.AccountNumber}${
                        !noSums ? `(${acc.Amounts[0].Text})` : ''
                    }${relatedProductStr}`,
                    value: acc.AccountID,
                    currency: acc.CurrencyName,
                    amountValue: acc.Amounts?.[0]?.Value ?? 0
                }
            })
    }

    const { forexPayments } = useSelector(state => state.PMCReducers)
    const { forexCurrency } = useSelector(state => state.AMCReducers)
    const fromAccounts = useMemo(() => {
        if (!forexPayments?.InitialAccounts) return []
        return formatAccountOptions(forexPayments.InitialAccounts, null, true, true)
    }, [forexPayments?.InitialAccounts])

    const destinationAccounts = useMemo(() => {
        if (!forexPayments?.DestinationAccounts) return []
        return formatAccountOptions(forexPayments.DestinationAccounts, true, true, false)
    }, [forexPayments?.InitialAccounts])

    const { language } = useSelector(state => state.languageReducer)
    const { CISO } = useSelector(
        state =>
            state?.translationReducer?.translations?.find(
                el => el.culture === language && el.context === 'BBS.Classificators'
            )?.Groups ?? []
    )

    return (
        <FormEntrySwitch
            PVR={props.PVR}
            name={props.paymentType}
            submit={props.submit}
            submitting={props.submitting}
            data={props.data}
            user={props.user}
            decorator={props.decorator}
            errors={props.errors}
            fees={props.fees}
            forexPayments={props.forexPayments}
            feePaymentHistory={props.feePaymentHistory}
            InitialAccounts={InitialAccounts}
            InitialAccountsResponse={InitialAccountsResponse}
            DestinationAccount={DestinationAccount}
            DestinationAccountResponse={DestinationAccountResponse}
            SEPAPaymentTypes={SEPAPaymentTypes}
            PaymentTemplates={PaymentTemplates}
            PayPersonTypes={props.PayPersonTypes}
            templateId={props.templateId}
            historicalPayments={props.historicalPayments}
            onFullTextSearch={props.onFullTextSearch}
            checkIBANResult={props.checkIBANResult}
            sendCheckIBAN={props.sendCheckIBAN}
            clearErrors={props.clearErrors}
            checkIBANLength={props.checkIBANLength}
            checkIBANValid={props.checkIBANValid}
            contractDesc2={props.contractDesc2}
            className={props.className}
            buttons={buttons}
            initialAccount={initialAccount}
            checkAcountId={checkAcountId}
            destinationAcountId={destinationAcountId}
            checkDestinationAcountId={checkDestinationAcountId}
            accountOverdraftInfo={accountOverdraftInfo}
            forexCurrency={forexCurrency}
            fromAccounts={fromAccounts}
            destinationAccounts={destinationAccounts}
            enums={{ CISO }}
            aml={AMLReducer}
        />
    )
}

PaymentFill.propTypes = {
    paymentType: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    forexPayments: PropTypes.object
}
