export const DepositConstants = {
    POST_DEPOSIT_TERMINATION_REQUEST: 'POST_DEPOSIT_TERMINATION_REQUEST',
    POST_DEPOSIT_TERMINATION_SUCCESS: 'POST_DEPOSIT_TERMINATION_SUCCESS',
    POST_DEPOSIT_TERMINATION_ERROR: 'POST_DEPOSIT_TERMINATION_ERROR',

    POST_DEPOSIT_TERMINATION_CONFIRM_REQUEST: 'POST_DEPOSIT_TERMINATION_CONFIRM_REQUEST',
    POST_DEPOSIT_TERMINATION_CONFIRM_SUCCESS: 'POST_DEPOSIT_TERMINATION_CONFIRM_SUCCESS',
    POST_DEPOSIT_TERMINATION_CONFIRM_ERROR: 'POST_DEPOSIT_TERMINATION_CONFIRM_ERROR',
    POST_DEPOSIT_TERMINATION_CONFIRM_CANCEL: 'POST_DEPOSIT_TERMINATION_CONFIRM_CANCEL',

    POST_DEPOSIT_OFFER_REQUEST: "POST_DEPOSIT_OFFER_REQUEST",
    POST_DEPOSIT_OFFER_SUCCESS: "POST_DEPOSIT_OFFER_SUCCESS",
    POST_DEPOSIT_OFFER_ERROR: "POST_DEPOSIT_OFFER_ERROR",

    POST_CONFIRM_DEPOSIT_REQUEST:"POST_CONFIRM_DEPOSIT_REQUEST",
    POST_CONFIRM_DEPOSIT_SUCCESS: "POST_CONFIRM_DEPOSIT_SUCCESS",
    POST_CONFIRM_DEPOSIT_ERROR: "POST_CONFIRM_DEPOSIT_ERROR",
    POST_CONFIRM_DEPOSIT_CLEAR_DATA: "POST_CONFIRM_DEPOSIT_CLEAR_DATA",

    POST_DEPOSIT_DOCUMENT_REQUEST: "POST_DEPOSIT_DOCUMENT_REQUEST",
    POST_DEPOSIT_DOCUMENT_SUCCESS: "POST_DEPOSIT_DOCUMENT_SUCCESS",
    POST_DEPOSIT_DOCUMENT_ERROR: "POST_DEPOSIT_DOCUMENT_ERROR",
};