import { translationGroups, trb } from '@utilities'
import {AMCReducers, AMLReducer} from "@redux";

export const ForexValidation = (values, props, AMCReducers) => {
  const errorFields = {}
  const getMaximumPaymentAmount = accountNr => {
   const found = props.fromAccounts.find(el => el.value === accountNr);

   if(found?.label.split('/')?.length>1){
     return AMCReducers?.accountOverdraftInfo?.totalAvailable || 0
   }

    return (
      found?.amountValue || 0
    )
  }
  if (values.Payment) {
    if (values.Payment.AmountBuy > 0) {
      const maxPaymentAmount = getMaximumPaymentAmount(
        values.Payment.FromAccount
      )
      if (maxPaymentAmount < values.Payment.AmountBuy) {
        errorFields.AmountBuy = trb(translationGroups.ErrorCode + 'PMC00004')
      }
    }
  }

  return {
    Payment: {
      ...errorFields
    }
  }
}
