import React from 'react'
import { appEnvSME, i18, required, trb } from '@utilities'
import { FormField, FormFieldType } from '@components/FormField'
import { Button } from 'antd'

export const PaymentTemplateAssign = props => {
  const assignTypes = [
    {
      label: trb(i18.Labels.NewGroup),
      value: true
    },
    {
      label: trb(i18.Labels.ExistingGroup),
      value: false
    }
  ]

  // TODO: Deployment comment
  return (<></>)

  return (
    <>
      <div className="eb_content_subtitle">
        {trb(i18.ContentSubTitles.GroupPaymentAddCreate)}
      </div>
      <div className={appEnvSME ? 'templates row mb-3' : 'row mb-3'}>
        <div className="col-12 col-sm-12 col-md-6">
          <FormField
            name="IsNewGroup"
            component={FormFieldType.radio}
            options={assignTypes}
            optionsLabel="label"
            optionsValue="value"
            className="form-field-wrapper big-input small-input small-input-bordered"
            classNameItem="text-nowrap"
          />
        </div>
        <div className="col-12 col-sm col-md-3">
          {props.values.IsNewGroup ? (
            <FormField
              name="GroupName"
              className="form-field-wrapper big-input small-input"
              component={FormFieldType.input}
              placeholder={i18.PlaceHolders.CreateGroup}
              validate={required}
            />
          ) : (
            <FormField
              name="ExistingGroupID"
              component={FormFieldType.select}
              className="form-field-wrapper small-input align-content-center"
              options={props.groups}
              optionsLabel="Name"
              optionsValue="ID"
              placeholder={i18.PlaceHolders.SelectGroup}
              validate={required}
            />
          )}
        </div>
        <div className="col-12 col-sm-auto col-md-3 text-right">
          <Button type="primary" htmlType="submit" loading={props.submitting}>
            {props.values.IsNewGroup === true
              ? trb(i18.Buttons.Create)
              : trb(i18.Buttons.Include)}
          </Button>
        </div>
      </div>
    </>
  )
}
