import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import {FormFieldType} from './FormFieldType';

import {
    AutocompleteFormField,
    CheckboxFormField,
    CheckboxOnlyFormField,
    DateFormField,
    HTMLFormField,
    InputFormField,
    InputOnlyFormField,
    InputPasswordFormField,
    InputSearchFormField,
    NumberFormField,
    RadioFormField,
    SelectFormField,
    SelectSearchFormField,
    TextareaFormField,
    NumberLikeRadioFormField
} from '../';
import {AmountCurrencyFormField} from "./Components/AmountCurrencyFormField";

export const FormField = (props) => {
    const componentSwitch = (component) => {
        switch (component) {
            case FormFieldType.autocomplete:
                return AutocompleteFormField;
            case FormFieldType.checkbox:
                return CheckboxFormField;
            case FormFieldType.checkboxOnly:
                return CheckboxOnlyFormField;
            case FormFieldType.date:
                return DateFormField;
            case FormFieldType.input:
                return InputFormField;
            case FormFieldType.inputOnly:
                return InputOnlyFormField;
            case FormFieldType.inputPassword:
                return InputPasswordFormField;
            case FormFieldType.inputSearch:
                return InputSearchFormField;
            case FormFieldType.number:
                return NumberFormField;
            case FormFieldType.amountCurrency:
                return AmountCurrencyFormField;
            case FormFieldType.select:
                return SelectFormField;
            case FormFieldType.selectSearch:
                return SelectSearchFormField;
            case FormFieldType.radio:
                return RadioFormField;
            case FormFieldType.textarea:
                return TextareaFormField;
            case FormFieldType.html:
                return HTMLFormField;
            case FormFieldType.numberLikeRadio:
                return NumberLikeRadioFormField;
            default:
                console.log('%cComponent not found: ' + component, 'background: #222; color: #bada55; padding: 5px');
        }
    };

    const component = props.component;

    return <Field
        {...props}
        valueForDefault={props.defaultValue}
        type={FormFieldType === 'checkboxOnly' ? 'checkbox' : FormFieldType}
        component={componentSwitch(component, props)}
    />;
};

FormField.propTypes = {
    title: PropTypes.string,
    titleWithoutTranslate: PropTypes.bool,
    className: PropTypes.string,
    classNameEl: PropTypes.string,
    style: PropTypes.object,
    inline: PropTypes.bool,
    onlyComponent: PropTypes.bool,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    componentOptions: PropTypes.object,
    inputType: PropTypes.string,
    autoFocus: PropTypes.bool,
    formatNumber: PropTypes.object,
    formatNumberType: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    options: PropTypes.array,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    label: PropTypes.string,
    translate: PropTypes.string,
    disabled: PropTypes.bool,
    validate: PropTypes.func,
    onSelect: PropTypes.func,
    onSearch: PropTypes.func,
    suffix: PropTypes.any,
    html: PropTypes.string,
    maxLength: PropTypes.number,
    loading: PropTypes.bool
};

