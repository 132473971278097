import {
    appEnvSME,
    checkMaxLength,
    checkMinLength,
    classifikatorsMap,
    EUR,
    i18,
    required,
    validateTemplateIban
} from '@utilities'
import {FormFieldType} from '@components'
import {
    getBeneficiaryAddressValidation,
    getBeneficiaryBankCodeValidation,
    getPurposeValidation
} from './PaymentFormValidations'

export const TemplateFormInternational = props => {
    const {CISO} = props;

    return {
        parts: [
            {
                title: i18.Labels.Template + props.type,
                fields: [
                    {
                        title: i18.Labels.PaymentTemplateName,
                        name: 'Payment.TemplateName',
                        component: FormFieldType.input,
                        inline: !appEnvSME,
                        //visible: 'Payment.SaveTemplate',
                        validate: required
                    },
                    {
                        title: i18.Labels.PayeeTitle,
                        name: 'Payment.Recipient',
                        component: FormFieldType.input,
                        inline: !appEnvSME,
                        validateCompose: true,
                        validate: [required, checkMaxLength(35)]
                    },
                    {
                        title: i18.Labels.PayeeAccount,
                        name: 'Payment.BeneficiaryAccountNumber',
                        component: FormFieldType.input,
                        inline: !appEnvSME,
                        validate: validateTemplateIban
                    },
                    {
                        title: i18.Labels.Purpose,
                        name: 'Payment.Purpose',
                        component: FormFieldType.input,
                        validateCompose: true,
                        validate: getPurposeValidation(EUR, true),
                    },
                    {
                        title: i18.Labels.BeneficiaryAddress,
                        name: 'Payment.BeneficiaryAddress',
                        component: FormFieldType.input,
                        validateCompose: true,
                        validate: getBeneficiaryAddressValidation(EUR, true),
                    },
                    {
                        title: i18.Labels.RecipientInternationalBankCountry,
                        name: 'Payment.RecipientBankCountry',
                        component: FormFieldType.selectSearch,
                        options: classifikatorsMap(CISO),
                        optionsLabel: 'label',
                        optionsValue: 'value',
                        validate: required
                    },
                    {
                        title: i18.Labels.BeneficiaryCity,
                        name: 'Payment.BeneficiaryCity',
                        component: FormFieldType.input,
                        validateCompose: true,
                        validate: [required, checkMaxLength(25)]
                    },
                    {
                        title: i18.Labels.BeneficiaryPostcode,
                        name: 'Payment.BeneficiaryPostCode',
                        component: FormFieldType.input,
                        validateCompose: true,
                        validate: [required, checkMaxLength(10)]
                    },
                    {
                        title: i18.Labels.CorrespondentBankBicInternational,
                        name: 'Payment.CorrespondentBankBic',
                        component: FormFieldType.input,
                        validateCompose: true,
                        validate: getBeneficiaryBankCodeValidation(EUR, true)
                    },
                ]
            }
        ]
    }
}
