import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Steps} from 'antd'
import {EditOutlined, EyeOutlined} from '@ant-design/icons'

import {appEnvSME, checkJsonExist, checkJsonProp, CustomIcon, i18, trb} from '@utilities'
import {OMCActions, PMCActions} from '@redux'
import {Container, ContentLoader, ContentNoData, Notification} from '@components'
import {GroupPaymentFill, GroupPaymentPreview, GroupPaymentSuccess} from './Components'
import {history, routerPath} from '@config'
import {useLocation, useNavigate} from "react-router-dom";

const Step = Steps.Step

export const GroupPayment = props => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0)
    const [paymentValues, setValues] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [errors, setErrors] = useState(null)
    const [stateValues, setStateValues] = useState({})

    const {sendPTR, PTR} = useSelector(state => state.PMCReducers)
    const {language} = useSelector(state => state.languageReducer)


    const submit = values => {
        setSubmitting(true)
        setErrors(null)

        const data = {
            ...JSON.parse(JSON.stringify(values))
        }


        // TODO: for some reason this one was converted to text instead of number
        data.GroupPayments.map(item => {
            item.Amount = Number(item.Amount.toString().replace(',', '.'))
            return item
        })

        dispatch(PMCActions.postSGPVR({...data, Language: language})).then(
            response => {
                if (response.payload && response.payload.IsSuccessfull) {
                    const accounts = PTR?.InitialAccounts
                    const acc = accounts.find(x => x.AccountID === values.AccountID)
                    let x1 = checkJsonExist(response, 'payload.PaymentsTotalAmount')
                    let x2 = parseFloat(
                        checkJsonProp(response, 'payload.PaymentsTotalAmount')
                    )
                    let x3 = parseFloat(
                        checkJsonProp(response.payload, 'GroupPayments').length &&
                        checkJsonProp(response.payload, 'GroupPayments').reduce(
                            (sum, current) =>
                                sum +
                                checkJsonProp(current, 'amountNational') +
                                checkJsonProp(current, 'commissionAmount'),
                            0.0
                        )
                    )
                    let x4 = parseFloat(checkJsonProp(acc, 'Amounts.0.Value'))
                    let x5 = checkJsonProp(acc, 'Amounts.0.Value')
                    if ((x1 && x2 > x5) || x3 >= x4) {
                        setSubmitting(false)
                        setErrors([{ErrorCode: 'PMC00004'}])
                    } else {
                        setSubmitting(false)
                        setCurrentStep(1)
                        setValues(values)
                        setErrors(null)
                    }
                } else {
                    setSubmitting(false)
                    setErrors(response?.payload?.ErrorCaptions)
                }
            }
        )
    }

    const submitPreview = () => {
        setSubmitting(true)
        setErrors(null)

        const data = {
            ...JSON.parse(JSON.stringify(paymentValues))
        }

        // TODO: for some reason this one was converted to text instead of number
        // data.GroupPayments.map(item => {
        //   item.Amount = item.Amount.toString().replace('.', ',')
        //   return item
        // })
        delete data.PaymentGroupId
        dispatch(PMCActions.postCGPSGNR({...data, Language: language})).then(
            response => {
                if (response.payload.IsSuccessfull) {
                    const rq =
                        response.payload.AuthType &&
                        (response.payload.AuthType.toUpperCase() === 'SMS' || response.payload.AuthType.toUpperCase() === 'BIOFACE')
                            ? response.payload.SMSToken
                            : response.payload.iSignToken
                    setSubmitting(true)
                    setErrors(null)
                    setStateValues(prev => {
                        return {
                            ...prev,
                            approve: true,
                            AuthType: response.payload.AuthType,
                            RQ_ID: rq,
                            Code: response.payload.ControlCode
                        }
                    })
                } else {
                    setSubmitting(false)
                    setErrors(
                        response.payload.PaymentErrors.filter(
                            (items, index) =>
                                index ===
                                response.payload.PaymentErrors.findIndex(
                                    item => item.ErrorCode === items.ErrorCode
                                )
                        )
                    )
                }
            }
        )
    }

    const back = () => {
        setSubmitting(false)
        setCurrentStep(prev => prev - 1)
        setErrors(null)
        setStateValues(prev => {
            return {
                ...prev,
                approve: false,
                send: false
            }
        })
    }
    const cancelApprove = ErrorCode => {
        let message = ErrorCode
        if (message?.replace) {
            message = message.replace('.', '')
        }

        setSubmitting(false)
        setStateValues(prev => {
            return {
                ...prev,
                approve: false,
                RQ_ID: null,
                AuthType: null,
                Code: null,
                send: false
            }
        })

        Notification({
            type: 'warning',
            message: message
                ? i18.NotificationTitles[message]
                : i18.NotificationTitles.PaymentCanceled,
            description: message
                ? i18.NotificationMessages[message]
                : i18.NotificationMessages.PaymentCanceled
        })
    }
    const BadApproveCode = ErrorCode => {
        let message = ErrorCode
        if (message?.replace) {
            message = message.replace('.', '')
        }
        setSubmitting(false)
        setStateValues(prev => {
            return {
                ...prev,
                approve: false,
                RQ_ID: null,
                AuthType: null,
                Code: null,
                send: false
            }
        })

        Notification({
            type: 'error',
            message: message
                ? i18.NotificationTitles[message]
                : i18.NotificationTitles.BadApproveCode,
            description: message
                ? i18.NotificationMessages[message]
                : i18.NotificationMessages.BadApproveCode
        })
    }

    const successApprove = () => {
        setCurrentStep(prev => prev + 1)
        setStateValues(prev => {
            return {
                ...prev,
                approve: false,
                send: false
            }
        })
    }

    const submitSmsApprove = values => {
        dispatch(OMCActions.postSUORSMS(values)).then(response => {
            const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull')
            const ErrorCode = checkJsonProp(response, 'payload.ErrorCode')
            if (IsSuccessfull) {
                successApprove()
            } else {
                BadApproveCode(ErrorCode)
            }
        })
    }

    const submitMobileApprove = async () => {
        const {RQ_ID} = stateValues
        const response = await dispatch(OMCActions.postSUOR({iSignToken: RQ_ID}))
        const AuthType = checkJsonProp(response.payload, 'AuthType')
        const IsSuccessfull = checkJsonProp(response.payload, 'IsSuccessfull')
        const ErrorCode = checkJsonProp(response, 'payload.ErrorCode')
        if (AuthType === null && IsSuccessfull === false) {
            return true
        }
        if (AuthType === 'false') {
            cancelApprove(ErrorCode)
        } else if (IsSuccessfull === true) {
            successApprove()
        }
        return false
    }
    const close = () => {
        navigate(routerPath.index)
    }

    if (sendPTR) {
        return (
            <>
                <div className="eb_content_title">
                    {trb(i18.ContentTitles.GroupPayment)}
                </div>
                <ContentLoader/>
            </>
        )
    }

    if (!PTR) {
        return (
            <>
                <div className="eb_content_title">
                    {trb(i18.ContentTitles.GroupPayment)}
                </div>
                <ContentNoData/>
            </>
        )
    }

    return (
        <>
            <div className="eb_content_subtitle">
                {trb(i18.ContentTitles.GroupPayment)}
                {appEnvSME && (
                    <span className="ml-3">
            <Button
                className="eb_fs_18"
                onClick={() => navigate(routerPath.paymentGroups)}
                ghost
                style={{border: 'none'}}>
              <EditOutlined/>
            </Button>
          </span>
                )}
            </div>
            <Container gradient={currentStep === 2}>
                <Steps current={currentStep} className="mb-5 d-none d-md-flex eb_steps">
                    <Step
                        direction="horizontal"
                        title={appEnvSME ? null : trb(i18.Steps.PaymentFill)}
                        description={
                            appEnvSME ? null : trb(i18.Steps.PaymentFillDescription)
                        }
                        icon={
                            appEnvSME ? (
                                <CustomIcon size={50} type="custom" icon="paymentFirstStep"/>
                            ) : null
                        }
                    />
                    <Step
                        title={appEnvSME ? null : trb(i18.Steps.PaymentPreview)}
                        description={
                            appEnvSME ? null : trb(i18.Steps.PaymentPreviewDescription)
                        }
                        icon={
                            appEnvSME ? (
                                <CustomIcon size={50} type="custom" icon="paymentSecondStep"/>
                            ) : null
                        }
                    />
                    <Step
                        title={appEnvSME ? null : trb(i18.Steps.PaymentSuccess)}
                        description={
                            appEnvSME ? null : trb(i18.Steps.PaymentSuccessDescription)
                        }
                        icon={
                            appEnvSME ? (
                                <CustomIcon size={50} type="custom" icon="paymentThirdStep"/>
                            ) : null
                        }
                    />
                </Steps>
                <div
                    className={
                        appEnvSME
                            ? 'position-relative w-75 center-horizontally'
                            : 'position-relative w-100'
                    }>
                    {currentStep === 0 && (
                        <GroupPaymentFill
                            data={PTR}
                            initialValues={{
                                PaymentGroupId: props?.history?.location?.state?.groupId
                            }}
                            submit={submitting ? () => {
                            } : submit}
                            errors={errors}
                            submitting={submitting}
                            buttons={[
                                {
                                    title: i18.Buttons.Preview,
                                    props: {
                                        type: 'primary',
                                        htmlType: 'submit',
                                        icon: <EyeOutlined/>,
                                        loading: submitting
                                    }
                                }
                            ]}
                        />
                    )}
                    {currentStep === 1 && paymentValues && (
                        <GroupPaymentPreview
                            {...stateValues}
                            initialValues={{
                                PaymentGroupId: location?.state?.groupId,
                                ...paymentValues
                            }}
                            values={paymentValues}
                            data={PTR}
                            disabled={true}
                            submitting={submitting}
                            errors={errors}
                            submit={submitting ? () => {
                            } : submitPreview}
                            cancelApprove={cancelApprove}
                            successApprove={successApprove}
                            submitSmsApprove={submitSmsApprove}
                            submitMobileApprove={submitMobileApprove}
                            buttons={[
                                {
                                    title: i18.Buttons.Cancel,
                                    func: back
                                },
                                {
                                    title: i18.Buttons.Submit,
                                    func: submitPreview,
                                    props: {
                                        type: 'primary',
                                        loading: submitting
                                    }
                                }
                            ]}
                        />
                    )}
                    {currentStep === 2 && <GroupPaymentSuccess close={close}/>}
                </div>
            </Container>
        </>
    )
}
