import React, {useEffect, useState} from 'react'
import {currencyTextValue, dateFormat, EUR, formatDate, i18, numberFormat, trb} from '@utilities'
import {UnsignedPaymentEditTable} from '@containers'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {routerPath} from '@config'
import {BulkFileActions, OMCActions} from '@redux'
import {Button, Modal} from 'antd'
import {ContentLoader, Notification, Number, OperationApproval} from '@components'

const ButtonGroup = Button.Group

export const UnsignedPaymentFileDetails = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate();

    const [showConfirm, setShowConfirm] = useState(false)
    const [approve, setApprove] = useState(false)

    const {FilesList, loading, error} = useSelector(
        state => state.BulkFileReducer
    )
    const {AuthType} = useSelector(state => state.UACReducers?.ATR)
    const {language} = useSelector(state => state.languageReducer)

    const File = FilesList.find(item => item.FileGuid === params.FileGuid)

    useEffect(() => {
        dispatch(BulkFileActions.getBulkPaymentsFileDetails(params.FileGuid))
    }, [])

    const handlePreview = () => {
        dispatch(BulkFileActions.getBulkPaymentsPreview(params.FileGuid))
        setShowConfirm(true)
    }

    const clickApproveModal = async () => {
        await dispatch(
            BulkFileActions.postBulkPaymentsSign({
                FileGuid: File?.FileGuid,
                Language: language
            })
        )
        setApprove(true)
    }
    const cancelApprove = () => {
        setApprove(false)
        dispatch(BulkFileActions.cancelLoading())
    }
    const successApprove = () => {
        setApprove(false)
        setShowConfirm(false)
        dispatch(BulkFileActions.removeFromList(File?.FileGuid))
        navigate(`${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`, {
            state: {
                paymentSuccess: true
            }
        })
        Notification({
            type: 'success',
            message: i18.NotificationTitles.SuccessApprove,
            description: i18.NotificationMessages.SuccessApprove
        })
    }

    const BadApproveCode = ErrorCode => {
        let message = ErrorCode
        if (message?.replace) {
            message = message.replace('.', '')
        }
        // setShowConfirm(false)
        // setApprove(false)
        Notification({
            type: 'error',
            message: message
                ? i18.NotificationTitles[message]
                : i18.NotificationTitles.BadApproveCode,
            description: message
                ? i18.NotificationMessages[message]
                : i18.NotificationMessages.BadApproveCode
        })
    }

    const submitSmsApprove = async values => {
        try {
            const response = await dispatch(OMCActions.postSUORSMS(values))
            const ErrorCode = response?.payload?.ErrorCode
            if (response.payload.IsSuccessfull) {
                successApprove()
            } else {
                BadApproveCode(ErrorCode)
            }
        } catch (e) {
        }
    }

    const submitMobileApprove = async () => {
        if (error) return;
        const RQ_ID = File?.iSignToken

        const response = await dispatch(OMCActions.postSUOR({iSignToken: RQ_ID}))
        const AuthType = response?.payload?.AuthType
        const IsSuccessfull = response?.payload?.IsSuccessfull
        const ErrorCode = response?.payload?.ErrorCode

        if (ErrorCode) {
            return false;
        }

        if (AuthType === null && IsSuccessfull === false) {
            return true
        }
        if (AuthType === 'false') {
            cancelApprove(ErrorCode)
        } else if (IsSuccessfull === true) {
            successApprove()
        }
        return false
    }

    return (
        <>
            <h3 className="eb_content_subtitle">{trb(i18.Labels.Summary)}</h3>
            <div className="eb_table mb-3 w-100">
                <div className="row eb_table_head">
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                    <div className="col-md-3 text-right"/>
                </div>
                <div className="row eb_table_row ">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.UploadDateTime)}</div>
                        <div className="eb_fw_600">
                            {formatDate(File?.FileUploadDate, dateFormat().dateTimeSecond)}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.FileName)}</div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                            className="eb_fw_600">
                            {File?.FileName}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.FileId)}</div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                            className="eb_fw_600">
                            {File?.FileHeaderId}
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="">{trb(i18.Labels.PaymentCount)}</div>
                        <div className="eb_fw_600">{File?.PaymentsCount}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                        <div className="">{trb(i18.Labels.PaymentSum)}</div>
                        <div className="eb_fw_600">
                            {currencyTextValue(File?.PaymentValue, EUR)}
                        </div>
                    </div>
                </div>
            </div>

            {loading && !showConfirm && <ContentLoader/>}
            {!!File?.ReturnValue && (
                <h3 className="eb_content_subtitle">{trb(i18.Labels.Payments)}</h3>
            )}
            {!!File?.ReturnValue && (
                <div className="eb_table mb-3 w-100">
                    <div className="row eb_table_head">
                        <div className="col-md-3">{trb(i18.Labels.Payer)}</div>
                        <div className="col-md-3 ">{trb(i18.Labels.Payee)}</div>
                        <div className="col-md-4 ">{trb(i18.Labels.DestinationsCode)}</div>
                        <div className="col-md-2 text-right">{trb(i18.Labels.Amount)}</div>
                    </div>
                    {File?.ReturnValue?.map(item => {
                        return (
                            <div className="row eb_table_row " key={item.ID}>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="">{item.SenderName}</div>
                                    <div className="">{item.SenderAccount}</div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="">{item.ReceiverName}</div>
                                    <div className="">{item.ReceiverAccount}</div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="">{item.PaymentPurpose}</div>
                                    <div className="">{item.UniqPayCode}</div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-2 text-lg-right">
                                    <div className="">{item.Amount?.[0]?.Text}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            {FilesList?.length && (
                <div className="mb-3 text-right">
                    <ButtonGroup>
                        <Button
                            type="primary"
                            onClick={handlePreview}
                            disabled={loading || approve}>
                            {trb(i18.Labels.Confirm)}
                        </Button>
                    </ButtonGroup>
                </div>
            )}

            {showConfirm && (
                <Modal
                    width={700}
                    centered
                    style={{top: 0}}
                    bodyStyle={{paddingTop: 0, paddingBottom: 0}}
                    visible={showConfirm}
                    title={
                        <div className="eb_fw_600">
                            {trb(i18.Labels.PaymentSubmitModal)}
                        </div>
                    }
                    onCancel={() => {
                        setShowConfirm(false)
                        setApprove(false)
                        dispatch(BulkFileActions.cancelLoading())
                    }}
                    closable
                    footer={[
                        <Button.Group key="footer">
                            <Button
                                type="primary"
                                onClick={clickApproveModal}
                                disabled={loading || approve}>
                                {trb(i18.Buttons.Submit)}
                            </Button>
                            <Button
                                type="default"
                                style={{marginLeft: 0}}
                                onClick={() => {
                                    setShowConfirm(false)
                                    setApprove(false)
                                    dispatch(BulkFileActions.cancelLoading())
                                }}>
                                {trb(i18.Buttons.Cancel)}
                            </Button>
                        </Button.Group>
                    ]}>
                    <div className="px-0 pb-3 p-md-3">
                        {File?.Report?.map(
                            (
                                {
                                    Amount,
                                    Commission,
                                    CommissionCurrency,
                                    Currency,
                                    Quantity,
                                    ExecutionDate,
                                    CutOffTime
                                },
                                index
                            ) => (
                                <div className="row" key={index}>
                                    <div
                                        className="col-3 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                        {trb(i18.Labels.PaymentsCount)}
                                    </div>
                                    <div
                                        className="col-3 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                                        <Number value={Quantity} format={numberFormat().number}/>
                                    </div>
                                    <div
                                        className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                        {trb(i18.Labels.PaymentsTotalAmount)}
                                        <span className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Amount}/>
                                            {` ${Currency}`}
                    </span>
                                    </div>

                                    <div
                                        className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                                        {trb(i18.Labels.PaymentsTotalCommFee)}
                                        <span
                                            className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Commission}/>
                                            {` ${CommissionCurrency}`}
                    </span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    {loading && !approve && <ContentLoader/>}
                    {approve && (
                        <div className="pb-3">
                            <OperationApproval
                                RQ_ID={File?.iSignToken}
                                AuthType={AuthType}
                                code={File?.ControlCode}
                                cancelApprove={cancelApprove}
                                successApprove={successApprove}
                                submitSmsApprove={submitSmsApprove}
                                submitMobileApprove={submitMobileApprove}
                                approveCount={File?.PaymentsCount}
                                errorCode={error}
                            />
                        </div>
                    )}
                </Modal>
            )}
        </>
    )
}
