import React from 'react';
import { Empty } from 'antd';

import './index.less';

export const ContentNoData = () => {
    return (
        <div className="eb_content_no_data">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
    );
};
