import React, { useState, useEffect } from 'react'
import { Alert, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'

import { AMCActions, DMCActions, PMCActions } from '@redux'
import {
  Container,
  ContentLoader,
  ContentNoData,
  Notification,
  OperationApproval
} from '@components'
import { appEnvMano, appEnvSME, checkJsonProp, i18, trb } from '@utilities'
import { LimitsList } from './Components'

const ButtonGroup = Button.Group

export const SettingsAccountsLimits = props => {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState(null)
  const [stateValues, setStateValues] = useState({})

  const { sendACCLMTS, ACCLMTS } = useSelector(state => state.AMCReducers)
  const { ATR } = useSelector(state => state.UACReducers)
  const { language } = useSelector(state => state.languageReducer)

  useEffect(() => {
    dispatch(AMCActions.postACCLMTS({}))
  }, [])

  const clickChangeLimits = form => {
    if (form) {
      form.reset()
    }

    setEdit(prevState => !prevState)
    setErrors(null)
  }

  const submit = values => {
    setSubmitting(true)
    setErrors(null)

    const currentData = {
      DocType: 'PayLimits',
      Content: JSON.stringify(values)
    }

    dispatch(DMCActions.postSDR({ ...currentData, Language: language })).then(
      response => {
        if (
          response.payload &&
          response.payload.IsSuccessfull &&
          response.payload.RQ_ID !== null
        ) {
          setStateValues(prevState => {
            return {
              ...prevState,
              approve: true,
              RQ_ID: response.payload.RQ_ID,
              AuthType: response.payload.AuthType,
              Code: response.payload.ControlCode
            }
          })
        } else {
          setSubmitting(false)
          setErrors(response.payload.ErrorCaptions)
        }
      }
    )
  }

  const cancelApprove = ErrorCode => {
    let message = ErrorCode
    if (message?.replace) {
      message = message.replace('.', '')
    }
    setEdit(true)
    setSubmitting(false)
    setStateValues(prevState => {
      return {
        ...prevState,
        approve: false,
        RQ_ID: '',
        AuthType: null,
        Code: null
      }
    })
    Notification({
      type: 'error',
      message: message
        ? i18.NotificationTitles[message]
        : i18.NotificationTitles.LimitsChangeFail,
      description: message
        ? i18.NotificationMessages[message]
        : i18.NotificationMessages.LimitsChangeFailed
    })
  }

  const successApprove = () => {
    Notification({
      type: 'success',
      message: i18.NotificationTitles.LimitsChanged,
      description: i18.NotificationMessages.LimitsChangedSuccessfully
    })
    setEdit(false)
    setSubmitting(false)
    setStateValues(prevState => {
      return {
        ...prevState,
        approve: false,
        RQ_ID: '',
        AuthType: null,
        Code: null
      }
    })
    dispatch(AMCActions.postACCLMTS({}))
  }

  const submitSmsApprove = values => {
    dispatch(PMCActions.postAPSMSR(values)).then(response => {
      const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull')
      const ErrorCode = checkJsonProp(response, 'payload.ErrorCode')
      if (IsSuccessfull) {
        successApprove()
      } else {
        cancelApprove(ErrorCode)
      }
    })
  }

  const submitMobileApprove = async () => {
    const { RQ_ID } = stateValues
    const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }))
    const AuthCheckResult = checkJsonProp(response, 'payload.AuthCheckResult')
    const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull')
    const ErrorCode = checkJsonProp(response, 'payload.ErrorCode')

    if (AuthCheckResult === null) {
      return true
    }
    if (AuthCheckResult === false) {
      cancelApprove(ErrorCode)
    } else if (AuthCheckResult === true && IsSuccessfull) {
      successApprove()
    }
    return false
  }

  if (sendACCLMTS) {
    return <ContentLoader />
  }

  if (!ACCLMTS) {
    return <ContentNoData />
  }

  return (
    <>
      <div className={appEnvSME ? 'eb_content_subtitle' : 'eb_content_title'}>
        {trb(i18.ContentTitles.SettingsAccountsLimits)}
      </div>
      <Container>
        <Form
          onSubmit={submit}
          initialValues={{
            SMSLimits: ACCLMTS.SmsLimits,
            ESIGNLimits: ACCLMTS.MSignLimits
          }}
          render={({ handleSubmit, form, valid, values }) => {
            return (
              <form className="mb-3 w-100" onSubmit={handleSubmit}>
                {!appEnvMano && !appEnvSME && (
                  <LimitsList
                    title={i18.ContentSubTitles.SMSLimits}
                    type="SMSLimits"
                    data={ACCLMTS.SmsLimits}
                    edit={edit}
                  />
                )}
                <LimitsList
                  title={i18.ContentSubTitles.MSignLimits}
                  type="ESIGNLimits"
                  data={ACCLMTS.MSignLimits}
                  edit={edit}
                />
                {errors && (
                  <Alert
                    className="mb-3"
                    message={trb(i18.ErrorTitles.AccountsLimitsChangeError)}
                    description={
                      <ul>
                        {errors.map((item, index) => (
                          <li key={index}>
                            {item.ErrorCode} - {item.ErrorCaption}
                          </li>
                        ))}
                      </ul>
                    }
                    type="error"
                  />
                )}
                <div className="mb-3 text-right">
                  {edit ? (
                    <>
                      <ButtonGroup
                        className={
                          !appEnvSME ? 'd-block d-md-none paddingX14' : 'd-none'
                        }>
                        <Button
                          onClick={() => clickChangeLimits(form)}
                          disabled={submitting}
                          className={!appEnvSME ? 'w-50' : ''}>
                          {trb(i18.Buttons.Cancel)}
                        </Button>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className={!appEnvSME ? 'w-50' : ''}
                          loading={submitting && !stateValues.approve}
                          disabled={
                            edit && stateValues.approve
                          }>
                          {trb(i18.Buttons.ChangeAccountLimitsSave)}
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup
                        className={!appEnvSME ? 'd-none d-md-block' : ''}>
                        <Button
                          onClick={() => clickChangeLimits(form)}
                          disabled={submitting}>
                          {trb(i18.Buttons.Cancel)}
                        </Button>
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={submitting && !stateValues.approve}
                          disabled={
                            edit && stateValues.approve
                          }>
                          {trb(i18.Buttons.ChangeAccountLimitsSave)}
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    ATR.AuthType === 'eSign' && (
                      <>
                        <ButtonGroup
                          className={
                            !appEnvSME
                              ? 'd-block d-md-none paddingX14'
                              : 'd-none'
                          }>
                          <Button
                            onClick={() => clickChangeLimits()}
                            type="primary"
                            className={!appEnvSME ? 'w-100' : ''}
                            loading={submitting}>
                            {trb(i18.Buttons.ChangeAccountLimits)}
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup
                          className={!appEnvSME ? 'd-none d-md-block' : ''}>
                          <Button
                            onClick={() => clickChangeLimits()}
                            type="primary"
                            loading={submitting}>
                            {trb(i18.Buttons.ChangeAccountLimits)}
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  )}
                </div>
                {edit && stateValues.approve && (
                  <OperationApproval
                    RQ_ID={stateValues.RQ_ID}
                    AuthType={stateValues.AuthType}
                    code={stateValues.Code}
                    cancelApprove={cancelApprove}
                    successApprove={successApprove}
                    submitSmsApprove={submitSmsApprove}
                    submitMobileApprove={submitMobileApprove}
                  />
                )}
              </form>
            )
          }}
        />
      </Container>
    </>
  )
}
