import React from 'react';

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, required, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCMainInformacion = (props) => {

    const ClientProfile = checkJsonProp(props.data, 'clientProfile'),
          initialData = {clientProfile: ClientProfile};

    const mapData = [
        {
            messageBefore: i18.NotificationMessages.KYCMainInformacion,
            elements: [{
                title: i18.Labels.ClientName,
                name: 'clientProfile.0.data.clientName',
                component: FormFieldType.input,
                className: 'col-12 ml-md-5',
                classNameTitle: 'col-6 col-md-4 text-right pr-1',
                classNameField: 'col-6 col-md-8 pl-1',
                disabled: true,
            },{
                title: i18.Labels.ClientTitle,
                name: 'clientProfile.0.data.clientTitle',
                component: FormFieldType.input,
                className: 'col-12 ml-md-5',
                classNameTitle: 'col-6 col-md-4 text-right pr-1',
                classNameField: 'col-6 col-md-8 pl-1',
                disabled: true,
            },{
                title: i18.Labels.ClientId,
                name: 'clientProfile.0.data.clientId',
                component: FormFieldType.input,
                className: 'col-12 ml-md-5',
                classNameTitle: 'col-6 col-md-4 text-right pr-1',
                classNameField: 'col-6 col-md-8 pl-1',
                disabled: true,
            },{
                title: i18.Labels.ClientRegCountry,
                name: 'clientProfile.0.data.clientRegCountry',
                component: FormFieldType.selectSearch,
                options: props.classifikators.CISO,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12 ml-md-5',
                classNameTitle: 'col-6 col-md-4 text-right pr-1',
                classNameField: 'col-6 col-md-8 pl-1',
                disabled: true,
            },
        ]}
    ];

    return <KYCFormSwitch
      permissons={props.permissons}
      title={i18.ContentSubTitles.MainInformacion}
      mapData={mapData}
      initialData={initialData}
      nextStep={props.nextStep}
      prevStep={props.prevStep}
    />
};
