import json from '../Config/config'
import {store} from '@redux/Config/store';


const state= store?.getState()

let language = state.languageReducer.language

const getLanguage = () => (language = state.languageReducer.language)
store.subscribe(getLanguage)

export const numberFormat = () => ({
  currency: json[language].format.numberFormat.currency,
  currencyWithMinus: json[language].format.numberFormat.currencyWithMinus,
  percent: json[language].format.numberFormat.percent2decimal,
  percent3decimal: json[language].format.numberFormat.percent3decimal,
  percent4decimal: json[language].format.numberFormat.percent4decimal,
  percent5decimal: json[language].format.numberFormat.percent5decimal,
  coefficient: json[language].format.numberFormat.coefficient2decimal,
  coefficient3decimal: json[language].format.numberFormat.coefficient3decimal,
  coefficient4decimal: json[language].format.numberFormat.coefficient4decimal,
  coefficient6decimal: json[language].format.numberFormat.coefficient6decimal,
  coefficient3decimalTrim:
    json[language].format.numberFormat.coefficient3decimalTrim,
  number: json[language].format.numberFormat.number,
  numberCode: json[language].format.numberFormat.numberCode,
  date: json[language].format.numberFormat.date,
  phone: json[language].format.numberFormat.phone,
  period: json[language].format.numberFormat.period
})

export const dateFormat = () => ({
  date: json[language].format.formatDate.date,
  dateTime: json[language].format.formatDate.dateTime,
  dateTimeSecond: json[language].format.formatDate.dateTimeSecond,
  time: json[language].format.formatDate.time
})

export const groupBy = (xs, f) => {
  return xs.reduce(
    (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  )
}

export const currencyFormat = (value, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: minimumFractionDigits
  }).format(value)
}

export const currencyTextValue = (
  value,
  currency,
  minimumFractionDigits = 2
) => {
  return `${currencyFormat(value, minimumFractionDigits)} ${currency ?? ''}`
}

export const getAmountSign = value => {
  return value < 0 ? '-' : ''
}

export const numberWithSign = (value, preFormattedNumber) => {
  return `${getAmountSign(value)}${preFormattedNumber ?? value}`
}
