import React, {useEffect, useState} from 'react'
import {Container, Notification} from '@components'
import {Progress, Steps} from 'antd'
import {PaymentFill, PaymentPreview} from '@containers'
import {useDispatch, useSelector} from 'react-redux'
import {CustomIcon, i18, Payment, trb} from '@utilities'
import { AMCActions, PMCActions } from '@redux'
import moment from 'moment/moment'

export const PaymentForex = (props) => {
    const dispatch = useDispatch()
    const {
        PTR,
        forexPayments
    } = useSelector(state => state.PMCReducers)
    const {language} = useSelector(state => state.languageReducer)
    const amcReducers = useSelector(state => state.AMCReducers)
    const {forexRate, forexRateRequestData, forexDraftInfo} = amcReducers

    const [submitting, setSubmitting] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [data, setData] = useState(null)
    const [totalTime, setTotalTime] = useState(0)
    const [passedTime, setPassedTime] = useState(undefined)

    useEffect(() => {
        dispatch(AMCActions.clearForexRate(language))
        dispatch(AMCActions.getForexCurrency(language))
    }, [])

    useEffect(() => {
        const duration = moment.duration(moment(forexRate?.ExpiresAt).diff(moment(new Date())))._milliseconds / 1000

        setPassedTime(0)
        setTotalTime(duration)
    }, [forexRate])

    useEffect(() => {
        const duration = moment.duration(moment(forexDraftInfo?.Calculation?.ExpiresAt).diff(moment(new Date())))._milliseconds / 1000

        setPassedTime(0)
        setTotalTime(duration)
    }, [forexDraftInfo])


    useEffect(() => {
        let intervalId = null

        if (totalTime > 0) {
            intervalId = setInterval(() => {
                setPassedTime(prevCount => prevCount + 1)
            }, 1000)

            if (totalTime <= passedTime) {
                clearInterval(intervalId)

                if (currentStep === 0) {
                    getForexRate()
                }
            }
        } else {
            clearInterval(intervalId)
        }

        return () => clearInterval(intervalId)
    }, [totalTime, passedTime])

    const getForexRate = async () => {
        await dispatch(AMCActions.getForexRate({
            from: forexRateRequestData?.from,
            to: forexRateRequestData?.to,
            amountFrom: forexRateRequestData?.amountFrom,
            amountTo: forexRateRequestData?.amountTo
        }))
    }

    const handleForexExchange = async values => {
        const data = {
            from: forexRateRequestData.from,
            to: forexRateRequestData.to,
            accountFromId: values.Payment.FromAccount,
            accountToId: values.Payment.BeneficiaryAccountNumber
        }

        if (forexRateRequestData.amountFrom) {
            data.amountFrom = forexRateRequestData.amountFrom
        } else {
            data.amountTo = forexRateRequestData.amountTo
        }

        const response = await dispatch(AMCActions.postForexDraft(data))
        setSubmitting(false)

        if (response?.payload?.Id) {
            setCurrentStep(prevStep => prevStep + 1)
        }
    }

    const edit = () => {
        setTotalTime(null)
        setCurrentStep(0)
    }

    const handleForexSubmitPreview = async () => {
        const response = await dispatch(AMCActions.confirmForexRate(forexDraftInfo.Id))

        if (response?.payload?.Success) {
            setCurrentStep(prevStep => prevStep + 1)

            Notification({
                type: 'success',
                message: trb(i18.Messages.ForexExchangeIsSuccessfull)
            })
        } else {
            Notification({
                type: 'error',
                message: response?.payload?.Reason
            })

            await resetPayment();
        }
    }

    const submit = async (values, form) => {
        setSubmitting(true)
        setData(values)

        await handleForexExchange(values)
    }

    const submitPreview = async () => {
        setSubmitting(true)

        await handleForexSubmitPreview()
    }

    const successApprove = async () => {
        setCurrentStep(prevStep => prevStep + 1)
    }

    const getFormatedForexDrafrInfo = () => {
        const dt = {...forexDraftInfo}
        if (dt.accountFromId) {
            dt.IBANAccountFrom = forexPayments?.InitialAccounts?.find(
                el => el.AccountID === dt.accountFromId
            )?.AccountNumber
        }
        if (dt.accountToId) {
            dt.IBANAccountTo = forexPayments?.DestinationAccounts?.find(
                el => el.AccountID === dt.accountToId
            )?.AccountNumber
        }

        return dt
    }

    const addProgressTimer = () => {
        return (
            <Progress
                type='circle'
                percent={Math.min((100 / totalTime) * (totalTime - passedTime), 99.99)}
                format={() =>
                    `${Math.round(totalTime - passedTime)} ${trb(
                        i18.Labels.SecondsShort
                    )}`
                }
                width={100}
            />
        )
    }

    const resetPayment = async () => {
        dispatch(PMCActions.postPTR())
        dispatch(AMCActions.clearForexRate(language))
        setTotalTime(null)
        setPassedTime(null)
        setData({
            Payment: {}
        })
        setSubmitting(false)
        setCurrentStep(0)
    }

    return (
        <>
            <div className='eb_content_subtitle'>{trb(i18.ContentTitles.ForexExchange)}</div>
            <Container wide gradient={currentStep === 2}>
                <Steps
                    direction="horizontal"
                    current={currentStep}
                    className={`mb-3 mb-md-5 eb_steps`}>
                    <Steps.Step icon={(<CustomIcon size={50} type='custom' icon='paymentFirstStep'/>)}/>
                    <Steps.Step icon={(<CustomIcon size={50} type='custom' icon='paymentSecondStep'/>)}/>
                    <Steps.Step icon={(<CustomIcon size={50} type='custom' icon='paymentThirdStep'/>)}/>
                </Steps>
                <div className='position-relative w-100'>
                    {currentStep === 0 && (
                        <PaymentFill
                            PTR={PTR}
                            paymentType={Payment.FOREX_EXCHANGE}
                            data={data}
                            forexPayments={forexPayments}
                            submit={submitting ? () => {
                            } : submit}
                            submitting={submitting}
                            submitDisabled={false}
                        />
                    )}
                    {currentStep === 1 && (
                        <PaymentPreview
                            paymentType={Payment.FOREX_EXCHANGE}
                            data={data}
                            PTR={PTR}
                            edit={edit}
                            submit={submitting ? () => {
                            } : submitPreview}
                            submitting={submitting}
                            successApprove={successApprove}
                            forexDraftInfo={getFormatedForexDrafrInfo()}
                        />
                    )}
                    {currentStep === 2 && (
                        <PaymentPreview
                            paymentType={Payment.FOREX_EXCHANGE}
                            data={data}
                            PTR={PTR}
                            edit={edit}
                            submit={submitting ? () => {
                            } : submitPreview}
                            submitting={submitting}
                            success
                            forexDraftInfo={getFormatedForexDrafrInfo()}
                            resetPayment={resetPayment}
                        />
                    )}
                    {currentStep !== 2 && (
                        <div className={passedTime >= totalTime ? 'invisible' : 'visible'}>
                            <div className='d-flex justify-content-center mb-2'>
                                {addProgressTimer()}
                            </div>
                            <div className='d-flex justify-content-center mb-2'>
                                {trb(i18.Messages.ForexTimerMessage)}
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </>
    )
}
