import {
  checkFirmCode,
  checkPersonCode,
  i18,
  trb,
  replaceAll
} from '@utilities'
import { isFixedIban } from './NewPaymentValidation'
import { isValidIBAN } from 'ibantools'

export const TemplateValidation = (values, props) => {
  const errorFields = {}
  const maxSelectedPurpose = 140
  const maxNotSelectedPurpose = 35
  const minSelectedPurpose = 5
  if (values.Payment) {
    if (values.Payment.IsPurposeSelected === 'true') {
      if (
        values.Payment.Purpose &&
        values.Payment.Purpose.length > maxSelectedPurpose
      ) {
        errorFields.Purpose =
          trb(i18.Validations.InputIsTooLong) +
          ':' +
          trb(i18.Validations.MaxInputIs) +
          ' ' +
          maxSelectedPurpose
      }
      if (
        values.Payment.Purpose &&
        values.Payment.Purpose.length < minSelectedPurpose
      ) {
        errorFields.Purpose =
          trb(i18.Validations.InputIsTooShort) +
          ':' +
          trb(i18.Validations.MinInputIs) +
          ' ' +
          minSelectedPurpose
      }
    } else {
      if (
        values.Payment.Purpose &&
        values.Payment.Purpose.length > maxNotSelectedPurpose
      ) {
        errorFields.Purpose =
          trb(i18.Validations.InputIsTooLong) +
          ':' +
          trb(i18.Validations.MaxInputIs) +
          ' ' +
          maxNotSelectedPurpose
      }
    }
    /** AV TODO uncomment when no nium/target2/forex
     * if (
     *  !isValidIBAN(replaceAll(values.Payment.BeneficiaryAccountNumber, ' ', ''))
     * ) {
     * errorFields.BeneficiaryAccountNumber = trb(i18.Validations.IBANNotValid)
     * }
     */

    // if (isFixedIban(values.Payment.BeneficiaryAccountNumber) && values.Payment.IsPurposeSelected) {
    //   errorFields.IsPurposeSelected = trb(i18.Validations.MustBePurposeSelected);
    // }

    if (
      values.Payment.PayerType === 'p' &&
      values.Payment.PayerIdentTypeCode === 'NIDN' &&
      values.Payment.PayerIdentTypeValue &&
      !checkPersonCode(values.Payment.PayerIdentTypeValue)
    ) {
      errorFields.PayerIdentTypeValue = trb(i18.Validations.BadPersonalCode)
    }

    if (
      values.Payment.InitPayerType === 'p' &&
      values.Payment.InitPayerIdentTypeCode === 'NIDN' &&
      values.Payment.InitPayerIdentTypeValue &&
      !checkPersonCode(values.Payment.InitPayerIdentTypeValue)
    ) {
      errorFields.InitPayerIdentTypeValue = trb(i18.Validations.BadPersonalCode)
    }
    if (
      values.Payment.PayeeType === 'p' &&
      values.Payment.PayeeIdentTypeCode === 'NIDN' &&
      values.Payment.PayeeIdentTypeValue &&
      !checkPersonCode(values.Payment.PayeeIdentTypeValue)
    ) {
      errorFields.PayeeIdentTypeValue = trb(i18.Validations.BadPersonalCode)
    }
    if (
      values.Payment.FinalPayeeType === 'p' &&
      values.Payment.FinalPayeeIdentTypeCode === 'NIDN' &&
      values.Payment.FinalPayeeIdentTypeValue &&
      !checkPersonCode(values.Payment.FinalPayeeIdentTypeValue)
    ) {
      errorFields.FinalPayeeIdentTypeValue = trb(
        i18.Validations.BadPersonalCode
      )
    }

    if (
      values.Payment.PayerType === 'o' &&
      values.Payment.PayerIdentTypeCode === 'COID' &&
      values.Payment.PayerIdentTypeValue &&
      !checkFirmCode(values.Payment.PayerIdentTypeValue)
    ) {
      errorFields.PayerIdentTypeValue = trb(i18.Validations.BadCompanyCode)
    }

    if (
      values.Payment.InitPayerType === 'o' &&
      values.Payment.InitPayerIdentTypeCode === 'COID' &&
      values.Payment.InitPayerIdentTypeValue &&
      !checkFirmCode(values.Payment.InitPayerIdentTypeValue)
    ) {
      errorFields.InitPayerIdentTypeValue = trb(i18.Validations.BadCompanyCode)
    }
    if (
      values.Payment.PayeeType === 'o' &&
      values.Payment.PayeeIdentTypeCode === 'COID' &&
      values.Payment.PayeeIdentTypeValue &&
      !checkFirmCode(values.Payment.PayeeIdentTypeValue)
    ) {
      errorFields.PayeeIdentTypeValue = trb(i18.Validations.BadCompanyCode)
    }
    if (
      values.Payment.FinalPayeeType === 'o' &&
      values.Payment.FinalPayeeIdentTypeCode === 'COID' &&
      values.Payment.FinalPayeeIdentTypeValue &&
      !checkFirmCode(values.Payment.FinalPayeeIdentTypeValue)
    ) {
      errorFields.FinalPayeeIdentTypeValue = trb(i18.Validations.BadCompanyCode)
    }
  }
  return {
    Payment: {
      ...errorFields
    }
  }
}
