import {Component} from 'react';
import {connect} from 'react-redux';

import {TranslationsAction} from '../Redux';

class TranslationsInit extends Component {
    componentDidMount() {
        if (this.props?.context && this.props?.language) {
            this.props.dispatch(TranslationsAction.getTranslations(this.props.context, this.props.language));
            const nextLanguage = this.props.language === 'lt-LT' ? 'en-EN' : 'lt-LT';
            this.props.dispatch(TranslationsAction.getTranslations(this.props.context, nextLanguage));  //storina iskart visu kalbu vertimus, kad isvengti bug ant kalbos keitimo kai atvaizduojami tik label names
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch} = this.props;

        if (this.props.language !== prevProps.language) {
            dispatch(TranslationsAction.getTranslations(this.props.context, this.props.language));
        }
    }

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    const {language} = state.languageReducer;
    return {
        language,
    };
}

const connectedTranslationsInit = connect(mapStateToProps)(TranslationsInit);
export {connectedTranslationsInit as TranslationsInit};
