import React from 'react';
import {connect} from 'react-redux';
import {Select} from 'antd';

import {LanguageActions} from '../../Redux';
import {appEnvSME} from '../../Utilities';

import flagLT from './Flags/lt.png';
import flagGB from './Flags/gb.png';
import flagRU from './Flags/ru.png';

const {Option} = Select;

const LanguageSelect = (params) => {
    const {withoutLabels, language, className} = params,
    showLabel = window.innerWidth > 426, // || withoutLabels,
    languageSelect = appEnvSME ?
      [
        {value: 'lt-LT', label: <><img style={ appEnvSME ? { paddingBottom: '3px' } : null} src={flagLT} alt="lt-LT"/>{showLabel && ' Lietuvių'}</>},
        {value: 'en-GB', label: <><img style={ appEnvSME ? { paddingBottom: '3px' } : null} src={flagGB} alt="en-GB"/>{showLabel && ' English'}</>},
        // {value: 'ru-RU', label: <><img src={flagRU} alt="ru-RU"/>{showLabel && ' Русский'}</>}
      ]
      :
      [
          {value: 'lt-LT', label: <><img style={ appEnvSME ? { paddingBottom: '3px' } : null} src={flagLT} alt="lt-LT"/>{showLabel && ' Lietuvių'}</>},
          {value: 'en-GB', label: <><img style={ appEnvSME ? { paddingBottom: '3px' } : null} src={flagGB} alt="en-GB"/>{showLabel && ' English'}</>},
          //{value: 'ru-RU', label: <><img src={flagRU} alt="ru-RU"/>{showLabel && ' Русский'}</>}
      ],
    languageChange = (evt) => {
        params.dispatch(LanguageActions.setLanguage(evt));
    },
    smallClass = withoutLabels ? 'bbs_language_small' : null;

    return <Select
        id="LanguageSelect"
        value={language}
        style={{
            width: !showLabel ? 60 : 110
        }}
        bordered="false"

        className={`bbs_language ${className ? className : ''} ${smallClass ? smallClass : ''}`}
        onChange={languageChange}
        // dropdownStyle={ appEnvSME ? { display: 'none' } : { overflow: 'hidden' }}
        dropdownStyle={ appEnvSME ? {} : { overflow: 'hidden' }}
    >
        {languageSelect.map((item, index) =>
            <Option id={`LanguageSelectOption${item.value}`} style={appEnvSME ? {paddingLeft: 20} : null} className={!appEnvSME ? withoutLabels && "text-center" : "bbs_language"} value={item.value} key={index}>{item.label}</Option>
        )}
    </Select>;
};

function mapStateToProps(state) {
    const {language} = state.languageReducer;

    return {
        language
    };
}

const connectedLanguage = connect(mapStateToProps)(LanguageSelect);
export {connectedLanguage as LanguageSelect};
