import React from 'react'
import {useSelector} from 'react-redux'
import {FormEntrySwitch} from '@components'
import {appEnvSME, i18} from '@utilities'

export const DepositFill = props => {
    let {data} = props
    const {ATR} = useSelector(state => state.UACReducers)
    const {CIPTR, PDR, depositOffer} = props,
        subProductsData = CIPTR?.InitialAccounts,
        subProducts = subProductsData.map(item => {
            const data = {
                label: `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})`,
                value: item.AccountID
            }
            return data
        }),
        InterestPaymentTypes = CIPTR?.InterestPaymentTypes.map(item => ({
            ...item,
            disabled: item.Caption === "EveryMonth",
            selected: item.Caption === "ContractEndDate"
        }))
    const Currencies = CIPTR?.Currencies,
        Periods = PDR?.Periods,
        DepositAmountNotValid = !!depositOffer?.ErrorDescriptions
            ?.DepositAmountNotValid

    if (appEnvSME) {
        data.CurrencyID = data.CurrencyID || 2
        data.SubProductID = InterestPaymentTypes.find(item => item.Caption === "ContractEndDate")?.ID
    }

    return (
        <FormEntrySwitch
            name="deposits"
            data={data}
            errors={props.errors}
            accounts={subProducts}
            CIPTR={CIPTR}
            InterestPaymentTypes={InterestPaymentTypes}
            Currencies={Currencies}
            html={props.DepositsTerms}
            Periods={Periods}
            submit={props.submit}
            decorator={props.decorator}
            DepositAmountNotValid={DepositAmountNotValid}
            buttons={[
                {
                    title: i18.Buttons.Submit,
                    props: {
                        type: 'primary',
                        htmlType: 'submit',
                        disabled:
                        props.submitting,
                        loading: props.submitting,
                        className: appEnvSME ? 'col-12 col-md-12' : 'col-5 col-md-12'
                    }
                }
            ]}
        />
    )
}
