import React from 'react';
import {Input} from 'antd';

import {FormFieldInputWrapperClasses, FormFieldWrapperClasses} from '../';
import {trb, formFieldId} from '../../../Utilities';

export class InputPasswordFormField extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            active: !this.props.input.value && this.props.input.value !== 0
            && !this.props.defaultValue && this.props.defaultValue !== 0 ? true : false,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.props.input.value && this.props.input.value !== prevProps.input.value)
        || (this.props.defaultValue  && this.props.defaultValue !== prevProps.defaultValue)) this.setState({
            active: false,
        });
    }

    onBlur = (e) => {
        if (!this.props.input.value && this.props.input.value !== 0) this.setState({
            active: true
        });
        this.props.input.onBlur(e);
        if (this.props.onBlur) this.props.onBlur(e.target.value);
    }

    onSelect = () => {
        this.setState({
            active: false
        });
    }

    render(){
        const {title,
            titleWithoutTranslate,
            className,
            size,
            inline,
            placeholder,
            disabled,
            input,
            suffix,
            maxLength,
            hightlight,
            autoFocus,
            meta
        } = this.props,
        {active} = this.state;

        return <div id={formFieldId(input.name, 'InputPasswordWrapper')} className={FormFieldWrapperClasses(className, inline, disabled, active, false, hightlight)}>
            {title && <div className={inline ? 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0' : 'form-field-title col-12'}>{titleWithoutTranslate ? title : trb(title)}</div>}
            <div className={FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline)}>
                <Input.Password
                    id={formFieldId(input.name, 'InputPassword')}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="off"
                    size={size}
                    placeholder={trb(placeholder)}
                    disabled={disabled}
                    name={input.name}
                    value={input.value}
                    onChange={this.props.input.onChange}
                    type="password"
                    autoFocus={autoFocus}
                    suffix={suffix}
                    maxLength={maxLength}
                    onBlur={this.onBlur}
                    onSelect={this.onSelect}
                />
            </div>
            <div id={formFieldId(input.name, 'InputPasswordError')} className={inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>{meta.touched && (meta.error || meta.submitError)}</div>
        </div>
    }
};
