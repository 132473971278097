import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Button, Layout} from 'antd';

import {CustomIcon, getWindowSize, i18, Logout, trb} from '@utilities';
import {UserMessages, UserSelectContainer} from "@components";
import {history, routerPath} from '@config';
import {LanguageSelect} from '@components';
import {MenuActions} from '@redux';
import {useNavigate} from "react-router-dom";

const Header = Layout.Header;

export const PartHeader = (props) => {
    const windowSize = getWindowSize();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {isRemote} = useSelector((state) => state.JWEReducer);
    const {menuCollapsed} = useSelector((state) => state.MenuReducer)

    const toggle = () => {
        dispatch(MenuActions.toggle());
    }
    const mobileBackClick = () => {
        navigate(routerPath.index);
        if (windowSize.mobileCheck) toggle();
    }

    return (
        <Header className="eb_header" style={{backgroundColor: !!isRemote ? 'white' : 'unset'}}>
            <div className="eb_inner d-flex justify-content-between">
                {/* TODO: Enable when necessary
               {checkDevEnv && (<div className="d-block col-auto">
                    <Button type="link"
                            className="eb_header_eServices text-click"
                            onClick={() => history.push(routerPath.logs)}>
                        <OrderedListOutlined className="m-0 ml-lg-2"/>
                        <span className="d-none d-lg-inline">{trb(i18.Buttons.Logs)}</span>
                    </Button>
                </div>)}
                */}
                {!isRemote && (<div></div>)}
                {!!isRemote && (<div className="sme_external_link_container">
                    <Button type="link" className="sme_external_link left">
                        <span className="label">{trb(i18.Labels.MyDashboard)}</span>
                    </Button>
                    <Button type="link" className="sme_external_link active">
                        <span className="label active">{trb(i18.Labels.SMEBank)}</span>
                    </Button>
                    <Button type="link" className="sme_external_link right">
                        <a href={process.env.REACT_APP_SMEF_URL} className="label">{trb(i18.Labels.SMEFinance)}</a>
                    </Button>
                </div>)}

                <div className="eb_menu_logo d-md-none" onClick={mobileBackClick}>
                    {window.location.pathname === '/'
                        ? <img src={`${process.env.REACT_APP_BASE_PATH_IMG}images/logos/${process.env.REACT_APP_LOGO}`}
                               id={"logo"} alt={process.env.REACT_APP_TITLE}/>
                        : <CustomIcon icon="caretLeft" type="custom"/>
                    }
                </div>
                <div className="eb_header_right justify-content-between">
                    <div style={{paddingTop: 1}}>
                        <LanguageSelect withoutLabels className="eb_sme_language"/>
                    </div>
                    <div>
                        <UserMessages/>
                    </div>
                    <div>
                        <UserSelectContainer/>
                    </div>
                    <div>
                        <Button
                            id="HeaderSettings"
                            className="text-click d-none d-md-inline-block"
                            onClick={() => navigate(routerPath.settings)}
                            type="link"
                        >
                            <CustomIcon type="custom" icon="settings"/>
                        </Button>
                        <Button
                            id="HeaderLogout"
                            className="text-click"
                            onClick={() => Logout()}
                            type="link"
                        >
                            <CustomIcon type="custom" icon="logout"/>
                        </Button>
                        <Button
                            id="HeaderMenuCollapse"
                            className="text-click d-md-none"
                            onClick={toggle}
                            type="link"
                        >
                            <CustomIcon type="custom" icon={menuCollapsed ? "close" : "toggle"}/>
                        </Button>
                    </div>
                </div>
            </div>
        </Header>
    );
};
