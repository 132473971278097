import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FileAddOutlined, SearchOutlined} from '@ant-design/icons'
import {Button, Input} from 'antd'
import {matchPath, Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {appEnvSME, arraySearch, i18, PaymentType, trb} from '@utilities'

import {PaymentTemplateForm, PaymentTemplateList} from './'
import {Container, ContentLoader, Notification} from '@components'
import {PMCActions} from '@redux'
import {history, routerPath} from '@config'
import {FINAL_PAYEE_INFO_RESET, INIT_PAYER_INFO_RESET, PAYEE_INFO_RESET, PAYER_INFO_RESET} from './constants'

export const PAYMENT_TEMPLATE_NEW = 'new'
export const PAYMENT_TEMPLATE_INTERNATIONAL = 'international'

export const PaymentTemplates = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigation = useNavigate();
    const {sendPTR, PTR, PayPersonTypes, checkIBANResult} = useSelector(
        state => state.PMCReducers
    )
    const {MALR, sendMALR} = useSelector(state => state.UACReducers)
    const {language} = useSelector(state => state.languageReducer)

    const [paymentTemplates, setPaymentTemplates] = useState(null)
    const [type, setType] = useState(null)
    const [templateID, setTemplateID] = useState(null)
    const [data, setData] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        setPaymentTemplates(PTR?.PaymentTemplates)
        dispatch(PMCActions.getPayPersonTypes({language}))
    }, [])

    useEffect(() => {
        const match = matchPath({
            path: `${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`,
            exact: true
        }, location.pathname)
        if (!match) {
            setType(null)
        }

    }, [location])

    const inputFilter = value => {
        if (value) {
            const keys = [
                'TemplateName',
                'Payment.Recipient',
                'Payment.BeneficiaryAccountNumber',
                'Payment.Purpose'
            ]

            setPaymentTemplates(arraySearch(PTR.PaymentTemplates, keys, value))
        } else {
            setPaymentTemplates(PTR.PaymentTemplates)
        }
    }

    const clickDelete = async id => {
        const data = {
            TemplateID: id,
            TemplateName: null
        }

        await dispatch(PMCActions.postDTR(data))
        const response = await dispatch(PMCActions.postPTR())
        setPaymentTemplates(response?.payload?.PaymentTemplates)
    }

    const clickEdit = id => {
        setTemplateID(id)
        setType('edit')
        const template = PTR.PaymentTemplates.find(item => item.TemplateID === id);
        let destination;
        switch (template?.PaymentType) {
            case PaymentType.INTERNATIONAL:
                destination = PAYMENT_TEMPLATE_INTERNATIONAL
                break;
            default:
                destination = PAYMENT_TEMPLATE_NEW
                break;
        }
        navigation(`${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`.replace(':templateType', destination))
    }

    const clickNew = () => {
        setType(PAYMENT_TEMPLATE_NEW);
        navigation(`${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`.replace(':templateType', PAYMENT_TEMPLATE_NEW))
    }

    const clickExecute = (templateId, paymentType) => {
        let currentPayment = null

        switch (paymentType) {
            case PaymentType.SEPA:
            case PaymentType.TARGET2:
                currentPayment = routerPath.paymentNew
                break
            case PaymentType.INTERNATIONAL:
                currentPayment = routerPath.paymentInternational
                break;
            default:
                Notification({
                    type: 'error',
                    message: i18.NotificationTitles.UnexpectedError,
                    description: i18.NotificationMessages.UnexpectedErrorReportAdmin
                })

                return null
        }

        navigation(`${routerPath.payments}/${currentPayment}`, {
            state: {
                templateId
            }
        })
    }

    const clickExecuteGroup = id => {
        history.push({
            pathname: routerPath.paymentGroup,
            state: {
                groupId: id
            }
        })
    }

    const submit = async values => {
        let selectedTemplates = []
        const templates = values?.templates

        if (templates?.length)
            templates.filter(e =>
                e.checked === true ? selectedTemplates.push(e.id.toString()) : null
            )

        if (!selectedTemplates.length) {
            Notification({
                type: 'warning',
                message: i18.NotificationTitles.TemplatesNotSelected,
                description: i18.NotificationMessages.TemplatesNotSelectedError
            })

            return null
        }

        setSubmitting(true)

        const data = {
            IsNewGroup: values.IsNewGroup,
            GroupName: values.IsNewGroup ? values.GroupName : '',
            ExistingGroupID: values.IsNewGroup ? 0 : values.ExistingGroupID,
            TeplateIDs: selectedTemplates
        }

        const response = await dispatch(PMCActions.postTTG(data))
        if (response?.payload?.IsSuccessfull) {
            setPaymentTemplates(response?.payload?.PaymentTemplates)

            Notification({
                type: 'success',
                message: i18.NotificationTitles.SavedSuccess,
                description: i18.NotificationMessages.SavedSuccess
            })
            const response = await dispatch(PMCActions.postPTR())
            setPaymentTemplates(response?.payload?.PaymentTemplates)
        } else {
            const error = response?.payload?.PaymentTemplatesErrors?.[0]

            Notification({
                type: 'error',
                message: i18.NotificationTitles.GroupCreateError,
                description: error != null ? error : i18.NotificationMessages.SavedError
            })
        }
        setSubmitting(false)
    }

    const checkIBANTarget2 = () => {
        const {Participant} = checkIBANResult ?? {}
        return (
            Participant?.SepaInst === false &&
            Participant?.SepaSCT === false &&
            Participant?.SepaSDD === false
        )
    }

    const submitTemplate = async values => {
        const currentValues = {
            ...values,
            TemplateName: values?.Payment?.TemplateName,
            TemplateID: values?.Payment?.TemplateID || templateID
        }

        // if (!values.Payment.IsPurposeSelected) {
        //     currentValues.Payment.ReferenceNo = currentValues.Payment.Purpose;
        //     currentValues.Payment.Purpose = "";
        // }
        if (!values.Payment.IsAdditionalPaymentInformationSelected) {
            currentValues.Payment = {
                ...currentValues.Payment,
                ...PAYER_INFO_RESET,
                ...INIT_PAYER_INFO_RESET,
                ...PAYEE_INFO_RESET,
                ...FINAL_PAYEE_INFO_RESET
            }
        } else {
            if (currentValues.Payment.PayerType === '') {
                currentValues.Payment = {
                    ...currentValues.Payment,
                    ...PAYER_INFO_RESET
                }
            }
            if (currentValues.Payment.InitPayerType === '') {
                currentValues.Payment = {
                    ...currentValues.Payment,
                    ...INIT_PAYER_INFO_RESET
                }
            }
            if (currentValues.Payment.PayeeType === '') {
                currentValues.Payment = {
                    ...currentValues.Payment,
                    ...PAYEE_INFO_RESET
                }
            }
            if (currentValues.Payment.FinalPayeeType === '') {
                currentValues.Payment = {
                    ...currentValues.Payment,
                    ...FINAL_PAYEE_INFO_RESET
                }
            }
        }
        if (currentValues.Payment.CustomersCode) {
            currentValues.Payment.CustCodeChk = true
        }
        currentValues.Payment.SaveTemplate = true
        currentValues.Payment.IsTemplate = true


        if (currentValues.Payment.PaymentType === PaymentType.SEPA) {
            delete currentValues.Payment.BeneficiaryCountryCode
            delete currentValues.Payment.BeneficiaryCity
            delete currentValues.Payment.BeneficiaryAddress
            delete currentValues.Payment.BeneficiaryPostCode
            delete currentValues.Payment.BankName
            delete currentValues.Payment.RecipientBankCountry
            delete currentValues.Payment.BIC
            delete currentValues.Payment.RoutingCode
            delete currentValues.Payment.CorrespondentBankBic
        }

        if (type === PAYMENT_TEMPLATE_NEW) {
            delete currentValues.Payment.TemplateID
            delete currentValues.TemplateID
        }

        setData(currentValues)
        setSubmitting(true)

        const response = await dispatch(PMCActions.postUTR(currentValues))
        if (response?.payload?.IsSuccessfull) {
            setType(null)
            setTemplateID(null)
            setData(null)
            const resp = await dispatch(PMCActions.postPTR())
            setPaymentTemplates(resp?.payload?.PaymentTemplates)
        } else {
            setErrors(response?.payload?.PaymentTemplatesErrors)
        }
        setSubmitting(false)
        clickBack();
    }

    const clickBack = () => {
        setType(null)
        setData(null)
        setErrors(null)
        navigation(`${routerPath.payments}/${routerPath.paymentTemplates}`)
    }

    if (!paymentTemplates || sendPTR || sendMALR) {
        return <ContentLoader/>
    }

    const templates = paymentTemplates
        ? paymentTemplates.filter(x => x.PaymentType !== 3)
        : null
    const user = MALR?.ManagedAccounts?.find(x => x.IsActive === true)
    return (
        <>
            {!type && (
                <div className="eb_content_subtitle">
                    {trb(i18.ContentMenu.PaymentTemplates)}
                </div>
            )}
            <Container wide>
                {!type && (
                    <div
                        className={
                            appEnvSME
                                ? 'templates row mb-5 w-100 mx-0'
                                : 'row mb-5 w-100 mx-0'
                        }>
                        <div
                            className="col-6 col-md-7 text-left mb-1 form-field-wrapper big-input small-input ml-0 pl-0">
                            <Input
                                placeholder={trb(i18.PlaceHolders.PaymentTemplateSearch)}
                                suffix={<SearchOutlined/>}
                                className="form-field-item"
                                allowClear
                                onChange={e => inputFilter(e.target.value)}
                                disabled={sendPTR}
                            />
                        </div>
                        <div className="col-6 col-md-5 text-right mr-0 pr-0">
                            <Button
                                type="primary"
                                onClick={clickNew}
                                loading={submitting}
                                disabled={submitting}
                                className="px-3 px-md-4">
                                <FileAddOutlined/>
                                {trb(i18.Buttons.CreatePaymentTemplate)}
                            </Button>
                        </div>
                    </div>
                )}
                <Routes>
                    <Route path={routerPath.paymentTemplatesNew} element={
                        <PaymentTemplateForm
                            submit={submitting ? () => {
                            } : submitTemplate}
                            clickBack={clickBack}
                            user={user}
                            PayPersonTypes={PayPersonTypes}
                            errors={errors}
                            submitting={submitting}
                            data={
                                type === 'edit' && !data
                                    ? paymentTemplates.find(x => x.TemplateID === templateID)
                                    : data
                            }
                        />
                    }/>
                    <Route index element={<PaymentTemplateList
                        groups={PTR ? PTR.PaymentGroups : []}
                        data={templates ?? []}
                        clickDelete={clickDelete}
                        clickEdit={clickEdit}
                        clickExecute={clickExecute}
                        clickExecuteGroup={clickExecuteGroup}
                        submit={submitting ? () => {
                        } : submit}
                        submitting={submitting}
                    />}/>
                </Routes>
            </Container>
        </>
    )
}
