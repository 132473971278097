export const apiElkus = process.env.REACT_APP_API_ELKUS
export const appRoot = process.env.REACT_APP_ROOT

export const TranslationContexts = {
  classificators: 'BBS.Classificators',
  eBank: 'BBS.EBankNew'
}
export const aPIPaths = {
  //AMC
  get_AMC_ALR: 'a/AMC/ALR/',
  post_AMC_LLO: 'a/AMC/LLO/',
  post_AMC_ADR: 'a/AMC/ADR/',
  post_AMC_SOD: 'a/AMC/SOD/',
  post_AMC_CAINF: 'a/AMC/CAINF/',
  post_AMC_BCA: 'a/AMC/BCA/',
  post_AMC_ACCSXML: 'a/AMC/ACCSXML/',
  post_AMC_ACCSXLSX: 'a/AMC/ACCSXLSX/',
  post_AMC_ACCLMTS: 'a/AMC/ACCLMTS/',
  get_AMC_GetCompany: 'a/AMC/GetCompany/',
  post_AMC_ADROD: 'a/AMC/ADROD/',
  get_AMC_GetAccountOverdraft: 'a/AMC/GetOverdraft/',
  get_AMC_Forex_Currency: 'a/AMC/GetForexCurrency/',
  get_AMC_Forex_Rate: 'a/AMC/GetForexRate',
  post_AMC_FOREXT_CONFIRM: 'a/AMC/ForexConfirm/',
  post_AMC_ForexDraft: 'a/AMC/ForexDraft',

  //CMC
  post_CMC_CIPTR: 'a/CMC/CIPTR/',
  post_CMC_PDR: 'a/CMC/PDR/',
  post_CMC_CIR: 'a/CMC/CIR/',
  post_CMC_VCR: 'a/CMC/VCR/',
  post_CMC_SCR: 'a/CMC/SCR/',
  post_CMC_DPDF: 'a/CMC/DPDF/',
  post_CMC_GetAccountBalanceXml: 'a/CMC/GetAccountBalanceXml/',

  //UTL
  post_UTL_GetLabels: 'a/UTL/GetLabels/',

  //UAC
  post_UAC_ESAR: 'a/UAC/ESAR',
  post_UAC_ESARSM: 'a/UAC/ESARSM',
  post_UAC_ESACR: 'a/UAC/ESACR',
  post_UAC_SMSR: 'a/UAC/SMSR',
  post_UAC_FullLogin: 'a/UAC/FullLogin',
  post_UAC_SimpleLogin: 'a/UAC/SimpleLogin',
  post_UAC_CheckLogin: 'a/UAC/CheckLoginStatus',
  post_UAC_CheckLoginJWE: 'a/UAC/CheckLoginStatusJwe',
  post_UAC_SMSGETPWD: 'a/UAC/SMSGETPWD',
  post_UAC_SMSAR: 'a/UAC/SMSAR',
  get_UAC_MACR: 'a/UAC/MACR',
  get_UAC_MALR: 'a/UAC/MALR',
  post_UAC_ATR: 'a/UAC/ATR',
  get_UAC_Logout: 'a/UAC/Logout',
  post_UAC_CPRQ: 'a/UAC/CPRQ',
  post_UAC_GPR: 'a/UAC/GPR',
  get_UAC_GetGGDataMY: 'a/UAC/GetGGDataMY',
  get_UAC_RFT: 'a/UAC/RFT',
  get_UAC_ESARGET: 'a/UAC/ESARGET',
  get_Login_Message: 'a/UAC/GetLoginMessage',

  //OMC
  post_OMC_ROL: 'a/OMC/ROL',
  post_OMC_ODL: 'a/OMC/ODL',
  postODLPaging: 'a/OMC/ODLPaging',
  post_OMC_UOLR: 'a/OMC/UOLR',
  post_OMC_DUOR: 'a/OMC/DUOR',
  post_OMC_PSUOR: 'a/OMC/PSUOR',
  post_OMC_SUORSMS: 'a/OMC/SUORSMS',
  post_OMC_SUOR: 'a/OMC/SUOR',
  get_OMC_BulkPaymentsFileList: 'a/OMC/BulkPaymentsFilelist',
  delete_OMC_BulkPaymentsFile: 'a/OMC/DeleteBulkPaymentFile',
  get_OMC_BulkPaymentsDetails: 'a/OMC/BulkPaymentsDetails',
  get_PMC_BulkPaymentsPreview: 'a/PMC/BulkPaymentsPreview',
  post_OMC_BulkPaymentsSign: 'a/OMC/BulkPaymentsSign',

  //PMC
  post_PMC_PTR: 'a/PMC/PTR',
  post_PMC_PVR: 'a/PMC/PVR',
  post_PMC_APR: 'a/PMC/APR',
  post_PMC_APSMSR: 'a/PMC/APSMSR',
  post_PMC_APESCR: 'a/PMC/APESCR',
  get_PMC_GetPayPersonTypes: 'a/PMC/GetPayPersonTypes',
  post_PMC_TTG: 'a/PMC/TTG',
  post_PMC_DTR: 'a/PMC/DTR',
  post_PMC_DGR: 'a/PMC/DGR',
  post_PMC_GGDR: 'a/PMC/GGDR',
  post_PMC_SGPVR: 'a/PMC/SGPVR',
  post_PMC_SDPVR: 'a/PMC/SDPVR',
  post_PMC_SPR: 'a/PMC/SPR',
  post_PMC_UTR: 'a/PMC/UTR',
  post_PMC_RTFGR: 'a/PMC/RTFGR',
  post_PMC_CGPSGNR: 'a/PMC/CGPSGNR',
  post_PMC_UPR: 'a/PMC/UPR',
  post_PMC_PUPR: 'a/PMC/PUPR',
  post_PMC_SUPR: 'a/PMC/SUPR',
  get_PMC_HistoricalPayments: 'a/PMC/GetHistoricalPayments',
  get_PMC_HistoricalPaymentById: 'a/PMC/GetHistoricalPaymentById',
  post_PMC_CHECK_IBAN: 'a/PMC/CheckIbanMember',
  post_GetFxRate: 'a/PMC/GetFxRate',
  get_GetCountryCurrencies: 'a/PMC/GetCountryCurrencies',
  get_GetPaymentPurposeCode: 'a/PMC/GetPaymentPurposeCode',
  get_GetBeneficiaryIdentificationCodeTypes: 'a/PMC/BeneficiaryCodeTypes',
  get_LengthIBAN: 'a/PMC/GetLengthIban',
  get_CheckIbanValid: 'a/PMC/ValidateIban',
  get_PMC_ForexPayments: 'a/PMC/ForexPayments',

  //DMC
  post_DMC_ADPDR: 'a/DMC/ADPDF',
  post_DMC_SPPDF: 'a/DMC/SPPDF',
  post_DMC_SDR: 'a/DMC/SDR',

  //FeeController
  post_Fee_LastFee: 'a/Fee/LastFee',

  //Fee
  get_Fee_List: 'a/Fee/list',
  get_FeePayment: 'a/Fee/GetFeePayment/',

  post_Messaging_Create: 'a/Messaging/Create',
  post_Messaging_Response: 'a/Messaging/ResponseMessage',
  get_Messaging_Chats: 'a/Messaging/Chats',
  get_Messaging_ChatMessages: 'a/Messaging/ChatMessages/',
  get_Messaging_NewMessagesCount: 'a/Messaging/NewMessagesCount',
  delete_Messaging_DeleteMessage: 'a/Messaging/DeleteMessage',

  get_Messaging_GetBroadCastList: 'a/Messaging/GetBroadCastList',
  get_Messaging_GetBroadCastMessage: 'a/Messaging/GetBroadCastMessage',
  get_Messaging_DeleteBroadCastMessage: 'a/Messaging/DeleteBroadCastMessage',

  //Translations
  get_Translations: 'a/Translations/index',
  get_Translations_CreateTranslation: 'a/Translations/GetCreateTranslation/',

  //KYC
  get_KYC: 'a/KYC/GetKyc',
  post_KYC: 'a/KYC/SetKyc',

  //Monitoring
  get_SessionLogs: 'a/Monitoring/SessionLogs/',

  //Deposit
  post_depositTermination: 'a/deposit/DepositTermination/',
  post_depositTerminationConfirm: 'a/deposit/DepositCompleteTermination/',
  post_depositTerminationCheck: 'a/deposit/DepositCompleteTerminationCheck',
  post_depositOffer: 'a/Deposit/GetDepositOffer',
  post_confirmDeposit: 'a/Deposit/ConfirmDeposit/',
  post_depositDocument: 'a/Deposit/GetDepositDocument',

  //AML
  get_AML_InternationalPayment: 'a/aml/InternationalPayment/',

  //Version
  get_Version: 'version.json'
}
